"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTextWidthElement = exports.getCanvasFontSize = exports.getTextWidth = void 0;
const ramda_1 = require("ramda");
exports.getTextWidth = (0, ramda_1.curry)(function (font, text) {
    // re-use canvas object for better performance
    const canvas = exports.getTextWidth.canvas ||
        (exports.getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    if (!context)
        return 0;
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
});
function getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
}
function getCanvasFontSize(el = document.body) {
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'Fira Sans';
    return `${fontWeight} ${fontSize} ${fontFamily}`;
}
exports.getCanvasFontSize = getCanvasFontSize;
exports.getTextWidthElement = (0, ramda_1.curry)((el, text) => (0, exports.getTextWidth)(getCanvasFontSize(el), text));
