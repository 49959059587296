// Call bootstrap scripts
import './application/bootstrap'

import { render } from 'react-dom'
import allSettled from 'promise.allsettled'

import '@fontsource/fira-sans/200.css'
import '@fontsource/fira-sans/400.css'
import '@fontsource/fira-sans/500.css'
import '@fontsource/fira-sans/600.css'
import '@fontsource/fira-sans/700.css'
import '@fontsource/fira-mono/400.css'
import '@fontsource/fira-mono/500.css'
import '@fontsource/fira-mono/700.css'

import { AllProviders } from './application/components/Providers/AllProviders'
import Dispatcher from './application/components/Dispatcher'
import { UnsupportedBrowser } from './domains/support/pages/UnsupportedBrowser'
import isSupportedBrowser from './utils/browserSupport'
import { onUIkitReady } from './styles/uikit'
import { inject } from './jsapi'

import { viewUnsupportedBrowser } from './store/storage'

allSettled.shim()

const root = document.getElementById('root')

inject()

onUIkitReady(() => {
  const application = (
    <AllProviders>
      <Dispatcher />
    </AllProviders>
  )

  const app = isSupportedBrowser() ? (
    application
  ) : viewUnsupportedBrowser() ? (
    application
  ) : (
    <UnsupportedBrowser />
  )
  render(app, root)
})
