import { UserSource } from '@exivity/data-layer'

import { unregisterUserWithSentry } from '../../../../utils/log'
import { createAsyncThunk } from '../../../../store/utils'
import { del, post } from '../../../../API'
import { authActions } from '../'
import { logoutSAML } from '../../SingleSignOn/SAML/authCycle'

export enum RevokeTokens {
  Me = 'me',
  All = '*'
}

const REVOKE = {
  [RevokeTokens.Me]: () => del('/auth/token'),
  [RevokeTokens.All]: () => post('/users/me/key')
}

// @todo SAML logout combined with revoke all
//  We do not support sign out with all devices in combination with SAML logout
//  as we SAML logout revokes our token which we need to call revoke all
export const logout = createAsyncThunk<void, RevokeTokens | undefined>(
  'auth/logout',
  (revokeToken = RevokeTokens.Me, { getState, dispatch }) => {
    unregisterUserWithSentry()

    const state = getState()
    const samlEnabled = false // configurationSelectors.isSamlEnabled(state)
    const currentUser = state.auth.currentUser

    // Logout through SAML if enabled
    if (samlEnabled && currentUser.attributes.source === UserSource.SAML) {
      // Calling SAML endpoint will revoke user token too
      return dispatch(logoutSAML()).then(() => {
        dispatch(authActions.updateIsAuthenticated(false))
      })
    } else {
      return REVOKE[revokeToken]().then(() => {
        dispatch(authActions.updateIsAuthenticated(false))
      })
    }
  }
)
