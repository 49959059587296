import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { themeColor, themeSpace } from '../Theme';
const StyledSeparator = styled.li `
  background-color: ${themeColor('dark')};
  height: ${themeSpace(2)};
  display: flex;
  align-items: center;

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid ${themeColor('darkGray')};
    margin: 0 ${themeSpace(2)};
  }
`;
export function Separator() {
    return (_jsx(StyledSeparator, Object.assign({ className: "nav__separator" }, { children: _jsx("hr", {}) })));
}
