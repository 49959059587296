var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import TextHighlighter from 'react-highlight-words';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
const _Columns = styled.div `
  display: flex;
  padding-left: ${fromTheme((theme) => theme.global.baseSpacing)}em;
  ${globalFont};
  font-size: ${fromTheme((theme) => (theme.global.sizes.base / 16) * 14)}em;

  &:hover,
  :focus {
    background-color: ${fromTheme((theme) => theme.colors.lightGray)};
  }

  ${({ maxWidth }) => maxWidth &&
    css `
      max-width: ${maxWidth}px;
    `}

  ${({ flex }) => flex &&
    css `
      > * {
        flex: ${flex};
      }
    `}
  
  ${({ width }) => width &&
    css `
      > * {
        width: ${typeof width === 'number' ? `${width}px` : width};
      }
    `}
`;
const _Column = styled.div `
  display: flex;
  align-items: center;
  ${({ flex }) => flex &&
    css `
      flex: ${flex};
    `}
  ${({ width }) => width &&
    css `
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}
`;
const Column = (_a) => {
    var { searchTerm, children } = _a, columnProps = __rest(_a, ["searchTerm", "children"]);
    const child = searchTerm && typeof children === 'string' ? (_jsx(TextHighlighter, { autoEscape: true, searchWords: searchTerm, textToHighlight: children })) : (children);
    return _jsx(_Column, Object.assign({}, columnProps, { children: child }));
};
const Columns = _Columns;
Columns.Column = Column;
export { Columns };
