import { observeProfilePartial } from '../../domains/my-profile/state'
import { debouncedPersistUserState } from '../user'

import { handleAuthChange } from '../../utils/auth'

import { stateObserver } from './stateObserver'

export const persistUserState = stateObserver({
  observe: (state) => ({
    user: observeProfilePartial(state.profile),
    reportsFilters: state.reports.filters,
    reportsGui: state.reports.gui
  }),
  onChange: debouncedPersistUserState
})

export const handlePermissionsChange = stateObserver({
  observe: (state) => ({ auth: state.auth }),
  onChange: ({ auth }) => handleAuthChange(auth)
})
