import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePurpose } from '../Theme';
const StyledSpinner = styled.svg `
  height: 40px;
  width: 40px;
  animation: rotate 2s linear infinite;
  z-index: 11;

  circle {
    stroke: ${themePurpose('primary')};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
export function Spinner({ className }) {
    return (_jsx(StyledSpinner, Object.assign({ className: className, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 50 50" }, { children: _jsx("circle", { cx: "25", cy: "25", r: "20", fill: "none", strokeWidth: "2" }) })));
}
