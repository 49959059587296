import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Highlighter from 'react-highlight-words';
import isEqual from 'lodash/isEqual';
import { ListItem } from '../../ListItem';
import { useSearchTerm } from '../../SearchableList';
export function DefaultItem({ data, index, style }) {
    const { items, value, onChange, labelAccessor, valueAccessor, noDataText } = data;
    const searchTerm = useSearchTerm();
    const item = items[index];
    const active = typeof item === 'object'
        ? isEqual(valueAccessor(item), value)
        : item === value;
    const label = labelAccessor(item);
    const hasData = label !== noDataText;
    const handleOnClick = useCallback((e) => {
        if (onChange && hasData) {
            const value = typeof item === 'object' ? valueAccessor(item) : item;
            onChange(value, e);
        }
    }, [hasData, valueAccessor, onChange, item]);
    return (_jsx(ListItem, Object.assign({ role: hasData ? 'listitem' : '', style: style, tabIndex: index + 1, active: active, noDataPlaceholder: !hasData, onClick: handleOnClick }, { children: _jsx(Highlighter, { autoEscape: true, searchWords: [searchTerm], textToHighlight: label }) })));
}
