import { listToMap } from '../map';
import { PARENT, CHILDREN } from './data';
import { getParents } from './traverse';
export function listToTreeMap(data, keyAccessor, parentKeyAccessor) {
    const map = listToMap(data, keyAccessor);
    Object.values(map).forEach((item) => {
        const parentKey = parentKeyAccessor(item);
        const parent = parentKey && map[parentKey];
        if (parent) {
            item[PARENT] = parent;
            const parentChildren = parent[CHILDREN];
            if (!parentChildren) {
                parent[CHILDREN] = [item];
            }
            else if (!parentChildren.includes(item)) {
                parentChildren.push(item);
            }
        }
    });
    Object.values(map).forEach((item) => {
        item.level = getParents(item).length + 1;
        item.getPlainData = function () {
            return data.find((plainItem) => keyAccessor(item) === keyAccessor(plainItem));
        };
    });
    return map;
}
