import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { themePriority, themeGlobal, themePurpose, themeFontSize, themePalette, matchColorFromPurpose } from '../Theme';
import { injectIf } from '../utils';
import { Spinner } from '../Spinner';
import { Progress } from '../Progress';
import { AppBarButton } from './AppBarButton';
import { AppBarDropdown } from './AppBarDropdown';
import { AppBarLink } from './AppBarLink';
import { AppBarAvatar, Separator } from './AppBarAvatar';
const Bar = styled.header `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${themeGlobal('headerHeight')}px;
  z-index: ${themePriority(5)};

  font-family: ${themeGlobal('fontFamily')};
  background-color: transparent;

  display: flex;
  align-items: center;
  flex-direction: row;
`;
const AppIcon = styled.div `
  min-width: 70px;
  max-width: 70px;
  height: ${themeGlobal('headerHeight')}px;

  display: flex;
  align-items: center;
  justify-content: center;

  circle {
    stroke: white;
  }
`;
const AppLogo = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${themePalette('neutral', 900)};
  height: ${themeGlobal('headerHeight')}px;
  min-width: 200px;
  max-width: 200px;
`;
const Title = styled.div `
  font-size: ${themeFontSize(3)};
  color: ${themeGlobal('inverseBrandTextColor')};
  margin: 0 ${themeGlobal('baseSpacing')}em;
  overflow: hidden;

  ${injectIf('isMessage', css `
      font-size: ${themeFontSize(2)};
    `)}

  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Options = styled.div `
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: ${themeGlobal('inverseBrandTextColor')};
  height: ${themeGlobal('headerHeight')}px;
`;
const MessageBackground = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${themeGlobal('headerHeight')}px;
  z-index: ${themePriority(5)};
  background-color: ${matchColorFromPurpose(500)};
  transition: opacity 0.5s ease;
  opacity: 0;

  &.appbar__message {
    opacity: 1;
    transition: opacity 0.5s ease;

    &--success {
      /* background-color: ${themePurpose('success')}; */
      background-color: ${themePalette('success', 500)};
    }

    &--danger {
      background-color: ${themePalette('danger', 500)};
    }
  }
`;
const Background = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${themeGlobal('headerHeight')}px;
  z-index: ${themePriority(5)};

  background-color: ${themePalette('brand', 500)};
`;
// @todo Improve spinner animation -> is only 30fps
function AppBar({ className, icon, logo, title, isLoading = false, progress = 0, message, children }) {
    const messageClassName = (type) => message && type === message.type
        ? `appbar__message appbar__message--${message.type}`
        : '';
    const text = message ? message.text : title;
    return (_jsxs(_Fragment, { children: [_jsx(Background, {}), _jsx(MessageBackground, { success: true, className: messageClassName('success') }), _jsx(MessageBackground, { danger: true, className: messageClassName('danger') }), _jsxs(Bar, Object.assign({ className: className }, { children: [_jsx(AppIcon, { children: isLoading ? _jsx(Spinner, {}) : icon }), _jsx(AppLogo, { children: logo }), _jsx(Title, Object.assign({ title: text, isMessage: !!message }, { children: text })), _jsx(Options, { children: children }), _jsx(Progress, { active: isLoading, percentage: progress })] }))] }));
}
AppBar.Button = AppBarButton;
AppBar.Dropdown = AppBarDropdown;
AppBar.Link = AppBarLink;
AppBar.Avatar = AppBarAvatar;
AppBar.Separator = Separator;
export { AppBar };
