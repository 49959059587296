import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { themeColor, themePurpose, themeSpace } from '../Theme';
import { injectIf } from '../utils';
import { Title } from './Title';
import { NavIcon } from './NavIcon';
import { NavGroupContext } from './NavBar';
// eslint-disable-next-line max-len
export const chevronRight = 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%3E%3C%2Fpolyline%3E%0A%3C%2Fsvg%3E';
const Group = styled.div `
  display: flex;
  align-items: center;
  padding: 0 ${themeSpace(2)};
  height: 64px;

  &:hover {
    background-color: ${themeColor('lightDark')};
  }
`;
const Options = styled.ul `
  padding: 10px 0;
  margin: 0;
  width: 100%;
`;
const StyledNavGroup = styled.li `
  display: flex;
  flex-direction: column;
  background-color: ${themeColor('dark')};
  color: ${themeColor('white')};
  text-decoration: none;
  text-transform: uppercase;

  ${Options} {
    display: none;
  }

  &.group--expand {
    ${Options} {
      display: block;
    }
  }

  ${injectIf('active', css `
      ${Group} {
        background-color: ${themeColor('lightDark')};
      }

      ${NavIcon} {
        color: ${themePurpose('brand')};
      }
    `)}
`;
const Chevron = styled.i.attrs({ className: 'nav-group__chev' }) `
  &::after {
    content: '';
    width: 1.5em;
    height: 1.5em;
    float: right;
    background-image: url(${chevronRight});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transform: rotate(180deg);
    transition-property: transform;
    transition-duration: 200ms;
    transition-delay: 0ms;
    transition-timing-function: ease-in-out;

    ${injectIf('open', css `
        transition-property: transform;
        transition-duration: 200ms;
        transition-delay: 0ms;
        transition-timing-function: ease-in-out;
        transform: rotate(270deg);
      `)};
  }
`;
const getIndexOfSelf = (node) => Array.from(node.parentElement.children).findIndex((child) => child === node);
export function NavGroup({ name, active = false, icon, children }) {
    const { openAtIndex, onClick } = useContext(NavGroupContext);
    const [index, setIndex] = useState(null);
    return (_jsxs(StyledNavGroup, Object.assign({ className: "navbar__group", tabIndex: -1, active: active, ref: (node) => {
            if (node) {
                setIndex(getIndexOfSelf(node));
            }
        } }, { children: [_jsxs(Group, Object.assign({ onClick: () => index !== null && onClick(index) }, { children: [_jsx(NavIcon, { children: icon }), _jsx(Title, { children: name }), _jsx(Chevron, { open: openAtIndex === index })] })), _jsx(Options, { children: React.Children.map(children, (child) => React.cloneElement(child, { isSubLink: true })) })] })));
}
