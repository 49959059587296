import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum SummaryPrintMode {
  Print = 'print',
  PrintAll = 'print-all'
}

const initialState = {
  summaryPrintMode: null as SummaryPrintMode | null
}

export const { actions, reducer } = createSlice({
  name: 'reports/api',
  initialState,
  reducers: {
    updateSummaryPrintMode(
      api,
      action: PayloadAction<SummaryPrintMode | null>
    ) {
      api.summaryPrintMode = action.payload
    }
  }
})
