/* eslint-disable */
/** Resource types generated from the schema */
export var UserGroupPermission;
(function (UserGroupPermission) {
    UserGroupPermission["All"] = "*";
    UserGroupPermission["ViewAudit"] = "view_audit";
    UserGroupPermission["ViewBudgets"] = "view_budgets";
    UserGroupPermission["ViewCogs"] = "view_cogs";
    UserGroupPermission["ViewLogs"] = "view_logs";
    UserGroupPermission["ViewReports"] = "view_reports";
    UserGroupPermission["ViewBilling"] = "view_billing";
    UserGroupPermission["ManageAccounts"] = "manage_accounts";
    UserGroupPermission["ManageDatasets"] = "manage_datasets";
    UserGroupPermission["ManageDataSources"] = "manage_data_sources";
    UserGroupPermission["ManageMetadataDefinitions"] = "manage_metadata_definitions";
    UserGroupPermission["ManageFiles"] = "manage_files";
    UserGroupPermission["ManageReports"] = "manage_reports";
    UserGroupPermission["ManageServices"] = "manage_catalogue";
    UserGroupPermission["ManageSettings"] = "manage_settings";
    UserGroupPermission["ManageUsers"] = "manage_users";
    UserGroupPermission["ManageWorkflows"] = "manage_workflows";
})(UserGroupPermission || (UserGroupPermission = {}));
export var NotificationSubscriptionType;
(function (NotificationSubscriptionType) {
    NotificationSubscriptionType["BudgetEvaluated"] = "budget_evaluated";
    NotificationSubscriptionType["ReportPublished"] = "report_published";
    NotificationSubscriptionType["WorkflowEnded"] = "workflow_ended";
})(NotificationSubscriptionType || (NotificationSubscriptionType = {}));
export var NotificationChannelType;
(function (NotificationChannelType) {
    NotificationChannelType["mail"] = "mail";
    NotificationChannelType["slack"] = "slack";
    NotificationChannelType["nexmo"] = "nexmo";
    NotificationChannelType["webhook"] = "webhook";
})(NotificationChannelType || (NotificationChannelType = {}));
export var SSOLoginMethod;
(function (SSOLoginMethod) {
    SSOLoginMethod["LocalUserOnly"] = "local_user_only";
    SSOLoginMethod["LocalUserLDAP"] = "local_user_or_ldap";
    SSOLoginMethod["LocalUserSAML"] = "local_user_or_saml";
    SSOLoginMethod["SAMLOnly"] = "saml_only";
    SSOLoginMethod["LocalUserLDAPSAML"] = "local_user_or_ldap_or_saml";
})(SSOLoginMethod || (SSOLoginMethod = {}));
export var TokenTTL;
(function (TokenTTL) {
    TokenTTL["OneHour"] = "1 hour";
    TokenTTL["FourHours"] = "4 hours";
    TokenTTL["OneDay"] = "1 day";
    TokenTTL["ThreeDays"] = "3 days";
    TokenTTL["OneWeek"] = "1 week";
})(TokenTTL || (TokenTTL = {}));
export var DecimalSeparator;
(function (DecimalSeparator) {
    DecimalSeparator["Dot"] = ".";
    DecimalSeparator["Comma"] = ",";
})(DecimalSeparator || (DecimalSeparator = {}));
export var CSVDelimiter;
(function (CSVDelimiter) {
    CSVDelimiter["Comma"] = ",";
    CSVDelimiter["Colon"] = ":";
    CSVDelimiter["Semicolon"] = ";";
    CSVDelimiter["Tab"] = "\\t";
    CSVDelimiter["Pipe"] = "|";
})(CSVDelimiter || (CSVDelimiter = {}));
export var CSVDecimalSeparator;
(function (CSVDecimalSeparator) {
    CSVDecimalSeparator["Comma"] = ",";
    CSVDecimalSeparator["Dot"] = ".";
})(CSVDecimalSeparator || (CSVDecimalSeparator = {}));
export var ThousandSeparator;
(function (ThousandSeparator) {
    ThousandSeparator["None"] = "";
    ThousandSeparator["Space"] = " ";
    ThousandSeparator["Dot"] = ".";
    ThousandSeparator["Comma"] = ",";
})(ThousandSeparator || (ThousandSeparator = {}));
export var DateFormat;
(function (DateFormat) {
    DateFormat["DASH_DMY"] = "dd-MM-yyyy";
    DateFormat["DASH_MDY"] = "MM-dd-yyyy";
    DateFormat["DASH_YMD"] = "yyyy-MM-dd";
    DateFormat["SLASH_DMY"] = "dd/MM/yyyy";
    DateFormat["SLASH_MDY"] = "MM/dd/yyyy";
    DateFormat["SLASH_YMD"] = "yyyy/MM/dd";
})(DateFormat || (DateFormat = {}));
export var ReportStartMonth;
(function (ReportStartMonth) {
    ReportStartMonth[ReportStartMonth["January"] = 1] = "January";
    ReportStartMonth[ReportStartMonth["February"] = 2] = "February";
    ReportStartMonth[ReportStartMonth["March"] = 3] = "March";
    ReportStartMonth[ReportStartMonth["April"] = 4] = "April";
    ReportStartMonth[ReportStartMonth["May"] = 5] = "May";
    ReportStartMonth[ReportStartMonth["June"] = 6] = "June";
    ReportStartMonth[ReportStartMonth["July"] = 7] = "July";
    ReportStartMonth[ReportStartMonth["August"] = 8] = "August";
    ReportStartMonth[ReportStartMonth["September"] = 9] = "September";
    ReportStartMonth[ReportStartMonth["October"] = 10] = "October";
    ReportStartMonth[ReportStartMonth["November"] = 11] = "November";
    ReportStartMonth[ReportStartMonth["December"] = 12] = "December";
})(ReportStartMonth || (ReportStartMonth = {}));
export var PasswordPolicy;
(function (PasswordPolicy) {
    PasswordPolicy["Length"] = "length";
    PasswordPolicy["LengthDictionary"] = "length_dictionary";
    PasswordPolicy["LengthDictionaryEntropy"] = "length_dictionary_entropy";
})(PasswordPolicy || (PasswordPolicy = {}));
export var MailEncryption;
(function (MailEncryption) {
    MailEncryption["SSL"] = "ssl";
    MailEncryption["TLS"] = "tls";
})(MailEncryption || (MailEncryption = {}));
export var LDAPEncryption;
(function (LDAPEncryption) {
    LDAPEncryption["SSL"] = "ssl";
    LDAPEncryption["TLS"] = "tls";
})(LDAPEncryption || (LDAPEncryption = {}));
export var LogSeverity;
(function (LogSeverity) {
    LogSeverity[LogSeverity["DebugX"] = 0] = "DebugX";
    LogSeverity[LogSeverity["Debug"] = 1] = "Debug";
    LogSeverity[LogSeverity["Info"] = 2] = "Info";
    LogSeverity[LogSeverity["Warn"] = 3] = "Warn";
    LogSeverity[LogSeverity["Error"] = 4] = "Error";
    LogSeverity[LogSeverity["Fatal"] = 5] = "Fatal";
    LogSeverity[LogSeverity["Internal"] = 6] = "Internal";
    LogSeverity[LogSeverity["User"] = 7] = "User";
    LogSeverity[LogSeverity["System"] = 8] = "System";
})(LogSeverity || (LogSeverity = {}));
export var WorkflowStepLogStatus;
(function (WorkflowStepLogStatus) {
    WorkflowStepLogStatus["Started"] = "started";
    WorkflowStepLogStatus["Success"] = "success";
    WorkflowStepLogStatus["Failed"] = "failed";
    WorkflowStepLogStatus["TimedOut"] = "timed_out";
    WorkflowStepLogStatus["Invalid"] = "invalid";
    WorkflowStepLogStatus["InternalError"] = "internal_error";
})(WorkflowStepLogStatus || (WorkflowStepLogStatus = {}));
export var WorkflowScheduleFrequency;
(function (WorkflowScheduleFrequency) {
    WorkflowScheduleFrequency["Hourly"] = "hourly";
    WorkflowScheduleFrequency["Daily"] = "daily";
    WorkflowScheduleFrequency["Monthly"] = "monthly";
})(WorkflowScheduleFrequency || (WorkflowScheduleFrequency = {}));
export var LicenceStatus;
(function (LicenceStatus) {
    LicenceStatus["Valid"] = "valid";
    LicenceStatus["Invalid"] = "invalid";
    LicenceStatus["Expired"] = "expired";
    LicenceStatus["Pending"] = "pending";
    LicenceStatus["NotFound"] = "notfound";
})(LicenceStatus || (LicenceStatus = {}));
export var WorkflowRunStatus;
(function (WorkflowRunStatus) {
    WorkflowRunStatus["Started"] = "started";
    WorkflowRunStatus["Success"] = "success";
    WorkflowRunStatus["Failed"] = "failed";
    WorkflowRunStatus["TimedOut"] = "timed_out";
    WorkflowRunStatus["Invalid"] = "invalid";
    WorkflowRunStatus["InternalError"] = "internal_error";
})(WorkflowRunStatus || (WorkflowRunStatus = {}));
export var BudgetInterval;
(function (BudgetInterval) {
    BudgetInterval["Monthly"] = "month";
    BudgetInterval["Quarterly"] = "quarter";
    BudgetInterval["Yearly"] = "year";
})(BudgetInterval || (BudgetInterval = {}));
export var BudgetMetric;
(function (BudgetMetric) {
    BudgetMetric["Charge"] = "charge";
    BudgetMetric["Cogs"] = "cogs";
})(BudgetMetric || (BudgetMetric = {}));
export var BudgetItemKind;
(function (BudgetItemKind) {
    BudgetItemKind["Account"] = "account";
})(BudgetItemKind || (BudgetItemKind = {}));
export var BudgetItemStatus;
(function (BudgetItemStatus) {
    BudgetItemStatus["Regular"] = "regular";
    BudgetItemStatus["Excluded"] = "excluded";
    BudgetItemStatus["Remainder"] = "remainder";
})(BudgetItemStatus || (BudgetItemStatus = {}));
export var BudgetItemDistribution;
(function (BudgetItemDistribution) {
    BudgetItemDistribution["None"] = "none";
    BudgetItemDistribution["Shared"] = "shared";
    BudgetItemDistribution["Even"] = "even";
})(BudgetItemDistribution || (BudgetItemDistribution = {}));
export var BudgetItemFilter;
(function (BudgetItemFilter) {
    BudgetItemFilter["None"] = "none";
    BudgetItemFilter["Category"] = "servicecategory";
    BudgetItemFilter["Service"] = "service";
})(BudgetItemFilter || (BudgetItemFilter = {}));
export var ServiceType;
(function (ServiceType) {
    ServiceType["ServiceNameInHeader"] = "service_name_in_header";
    ServiceType["ServiceNameInData"] = "service_name_in_data";
})(ServiceType || (ServiceType = {}));
export var ServiceInterval;
(function (ServiceInterval) {
    ServiceInterval["Individually"] = "individually";
    ServiceInterval["Day"] = "day";
    ServiceInterval["Month"] = "month";
})(ServiceInterval || (ServiceInterval = {}));
export var ServiceChargeType;
(function (ServiceChargeType) {
    ServiceChargeType["Manual"] = "manual";
    ServiceChargeType["Automatic"] = "automatic";
    ServiceChargeType["ManualTieredStandard"] = "manual_tiered_standard";
    ServiceChargeType["ManualTieredInherited"] = "manual_tiered_inherited";
    ServiceChargeType["None"] = "none";
    ServiceChargeType["Other"] = "other";
})(ServiceChargeType || (ServiceChargeType = {}));
export var ServiceChargeModel;
(function (ServiceChargeModel) {
    ServiceChargeModel["Peak"] = "peak";
    ServiceChargeModel["Average"] = "average";
    ServiceChargeModel["SpecificDay"] = "specific_day";
    ServiceChargeModel["LastDay"] = "last_day";
})(ServiceChargeModel || (ServiceChargeModel = {}));
export var ServiceCogsType;
(function (ServiceCogsType) {
    ServiceCogsType["None"] = "none";
    ServiceCogsType["Manual"] = "manual";
    ServiceCogsType["Automatic"] = "automatic";
    ServiceCogsType["ManualTieredStandard"] = "manual_tiered_standard";
    ServiceCogsType["ManualTieredInherited"] = "manual_tiered_inherited";
})(ServiceCogsType || (ServiceCogsType = {}));
export var ServiceProrationType;
(function (ServiceProrationType) {
    ServiceProrationType["Full"] = "full";
    ServiceProrationType["None"] = "none";
})(ServiceProrationType || (ServiceProrationType = {}));
export var AdjustmentType;
(function (AdjustmentType) {
    AdjustmentType["Absolute"] = "absolute";
    AdjustmentType["Relative"] = "relative";
})(AdjustmentType || (AdjustmentType = {}));
export var AdjustmentTarget;
(function (AdjustmentTarget) {
    AdjustmentTarget["Charge"] = "charge";
    AdjustmentTarget["Quantity"] = "quantity";
})(AdjustmentTarget || (AdjustmentTarget = {}));
export var UserAccountAccessType;
(function (UserAccountAccessType) {
    UserAccountAccessType["All"] = "all";
    UserAccountAccessType["Custom"] = "custom";
})(UserAccountAccessType || (UserAccountAccessType = {}));
export var UserSource;
(function (UserSource) {
    UserSource["LOCAL"] = "local";
    UserSource["LDAP"] = "ldap";
    UserSource["SAML"] = "saml";
})(UserSource || (UserSource = {}));
export var ServiceSubscriptionAltInterval;
(function (ServiceSubscriptionAltInterval) {
    ServiceSubscriptionAltInterval["Day"] = "day";
    ServiceSubscriptionAltInterval["Month"] = "month";
    ServiceSubscriptionAltInterval["Year"] = "year";
})(ServiceSubscriptionAltInterval || (ServiceSubscriptionAltInterval = {}));
export var ServiceSubscriptionType;
(function (ServiceSubscriptionType) {
    ServiceSubscriptionType["Recurring"] = "recurring";
    ServiceSubscriptionType["OneOff"] = "one_off";
})(ServiceSubscriptionType || (ServiceSubscriptionType = {}));
export var WorkflowStepType;
(function (WorkflowStepType) {
    WorkflowStepType["Core"] = "proximity";
    WorkflowStepType["RunExtractor"] = "extract";
    WorkflowStepType["RunTransformer"] = "transform";
    WorkflowStepType["PrepareReport"] = "prepare_report";
    WorkflowStepType["PublishReport"] = "publish_report";
    WorkflowStepType["EvaluateBudget"] = "evaluate_budget";
    WorkflowStepType["Execute"] = "execute";
})(WorkflowStepType || (WorkflowStepType = {}));
export var WorkflowStepSplit;
(function (WorkflowStepSplit) {
    WorkflowStepSplit["Daily"] = "daily";
    WorkflowStepSplit["Monthly"] = "monthly";
})(WorkflowStepSplit || (WorkflowStepSplit = {}));
export var ColumnStatus;
(function (ColumnStatus) {
    ColumnStatus["Ok"] = "ok";
    ColumnStatus["Invalid"] = "invalid";
})(ColumnStatus || (ColumnStatus = {}));
export const enums = {
    NotificationSubscriptionType,
    NotificationChannelType,
    SSOLoginMethod,
    TokenTTL,
    DecimalSeparator,
    CSVDelimiter,
    CSVDecimalSeparator,
    ThousandSeparator,
    DateFormat,
    ReportStartMonth,
    PasswordPolicy,
    WorkflowStepLogStatus,
    WorkflowScheduleFrequency,
    LicenceStatus,
    WorkflowRunStatus,
    BudgetInterval,
    BudgetMetric,
    BudgetItemKind,
    BudgetItemStatus,
    BudgetItemDistribution,
    BudgetItemFilter,
    ServiceType,
    ServiceInterval,
    ServiceChargeType,
    ServiceCogsType,
    ServiceProrationType,
    AdjustmentType,
    AdjustmentTarget,
    UserAccountAccessType,
    UserSource,
    ServiceSubscriptionType,
    WorkflowStepType,
    ColumnStatus
};
