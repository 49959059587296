import { useEffect } from 'react'
import { Button, Flex, Alert } from '@exivity/ui'
import { compose } from '@exivity/fp'
import { translate } from '@exivity/translations'
import { useForceUpdate } from '@exivity/hooks'
import { useSelector, useDispatch } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { differenceInMilliseconds } from 'date-fns'

import { authThunks, authSelectors } from '../../state'
import { useLogout } from '../../hooks'

const ALMOST_EXPIRING_THRESHOLD = 30 * 60 * 1000
const BUFFER = 2000

enum TokenExpirationStatus {
  Active = 'active',
  AlmostExpiring = 'almost_expiring',
  Expired = 'expired'
}

const getExpirationMs = (timestamp: number) =>
  differenceInMilliseconds(timestamp, new Date())

const getExpirationTimestamp = (token: string | null) =>
  token ? jwtDecode<{ exp: number }>(token).exp * 1000 : Date.now()

const getExpirationStatus = (milliseconds: number) => {
  if (milliseconds <= 0) return TokenExpirationStatus.Expired
  if (milliseconds - ALMOST_EXPIRING_THRESHOLD <= 0)
    return TokenExpirationStatus.AlmostExpiring
  return TokenExpirationStatus.Active
}

const getMillisecondsToExpiration = compose(
  getExpirationMs,
  getExpirationTimestamp
)

const getTokenExpirationStatus = compose(
  getExpirationStatus,
  getMillisecondsToExpiration
)

const getMillisecondsTillNextStatusCheck = (token: string) =>
  ({
    active:
      getMillisecondsToExpiration(token) - ALMOST_EXPIRING_THRESHOLD + BUFFER,
    almost_expiring: getMillisecondsToExpiration(token) + BUFFER,
    expired: 100
  }[getTokenExpirationStatus(token)])

function useTokenExpirationStatus() {
  const token = useSelector(authSelectors.getToken)
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      forceUpdate()
    }, getMillisecondsTillNextStatusCheck(token))

    return () => clearTimeout(timeoutId)
  }, [token, forceUpdate])

  return getTokenExpirationStatus(token)
}

export function TokenExpirationWarning() {
  const tokenStatus = useTokenExpirationStatus()
  const refreshingSession = useSelector(authSelectors.isRefreshingSession)
  const dispatch = useDispatch()
  const logout = useLogout()

  if (tokenStatus === TokenExpirationStatus.Active) {
    return null
  }

  return (
    <Alert warning closable={false}>
      <Flex justifyContent="space-between" alignItems="center">
        {tokenStatus === TokenExpirationStatus.AlmostExpiring && (
          <>
            {translate('Your session is about to expire.')}
            <Button
              warning
              tiny
              disabled={refreshingSession}
              onClick={() => {
                dispatch(authThunks.refreshToken())
              }}
            >
              {translate('Refresh Session')}
            </Button>
          </>
        )}
        {tokenStatus === TokenExpirationStatus.Expired && (
          <>
            {translate('Your session has expired.')}
            <Button warning tiny onClick={logout}>
              {translate('Logout')}
            </Button>
          </>
        )}
      </Flex>
    </Alert>
  )
}
