var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { format as dateFormat } from 'date-fns';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { translate } from '@exivity/translations';
import styled from 'styled-components';
import { SelectInput } from '../Input/SelectInput';
import { ModePicker } from '../Calendar/ModePicker';
import { DateCalendarV2 } from '../Calendar/DateCalendarV2';
import { CalendarMode } from '../Calendar/CalendarModes';
import { useFieldContext } from '../Field/context';
import { Dropdown } from '../Dropdown';
import { withExtendableProps } from '../ExtendProps';
import { themeSpace } from '../Theme';
const DatePickerDropDown = styled(Dropdown) `
  .dropdown__drop {
    box-sizing: border-box;
    padding: ${themeSpace(2)};
    width: 340px;
  }
`;
export function _DateFilter(_a) {
    var { name, format = 'yyyyMMdd', className, placeholder = translate('Choose date'), required, disabled, placement = 'bottom-start', mode = CalendarMode.Months, value, onModeChange, onChange = identity, onClear, offset } = _a, purposes = __rest(_a, ["name", "format", "className", "placeholder", "required", "disabled", "placement", "mode", "value", "onModeChange", "onChange", "onClear", "offset"]);
    const [isOpen, toggleOpen] = useToggle(false);
    const { id } = useFieldContext();
    const calendar = (_jsx(DateCalendarV2, { mode: mode, offset: offset, value: value, onChange: (date, mode) => {
            onChange(date, mode);
            toggleOpen();
        }, onClear: onClear ? juxt([onClear, toggleOpen]) : undefined }));
    return (_jsx(DatePickerDropDown, Object.assign({ className: className, placement: placement, disabled: disabled, reference: _jsx(SelectInput, Object.assign({ id: id, name: name, value: value ? dateFormat(value, format) : '', outlined: true, secondary: true, placeholder: placeholder, required: required, disabled: disabled }, purposes)), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: onModeChange ? (_jsx(ModePicker, Object.assign({ value: mode, onChange: onModeChange }, { children: calendar }))) : (calendar) })));
}
export const DateFilter = withExtendableProps('DateFilter')(_DateFilter);
