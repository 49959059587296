"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeTranslator = void 0;
const lodash_1 = require("lodash");
const fp_1 = require("lodash/fp");
// We match anything inbetween curly braces.
const placeholderRegex = /({+.*?}+)/gm;
const getPlaceholders = (text) => text.match(placeholderRegex) || [];
// If we find more than one enclosing set of curly braces it means it is cancelled.
const canceledPlaceholderRegex = /^[{]{2,}.*[}]{2,}$/;
const isCanceledPlaceholder = (placeholder) => canceledPlaceholderRegex.test(placeholder);
const uncancelPlaceholder = (text, placeholder) => {
    // We uncancel canceled placeholders by removing one of te pairs of curly braces.
    return text.replace(placeholder, placeholder.slice(1, -1));
};
const uncancelPlaceholders = (placeholders) => {
    return (text) => placeholders.reduce(uncancelPlaceholder, text);
};
const replacePlaceholders = (placeholders, values) => {
    return (text) => placeholders.reduce((text, param, index) => text.replace(param, String(values[index])), text);
};
// If we dont do this -> markdown used in translations wont work anymore
function convertTemplateLiteralToNormalString(str) {
    return (' ' + str).trim();
}
function makeTranslator(translation) {
    return function translate(text, ...values) {
        var _a;
        const [canceledPlaceholders, placeholders] = (0, lodash_1.partition)(getPlaceholders(text), isCanceledPlaceholder);
        return (0, fp_1.compose)(convertTemplateLiteralToNormalString, replacePlaceholders(placeholders, values), uncancelPlaceholders(canceledPlaceholders))(((_a = translation === null || translation === void 0 ? void 0 : translation.translations) === null || _a === void 0 ? void 0 : _a[text]) || text);
    };
}
exports.makeTranslator = makeTranslator;
