import { browserHistory } from '@exivity/routing'

import { createAsyncThunk } from '../../../../store/utils'
import { workThunks } from '../../../work/thunks'
import { post, put } from '../../../../API/fetch'

type ArgsResetPassword = {
  token: string
  username: string
  password: string
  verifyPassword: string
}

export const resetPassword = createAsyncThunk<void, ArgsResetPassword>(
  'auth/resetPassword',
  (
    { token, username, password, verifyPassword },
    { dispatch, extra: { translate } }
  ) => {
    const body = {
      token,
      username,
      password,
      password_confirmation: verifyPassword
    }
    put('/auth/resetpassword', {}, body).then(() => {
      dispatch(
        workThunks.showSuccessMessage(translate('Password has been reset.'))
      )
      browserHistory.push('/login')
    })
  }
)

export const requestResetPassword = createAsyncThunk<void, string>(
  'auth/resetPassword',
  (email, { dispatch, extra: { translate } }) => {
    post('/auth/resetpassword', {}, { email_address: email }).then(() => {
      dispatch(
        workThunks.showSuccessMessage(
          translate(
            "If there's an account associated with {email}, we'll send a password reset email.",
            email
          )
        )
      )
    })
  }
)
