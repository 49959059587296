import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { prop } from '@exivity/fp';
import { globalFont, isBrightColor } from '../utils';
import { useTheme, themeGlobal, themeSpace, useIsSmallScreen } from '../Theme';
export const Content = styled.main `
  box-sizing: border-box;
  display: block;
  float: right;
  position: relative;
  top: ${themeGlobal('headerHeight')}px;
  min-height: ${themeGlobal('contentHeight')};
  width: ${prop('width')};
  padding: ${themeSpace(2)};

  @media print {
    float: none;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: white;
  }
`;
const rgbComponents = (hex) => `${parseInt(hex.substr(1, 2), 16)},
   ${parseInt(hex.substr(3, 2), 16)},
   ${parseInt(hex.substr(5, 2), 16)}`;
const Container = styled.div `
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  ${globalFont};
`;
export function Shell({ className, appBar, navBar, appCss, navCollapsed = false, children }) {
    var _a, _b;
    const appColour = (_b = (_a = useTheme()) === null || _a === void 0 ? void 0 : _a.colors.brand[500]) !== null && _b !== void 0 ? _b : null;
    const isSmallScreen = useIsSmallScreen();
    return (_jsxs(_Fragment, { children: [appColour && (_jsx("style", { children: `
          html,
          body,
          #root {
            position: relative;
            margin: 0 !important;
            padding: 0 !important;
            min-height: 100%;
            min-width: 100%;
          }
          
          :root {
            --app-colour: ${appColour};
            --app-colour-components: ${rgbComponents(appColour)};
            --app-colour-inverse: ${isBrightColor(appColour) ? 'black' : 'white'};
            ${isBrightColor(appColour) ? '--app-colour-override: black;' : ''};
        }` })), appCss && _jsx("style", { children: appCss }), _jsxs(Container, Object.assign({ className: className }, { children: [appBar, navBar ? navBar : null, _jsx(Content, Object.assign({ width: !navBar
                            ? '100%'
                            : navCollapsed || isSmallScreen
                                ? 'calc(100% - 70px)'
                                : 'calc(100% - 270px)' }, { children: children }))] }))] }));
}
