import styled from 'styled-components';
import { Link } from '@exivity/routing';
import { themeColor } from '../Theme';
export const AppBarLink = styled(Link) `
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: ${themeColor('black')};
  font-size: 0.875rem;

  &::before {
    width: 100%;
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    border-bottom: 1px solid #333;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-out;
  }

  &:hover {
    color: ${themeColor('black')};
    text-decoration: none;

    &::before {
      transform: scale(1);
      transition: transform 0.3s ease-out;
    }
  }
`;
