import { detect } from "detect-browser";

const supportedBrowsers = {
  chrome: 59,
  edge: 16,
  opera: 46,
  firefox: 65,
  "edge-chromium": 80,
  safari: 10,

  // Let's not even talk about IE
};

const isSupportedBrowser = () => {
  const browser = detect();

  if (!browser) {
    return false;
  }

  if (!supportedBrowsers[browser.name]) {
    return false;
  }

  return parseFloat(browser.version) >= supportedBrowsers[browser.name];
};

export const eventPath = (evt) => {
  const path = (evt.composedPath && evt.composedPath()) || evt.path;

  const target = evt.target;

  if (path != null) {
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  const getParents = (node, memo) => {
    memo = memo || [];
    const parentNode = node.parentNode;

    if (!parentNode) {
      return memo;
    }
    return getParents(parentNode, memo.concat(parentNode));
  };

  return [target].concat(getParents(target), window);
};

export default isSupportedBrowser;
