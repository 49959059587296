var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';
import { concat, flip, findIndex, equals } from 'ramda';
import { Legend } from './Legend';
import { getPaletteColor } from './config';
const attach4D = flip(concat)('4D');
const chartOptions = {
    padding: 0.3,
    ariaLabel: 'CUPR Overview Bar Chart',
    barAriaLabel: (e) => `${e.id}: ${e.formattedValue} in dataset: ${e.indexValue}`,
    role: 'img',
    margin: {
        top: 20,
        right: 0,
        bottom: 40,
        left: 40
    },
    indexBy: 'date',
    axisTop: null,
    axisRight: null,
    axisBottom: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0
    },
    axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0
    },
    labelTextColor: '#ffffff',
    labelSkipWidth: 12,
    labelSkipHeight: 12
};
const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 500px;
`;
export const BarChart = (_a) => {
    var { data, keys } = _a, rest = __rest(_a, ["data", "keys"]);
    const [highlightKey, setHighlightKey] = useState(null);
    return (_jsxs(_Fragment, { children: [_jsx(Container, { children: _jsx(ResponsiveBar, Object.assign({ data: data, keys: keys, colorBy: "id", colors: ({ id }) => {
                        const index = findIndex(equals(id), keys);
                        return highlightKey === id || highlightKey === null
                            ? getPaletteColor(index)
                            : attach4D(getPaletteColor(index));
                    } }, rest, chartOptions)) }), _jsx(Legend, { keys: keys, onChange: setHighlightKey })] }));
};
