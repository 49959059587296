var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { setMonth, format } from 'date-fns';
import { CalendarMode } from '../CalendarMode';
import { StyledTimeUnits, TimeUnit } from '../shared';
export const StyledMonths = styled(StyledTimeUnits) `
  li {
    width: calc(33.33% - 5px);
  }
`;
export function Months(_a) {
    var { value, browserDate, onHoverItem, onEndHoverItem } = _a, rest = __rest(_a, ["value", "browserDate", "onHoverItem", "onEndHoverItem"]);
    function renderMonth(_, month) {
        const thisMonth = setMonth(browserDate, month);
        return (_jsx("span", Object.assign({ "data-testid": "calendar-months-month" }, { children: _jsx(TimeUnit, Object.assign({ date: thisMonth, mode: CalendarMode.Months, value: value, onHover: onHoverItem, onStopHovering: onEndHoverItem }, rest, { children: format(thisMonth, 'MMM') })) }), month));
    }
    return (_jsx(StyledMonths, Object.assign({ "data-testid": "calendar-months" }, { children: new Array(12).fill(null).map(renderMonth) })));
}
