import { insertIf } from "../../../utils/misc";
import { NOT_AVAILABLE_CHARACTER } from "../.";
import auth from "../../../utils/auth";
import { UserGroupPermission } from "../../../data/types";

export default (report, includeCogsAndNet) => {
  if (!report) {
    return [];
  }

  // For testing purposes in if statement
  if (includeCogsAndNet === undefined) {
    includeCogsAndNet = auth.can(UserGroupPermission.ViewCogs);
  }

  const instances = report.reduce((record, next) => {
    const key = next.service_id + '-' + next.instance_value

    if (!record[key]) {
      const cogsAndNet = {
        cogs: next.total_cogs,
        net: next.total_net
      }

      record[key] = {
        id: next.originalValue || next.instance_value,
        key: next.key,
        type: 'instance',
        service: next.service_description,
        chargeType: next.service_charge_type,
        cogsType: next.service_cogs_type,
        prorationType: next.proration_type,
        interval: next.interval,
        instance: next.instance_value,
        quantity: next.total_quantity,
        unit: next.service_unit_label,
        charge: next.total_charge,
        avgUnitBasedRate: next.service_charge_type === 'none'
          ? NOT_AVAILABLE_CHARACTER
          : next.avg_unit_based_rate,
        usageCol: next.breakdown,
        ...insertIf(includeCogsAndNet, cogsAndNet)
      }
    } else {
      if (includeCogsAndNet) {
        record[key].cogs = record[key].cogs + next.total_cogs;
        record[key].net = record[key].net + next.total_net;
      }

      record[key].quantity = record[key].quantity + next.total_quantity
      record[key].charge = record[key].charge + next.total_charge
    }

    return record;
  }, {});

  // Calculate rateAvg and then return
  return Object.values(instances);
};
