import { lensProp, map, over } from 'ramda';
// the API uses these operators for filtering
const opTransforms = {
    equal: (value) => `=${String(value)}`,
    gt: (value) => `>${String(value)}`,
    lt: (value) => `<${String(value)}`,
    gte: (value) => `>=${String(value)}`,
    lte: (value) => `<=${String(value)}`
};
const transform = map((filter) => {
    if (isAttributeFilterSpecifier(filter)) {
        return {
            kind: 'attribute',
            attribute: filter.attribute,
            op: 'equal',
            value: opTransforms[filter.op](filter.value)
        };
    }
    return filter;
});
function hasFilterSpecifiers(args) {
    return Object.prototype.hasOwnProperty.call(args, 'filter');
}
function isAttributeFilterSpecifier(args) {
    return (args === null || args === void 0 ? void 0 : args.kind) === 'attribute';
}
export const applyAPIQueryFilters = (qe) => {
    if (hasFilterSpecifiers(qe)) {
        return over(lensProp('filter'), transform, qe);
    }
    return qe;
};
