import styled from 'styled-components';
import { globalFont } from '../utils';
import { fromTheme, themePalette, _themeFontSize, _themeFontWeight } from '../Theme';
export const Variations = {
    Default: styled.span `
    td,
    th {
      ${globalFont};
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      font-weight: ${_themeFontWeight('medium')};
      font-size: ${_themeFontSize('small')};
    }

    th {
      color: ${themePalette('neutral', 700)};
      font-weight: normal;
      text-transform: uppercase;
    }

    tfoot td {
      color: ${themePalette('neutral', 700)};
      font-weight: normal;
      text-transform: uppercase;
    }
  `,
    ActionList: styled.span `
    --cell-padding: ${fromTheme((theme) => theme.space[1])};

    tbody td {
      border-bottom: 1px solid ${themePalette('neutral', 200)};
    }

    td {
      padding-top: ${fromTheme((theme) => theme.space[2])};
      padding-bottom: ${fromTheme((theme) => theme.space[2])};
      overflow: visible;
    }

    @media screen and (max-width: ${fromTheme((theme) => theme.breakpoints[0])}) {
      --padding-bottom: ${fromTheme((theme) => theme.space[2])};

      td:nth-child(2) {
        position: relative;
        .actions {
          position: absolute;
          left: 0;
          top: 0;
          height: calc(100% - var(--cell-padding) - var(--padding-bottom));
          width: 100%;
          padding-bottom: var(--padding-bottom);
          display: flex;
          flex-direction: column-reverse;
          align-items: center;

          > * {
            --action-spacing: ${fromTheme((theme) => theme.space[2])};
            margin: var(--action-spacing);
          }
        }
      }
    }
  `
};
