"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pipe = void 0;
/**
 * @description (((a, b, …, n) → o), (o → p), …, (x → y), (y → z)) → ((a, b, …, n) → z
 */
function pipe(...fns) {
    return (x) => fns.reduce((v, f) => f(v), x);
}
exports.pipe = pipe;
