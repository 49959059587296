import { useSystemConfiguration } from '@exivity/data-layer'

import './footer.css'

export const Footer = () => {
  const appName = useSystemConfiguration().attributes.APP_NAME || ''

  return (
    <footer className="ex-footer">
      <div>
        <span>
          &copy; {new Date().getFullYear()} {appName}
        </span>
      </div>
    </footer>
  )
}
