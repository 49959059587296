var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useFileReader } from '@exivity/hooks';
import { MdAdd, MdDelete } from 'react-icons/md';
import { fromTheme, themePalette } from '../Theme';
import { ProgressBar } from '../Progress';
import { Icon } from '../Icon';
import { useFieldContext } from '../Field/context';
const UploadBox = styled.div `
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
  width: 100px;
  padding: 10px;

  background-color: ${themePalette('neutral', 200)};
  border: 2px dashed
    ${({ error }) => error
    ? fromTheme((theme) => theme.global.purposes.danger)
    : fromTheme((theme) => theme.colors.gray)};
  border-radius: 3px;
  transition: border-color 0.3s ease;
  /* cursor: pointer; */
  outline: none;

  &:hover,
  &:focus {
    border-color: ${themePalette('primary', 500)};
    background-color: ${themePalette('primary', 100)};
    ${Icon} {
      color: ${themePalette('primary', 600)};
    }
  }

  ${Icon} {
    color: ${fromTheme((theme) => theme.colors.gray)};
    height: 28px;
  }
`;
const Overlay = styled.span `
  position: absolute;
  opacity: 0;
  transition: 0.3s ease;
  background-color: ${themePalette('neutral')};
  height: 100%;
  width: 100%;
`;
const StyledPreviewer = styled.div `
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
  width: 100px;
  padding: 10px;

  border: 2px solid ${themePalette('neutral', 200)};
  border-radius: 5px;

  ${Icon} {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: hidden;
    /* cursor: pointer; */
  }

  &:hover {
    background: transparent;
    border-color: ${themePalette('primary', 500)};
    img {
      filter: blur(3px);
    }
    ${Overlay} {
      opacity: 0.5;
    }

    ${Icon} {
      visibility: visible;
      opacity: 1;
    }
  }

  &:focus {
    border-color: ${fromTheme((theme) => theme.global.purposes.primary)};
    outline: none;
  }

  img {
    height: 100%;
    width: 100%;
    transition: 200ms filter ease;
  }
`;
function Previewer({ src, onClear }) {
    return (_jsxs(StyledPreviewer, Object.assign({ tabIndex: 0 }, { children: [_jsx(Overlay, {}), _jsx("img", { src: src, alt: "preview" }), _jsx(Icon, Object.assign({ onClick: onClear }, { children: _jsx(MdDelete, { size: 28 }) }))] })));
}
export function Upload(_a) {
    var { onChange, value } = _a, rest = __rest(_a, ["onChange", "value"]);
    const fieldContext = useFieldContext();
    const inputRef = useRef(null);
    const { onChange: inputOnChange, onResult, loading, progress, error } = useFileReader();
    const onUploadClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, []);
    onResult(onChange);
    return (_jsxs("div", { children: [_jsx("input", Object.assign({ ref: inputRef, type: "file", id: fieldContext.id, name: fieldContext.id, "data-testid": "upload-input", hidden: true, onChange: inputOnChange }, rest)), !value ? (_jsx(UploadBox, Object.assign({ tabIndex: 0, error: error, onClick: onUploadClick }, { children: loading ? (_jsx(ProgressBar, { percentage: progress })) : (_jsx(Icon, { children: _jsx(MdAdd, { size: 28 }) })) }))) : (_jsx(Previewer, { src: value, onClear: () => {
                    onChange(null);
                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }
                } }))] }));
}
