import { shallowEqual } from 'react-redux'
import memoizeOne from 'memoize-one'

import { RootState } from '../../reducers'

interface StoreObserver<G extends RootState, T> {
  observe: (state: G) => T
  onChange: (observedState: T, state: G) => void
}

export function memoizePartial<T extends Record<string, unknown>>(
  getSlice: (slice: T) => Partial<T>
) {
  return memoizeOne(getSlice, shallowEqual)
}

export function stateObserver<
  G extends RootState,
  T extends Record<string, unknown>
>({ observe, onChange }: StoreObserver<G, T>) {
  let prevState: any = null

  return function (state: G) {
    const currentState = observe(state)

    const shouldPersist =
      !prevState ||
      Object.keys(prevState).some((key) => prevState[key] !== currentState[key])

    if (shouldPersist) {
      onChange(currentState, state)
      prevState = currentState
    }
  }
}
