import styled from 'styled-components';
import { globalFont, preciseEm } from '../utils';
import { fromTheme } from '../Theme';
export const Description = styled.p `
  ${globalFont};
  font-size: ${preciseEm(0.9)}em;
  color: ${fromTheme((theme) => theme.colors.gray)};

  margin-top: 0;
  margin-bottom: ${fromTheme((theme) => theme.space[3])};

  max-width: 800px;
`;
