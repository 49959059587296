import { buildInflector, buildSerializerSettingsFor } from '@orbit/serializers';
import { JSONAPISerializers } from '@orbit/jsonapi';
import { JSONAPISource } from '@orbit/jsonapi';
import { schema } from '../../schema/JSONAPISchema';
import { ExRequestProcessor } from './RequestProcessor';
export var JSONAPIFetchEvent;
(function (JSONAPIFetchEvent) {
    JSONAPIFetchEvent["Before"] = "jsonapiBeforeFetch";
    JSONAPIFetchEvent["After"] = "jsonapiAfterFetch";
    JSONAPIFetchEvent["Error"] = "jsonapiError";
    JSONAPIFetchEvent["AuthError"] = "jsonapiAuthError";
})(JSONAPIFetchEvent || (JSONAPIFetchEvent = {}));
const defaultTimeout = 1000 * 60 * 10;
const getServerSources = () => [server, transientServer, serverOnly];
export const updateHost = (host) => {
    getServerSources().forEach((source) => {
        source.requestProcessor.urlBuilder.host = host;
    });
};
export const updateToken = (token) => {
    getServerSources().forEach((source) => {
        source.requestProcessor.defaultFetchSettings = Object.assign(Object.assign({}, source.requestProcessor.defaultFetchSettings), { headers: Object.assign(Object.assign({}, source.requestProcessor.defaultFetchSettings.headers), { Authorization: `Bearer ${token}` }) });
    });
};
export const isAuthError = (code) => code === 401;
export const getFirstErrorMessage = (data) => {
    var _a, _b;
    return (_b = (_a = data === null || data === void 0 ? void 0 : data.errors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.details;
};
export const server = new JSONAPISource({
    schema,
    name: 'server',
    host: '',
    namespace: 'v2',
    defaultQueryOptions: {
        parallelRequests: true
    },
    defaultFetchSettings: {
        timeout: defaultTimeout
    },
    autoValidate: false,
    serializerSettingsFor: buildSerializerSettingsFor({
        sharedSettings: {
            inflectors: {
                path: buildInflector({
                    cache: 'cache',
                    licence: 'licence',
                    servicecategory: 'servicecategories',
                    reportstatus: 'reportstatuses',
                    'third-party-licences': 'third-party-licences'
                }, (type) => `${type}s`),
                type: buildInflector({
                    'third-party-licences': 'third-party-licence'
                })
            }
        },
        settingsByType: {
            [JSONAPISerializers.ResourceTypePath]: {
                serializationOptions: { inflectors: ['path'] }
            }
        }
    }),
    RequestProcessorClass: ExRequestProcessor
});
export const transientServer = new JSONAPISource({
    schema,
    name: 'transient-server',
    host: '',
    namespace: 'v2',
    defaultQueryOptions: {
        parallelRequests: true
    },
    defaultFetchSettings: {
        timeout: defaultTimeout
    },
    autoValidate: false,
    serializerSettingsFor: buildSerializerSettingsFor({
        sharedSettings: {
            inflectors: {
                path: buildInflector({
                    licence: 'licence',
                    servicecategory: 'servicecategories',
                    reportstatus: 'reportstatuses'
                }, (type) => `${type}s`)
            }
        },
        settingsByType: {
            [JSONAPISerializers.ResourceTypePath]: {
                serializationOptions: { inflectors: ['path'] }
            }
        }
    }),
    RequestProcessorClass: ExRequestProcessor
});
export const serverOnly = new JSONAPISource({
    schema,
    name: 'server-only',
    host: '',
    namespace: 'v2',
    defaultQueryOptions: {
        parallelRequests: true
    },
    defaultFetchSettings: {
        timeout: defaultTimeout
    },
    autoValidate: false,
    serializerSettingsFor: buildSerializerSettingsFor({
        sharedSettings: {
            inflectors: {
                path: buildInflector({
                    licence: 'licence',
                    servicecategory: 'servicecategories',
                    reportstatus: 'reportstatuses'
                }, (type) => `${type}s`)
            }
        },
        settingsByType: {
            [JSONAPISerializers.ResourceTypePath]: {
                serializationOptions: { inflectors: ['path'] }
            }
        }
    }),
    RequestProcessorClass: ExRequestProcessor
});
