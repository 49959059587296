import React, { Suspense } from 'react'
import styled from 'styled-components'
import { Tabs, Widget, Form, Field, Label, Group } from '@exivity/ui'
import { findIndex, compose, equals, map } from '@exivity/fp'
import {
  Route,
  useRoute,
  getTitle,
  getPath,
  getPathname,
  useNavigate,
  getChildRoutes,
  useChildRouteSwitch
} from '@exivity/routing'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

const getActiveIndex = (exactRoute: Route, routes: Route[]) =>
  findIndex(equals(getPath(exactRoute)), map(getPath, routes))

const getToRoute = (routes: Route[]) => (index: number) =>
  routes[index] || routes[0]

export function NavigationTabs({ children }: { children: React.ReactNode }) {
  const { route, exactRoute } = useRoute()
  const navigate = useNavigate()
  const routes = getChildRoutes(route)

  useChildRouteSwitch()

  const navigateTo = compose(navigate, getPathname, getToRoute(routes))

  return (
    <Tabs
      activeIndex={getActiveIndex(exactRoute, routes)}
      onActiveIndexChange={navigateTo}
    >
      <Tabs.TabList>
        {getChildRoutes(route).map((child: any) => {
          const title = getTitle(child)
          return (
            <Tabs.Tab key={getPath(child)} data-testid={'tab-' + title}>
              {title}
            </Tabs.Tab>
          )
        })}
      </Tabs.TabList>
      {children}
    </Tabs>
  )
}

const SkeletonForm = () => (
  <Form>
    <Skeleton
      style={{
        marginBottom: '1rem'
      }}
    />
    <Group>
      <Field.Container>
        <SkeletonField />
        <SkeletonField />
        <SkeletonFieldLarge />
        <SkeletonField />
        <SkeletonField />
      </Field.Container>
    </Group>
    <Form.Toolbar>
      <Skeleton height={40} width={140} />
    </Form.Toolbar>
  </Form>
)

const SkeletonField = () => (
  <Field>
    <Label.Group>
      <Label>
        <Skeleton />
      </Label>
      <Label.Sub>
        <Skeleton baseColor="#f0f0f0" count={2} />
      </Label.Sub>
    </Label.Group>
    <Skeleton height={40} />
  </Field>
)

const SkeletonFieldLarge = () => (
  <Field>
    <Label.Group>
      <Label>
        <Skeleton />
      </Label>
      <Label.Sub>
        <Skeleton />
      </Label.Sub>
    </Label.Group>
    <Skeleton height={136} />
  </Field>
)

export function NavigationWithSuspense({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <NavigationTabs>
      <Suspense
        fallback={
          <Widget>
            <SkeletonForm />
          </Widget>
        }
      >
        {children}
      </Suspense>
    </NavigationTabs>
  )
}
