import FontFaceObserver from 'fontfaceobserver'

import {
  CLASSNAME_FONT_FIRA_SANS_400,
  CLASSNAME_FONT_FIRA_SANS_700
} from '../../jsapi/report'

// Add loaded class
window.onload = function () {
  document.body.classList.add('ex-loaded')
}

// Fonts to observe
const observeFonts = [
  // Fix for unloaded "Material Icons" font displaying in regular text
  {
    fontFamilyName: 'Material Icons',
    className: 'ex-loaded--material-icons'
  },

  // Fix for unloaded "Fira Sans" font on summary report PDF
  {
    fontFamilyName: 'Fira Sans',
    className: CLASSNAME_FONT_FIRA_SANS_400,
    variant: { weight: 400 }
  },
  {
    fontFamilyName: 'Fira Sans',
    className: CLASSNAME_FONT_FIRA_SANS_700,
    variant: { weight: 700 }
  }
]

observeFonts.forEach((font) => {
  new FontFaceObserver(font.fontFamilyName, font.variant)
    .load()
    .then(function () {
      document.body.classList.add(font.className)
    })
    .catch(() => {
      // Ignore errors
    })
})
