import { useState } from 'react'
import { pipe } from 'ramda'
import {
  queries,
  useCacheQuery,
  empty,
  withAttribute,
  useDataStore
} from '@exivity/data-layer'

const createLicence = (licence: string) =>
  pipe(empty('licence'), withAttribute('licence', licence))()

export function useLicence() {
  const licence = useCacheQuery(queries.findAll('licence'))[0]
  // A bit of a hack to make it work with modal by using obj reference
  const [licenceKey, updateLicenceKey] = useState({ key: '' })
  const memory = useDataStore()

  return {
    licence,
    updateLicenceKey,
    renewLicence: () => memory.update((t) => t.addRecord(createLicence(licenceKey.key) as any))
  }
}
