import { map, curry, zipObj, zipWith, filter, propEq } from 'ramda';
function hasData(arg) {
    return Object.prototype.hasOwnProperty.call(arg, 'data');
}
const convertLidToId = curry((lidIdMap, { lid, id, type }) => {
    return {
        id: lid ? lidIdMap[lid] : id,
        type
    };
});
const getIds = (result) => result.map((item) => (hasData(item) ? item.data.id : ''));
const getLids = (atomicDocs) => atomicDocs.map((doc) => {
    return doc.op === 'remove' ? '' : doc.data.lid || '';
});
/**
 * This function creates transforms to update the local orbit cache.
 * From the server response it takes the following:
 *  - remoteId (to replace lids)
 *  - attributes (might have changed)
 *
 *  As the server doesn't return relationships we take those from the
 *  atomicDocs generated by the client.
 */
export function atomicResultsToTransforms(result, atomicDocs) {
    const lidIdMap = zipObj(getLids(atomicDocs), getIds(result));
    const replaceLidWithId = map((relationship) => {
        return Object.prototype.hasOwnProperty.call(relationship, 'data')
            ? {
                data: Array.isArray(relationship.data)
                    ? map(convertLidToId(lidIdMap), relationship.data)
                    : relationship.data
                        ? convertLidToId(lidIdMap, relationship.data)
                        : null
            }
            : relationship;
    });
    return (t) => zipWith((doc, result) => {
        if (doc.op === 'remove')
            return t.removeRecord(doc.ref);
        const transform = doc.op === 'add' ? t.addRecord.bind(t) : t.updateRecord.bind(t);
        return transform({
            id: result.data.id,
            type: result.data.type,
            attributes: result.data.attributes,
            relationships: replaceLidWithId(doc.data.relationships || {})
        });
    }, atomicDocs, result);
}
/**
 * If no data is returned in response by the server, then this means nothing was updated
 * or added. In this case we have to use the atomicDocs to create remove transforms
 */
export function atomicDocToTransforms(atomicDoc) {
    return (t) => map(
    // @ts-ignore
    (record) => t.removeRecord(record.ref), filter(propEq('op', 'remove'), atomicDoc));
}
