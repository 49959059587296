var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Overlay } from '../Overlay';
import { Heading } from '../Heading';
import { ModalWrapper, Header, Body, Footer } from './components';
export function Modal(_a) {
    var { title, children, buttons, onOutsideClick } = _a, rest = __rest(_a, ["title", "children", "buttons", "onOutsideClick"]);
    const onOverlayClick = React.useCallback((e) => {
        if (onOutsideClick && e.currentTarget === e.target) {
            onOutsideClick();
        }
    }, [onOutsideClick]);
    return (_jsx(Overlay, Object.assign({}, rest, { onClick: onOverlayClick }, { children: _jsxs(ModalWrapper, { children: [title && (_jsx(Header, { children: typeof title === 'string' ? _jsx(Heading, { children: title }) : title })), _jsx(Body, { children: children }), buttons && _jsx(Footer, { children: buttons })] }) })));
}
