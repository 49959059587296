import { compose, pickBy, innerJoin, keys, equals, curry } from 'ramda';
import { hexOpacity } from '../utils/colorUtils';
import { defaultTheme, PURPOSES } from './';
const fromTheme = curry((themeResolver, props) => { var _a; return themeResolver(((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global) ? props.theme : defaultTheme); });
const themeColor = (color) => fromTheme((theme) => theme.colors[color]);
const themeSpace = curry((index, props) => fromTheme((theme) => theme.space[index], props));
const themeFontSize = (index) => fromTheme((theme) => theme.fontSizes[index]);
const themeFontFamily = fromTheme((theme) => theme.global.fontFamily);
const _themeFontFamily = (family) => fromTheme((theme) => theme.typography.family[family]);
const _themeFontSize = (size) => fromTheme((theme) => theme.typography.size[size]);
const _themeFontWeight = (weight) => fromTheme((theme) => theme.typography.weight[weight]);
const themePalette = (color, value = 500) => fromTheme((theme) => theme.colors[color][value]);
const themePurpose = (purpose) => fromTheme((theme) => theme.global.purposes[purpose]);
const themePriority = (priority) => fromTheme((theme) => theme.global.zPriority[priority]);
const themeGlobal = (global) => fromTheme((theme) => theme.global[global]);
const matchThemeProp = (themeResolver, options = {}) => (props) => {
    var _a, _b, _c, _d, _e;
    const themeObject = themeResolver(((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global) ? props.theme : defaultTheme);
    let match = Object.keys(props).find((propKey) => props[propKey] && themeObject[propKey]);
    if (!match && options.defaultValue) {
        return (_c = (_b = options === null || options === void 0 ? void 0 : options.modifier) === null || _b === void 0 ? void 0 : _b.call(options, options.defaultValue)) !== null && _c !== void 0 ? _c : options.defaultValue;
    }
    if (!match && themeObject._default) {
        match = themeObject._default;
    }
    if (!match || !themeObject[match]) {
        return null;
    }
    return (_e = (_d = options === null || options === void 0 ? void 0 : options.modifier) === null || _d === void 0 ? void 0 : _d.call(options, themeObject[match])) !== null && _e !== void 0 ? _e : themeObject[match];
};
const matchProp = (styleMap) => (props) => {
    const getTrueKeys = compose(keys, pickBy(equals(true)));
    const matchedProp = innerJoin(equals, keys(styleMap), getTrueKeys(props))[0] || 'default';
    const entry = styleMap[matchedProp];
    return typeof entry === 'function' ? entry(props) : entry;
};
const matchPurpose = matchThemeProp((theme) => theme.global.purposes);
const matchButtonHeight = matchThemeProp((theme) => theme.button.heights);
const matchSizes = matchThemeProp((theme) => theme.global.sizes);
/**
 * Get a theme color from purpose props in a styled component
 * @param colorValue 100 - 900, color variants from the theme
 * @param opacity 0 - 1
 * @param defaultPurpose Purpose
 * @returns hex or empty string
 */
const matchColorFromPurpose = (colorValue, opacity = 1, defaultPurpose) => (props) => {
    var _a;
    const themeColors = ((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global)
        ? props.theme.colors
        : defaultTheme.colors;
    const getTrueKeys = compose(keys, pickBy(equals(true)));
    const selectedPurpose = innerJoin(equals, PURPOSES, getTrueKeys(props))[0];
    const colorKey = !selectedPurpose
        ? defaultPurpose || 'primary'
        : selectedPurpose === 'secondary'
            ? 'neutral'
            : selectedPurpose;
    return hexOpacity(themeColors[colorKey][colorValue], opacity);
};
const colorFromPurpose = (purpose, colorValue, opacity = 1) => (props) => {
    var _a;
    const themeColors = ((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global)
        ? props.theme.colors
        : defaultTheme.colors;
    return hexOpacity(themeColors[purpose][colorValue], opacity);
};
export { themePalette, themeColor, themeSpace, themePurpose, themePriority, themeGlobal, themeFontSize, themeFontFamily, matchProp, matchThemeProp, matchPurpose, matchButtonHeight, matchSizes, matchColorFromPurpose, colorFromPurpose, fromTheme, _themeFontFamily, _themeFontSize, _themeFontWeight };
