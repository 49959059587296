var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, createContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { themeColor, themeSpace } from '../Theme';
import { withExtendableProps } from '../ExtendProps';
import { injectIf } from '../utils';
const FormContext = createContext({ stacked: false });
export function useFormContext() {
    return useContext(FormContext);
}
const stackedToolbarStyle = injectIf('stacked', css `
    background: unset;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding: 0;
  `);
const StyledToolbar = styled.div `
  position: sticky;
  bottom: ${themeSpace(2)};
  background: repeating-linear-gradient(
    120deg,
    transparent 0,
    ${themeColor('lightGray')} 1px,
    transparent 2px,
    transparent 4px
  );

  padding: ${themeSpace(1)};

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${themeSpace(1)};
  align-self: stretch;

  ${stackedToolbarStyle}
`;
const StyledForm = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${themeSpace(2)};
  width: 100%;
  height: 100%;

  h2 {
    margin: 0;
  }
`;
export function FormToolbar(_a) {
    var { stacked, children } = _a, rest = __rest(_a, ["stacked", "children"]);
    const ctx = useFormContext();
    return (_jsx(StyledToolbar, Object.assign({ stacked: stacked || ctx.stacked }, rest, { children: children })));
}
function Base(_a) {
    var { stacked } = _a, formProps = __rest(_a, ["stacked"]);
    const { ref, width = 600 } = useResizeObserver();
    return (_jsx(FormContext.Provider, Object.assign({ value: useMemo(() => ({
            stacked: stacked || width < 550
        }), [stacked, width]) }, { children: _jsx(StyledForm, Object.assign({ ref: ref }, formProps)) })));
}
function SubForm({ stacked, children }) {
    const { ref, width = 600 } = useResizeObserver();
    return (_jsx(FormContext.Provider, Object.assign({ value: useMemo(() => ({
            stacked: stacked || width < 550
        }), [stacked, width]) }, { children: _jsx(StyledForm, Object.assign({ ref: ref, as: "div" }, { children: children })) })));
}
Base.Toolbar = FormToolbar;
Base.SubForm = SubForm;
const Form = withExtendableProps('Form')(Base);
Form.Toolbar = FormToolbar;
Form.SubForm = SubForm;
export { Form };
