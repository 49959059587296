import { isNil, path } from 'ramda';
const comparatorAsc = (a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' });
const comparatorDsc = (a, b) => b.localeCompare(a, undefined, { sensitivity: 'base' });
export function sortRecords(records, sortSpecifiers = []) {
    const comparisonValues = new Map();
    records.forEach((record) => {
        comparisonValues.set(record, sortSpecifiers.map((sortSpecifier) => {
            if (sortSpecifier.kind === 'attribute') {
                return path(['attributes', sortSpecifier.attribute], record);
            }
            else {
                throw new Error(`Sort specifier ${sortSpecifier.kind} not recognized for queryExpression.`);
            }
        }));
    });
    const comparisonOrders = sortSpecifiers.map((sortExpression) => sortExpression.order === 'descending' ? -1 : 1);
    const comparisons = sortSpecifiers.map((sortExpression) => sortExpression.order === 'descending' ? comparatorDsc : comparatorAsc);
    return records.sort((record1, record2) => {
        const values1 = comparisonValues.get(record1);
        const values2 = comparisonValues.get(record2);
        for (let i = 0; i < sortSpecifiers.length; i++) {
            if (typeof values1[i] === 'string' && typeof values2[i] === 'string') {
                return comparisons[i](values1[i], values2[i]);
            }
            else if (values1[i] < values2[i]) {
                return -comparisonOrders[i];
            }
            else if (values1[i] > values2[i]) {
                return comparisonOrders[i];
            }
            else if (isNil(values1[i]) && !isNil(values2[i])) {
                return comparisonOrders[i];
            }
            else if (isNil(values2[i]) && !isNil(values1[i])) {
                return -comparisonOrders[i];
            }
        }
        return 0;
    });
}
