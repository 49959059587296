import { translate } from '@exivity/translations'
import { memory } from '@exivity/data-layer'
import moment from 'moment'

import { HookContext, HookType } from '../../fetchWithHooks/fetchWithHooks'
import { reportSelectors } from '../../../domains/reports/state'
import { enrichServices } from '../../../transformers/reports/services/index'
import { enrichInstances } from '../../../transformers/reports/instances/index'
import { enrichAccounts } from '../../../transformers/reports/accounts/index'
import { groupReportByAccount } from '../../../domains/reports/state/dimensions'

import { enrichSummary } from './enrichSummary'
import { Report } from './runReport'

export const addDateRangeParams = (ctx: HookContext<HookType.Before>) => {
  const dateRange = reportSelectors.getDateRange(ctx.options.state)

  ctx.params.query = {
    ...ctx.params.query,
    start: moment(dateRange[0]).format('YYYYMMDD'),
    end: moment(dateRange[1]).format('YYYYMMDD')
  }

  return ctx
}

export const addIncludedResourcesParams = (
  ctx: HookContext<HookType.Before>
) => {
  const state = ctx.options.state
  const account = reportSelectors.getSelectedAccount(state)
  const accountDepth = reportSelectors.getAccountDepth(state)
  const service = reportSelectors.getSelectedServiceId(state)
  const serviceCategory = reportSelectors.getSelectedServiceCategoryId(state)

  ctx.params.query = {
    depth: String(accountDepth),
    dimension: 'accounts,services',
    'filter[service_id]': service || '',
    'filter[servicecategory_id]': serviceCategory || '',
    'filter[account_id]': account || ''
  }

  return addDateRangeParams(ctx)
}

export const addRunReportParams =
  (report: Report, dimension: string) =>
  (ctx: HookContext<HookType.Before>) => {
    const state = ctx.options.state
    const account = reportSelectors.getSelectedAccount(state)
    const parentAccount = reportSelectors.getSelectedParentAccount(state)
    const accountDepth = reportSelectors.getAccountDepth(state)
    const parentAccountDepth = reportSelectors.getParentAccountDepth(state)

    const service = reportSelectors.getSelectedServiceId(state)
    const serviceCategory = reportSelectors.getSelectedServiceCategoryId(state)
    const granularity = reportSelectors.getGranularity(state)

    const timeline = (
      {
        accounts: granularity,
        services: granularity,
        instances: granularity,
        summaryInstances: 'none',
        summaryServices: 'none'
      } as const
    )[report]

    const defaultParams = {
      timeline,
      precision: 'highest',
      dimension,
      'filter[service_id]': service || '',
      'filter[servicecategory_id]': serviceCategory || ''
    }

    const variation = (
      {
        accounts: 'parentAccountFilter',
        services: 'accountFilter',
        instances: 'accountFilter',
        summaryInstances: 'parentAccountFilter',
        summaryServices: 'parentAccountFilter'
      } as const
    )[report]

    ctx.params.query = {
      parentAccountFilter: {
        ...defaultParams,
        depth: String(parentAccountDepth),
        'filter[parent_account_id]': parentAccount || ''
      },
      accountFilter: {
        ...defaultParams,
        depth: String(accountDepth),
        'filter[account_id]': account || ''
      }
    }[variation]

    return addDateRangeParams(ctx)
  }

export const checkIncludedResourcesResult = (
  ctx: HookContext<HookType.After>
) => {
  const { resources } = ctx.result

  if (!resources) {
    throw new Error(
      translate(
        'An error occurred while fetching the included resources for this report. Please try again.'
      )
    )
  }

  ctx.result = resources

  return ctx
}

export const checkRunReportResult = (ctx: HookContext<HookType.After>) => {
  const { report } = ctx.result

  if (!report || !Array.isArray(report)) {
    throw new Error(
      translate(
        'An error occurred while running this report. Please try again.'
      )
    )
  }

  ctx.result = report

  return ctx
}

export const enrichResult =
  (report: Report) => async (ctx: HookContext<HookType.After>) => {
    const enrich = {
      accounts: enrichAccounts,
      services: enrichServices,
      instances: enrichInstances,
      summaryServices: enrichSummary(memory),
      summaryInstances: enrichSummary(memory)
    }

    ctx.result = await enrich[report](ctx.options.state, ctx.result)

    return ctx
  }

export const groupResultByAccount = (ctx: HookContext<HookType.After>) => {
  ctx.result = groupReportByAccount(ctx.result)

  return ctx
}
