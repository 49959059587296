import { createSelector, createSelectors } from '../../../store/utils'
import staticConfig from '../../../application/config'
import { getUserSessionStorage } from '../../../store/storage'
import { FetchStatus } from '../../../API/types'

const auth = createSelector((state) => state.auth)

const curriedSelectors = {
  makeUrl: (endpoint: string) =>
    auth((auth) => `${auth.apiRoot}/v${staticConfig.app.apiVersion}${endpoint}`)
}

const selectors = createSelectors((state) => state.auth, {
  staticConfigHydrated: (auth) => auth.staticConfigHydrated,
  getToken: () => {
    return getUserSessionStorage.fromEitherStorage('token') ?? null
  },
  getUserPermissions: (auth) => auth.permissions,
  isAuthenticated: (auth) => auth.isAuthenticated,
  getCurrentUser: (auth) => auth.currentUser,
  isSigningOut: (auth) => auth.signingOut === FetchStatus.Loading,
  isRefreshingSession: (auth) => auth.refreshingSession === FetchStatus.Loading,
  getApiRoot: (auth) => auth.apiRoot,
  getHost: (auth) => auth.apiRoot,
  isWhiteLabeled: (auth) => auth.isWhiteLabeled
})

export const authSelectors = {
  ...curriedSelectors,
  ...selectors
}
