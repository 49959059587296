"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeAtPath = void 0;
const ramda_1 = require("ramda");
const updateAtPath_1 = require("./updateAtPath");
const updateChildren_1 = require("./updateChildren");
exports.removeAtPath = (0, ramda_1.curry)((indexPath, node) => {
    const [parentPath, childToRemoveIndex] = [
        indexPath.slice(0, indexPath.length - 1),
        indexPath[indexPath.length - 1]
    ];
    return (0, updateAtPath_1.updateAtPath)(parentPath, (0, updateChildren_1.updateChildren)((children) => children.filter((_, i) => i !== childToRemoveIndex)), node);
});
