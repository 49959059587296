import { useEffect } from 'react'
import { Widget, LoadingOverlay } from '@exivity/ui'
import { useDataStore } from '@exivity/data-layer'
import { useSelector } from 'react-redux'

import { NavigationWithSuspense } from '../../../../routes/NavigationTabs'
import { workSelectors } from '../../../work/state'

export const SettingsLayout = ({ children }: { children: React.ReactNode }) => {
  const isLoading = useSelector(workSelectors.isForegroundBusy)

  return (
    <LoadingOverlay loading={isLoading}>
      <Widget>{children}</Widget>
    </LoadingOverlay>
  )
}

export function SettingsPage({ children }: { children: JSX.Element }) {
  const memory = useDataStore()

  useEffect(() => {
    memory.query((q) => [
      q.findRecord({ type: 'configuration', id: 'system' }),
      q.findRecord({ type: 'configuration', id: 'saml' }),
      q.findRecord({ type: 'configuration', id: 'services' })
    ])
  }, [memory])

  return <NavigationWithSuspense>{children}</NavigationWithSuspense>
}
