import { groupOther } from "../index";

export default (report, maxSeries, valueType) => {
  if (!report) {
    return [];
  }

  let instances = {};

  report.forEach((item) => {
    if (instances[item.instance_value]) {
      instances[item.instance_value] = {
        key: item.instance_value,
        title: item.instance_value,
        value: instances[item.instance_value].value + item[valueType],
      };
    } else {
      instances[item.instance_value] = {
        key: item.instance_value,
        title: item.instance_value,
        value: item[valueType],
      };
    }
  });

  instances = Object.values(instances);

  return groupOther(instances, maxSeries);
};
