var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { fromTheme, matchThemeProp } from '../Theme';
const StyledLi = styled.li `
  display: flex;
  align-items: center;

  ${(props) => props.active
    ? css `
          color: ${fromTheme((theme) => theme.global.purposes.primary)};
        `
    : css `
          color: ${props.noDataPlaceholder
        ? fromTheme((theme) => theme.colors.gray)
        : 'inherit'};
        `}

  font-family: ${fromTheme((theme) => theme.global.fontFamily)};
  font-size: ${matchThemeProp((theme) => theme.global.sizes, {
    modifier: (em) => (em / 16) * 14
})}em;

  outline: none;

  ${(props) => props.focusable && !props.noDataPlaceholder
    ? css `
          &:hover,
          :focus {
            background-color: ${fromTheme((theme) => theme.colors.lightGray)};
          }
          cursor: pointer;
        `
    : ''}
`;
const StyledInnerItem = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
  ${space}
`;
export const focusElement = (event) => event.currentTarget.focus();
export function ListItem(_a) {
    var { children, focusable = true, px = 2 } = _a, rest = __rest(_a, ["children", "focusable", "px"]);
    return (_jsx(StyledLi, Object.assign({ "data-testid": "list-item" }, rest, { focusable: focusable, tabIndex: focusable ? -1 : undefined, onMouseOver: focusElement }, { children: _jsx(StyledInnerItem, Object.assign({ px: px }, { children: children })) })));
}
ListItem.displayName = 'ListItem';
