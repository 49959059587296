import Papa from 'papaparse'
import { userHasPermission, UserGroupPermission } from '@exivity/data-layer'
import { createAsyncThunk, AsyncThunkConfig } from '@reduxjs/toolkit'
import { memory } from '@exivity/data-layer'

import { get, post } from '../../../API'

import { datasourcesSelectors } from './index'
import { workThunks } from '../../work/thunks'

export type Log = {
  created: string
  filename: string
  lines: {
    date: string
    message: string
    level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
    metadata: any[]
  }[]
}

interface LogOptions {
  component: 'transcript' | 'use'
  filter: string
}

export const fetchRunLogs = createAsyncThunk<Log, LogOptions>(
  'datasources/run/log',
  async ({ component, filter = '' }) => {
    return get(
      '/log',
      {
        limit: 100,
        filter,
        truncate: 25,
        component
      },
      {
        background: true
      }
    ).then((data) => data.logfiles[0])
  },
  {
    condition(arg, { getState }) {
      return userHasPermission(getState().auth.permissions, [
        UserGroupPermission.ViewLogs
      ])
    }
  }
)

interface RunExtractorOptions {
  id: string
  args: string
  environmentId: string | null
}

export const runExtractor = createAsyncThunk<
  string,
  RunExtractorOptions,
  { rejectValue: string } & AsyncThunkConfig
>(
  'datasources/extractor/run',
  ({ id, args, environmentId }, { dispatch, rejectWithValue }) => {
    return post(
      `/extractors/${id}/run`,
      {
        environment_id: environmentId ?? '',
        arguments: args
      },
      {},
      {
        handleRejections: false,
        background: true
      }
    )
      .then((data) => {
        dispatch(fetchRunLogs({ component: 'use', filter: id }))
        return data[0]
      })
      .catch((error) => {
        dispatch(fetchRunLogs({ component: 'use', filter: id }))
        return rejectWithValue(error.message as string)
      })
  }
)

interface RunTransformerOptions {
  id: string
  dateRange: [string, string]
  environmentId: string | null
}

export const runTransformer = createAsyncThunk<
  string,
  RunTransformerOptions,
  { rejectValue: string } & AsyncThunkConfig
>(
  'datasources/transformer/run',
  (
    { id, dateRange: [start_date, end_date], environmentId },
    { dispatch, rejectWithValue }
  ) => {
    return post(
      `/transformers/${id}/run`,
      {
        environment_id: environmentId ?? '',
        start_date,
        end_date
      },
      {},
      {
        background: true,
        handleRejections: false
      }
    )
      .then((data) => {
        dispatch(fetchRunLogs({ component: 'transcript', filter: id }))
        memory.query((q) => q.findRecords('dataset').page({ offset: 0, limit: -1 }))
        return data
      })
      .catch((error) => {
        dispatch(fetchRunLogs({ component: 'transcript', filter: id }))
        return rejectWithValue(error.message as string)
      })
  }
)

type PreviewResolve = {
  data: Record<string, string>[]
  columns: string[]
}

export const runTransformerPreview = createAsyncThunk<
  PreviewResolve,
  string,
  { rejectValue: string } & AsyncThunkConfig
>(
  'datasources/transformer/run-preview',
  (transformerId, { dispatch, getState, rejectWithValue }) => {
    return post(
      `/transformers/${transformerId}/run`,
      datasourcesSelectors.getPreviewParams(getState()),
      {},
      {
        handleRejections: false,
        background: true
      }
    )
      .then((data) => {
        const parsedCsv = Papa.parse(data.content, {
          header: true,
          skipEmptyLines: true,
          escapeChar: datasourcesSelectors.getPreviewEscapeChar(getState())
        })

        dispatch(
          fetchRunLogs({ component: 'transcript', filter: transformerId })
        )
        return {
          data: parsedCsv.data as Record<string, string>[],
          columns: parsedCsv.meta.fields || []
        }
      })
      .catch((error) => {
        dispatch(
          fetchRunLogs({ component: 'transcript', filter: transformerId })
        )
        dispatch(workThunks.showErrorMessage(error.message as string))
        return rejectWithValue(error.message as string)
      })
  }
)
