"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateRelationship = exports.viewRelationship = exports.viewAttribute = exports.updateAttribute = exports.viewType = exports.viewId = exports.cleanRecord = exports.omitLinks = exports.omitId = exports.equalId = void 0;
const ramda_1 = require("ramda");
const update_1 = require("./update");
exports.equalId = (0, ramda_1.propEq)('id');
const omitId = (record) => (record === null || record === void 0 ? void 0 : record.id) ? (0, ramda_1.omit)(['id'], record) : record;
exports.omitId = omitId;
const omitLinks = (record) => record.links ? (0, ramda_1.omit)(['links'], record) : record;
exports.omitLinks = omitLinks;
const cleanRecord = (record) => (0, ramda_1.omit)(['links', 'id'], record);
exports.cleanRecord = cleanRecord;
function viewId(source) {
    return (0, ramda_1.view)((0, ramda_1.lensProp)('id'), source);
}
exports.viewId = viewId;
function viewType(source) {
    return (0, ramda_1.view)((0, ramda_1.lensProp)('type'), source);
}
exports.viewType = viewType;
exports.updateAttribute = (0, ramda_1.curry)(
// @ts-ignore
(attribute, value, source) => (0, update_1.update)((0, ramda_1.lensPath)(['attributes', attribute]), value, source));
exports.viewAttribute = (0, ramda_1.curry)((attribute, source) => { var _a; return (_a = source.attributes) === null || _a === void 0 ? void 0 : _a[attribute]; });
exports.viewRelationship = (0, ramda_1.curry)((relationship, source) => { var _a, _b; return (_b = (_a = source === null || source === void 0 ? void 0 : source.relationships) === null || _a === void 0 ? void 0 : _a[relationship]) === null || _b === void 0 ? void 0 : _b.data; });
exports.updateRelationship = (0, ramda_1.curry)((relationship, value, source) => (0, update_1.update)(
// assign relationship to get rid of links or other properties
(0, ramda_1.lensPath)(['relationships', relationship]), { data: value }, source));
