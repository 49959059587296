import { useMemo } from 'react';
function isMatch(value, searchTerm) {
    return value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
}
export function useSearchFilter(data, searchTerm, accessor) {
    return useMemo(() => {
        function filterData() {
            return data.filter((item) => {
                if (!accessor && item.value) {
                    return isMatch(item.value, searchTerm);
                }
                if (accessor) {
                    return isMatch(accessor(item), searchTerm);
                }
                return true;
            });
        }
        return searchTerm && searchTerm.length ? filterData() : data;
    }, [searchTerm, data]);
}
