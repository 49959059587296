import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePalette, themeSpace, themeGlobal, colorFromPurpose } from '../Theme';
export function DetailAccessory({ title = [], children }) {
    return (_jsxs(Layout, { children: [_jsxs(Detail, { children: [!!title[0] && _jsx(Title, { children: title[0] }), children[0]] }), _jsxs(Accessory, { children: [!!title[1] && _jsx(Title, { children: title[1] }), children[1]] })] }));
}
const Widget = styled.div `
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid ${colorFromPurpose('secondary', 300)};
`;
const Accessory = styled(Widget) `
  position: sticky;
  top: calc(${themeGlobal('headerHeight')}px + ${themeSpace(2)});
  width: 300px;
  align-self: flex-start;
`;
const Layout = styled.div `
  display: grid;
  grid-template-columns: minmax(0, 1fr) 300px;
  grid-template-rows: auto;
  gap: 1em;

  width: 100%;

  @media (max-width: 1000px) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto auto;

    ${Accessory} {
      grid-row: 1;
      position: relative;
      top: 0;
      width: 100%;
    }
  }
`;
const Detail = styled(Widget) `
  flex-grow: 1;
  align-self: stretch;
`;
export const Title = styled.div `
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 50px;
  border-bottom: 1px solid ${themePalette('neutral', 300)};
  padding: 0 ${themeSpace(2)};
  text-transform: uppercase;
  color: ${colorFromPurpose('secondary', 700)};
`;
