import { createContext, useContext } from 'react';
const defaultCtx = {
    list: [],
    onChange: (() => { }),
    value: undefined
};
export const ListContext = createContext(defaultCtx);
export function isMultiple(args) {
    return Array.isArray(args);
}
export function useListContext() {
    return useContext(ListContext);
}
