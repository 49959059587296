import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { globalFont, truncateText, classNames } from '../../utils';
import { fromTheme } from '../../Theme';
import { SortableItem, useSortable } from './sortable';
import { createCategorizedStrategy } from './categorization';
import { createFlatStrategy } from './flat';
import { List } from './List';
const ITEM_HEIGHT = 30;
const OVERSCAN_COUNT = 5;
const StyledList = styled.div `
  ${globalFont}

  height: 100%;

  font-size: ${fromTheme((theme) => theme.fontSizes[1])};

  .placeholder {
    color: ${fromTheme((theme) => theme.colors.gray)};
    padding-left: ${fromTheme((theme) => theme.global.baseSpacing)}em;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    height: ${ITEM_HEIGHT}px;
    padding-left: ${fromTheme((theme) => theme.global.baseSpacing)}em;
  }

  .columns {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
      box-sizing: border-box;
      ${truncateText}
    }
  }

  &.sortable {
    .drag-handle {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: ${fromTheme((theme) => theme.global.baseSpacing / 2)}em;
      z-index: 1;
      cursor: grab;
    }
  }
`;
function _InternalList({ data, categorization, isActive, sorting, onClick, columns, order }) {
    const sortable = useCallback((node, { item, slot }) => {
        if (!sorting)
            return node;
        return (_jsx(SortableItem, Object.assign({ id: sorting.getKey(item), slot: slot, handle: _jsx("span", Object.assign({ className: "drag-handle" }, { children: "\u2630" })), onSort: (dragKey, hoverKey) => {
                const dragItem = data.find((item) => sorting.getKey(item) === dragKey);
                const hoverItem = data.find((item) => sorting.getKey(item) === hoverKey);
                if (dragItem && hoverItem)
                    sorting.onSort(dragItem, hoverItem);
            } }, { children: node })));
    }, [sorting]);
    const renderColumns = useCallback((item) => {
        return (_jsx("div", Object.assign({ className: "columns" }, { children: columns.map((column, i, arr) => {
                const width = column.width || `${100 / arr.length}%`;
                return (_jsx("span", Object.assign({ style: { width } }, { children: column.renderCell(item) }), i));
            }) })));
    }, [columns]);
    const renderingStrategy = useMemo(() => {
        const sharedArgs = {
            order,
            sortable,
            isActive,
            renderColumns,
            onClick
        };
        return categorization
            ? createCategorizedStrategy(data, Object.assign(Object.assign({}, sharedArgs), { categorization }))
            : createFlatStrategy(data, sharedArgs);
    }, [data, categorization, order, sortable, isActive, renderColumns, onClick]);
    return (_jsx(StyledList, Object.assign({ className: classNames({ sortable: !!sorting }) }, { children: renderingStrategy.itemCount !== 0 ? (_jsx(List, Object.assign({}, renderingStrategy, { itemHeight: ITEM_HEIGHT, overscanCount: OVERSCAN_COUNT }))) : (_jsx("div", Object.assign({ className: "placeholder" }, { children: "No items in this list." }))) })));
}
export const InternalList = React.memo(_InternalList);
InternalList.useSortable = useSortable;
