import { jsx as _jsx } from "react/jsx-runtime";
import { useSpring, animated } from '@react-spring/web';
import styled from 'styled-components';
import { themePurpose } from '../Theme';
const StyledStatus = styled(animated.div) `
  height: 3px;
  background-color: ${themePurpose('brand')};
`;
const StyledBar = styled.div `
  height: 3px;
  width: 100%;
`;
export function ProgressBar({ className, percentage = 0 }) {
    const props = useSpring({ width: `${percentage}%` });
    return (_jsx(StyledBar, Object.assign({ className: className, style: { height: 3, width: '100%' } }, { children: _jsx(StyledStatus, { style: props }) })));
}
