var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useExpandable } from '../useExpandable';
import { ListFocus } from '../ListFocus';
import { StyledList } from '../SelectLegacy/SelectList/SelectList';
import { DefaultItem } from './DefaultItem';
const ITEM_HEIGHT = 30;
const StyledListFocus = styled(ListFocus) `
  overflow-y: auto;
`;
/** @deprecated */
export function LegacyTreeList(_a) {
    var { data, parentKeyAccessor, expandedKeys, children, keyAccessor, labelAccessor, itemHeight = ITEM_HEIGHT, maxItemsRendered, onChange, value, innerElementType = 'ul', width = '100%' } = _a, blockProps = __rest(_a, ["data", "parentKeyAccessor", "expandedKeys", "children", "keyAccessor", "labelAccessor", "itemHeight", "maxItemsRendered", "onChange", "value", "innerElementType", "width"]);
    const [expandableData] = useExpandable(data, keyAccessor, parentKeyAccessor, expandedKeys);
    const handleChange = useCallback(onChange || (() => null), [onChange]);
    const itemData = useMemo(() => ({
        items: expandableData,
        onChange: handleChange,
        selectedItem: value,
        labelAccessor: labelAccessor || ((item) => item.value)
    }), [expandableData, value, handleChange, labelAccessor]);
    const height = maxItemsRendered
        ? Math.min(maxItemsRendered, expandableData.length) * itemHeight
        : expandableData.length * itemHeight;
    return (_jsx(StyledListFocus, Object.assign({}, blockProps, { height: height, maxHeight: "100%", width: width }, { children: _jsx(AutoSizer, { children: ({ height, width }) => (_jsx(StyledList, Object.assign({ height: height, itemData: itemData, itemCount: expandableData.length, itemSize: itemHeight, innerElementType: innerElementType, width: width }, { children: children || DefaultItem }))) }) })));
}
export { ToggleExpandedButton } from './DefaultItem';
