import styled from 'styled-components';
import { fromTheme } from '../../Theme';
import { StyledButton } from '../../Button/Button';
export const ModalWrapper = styled.div `
  position: absolute;
  width: 600px;
  background-color: ${fromTheme((theme) => theme.colors.white)};
  left: calc((100vw - 600px) / 2);
  top: 50px;
`;
export const Header = styled.div `
  padding: ${fromTheme((theme) => theme.global.baseSpacing)}em
    ${fromTheme((theme) => theme.global.baseSpacing * 1.5)}em;
`;
export const Body = styled.div `
  padding: ${fromTheme((theme) => theme.global.baseSpacing * 1.5)}em;
  max-height: 400px;
  border-top: solid 1px ${fromTheme((theme) => theme.colors.lightGray)};
  border-bottom: solid 1px ${fromTheme((theme) => theme.colors.lightGray)};
  overflow-y: auto;
`;
export const Footer = styled.div `
  padding: ${fromTheme((theme) => theme.global.baseSpacing)}em
    ${fromTheme((theme) => theme.global.baseSpacing * 2)}em;
  color: ${fromTheme((theme) => theme.colors.gray)};
  display: flex;
  flex-direction: row-reverse;
  ${StyledButton} {
    margin-left: 20px;
  }
`;
