import { isBefore, isAfter, min, max } from 'date-fns';
export const isEmptyRange = (range) => !range[0] && !range[1];
export const isPartialRange = (range) => !!(range[0] && !range[1]);
export const isCompleteRange = (range) => !!(range[0] && range[1]);
export const orderRange = (range) => [min(range), max(range)];
export function constrainRangeToBounds(range, bounds) {
    return range.map((date) => {
        if (!date)
            return null;
        if (bounds.min && isBefore(date, bounds.min)) {
            return bounds.min;
        }
        if (bounds.max && isAfter(date, bounds.max)) {
            return bounds.max;
        }
        return date;
    });
}
