import { useState } from 'react';
import { identity } from 'ramda';
import { useCacheQueryFn } from './useCacheQueryFn';
import { useLiveQuery } from './useLiveQuery';
export function useCacheQuery(queryBuilder, listener = identity) {
    const queryCache = useCacheQueryFn();
    const [result, setResult] = useState(() => {
        const result = queryCache(queryBuilder);
        listener(result);
        return result;
    });
    useLiveQuery(queryBuilder.toQuery(), (result) => {
        setResult(result);
        listener(result);
    });
    return result;
}
export function useQueryListener(queryBuilder, listener) {
    useLiveQuery(queryBuilder.toQuery(), listener);
}
