import { groupOther } from "../";

export default (report, maxSeries, valueType) => {
  if (!report) {
    return [];
  }

  let services = {};

  report.forEach((item) => {
    if (services[item.service_id]) {
      services[item.service_id] = {
        key: item.service_id,
        title: item.service_description,
        value: services[item.service_id].value + item[valueType],
      };
    } else {
      services[item.service_id] = {
        key: item.service_id,
        title: item.service_description,
        value: item[valueType],
      };
    }
  });

  services = Object.values(services);

  return groupOther(services, maxSeries);
};
