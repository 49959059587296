import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { _Table, Variations } from '../Table';
function toFunction(prop) {
    return typeof prop === 'function' ? prop : () => prop;
}
export function _ActionList({ data, itemActions, listActions, keyExtractor, children }) {
    return (_jsx(Variations.ActionList, { children: _jsx(_Table, { data: data, keyExtractor: keyExtractor, columns: [
                {
                    content: children,
                    header: '',
                    width: '90%'
                },
                {
                    content: toFunction(itemActions),
                    footer: toFunction(listActions),
                    header: '',
                    width: '10%'
                }
            ] }) }));
}
export const ActionList = React.memo(_ActionList);
