import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { MdAdd, MdClear, MdEdit, MdCheck, MdDelete } from 'react-icons/md';
import { Button } from '../Button';
import { useModal } from '../Modal';
import { Icon } from '../Icon';
const HidableBtn = styled(Button) `
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
export const Toolbar = ({ onConfirm, onCreate, onUpdate, onDelete, onCancel, confirmDeleteText, actionState }) => {
    const confirm = useModal().confirm;
    const isConfirming = actionState !== 'none';
    const isActiveState = (option) => actionState === 'none' || actionState === option;
    return (_jsxs(_Fragment, { children: [_jsx(HidableBtn, Object.assign({ small: true, round: true, type: "submit", success: actionState === 'create', visible: isConfirming, onClick: () => {
                    onConfirm && onConfirm();
                } }, { children: _jsx(Icon, { children: _jsx(MdCheck, {}) }) })), _jsx(HidableBtn, Object.assign({ small: true, round: true, success: true, outlined: isConfirming, visible: !!onCreate && isActiveState('create'), onClick: () => {
                    if (isConfirming) {
                        onCancel && onCancel();
                    }
                    else {
                        onCreate && onCreate();
                    }
                } }, { children: _jsx(Icon, { children: isConfirming ? _jsx(MdClear, {}) : _jsx(MdAdd, {}) }) })), _jsx(HidableBtn, Object.assign({ small: true, round: true, outlined: isConfirming, visible: !!onUpdate && isActiveState('edit'), onClick: () => {
                    if (isConfirming) {
                        onCancel && onCancel();
                    }
                    else {
                        onUpdate && onUpdate();
                    }
                } }, { children: _jsx(Icon, { children: isConfirming ? _jsx(MdClear, {}) : _jsx(MdEdit, {}) }) })), _jsx(HidableBtn, Object.assign({ small: true, round: true, danger: true, visible: !!onDelete && !isConfirming, onClick: () => {
                    confirm({ body: confirmDeleteText }).then(() => {
                        onDelete && onDelete();
                    });
                } }, { children: _jsx(Icon, { children: _jsx(MdDelete, {}) }) }))] }));
};
