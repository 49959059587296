import React, { Suspense, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Shell as UIShell, Loader, themeGlobal } from '@exivity/ui'
import { singleLine, translate } from '@exivity/translations'
import { useSystemConfiguration } from '@exivity/data-layer'
import styled from 'styled-components'
import { AccountsProvider } from '@exivity/data-layer'

import { authSelectors } from '../../auth/state'
import { workSelectors } from '../../work/state'
import { profileSelectors } from '../../my-profile/state'
import { LicenceWarning } from '../../administration/components/LicenceWarning'
import { TokenExpirationWarning } from '../../auth/components'

import { useLoadNewVersion } from './ServiceWorkers/ServiceWorkers'
import { useTranslation } from './useTranslation'
import { ErrorBoundary } from './ErrorBoundary'
import { Navigation } from './NavBar'
import { AppBar } from './AppBar'
import { DebugWarning } from './DebugWarning'
import { useFavicon } from './useFavicon'

import './container.css'
import '../../../styles/font.css'
import { reportSelectors } from '../../reports/state'
import { SyncResources } from './SyncResources'
import { AuthContainer } from './AuthContainer'

interface ShellProps {
  children: React.ReactElement
}

const CenteredLoader = styled(Loader)`
  height: ${themeGlobal('contentHeight')};
`

export function Shell({ children }: ShellProps) {
  useFavicon()
  useLoadNewVersion()

  // Whenever we update state -> we change the key so we can rerender entire react tree
  const language = useTranslation() || ''

  const isForegroundBusy = useSelector(workSelectors.isForegroundBusy)
  const appCss = useSystemConfiguration().attributes.APP_CSS || ''
  const collapsed = useSelector(profileSelectors.isNavCollapsed)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const reportId = useSelector(reportSelectors.getSelectedReportId)

  const fallback = (
    <CenteredLoader
      timeout={10000}
      timeoutMessage={singleLine(
        translate('Loading is taking a bit longer than expected...'),
        translate('Try refreshing the page.')
      )}
    >
      {translate('Loading page...')}
    </CenteredLoader>
  )

  return (
    <UIShell
      className={`ex-app ${
        isForegroundBusy ? 'ex-app--foreground-loading' : ''
      }`}
      navCollapsed={collapsed}
      appBar={<AppBar key={language + 'appbar'} />}
      navBar={isAuthenticated ? <Navigation key={language} /> : undefined}
      appCss={appCss ?? undefined}
    >
      <ErrorBoundary>
        {isAuthenticated ? (
          <AuthContainer>
            <SyncResources>
              <AccountsProvider reportId={reportId}>
                <Suspense fallback={fallback}>
                  <Fragment key={language}>
                    <TokenExpirationWarning />
                    <LicenceWarning />
                    <DebugWarning />
                    {children}
                  </Fragment>
                </Suspense>
              </AccountsProvider>
            </SyncResources>
          </AuthContainer>
        ) : (
          <Suspense fallback={fallback}>{children}</Suspense>
        )}
      </ErrorBoundary>
    </UIShell>
  )
}
