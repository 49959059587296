import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
export function SortableItem({ id, slot, onSort, children, handle }) {
    const [, drag] = useDrag({
        type: slot,
        item: { id }
    });
    const [, drop] = useDrop({
        accept: slot,
        hover: (item) => {
            if (item.id !== id) {
                onSort(item.id, id);
            }
        }
    });
    return (_jsx("div", Object.assign({ ref: drop }, { children: _jsxs("div", Object.assign({ ref: drag }, { children: [handle, children] })) })));
}
/**
 * @description A hook that manages sorting state.
 */
export function useSortable(externalItems, { getKey, getSort, updateSort }) {
    const [items, setItems] = useState(externalItems);
    return {
        /**
         * @description Current state of the items.
         */
        items,
        /**
         * @description Updates the `items`
         * by swapping the sort value of item `a` and the sort value of item `b` with eachother.
         */
        sort: (a, b) => {
            setItems((items) => {
                return items.map((item) => {
                    if (getKey(item) === getKey(a))
                        return updateSort(item, getSort(b));
                    if (getKey(item) === getKey(b))
                        return updateSort(item, getSort(a));
                    else
                        return item;
                });
            });
        },
        /**
         * @description Sets the value of items.
         */
        setItems: setItems
    };
}
