import { Coordinator, LogTruncationStrategy, RequestStrategy, SyncStrategy } from '@orbit/coordinator';
import { memory } from '../sources/memory';
import { transientMemory } from '../sources/transient';
import { server, transientServer } from '../sources/server/server';
import { isQueryCached } from '../DataLayerProvider/queryExpressionCache';
import { CleanLicenceStrategy } from './CleanLicenceStrategy';
export const EVENT_LOGGING_STRATEGY_NAME = 'event-logging';
function isQuery(args) {
    return args.expressions !== undefined;
}
function alwaysFetch(query) {
    var _a, _b, _c, _d;
    if (Array.isArray(query.expressions)) {
        return query.expressions.some((expression) => {
            var _a, _b;
            return ((_a = expression === null || expression === void 0 ? void 0 : expression.record) === null || _a === void 0 ? void 0 : _a.type) === 'configuration' &&
                ((_b = expression === null || expression === void 0 ? void 0 : expression.record) === null || _b === void 0 ? void 0 : _b.id) === 'system';
        });
    }
    else {
        return (((_b = (_a = query.expressions) === null || _a === void 0 ? void 0 : _a.record) === null || _b === void 0 ? void 0 : _b.type) === 'configuration' &&
            ((_d = (_c = query.expressions) === null || _c === void 0 ? void 0 : _c.record) === null || _d === void 0 ? void 0 : _d.id) === 'system');
    }
}
export const coordinator = new Coordinator({
    sources: [memory, server, transientServer, transientMemory],
    strategies: [
        new LogTruncationStrategy(),
        new CleanLicenceStrategy(),
        new RequestStrategy({
            source: 'memory',
            on: 'beforeQuery',
            target: 'server',
            action: 'query',
            blocking: true,
            // false prevents the query being passed to the server
            filter(query) {
                // only query the server if the memory source is empty
                if (isQuery(query)) {
                    return (!isQueryCached({
                        options: query.options,
                        expressions: query.expressions
                    }) || alwaysFetch(query));
                }
                return true;
            }
        }),
        new RequestStrategy({
            source: 'memory',
            on: 'beforeUpdate',
            target: 'server',
            action: 'update',
            blocking: true,
            filter(update) {
                var _a;
                if ((_a = update === null || update === void 0 ? void 0 : update.options) === null || _a === void 0 ? void 0 : _a.doNotForward) {
                    return false;
                }
                return true;
            }
        }),
        new RequestStrategy({
            source: 'transient-memory',
            on: 'beforeQuery',
            target: 'transient-server',
            action: 'query',
            blocking: true
        }),
        /**
         * A SyncStrategy is set up between the server and the 'memory' source.
         * This strategy will synchronize changes that do NOT have the 'transient' option set to true.
         * This means that any changes from the server that are meant to be persistent (i.e., not transient)
         * will be stored in the 'memory' source. This is typically used for data that doesn't change frequently
         * and needs to be cached for performance reasons.
         */
        new SyncStrategy({
            source: 'server',
            target: 'memory',
            blocking: true
        }),
        /**
         * Another SyncStrategy is set up between the server and the 'transient-memory' source.
         * This strategy will synchronize changes that have the 'transient' option set to true.
         * This is useful for data like logs, which change frequently and don't need to be persisted.
         * The data will be stored in the 'transient-memory' source and cleared out before every sync operation.
         */
        new SyncStrategy({
            source: 'transient-server',
            target: 'transient-memory',
            blocking: true
        })
    ]
});
memory.requestQueue.on('fail', () => {
    // At the moment we wont do anything except to skip this task to unblock the queue
    memory.requestQueue.skip();
});
server.requestQueue.on('fail', () => {
    // At the moment we wont do anything except to skip this task to unblock the queue
    server.requestQueue.skip();
});
const isUpdateRecordOperation = (operation) => {
    return operation.op === 'updateRecord';
};
const cleanServiceRates = (operation) => {
    var _a, _b, _c, _d;
    if (!isUpdateRecordOperation(operation) ||
        operation.record.type !== 'service')
        return;
    const oldService = memory.cache.query((q) => q.findRecord({ type: 'service', id: operation.record.id }));
    const service = operation.record;
    if (oldService &&
        (((_a = oldService.attributes) === null || _a === void 0 ? void 0 : _a.charge_type) !== ((_b = service.attributes) === null || _b === void 0 ? void 0 : _b.charge_type) ||
            ((_c = oldService.attributes) === null || _c === void 0 ? void 0 : _c.cogs_type) !== ((_d = service.attributes) === null || _d === void 0 ? void 0 : _d.cogs_type))) {
        const rates = memory.cache.query((q) => q.findRelatedRecords(service, 'rates'));
        memory.cache.update((t) => rates.map((rate) => t.removeRecord({ type: 'rate', id: rate.id })));
        return Promise.resolve();
    }
    return Promise.resolve();
};
const isTransform = (args) => {
    return args.operations !== undefined;
};
memory.on('beforeUpdate', (transform) => {
    if (!isTransform(transform))
        return;
    if (Array.isArray(transform.operations)) {
        transform.operations.forEach(cleanServiceRates);
    }
    else {
        return cleanServiceRates(transform.operations);
    }
});
coordinator.activate();
