import React from "react";
import PropTypes from "prop-types";

import { classNames } from "../../../utils/misc";

/**
 * @type {React.ComponentType<any>}
 */

const Toolbar = ({ children, className, sticky, marginTop }) => (
  <div
    className={classNames(
      {
        "ex-form__toolbar--sticky": sticky,
        "ex-form__toolbar": true,
        "uk-width-1-1": true,
        "uk-text-right": true,
        "uk-margin-top": marginTop === "default",
        [`uk-margin-${marginTop}-top`]:
          marginTop === "small" || marginTop === "medium",
      },
      className
    )}
  >
    <div className="ex-form__toolbar__buttons">{children}</div>
  </div>
);

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  marginTop: PropTypes.oneOf(["small", "default", "medium"]),
  sticky: PropTypes.bool,
};

Toolbar.defaultProps = {
  marginTop: "medium",
};

Toolbar.defaultProps = {
  className: null,
  sticky: false,
};

export default Toolbar;
