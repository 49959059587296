import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { prop } from '@exivity/fp';
import { colorFromPurpose } from '../Theme';
const StyledPlaceholder = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorFromPurpose('secondary', 500)};
  width: 100%;
  height: ${prop('height')}px;
`;
export function Placeholder({ height = 300, className, children }) {
    return (_jsx(StyledPlaceholder, Object.assign({ className: className, height: height }, { children: children })));
}
