import styled from 'styled-components';
import { Block } from '../Block';
export const ExpandHeight = styled(Block) `
  min-height: calc(100vh - ${({ heightOffset = 100 }) => heightOffset}px);

  ${({ sticky }) => sticky &&
    `
    position: sticky;
    top: 80px;
    display: flex;
    flex-direction: column;
  `}

  & > * {
    height: 100%;
  }
`;
