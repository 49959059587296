const createServiceFinder = (reportItem) => {
  return (service) =>
    String(reportItem.service_id) === String(service.key) &&
    service.key !== "other";
};

export default (report, topServices, breakDownBy = "day", valueType) => {
  if (!report) {
    return null;
  }

  const data = {};
  // Loop over all top accounts, as to include only
  // the top series in the timeline limited by MAX_GRAPHS.
  report.forEach((reportItem) => {
    // If we didn't see this time-unit before, create an entry in the data list
    if (!data[reportItem[breakDownBy]]) {
      data[reportItem[breakDownBy]] = {
        date: reportItem[breakDownBy],
        other: 0,
      };
    }

    if (topServices.find(createServiceFinder(reportItem))) {
      data[reportItem[breakDownBy]][reportItem.service_id] =
        reportItem[valueType];
    } else {
      data[reportItem[breakDownBy]].other += reportItem[valueType];
    }
  });

  return {
    series: topServices.map((item) => ({ ref: item.key, title: item.title })),
    granularity: breakDownBy,
    data: Object.values(data),
  };
};
