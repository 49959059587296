import styled, { css } from 'styled-components';
import { height, size, space, width } from 'styled-system';
export const Image = styled.img.attrs((props) => ({
    as: props.background ? 'div' : 'img'
})) `
  box-sizing: border-box;
  ${(props) => props.background &&
    css `
      background-image: url('${props.src}');
      background-size: ${props.background};
      background-position: ${props.position && props.position !== 'center'
        ? props.position
        : '50% 50%'};
      background-repeat: ${props.repeat ? 'repeat' : 'no-repeat'};
    `}

  ${space}
  ${size}
  ${width}
  ${height}
`;
Image.displayName = 'Image';
