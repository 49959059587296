import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'date-fns';
import { fromTheme } from '../../Theme';
import { CalendarMode, getCalendar } from '../CalendarModes';
import { globalFont, toRgbString } from '../../utils';
import { CalendarBrowser } from '../CalendarBrowser';
import { Button } from '../../Button';
import { withExtendableProps } from '../../ExtendProps';
import { StyledQuarters } from '../CalendarModes/Quarters';
import { CalendarHeader } from '../CalendarHeader';
import { isCompleteRange, isEmptyRange, isPartialRange, orderRange } from '../DateRange';
import { useDateRange } from '../DateRangeCalendar/useDateRange';
import { isMac } from '../CalendarModes/shared';
const SingleUnitTip = styled.div `
  color: ${fromTheme((theme) => theme.colors.gray)};
  font-family: ${fromTheme((theme) => theme.global.fontFamily)};
  font-size: ${fromTheme((theme) => theme.global.sizes.small)}em;
  text-align: center;
`;
function getUnitName(mode) {
    switch (mode) {
        case CalendarMode.Days:
            return 'day';
        case CalendarMode.Months:
            return 'month';
        case CalendarMode.Quarters:
            return 'quarter';
        case CalendarMode.Years:
            return 'year';
        default:
            return '';
    }
}
const StyledDateRangeCalendar = styled.div `
  ${globalFont}

  min-width: 300px;
  margin-bottom: ${fromTheme((theme) => theme.space[1])};

  .time-unit {
    .header,
    .arrow {
      font-size: 20px;
    }

    &.start {
      border-radius: 180px;

      ${({ range }) => isPartialRange(range) &&
    css `
          &:not(:hover) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        `}

      ${({ range }) => isCompleteRange(range) &&
    css `
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        `}
    }

    &.end {
      border-bottom-right-radius: 180px;
      border-top-right-radius: 180px;
    }

    &.within-range {
      color: white;
      background-color: rgba(
        ${fromTheme((theme) => toRgbString(theme.global.purposes.primary))},
        0.5
      );
    }

    ${({ range }) => (isEmptyRange(range) || isCompleteRange(range)) &&
    css `
        &:not(.active):not(.out-of-bounds):not(.within-range):hover {
          border-radius: 180px;
        }

        &:not(.active):not(.out-of-bounds):hover {
          background-color: ${fromTheme((theme) => theme.global.purposes.primary)};
        }
      `}
  }

  ${StyledQuarters} {
    .start {
      ${({ range: [start] }) => start &&
    css `
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          border-top-right-radius: 180px;
          border-top-left-radius: 180px;
        `}
    }

    .end {
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      border-bottom-left-radius: 180px;
      border-bottom-right-radius: 180px;
    }

    .start.end {
      border-radius: 180px;
    }
  }

  button {
    width: 100%;
  }
`;
const compareDate = (dateOne, dateTwo) => {
    return dateOne && dateTwo ? isEqual(dateOne, dateTwo) : dateOne === dateTwo;
};
const equalRanges = (rangeOne, rangeTwo) => {
    return (compareDate(rangeOne[0], rangeTwo[0]) &&
        compareDate(rangeOne[1], rangeTwo[1]));
};
// Gonna refactor this soon
function _DateRangeCalendar({ mode = CalendarMode.Days, value, onChange = () => null, onClear, min, max, offset = 0 }) {
    const { Calendar, rangeReducer } = getCalendar(mode);
    const [browserDate, setBrowserDate] = useState((value && value[0]) || new Date());
    const useDateRangeOptions = useMemo(() => ({ bounds: { min, max }, offset }), [min, max, offset]);
    const [range, handlers] = useDateRange(value, rangeReducer, useDateRangeOptions);
    const [hoveredDate, setHoveredDate] = useState(null);
    useEffect(() => {
        const equal = value ? equalRanges(value, range) : false;
        if (!equal && isCompleteRange(range)) {
            onChange(range);
        }
    }, [onChange, value, range]);
    // The range as it is displayed in the calendar can be different from the actual range.
    const apparentRange = (hoveredDate && isPartialRange(range)
        ? orderRange([range[0], hoveredDate])
        : range);
    const tipText = `Hold down ${isMac() ? '⌘ CMD' : 'CTRL'} to select a single ${getUnitName(mode)}`;
    return (_jsx(StyledDateRangeCalendar, Object.assign({ "data-mode": mode, "data-offset": offset, "data-testid": "date-range-calendar", range: apparentRange }, { children: _jsxs(CalendarBrowser, Object.assign({ value: browserDate, mode: mode, onChange: setBrowserDate }, { children: [_jsx(CalendarHeader, { date: browserDate, mode: mode, offset: offset }), _jsx(Calendar, { value: apparentRange, browserDate: browserDate, min: min, max: max, offset: offset, onChange: (date, ctrlPressed) => {
                        if (ctrlPressed) {
                            handlers.selectDateAsRange(date);
                        }
                        else {
                            handlers.selectDate(date);
                        }
                    }, onHoverItem: setHoveredDate, onEndHoverItem: () => setHoveredDate(null) }), _jsx(SingleUnitTip, { children: tipText }), onClear && (_jsx(Button.Clear, Object.assign({ onClick: () => {
                        onClear();
                        handlers.resetRange();
                    } }, { children: "Clear range" })))] })) })));
}
export const DateRangeCalendarV2 = withExtendableProps('DateRangeCalendar')(_DateRangeCalendar);
