var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { matchColorFromPurpose, matchProp, themeFontSize, themeGlobal } from '../Theme';
export const StyledBadge = styled.label `
  pointer-events: none;
  vertical-align: middle;
  word-break: keep-all;
  font-size: ${themeFontSize(1)};
  text-transform: uppercase;
  color: ${matchProp({
    light: matchColorFromPurpose(900, 1, 'secondary'),
    default: matchColorFromPurpose(100, 1, 'secondary'),
    dark: matchColorFromPurpose(100, 1, 'secondary')
})};

  background-color: ${matchProp({
    light: matchColorFromPurpose(200, 1, 'secondary'),
    default: matchColorFromPurpose(500, 1, 'secondary'),
    dark: matchColorFromPurpose(700, 1, 'secondary')
})};
  border-radius: ${themeGlobal('borderRadius')}px;
  padding: ${({ small }) => (small ? '2px 4px' : '4.5px 8px')};
`;
export const Badge = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return _jsx(StyledBadge, Object.assign({}, rest, { children: children }));
};
