import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { colorFromPurpose, fromTheme, themeGlobal, themeSpace } from '../Theme';
import { toRgbString } from '../utils';
import { LoadingOverlay } from '../Loaders';
export const rgbColor = fromTheme((theme) => toRgbString(theme.global.purposes.brand));
const Layout = styled.div `
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 300px 300px minmax(0, 1fr);
  grid-template-rows: auto;
  grid-gap: ${themeSpace(1)};

  & > * {
    background-color: white;
    border: 1px solid ${colorFromPurpose('secondary', 300)};

    &:nth-child(1),
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      top: calc(${themeGlobal('headerHeight')}px + ${themeSpace(2)});
      position: sticky;
      max-height: ${themeGlobal('contentHeight')};
    }

    &:nth-child(3) {
      box-sizing: border-box;
      min-height: ${themeGlobal('contentHeight')};
      padding: ${themeSpace(2)};
    }
  }
`;
export function MasterMasterDetail({ loading = false, children }) {
    return (_jsxs(Layout, { children: [_jsx("div", { children: _jsx(LoadingOverlay, Object.assign({ loading: loading }, { children: children[0] })) }), _jsx("div", { children: _jsx(LoadingOverlay, Object.assign({ loading: loading }, { children: children[1] })) }), _jsx("div", { children: _jsx(LoadingOverlay, Object.assign({ loading: loading }, { children: children[2] })) })] }));
}
