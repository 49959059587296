import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { NavBar } from '@exivity/ui'
import { translate } from '@exivity/translations'
import {
  Link,
  Route,
  getIcon,
  getTitle,
  getPathname,
  getFeatureFlag,
  getFeatureLabel,
  getRequiredPermissions,
  useIsActiveRoute
} from '@exivity/routing'
import {
  useUserHasPermissionFn,
  useSystemConfiguration
} from '@exivity/data-layer'

import { profileActions, profileSelectors } from '../../my-profile/state'
import { useFeatureFlag } from '../../../components/organisms/Feature/Feature'
import { ReportFilter } from '../../reports/components/filters'
import { workSelectors } from '../../work/state'
import { app } from '../../../routes'

const StyledFooter = styled.div`
  width: 100%;
  height: 100%;

  a {
    display: none;
  }

  &:hover {
    a {
      display: block;
    }

    span {
      display: none;
    }
  }
`

const NavFooter = () => {
  const appName = useSystemConfiguration().attributes.APP_NAME || ''
  const about = app.routes.about
  return (
    <StyledFooter>
      <span>
        &copy; {new Date().getFullYear()} {appName}
      </span>
      <Link to={getPathname(about)} style={{ textDecoration: 'underline' }}>
        {getTitle(about)}
      </Link>
    </StyledFooter>
  )
}

function NavGroup<T extends Route>({ route }: { route: T }) {
  const group = route
  const shouldRender = useFeatureFlag()
  const hasPermission = useUserHasPermissionFn()
  const groupRoutes = Object.values(group.routes || {})
  const isActive = useIsActiveRoute()

  if (!hasPermission(getRequiredPermissions(route))) {
    return null
  }

  const filteredGroupRoutes = groupRoutes.filter(
    (route) =>
      hasPermission(getRequiredPermissions(route)) &&
      shouldRender(getFeatureFlag(route))
  )

  if (!filteredGroupRoutes || !filteredGroupRoutes.length) return null

  return (
    <NavBar.Group
      name={getTitle(route)}
      icon={getIcon(route)}
      active={isActive(route)}
    >
      {filteredGroupRoutes.map((route) => (
        <NavBar.Link
          key={getPathname(route)}
          to={getPathname(route)}
          active={isActive(route)}
          label={getFeatureLabel(route)}
        >
          {getTitle(route)}
        </NavBar.Link>
      ))}
    </NavBar.Group>
  )
}

export function Navigation() {
  const { home, reports, services, accounts, datapipelines } = app.routes

  const collapsed = useSelector(profileSelectors.isNavCollapsed)
  const progress = useSelector(workSelectors.getBackgroundProgress)
  const backgroundIsBusy = useSelector(workSelectors.isBackgroundBusy)

  const isActive = useIsActiveRoute()
  const dispatch = useDispatch()

  return (
    <NavBar
      progress={progress}
      progressIsActive={backgroundIsBusy}
      progressText={translate('Working...')}
      footer={<NavFooter />}
      collapsed={collapsed}
      onCollapse={(status) => {
        dispatch(profileActions.updateCollapseSidebar(status))
      }}
    >
      <NavBar.Link
        to={getPathname(home)}
        icon={getIcon(home)}
        active={isActive(home)}
      >
        {getTitle(home)}
      </NavBar.Link>
      <NavBar.Separator />
      <ReportFilter />
      <NavGroup route={reports} />
      <NavGroup route={services} />
      <NavGroup route={accounts} />
      <NavBar.Separator />
      <NavGroup route={datapipelines} />
    </NavBar>
  )
}
