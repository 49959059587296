import config from '../../../../application/config'
import { createAsyncThunk, ActionDispatcher } from '../../../../store/utils'
import { post, patch } from '../../../../API'
import { authActions } from '../'
import { reportsActions } from '../../../reports/state'
import { profileActions } from '../../../my-profile/state'
import { registerUserWithSentry } from '../../../../utils/log'
import { AuthToken } from '../../../../API/ResponseSchema'

type GetTokenArgs = {
  username: string
  password: string
  onAbortController?: (abortController: AbortController) => void
}

const initializeUser = createAsyncThunk(
  'auth/initializeUser',
  (
    { token, permissions, state, user }: AuthToken['response'],
    { dispatch }
  ) => {
    registerUserWithSentry(user.username)
    dispatch(authActions.updateToken(token))
    dispatch(authActions.updatePermissions(permissions))
    dispatch(authActions.updateIsAuthenticated(true))

    const { id, ...attributes } = user

    dispatch(authActions.updateCurrentUserId(id))
    dispatch(
      authActions.updateCurrentUser({ type: 'user', id, attributes } as any)
    )
    dispatch(profileActions.loadSlice(state.user))
    dispatch(reportsActions.filters.loadSlice(state.reportsFilters))
    dispatch(reportsActions.gui.loadSlice(state.reportsGui))
  }
)

export const loginWithCredentials = createAsyncThunk<any, GetTokenArgs>(
  'auth/loginWithCredentials',
  ({ username, password }, { dispatch, signal }) =>
    post(
      '/auth/token',
      { app: config.app.name },
      { username, password },
      { signal }
    ).then((response) => dispatch(initializeUser(response)))
)

export const loginWithToken = createAsyncThunk(
  'auth/loginWithToken',
  (token: string, { dispatch }) => {
    dispatch(authActions.updateToken(token))

    return patch('/auth/token', { app: config.app.name }).then((response) =>
      dispatch(initializeUser(response))
    )
  }
)

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  (_, { dispatch }) =>
    patch('/auth/token', { app: config.app.name }).then((response) =>
      dispatch(authActions.updateToken(response.token))
    )
)

