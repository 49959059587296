import { Hook, HookContext, HookType } from '../fetchWithHooks/fetchWithHooks'

import { ifElse } from './ifElse'

export function iff<HT extends HookType, T>(
  this: any,
  predicate: (ctx: HookContext<HT, T>) => boolean,
  ...trueHooks: Hook<HT, T>[]
) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this

  function iffWithoutElse(context: HookContext<HT, T>) {
    return ifElse(predicate, trueHooks, []).call(self, context)
  }

  iffWithoutElse.else = (...falseHooks: Hook<HT, T>[]) =>
    ifElse(predicate, trueHooks, falseHooks)

  return iffWithoutElse
}
