import styled from 'styled-components';
import { compose } from 'ramda';
import { globalFont, toRgbString } from '../utils';
import { colorFromPurpose } from '../Theme';
const brandRGB = compose(toRgbString, colorFromPurpose('brand', 500));
export const Link = styled.a `
  ${globalFont};
  font-size: inherit;

  display: inline-grid;
  grid-gap: 0.25em;
  align-items: center;
  grid-auto-flow: column;
  text-decoration: none;

  color: ${colorFromPurpose('brand', 500)};
  --focus-color: ${brandRGB};

  &:focus {
    outline: none;
    border-radius: 1px;
    background-color: rgba(var(--focus-color), 0.3);
    box-shadow: 0 0 0 4px rgba(var(--focus-color), 0.3);
  }

  &:hover {
    text-decoration: underline;
  }
`;
