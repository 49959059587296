import { PARENT } from './data';
import { getChildren } from './traverse';
import { listToTreeMap } from './map';
export function treeMapToTreeList(map) {
    return Object.values(map).reduce((list, item) => {
        return item[PARENT] ? list : list.concat([item, ...getChildren(item)]);
    }, []);
}
export function listToTreeList(list, keyAccessor, parentKeyAccessor) {
    const treeMap = listToTreeMap(list, keyAccessor, parentKeyAccessor);
    return treeMapToTreeList(treeMap);
}
