import { createContext, useContext } from 'react';
export const TabsContext = createContext({
    activeIndex: 0,
    setActiveIndex: () => { },
    disableAnimation: false,
    purposes: {}
});
export const useTabsContext = () => {
    return useContext(TabsContext);
};
