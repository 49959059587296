import { BudgetReportData } from '../../../../API/APIResponseTypes/reports'

export const DATA_REPORTS_UPDATE_BUDGETS = 'DATA_REPORTS_UPDATE_BUDGETS'
export const DATA_REPORTS_BUDGET_SELECT = 'DATA_REPORTS_BUDGET_SELECT'
export const DATA_REPORTS_BUDGET_SET_DATE_RANGE =
  'DATA_REPORTS_BUDGET_SET_DATE_RANGE'

export const updateBudgetReportData = (data: BudgetReportData[] | string) =>
  ({
    type: DATA_REPORTS_UPDATE_BUDGETS,
    data
  } as const)

export const removeBudgetReportData = () => updateBudgetReportData([])

export const selectBudget = (budgetId: string) =>
  ({
    type: DATA_REPORTS_BUDGET_SELECT,
    budgetId
  } as const)

export const setDateRange = ([start, end]: [string, string]) =>
  ({
    type: DATA_REPORTS_BUDGET_SET_DATE_RANGE,
    range: [start, end]
  } as const)

export const clearDateRange = () =>
  ({
    type: DATA_REPORTS_BUDGET_SET_DATE_RANGE,
    range: null
  } as const)

export type BudgetReportActions =
  | ReturnType<typeof updateBudgetReportData>
  | ReturnType<typeof removeBudgetReportData>
  | ReturnType<typeof selectBudget>
  | ReturnType<typeof setDateRange>
  | ReturnType<typeof clearDateRange>
