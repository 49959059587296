import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { fromTheme, themeColor, themeGlobal, themeSpace } from '../Theme';
import { toRgbString } from '../utils';
export const Widget = styled.div `
  height: 100%;
  background: white;
  box-shadow: 1px 1px 0
    rgba(${fromTheme((theme) => toRgbString(theme.global.purposes.brand))}, 0.4);
  box-sizing: border-box;
`;
export const Container = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
`;
export const Master = styled.div `
  box-sizing: border-box;
  position: sticky;
  top: 80px;

  min-width: ${({ width }) => width || '325'}px;
  max-width: ${({ width }) => width || '420'}px;
  height: ${themeGlobal('contentHeight')};
  margin-right: ${themeSpace(1)};

  @media all and (display-mode: fullscreen) {
    height: ${themeGlobal('fullscreenContentHeight')};
  }
`;
export const Title = styled.div `
  display: flex;
  align-items: center;
  padding: 0 ${themeSpace(2)};
  height: 50px;
  border-bottom: 1px solid ${themeColor('lightGray')};
  text-transform: uppercase;
  color: ${themeColor('gray')};
`;
export const StyledDetail = styled.div `
  position: relative;
  width: 100%;

  & > ${Widget} {
    min-height: ${themeGlobal('contentHeight')};
    padding: ${themeSpace(2)};
  }

  @media all and (display-mode: fullscreen) {
    & > ${Widget} {
      min-height: ${themeGlobal('fullscreenContentHeight')};
    }
  }
`;
export function DetailWidget({ children }) {
    return (_jsx(StyledDetail, { children: _jsx(Widget, { children: children }) }));
}
export function MasterWidget({ title, width, children }) {
    return (_jsx(Master, Object.assign({ width: width }, { children: _jsxs(Widget, { children: [title && _jsx(Title, { children: title }), children] }) })));
}
