import React from "react";
import PropTypes from "prop-types";

import { hasValue } from "./../../../utils/misc";

const Checkbox = ({
  label,
  name,
  className,
  labelClassName,
  size,
  width,
  checked,
  onChange,
  onClick,
  disabled,
  controlled,
  value = true,
}) => (
  <div className={`uk-width-${width}`}>
    <label className={labelClassName}>
      <input
        type="checkbox"
        className={`uk-checkbox ${className} uk-form-${size}`}
        name={name}
        value={value}
        checked={controlled ? checked : undefined}
        defaultChecked={controlled ? undefined : checked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
      &nbsp;{hasValue(label) ? label : name}
    </label>
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  controlled: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "normal", "large"]),
  value: PropTypes.string,
  width: PropTypes.oneOf(["auto", "1-1", "1-2", "1-3", "1-4"]),
};

Checkbox.defaultProps = {
  size: "normal",
  width: "auto",
  checked: false,
  controlled: false,
  disabled: false,
  labelClassName: "",
  className: "",
  onChange: () => {},
};

export default Checkbox;
