import styled, { css } from 'styled-components';
import { globalFont, injectIf } from '../utils';
import { themePurpose } from '../Theme';
import { Label } from './Label';
import { Highlight } from './Highlight';
const _Text = styled.span `
  ${globalFont}
  ${injectIf('active', css `
      color: ${themePurpose('brand')} !important;
    `)}
`;
const Text = _Text;
Text.displayName = 'Text';
Text.Label = Label;
Text.Highlight = Highlight;
export { Text };
