import { DeepPartial } from '@reduxjs/toolkit'
import { Resources } from '@exivity/data-layer'

import { patch } from '../../../../API/fetch'
import { createAsyncThunk } from '../../../../store/utils'
import { workThunks } from '../../../work/thunks'

export const updateCurrentUser = createAsyncThunk(
  'patchCurrentUser',
  (
    user: DeepPartial<Resources['user']>,
    { dispatch, extra: { translate } }
  ) => {
    return patch(
      '/users/me',
      {},
      { data: user },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }
    ).then((response) => {
      dispatch(workThunks.showSuccessMessage(translate('Profile updated.')))
      return response.data
    })
  }
)
