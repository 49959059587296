import React from "react";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";

import { Checkbox } from "../Form";

import { classNames } from "./../../../utils/misc";

const expandCollapseHandler = function (event, item, cb) {
  event.stopPropagation();
  item.meta.expanded = !item.meta.expanded;
  cb && cb(item);
};

const checkedHandler = function (event, item, autoCheckChildren, cb) {
  event.stopPropagation();
  if (!(autoCheckChildren && item.parent && item.parent.meta.checked)) {
    item.meta.checked = !item.meta.checked;
  }
  cb && cb(item, autoCheckChildren);
};

// Using onMouseDown instead of onClick in order to prevent UIKIT dropdown from closing

const expand = (item, onClick) => (
  <span key="expand" className="ex-advanced-select__expand-collapse">
    <span
      data-uk-icon="icon: plus; ratio: .6"
      onMouseDown={(event) => expandCollapseHandler(event, item, onClick)}
    />
  </span>
);

const collapse = (item, onClick) => (
  <span key="collapse" className="ex-advanced-select__expand-collapse">
    <span
      data-uk-icon="icon: minus; ratio: .6"
      onMouseDown={(event) => expandCollapseHandler(event, item, onClick)}
    />
  </span>
);

const Cell = ({
  item,
  field,
  onExpandOrCollapse,
  onChecked,
  grouped,
  expandable,
  expanded,
  searchTerm,
  autoCheckChildren,
  checked,
  checkable,
  useOnClickToCheck,
  className,
  muted,
  cellRenderer,
  cellFull,
}) => {
  let itemText;
  let showExpandCollapse = false;
  let simple = item.attributes[field];
  let rendered = item.attributes[field];

  // @todo refactor all code using origValue to use cellRenderer instead
  if (item.meta.origValue) {
    simple = item.meta.origValue;
  }

  if (cellRenderer) {
    rendered = cellRenderer({ cellData: simple, rowData: item });
  }

  if (searchTerm) {
    itemText = (
      <span
        key="text"
        className={classNames({ "uk-width-1-1": cellFull }, "uk-text-truncate")}
      >
        <Highlighter
          autoEscape
          searchWords={[searchTerm]}
          textToHighlight={simple}
        />
      </span>
    );
  } else {
    itemText = (
      <span
        key="text"
        className={classNames(
          {
            "uk-text-muted": muted,
            "uk-width-1-1": cellFull,
          },
          "uk-text-truncate"
        )}
      >
        {rendered}
      </span>
    );
  }

  const handleOnClick = !useOnClickToCheck
    ? (event) => checkedHandler(event, item, autoCheckChildren, onChecked)
    : () => null;

  const checkbox = (
    <Checkbox
      key={item.attributes[field]}
      name={field}
      value={String(item.key)}
      className="uk-margin-small-right"
      label=""
      checked={
        !!(
          checked ||
          (item.parent && item.parent.meta.checked && autoCheckChildren)
        )
      }
      disabled={autoCheckChildren && item.parent && item.parent.meta.checked}
      controlled
      onChange={handleOnClick}
    />
  );

  if (expandable || grouped) {
    showExpandCollapse = !expanded && !searchTerm;

    const spacing = (
      <span key="spacing" className="ex-advanced-select__spacers">
        {new Array(item.meta.depth).fill(null).map((item, index) => (
          <span key={index} className="ex-advanced-select__spacer" />
        ))}
      </span>
    );

    if (item.meta.expanded) {
      itemText = [
        spacing,
        showExpandCollapse && collapse(item, onExpandOrCollapse),
        checkable && checkbox,
        itemText,
      ];
    } else if (item.children && item.children.length) {
      itemText = [
        spacing,
        showExpandCollapse && expand(item, onExpandOrCollapse),
        checkable && checkbox,
        itemText,
      ];
    } else {
      itemText = [spacing, checkable && checkbox, itemText];
    }
  }

  const classes = {
    "ex-advanced-select__cell": true,
    "ex-advanced-select__cell--expandable": expandable && showExpandCollapse,
    "ex-advanced-select__cell--grouped": grouped,
  };

  return (
    <div
      key={item.key}
      className={classNames(classes, className)}
      title={simple ? String(simple) : undefined}
    >
      {!(expandable || grouped) && checkable && checkbox}
      {itemText}
    </div>
  );
};

Cell.propTypes = {
  autoCheckChildren: PropTypes.bool,
  cellFull: PropTypes.bool,
  cellRenderer: PropTypes.any,
  checkable: PropTypes.bool,
  checked: PropTypes.any,
  className: PropTypes.any,
  expandable: PropTypes.bool,
  expanded: PropTypes.any,
  field: PropTypes.any,
  grouped: PropTypes.any,
  item: PropTypes.object.isRequired,
  muted: PropTypes.bool,
  onChecked: PropTypes.any,
  onExpandOrCollapse: PropTypes.func,
  searchTerm: PropTypes.string,
  useOnClickToCheck: PropTypes.bool,
};

Cell.defaultProps = {
  autoCheckChildren: false,
  checkable: false,
  expandable: false,
  filterable: false,
  muted: false,
  searchable: false,
  useOnClickToCheck: false,
  full: false,
};

export default Cell;
