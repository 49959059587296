var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePriority } from '../Theme';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { Flex } from '../Flex';
const LoadingOverlayContainer = styled.div `
  position: relative;
  height: 100%;
  width: 100%;
`;
const Overlay = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(255, 255, 255, 0.8);
  z-index: ${themePriority(3)};
`;
export const LoadingOverlay = (_a) => {
    var { children, loading, onCancel } = _a, props = __rest(_a, ["children", "loading", "onCancel"]);
    return (_jsxs(LoadingOverlayContainer, Object.assign({}, props, { children: [loading && (_jsx(Overlay, { children: _jsxs(Flex, Object.assign({ direction: "column", alignItems: "center", gap: 10 }, { children: [_jsx(Spinner, { className: "ui-loading-overlay__svg" }), onCancel && _jsx(Button.Cancel, { onClick: onCancel })] })) })), children] })));
};
