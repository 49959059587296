import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { Block } from '../Block';
import { colorFromPurpose } from '../Theme';
export const StyledWidget = styled(Block) `
  background: white;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${colorFromPurpose('secondary', 300)};

  ${({ sticky, stickyOffset = 0 }) => sticky &&
    css `
      top: ${stickyOffset}px;
      position: sticky;
    `}
`;
export const Widget = (props) => (_jsx(StyledWidget, Object.assign({ borderRadius: 3, p: 2 }, props)));
Widget.displayName = 'Widget';
