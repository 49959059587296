"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reduce = void 0;
const ramda_1 = require("ramda");
exports.reduce = (0, ramda_1.curry)((f, init, tree) => {
    function go(acc, node, indexPath) {
        return node.children.reduce((acc, child, i) => go(acc, child, [...indexPath, i]), f(acc, node, indexPath, tree));
    }
    return go(init, tree, []);
});
