import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { fromTheme, themePriority } from '../Theme';
const MenuItem = styled.div `
  display: flex;
  flex-direction: flex-row;
  align-items: center;

  margin-right: ${fromTheme((theme) => theme.space[1])};
  z-index: ${themePriority(2)};

  label {
    margin-right: ${fromTheme((theme) => theme.space[1])};
    white-space: nowrap;
  }
`;
const StyledMenu = styled.div `
  position: relative;
  height: 50px;

  .container {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;
    background-color: ${fromTheme((theme) => theme.colors.veryLightGray)};
    cursor: pointer;

    border-radius: 0.4rem;
    padding: 0 ${fromTheme((theme) => theme.space[2])};

    & > div {
      margin-right: ${fromTheme((theme) => theme.space[1])};
    }

    & div:last-child {
      margin-right: 0;
    }
  }
`;
export function Menu({ children, className }) {
    return (_jsx(StyledMenu, Object.assign({ className: className }, { children: _jsx("div", Object.assign({ className: "container" }, { children: children })) })));
}
Menu.Item = MenuItem;
