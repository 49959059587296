import { useSelector } from 'react-redux'
import { AppBar as AppBarUI } from '@exivity/ui'
import { useRoute } from '@exivity/routing'
import { UserGroupPermission, useUserHasPermission } from '@exivity/data-layer'

import { workSelectors } from '../../work/state'

import { usePageTitle } from './usePageTitle'
import { useIcon } from './useIcon'
import { useLogo } from './useLogo'
import { AdministrationMenu } from './AdministrationMenu'
import { UserMenu } from './UserMenu'
import { DocumentationLink } from './DocumentationLink'
import { StatusLink } from './StatusLink'

export function AppBar() {
  const workMessage = useSelector(workSelectors.getUserMessage)
  const progress = useSelector(workSelectors.getForegroundProgress)
  const isLoading = useSelector(workSelectors.isForegroundBusy)
  const AppIcon = useIcon()
  const AppLogo = useLogo()
  const title = usePageTitle()
  const { exactRoute } = useRoute()

  const hasDatasourcesPermission = useUserHasPermission([
    UserGroupPermission.ManageDataSources
  ])

  const hasReportPermission = useUserHasPermission([
    UserGroupPermission.ManageReports
  ])

  return (
    <AppBarUI
      message={workMessage}
      icon={<AppIcon />}
      logo={<AppLogo />}
      title={title}
      isLoading={isLoading}
      progress={progress}
    >
      {exactRoute.requiresAuth && (
        <>
          <DocumentationLink />
          {(hasReportPermission || hasDatasourcesPermission) ? <StatusLink /> : null}
          <AdministrationMenu />
          <UserMenu />
        </>
      )}
    </AppBarUI>
  )
}
