import { createSelectors } from '../../../store/utils'

export const selectors = createSelectors((state) => state.datasources, {
  getExtractorRunStatus: (datasources) => datasources.use.runStatus,
  getExtractorRunLog: (datasources) => datasources.use.runLog,
  getExtractorRunId: (datasources) => datasources.use.runId,
  getTransformerRunStatus: (datasources) => datasources.transcript.runStatus,
  getTransformerRunLog: (datasources) => datasources.transcript.runLog,
  getTransformerRunId: (datasources) => datasources.transcript.runId,
  getTranscriptState: (datasources) => datasources.transcript,
  getPreviewRunStatus: (datasources) => datasources.previewer.runStatus,
  getPreviewData: (datasources) => datasources.transcript.previewData,
  getPreviewColumns: (datasources) => datasources.transcript.previewColumns,
  getPreviewParams: (datasources) => ({
    preview: 1,
    environment_id: datasources.transcript.environmentId ?? '',
    date: datasources.transcript.date,
    limit: datasources.transcript.limit,
    // Backend handles this value as 'break at' while we want
    // it to work as 'break after'. Hence the +1.
    break_at: datasources.transcript.breakAfter + 1,
    snapshot_dset: datasources.transcript.dataset
  }),
  getPreviewEscapeChar: (datasources) => datasources.transcript.escapeChar,
  getPreviewError: (datasources) => datasources.transcript.previewError
})
