import { composeAsync, map } from '@exivity/fp'

import { Hook, HookContext, HookType } from './fetchWithHooks'
import { isHookContext } from './hookObject'

export function processHooks<HT extends HookType>(
  hooks: Hook<HT>[],
  initialHookObject: HookContext<HT>
) {
  let hookObject = initialHookObject

  const updateCurrentHook = composeAsync((current: any) => {
    if (current) {
      if (!isHookContext(current)) {
        throw new Error(`${hookObject.type} hook for 
        '${hookObject.method}' method returned invalid hook object`)
      }

      hookObject = current
    }

    return hookObject
  })

  const alteredHooks = map(
    (hook) => (obj: HookContext<HT>) => updateCurrentHook(hook(obj)),
    hooks
  )

  // Go through all hooks and chain them into our promise
  // @ts-ignore
  const promise = composeAsync(...alteredHooks.reverse())

  // @ts-ignore
  return promise(initialHookObject)
    .then(() => hookObject)
    .catch((error) => {
      error.hook = hookObject
      throw error
    })
}
