import { any, add, map, prop, last, none, isNil, chain, ifElse, always, isEmpty, compose, zipWith, subtract, arrayFrom, unlessIsNil, composeAsync, querySelector, addElementClass, getElementChild, querySelectorAll, elementContainsClass, getBoundingClientRect, getElementAnimations, removeElementClass, animateElement } from '@exivity/fp';
const getElementTop = compose(ifElse(isNil, always(0), compose(prop('top'), getBoundingClientRect)));
const getChildren = (el) => arrayFrom(el.children);
const getOpenGroups = compose(arrayFrom, querySelectorAll('.group--expand'));
const getListHeight = compose(ifElse(isNil, always(0), compose(prop('height'), getBoundingClientRect)), 
// @ts-ignore - isNil check
querySelector('ul'));
const processCoverHeight = compose(getListHeight, last);
export const getCoverHeight = compose(ifElse(isEmpty, always(0), processCoverHeight), arrayFrom, querySelectorAll('.navbar__group'));
const anyNotZero = any((x) => x !== 0);
const allAnimationsDone = compose(isEmpty, chain(getElementAnimations), getChildren);
export const measurePositions = compose(map(getElementTop), getChildren);
function getTranslateY(element) {
    const style = window.getComputedStyle(element);
    const matrix = new WebKitCSSMatrix(style.transform);
    return matrix.m42;
}
export const getTranslatesYChildren = compose(map(getTranslateY), getChildren);
export const openGroup = (el, index) => unlessIsNil(addElementClass('group--expand'), getElementChild(index, el));
const openGroups = map(addElementClass('group--expand'));
const closeAllGroups = compose(map(removeElementClass('group--expand')), getChildren);
const allGroupsAreClosed = compose(none(elementContainsClass('group--expand')), getChildren);
const subtractLists = zipWith(subtract);
const addLists = zipWith(add);
const getFromToOpenGroup = (navElement, clickedIndex) => {
    const current = measurePositions(navElement);
    openGroup(navElement, clickedIndex);
    const end = measurePositions(navElement);
    const currentTranslates = getTranslatesYChildren(navElement);
    const from = anyNotZero(currentTranslates)
        ? currentTranslates
        : subtractLists(current, end);
    return map((from) => [from, 0], from);
};
const getFromToCloseGroup = (navElement) => {
    const current = measurePositions(navElement);
    const openedGroups = getOpenGroups(navElement);
    closeAllGroups(navElement);
    const end = measurePositions(navElement);
    openGroups(openedGroups);
    return zipWith((from, to) => [from, to], getTranslatesYChildren(navElement), subtractLists(end, current));
};
const getFromToOpenAndCloseGroup = (navElement, clickedIndex) => {
    const current = measurePositions(navElement);
    openGroup(navElement, clickedIndex);
    const between = measurePositions(navElement);
    const openedGroups = getOpenGroups(navElement);
    closeAllGroups(navElement);
    openGroup(navElement, clickedIndex);
    const end = measurePositions(navElement);
    openGroups(openedGroups);
    const startOffset = subtractLists(current, between);
    const from = addLists(startOffset, getTranslatesYChildren(navElement));
    const to = addLists(startOffset, subtractLists(end, current));
    return zipWith((from, to) => [from, to], from, to);
};
const animate = (el, [from, to]) => animateElement({
    duration: 200,
    easing: 'ease-in-out'
}, [
    { transform: `translateY(${from}px)` },
    { transform: `translateY(${to}px)` }
], el);
export const animateNavElements = (navElement, openIndex) => {
    const fromTo = !openIndex
        ? getFromToCloseGroup(navElement)
        : allGroupsAreClosed(navElement)
            ? getFromToOpenGroup(navElement, openIndex)
            : getFromToOpenAndCloseGroup(navElement, openIndex);
    const animations = zipWith(animate, getChildren(navElement), fromTo);
    const switchToFinalStateWhenFinished = composeAsync(() => {
        if (allAnimationsDone(navElement)) {
            closeAllGroups(navElement);
            openIndex && openGroup(navElement, openIndex);
        }
    }, Promise.all.bind(Promise), map(prop('finished')));
    switchToFinalStateWhenFinished(animations);
};
