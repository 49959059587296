import { useCallback } from 'react';
import { omit } from 'ramda';
import { useDataStore } from '../useDataStore';
import { getExpressionResult } from '../queries/getExpressionResult';
const omitSort = omit(['sort']);
const array = [];
export const useCacheQueryFn = () => {
    const store = useDataStore();
    return useCallback((queryModifier) => {
        const queryExpression = queryModifier.toQuery();
        try {
            // We have to narrow sort and filter types for records and
            // relatedRecords operations
            // @ts-ignore
            return getExpressionResult(queryExpression, () => store.cache.query(omitSort(queryExpression)));
        }
        catch (_a) {
            // When query fails return array for collections and null for record
            return (queryExpression.op === 'findRecords' ||
                queryExpression.op === 'findRelatedRecords'
                ? array
                : null);
        }
    }, [store]);
};
