import { useCallback } from 'react';
import { complement, compose } from '@exivity/fp';
import { shouldNotExecuteQuery } from '../queries/getExpressionResult';
import { getResourcePermissions, useUserHasPermissionFn } from '../permissions';
import { useServer } from '../DataLayerProvider';
import { getQueryType } from '../queries/utils';
import { applyAPIQueryFilters } from './APIQueryFilters';
export function useAPIQuery() {
    const server = useServer();
    const userHasPermission = useUserHasPermissionFn();
    return useCallback((queryBuilder, options) => {
        const queryExpression = queryBuilder.toQuery();
        if (shouldNotExecuteQuery(queryExpression))
            return;
        const resourceNotAllowed = compose(complement(userHasPermission), getResourcePermissions, getQueryType);
        if (resourceNotAllowed(queryExpression))
            return;
        try {
            if (queryExpression.op === 'findRecords' ||
                queryExpression.op === 'findRelatedRecords') {
                queryExpression.page = { offset: 0, limit: -1, kind: 'offsetLimit' };
            }
            // Do not return result - We use the memory source for results in components
            server.query(
            // @ts-ignore
            applyAPIQueryFilters(queryExpression), options);
        }
        catch (_a) { }
    }, [userHasPermission, server]);
}
