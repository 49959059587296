import  { useEffect, useMemo, useState } from 'react'
import { transformSelectors, useServerOnly, useStoreListener } from '@exivity/data-layer';
import { useSelector } from 'react-redux';
import { groupBy, map, max, min, path, pipe } from 'ramda';
import { tube } from '@exivity/fp';

import { authSelectors } from '../../auth/state';
import { Resources } from '@exivity/data-layer';
import { useHasJobStatusPermission } from './useJobs';

export type ReportActions = Record<string, { start_date: string, end_date: string }>

export const useReportStatuses = (appNeedsSync: boolean) => {
  const serverOnly = useServerOnly()

  const isAuthenticated = useSelector(authSelectors.isAuthenticated)

  const [reportStatuses, setReportStatuses] = useState<Resources['reportstatus'][]>([]) 

  const { canSeeReportStatuses } = useHasJobStatusPermission()

  useStoreListener(
    'update',
    pipe(
      transformSelectors.getOperationsProp,      
      transformSelectors.filterResources(
        'service',
        'rate', 
        'adjustment', 
        'servicesubscription', 
        'ratetier',
      ),
      (operations) => {
        if (operations.length > 0 && !appNeedsSync && canSeeReportStatuses) {
          serverOnly.query((q) => q
            .findRecords('reportstatus')
            .page({ limit: -1, offset: 0 })
            .options({
              include: ['reportdefinition'],
              settings: {
                silent: true,
                params: {
                  sort: '-date',
                  'filter[invalidated_at]': 'NOT_NULL'
                },
              },
          }))
          .then((result) => setReportStatuses(result as Resources['reportstatus'][]))
          .catch(() => {
            // do nothing
          })
        }
      }
    )
  )

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const getReportStatuses = ({ silent = false } = {}) => {
      serverOnly.query((q) => q
        .findRecords('reportstatus')
        .page({ limit: -1, offset: 0 })
        .options({
          include: ['reportdefinition'],
          settings: {
            silent,
            params: {
              sort: '-date',
              'filter[invalidated_at]': 'NOT_NULL'
            },
          },
        }))
        .then((result) => setReportStatuses(result as Resources['reportstatus'][]))
        .catch(() => {
          // do nothing
        })

      timeout = setTimeout(() => {
        getReportStatuses({
          silent: true,
        });
      }, 30000)
    }

    isAuthenticated && canSeeReportStatuses && getReportStatuses()

    return () => {
      clearTimeout(timeout);
    };
  }, [serverOnly, isAuthenticated, canSeeReportStatuses]);

  return useMemo(() => (
    tube(
      reportStatuses,
      groupBy(path(['relationships', 'reportdefinition', 'data', 'id']) as any) as any,
      map(group => {
        const dates: string[] = map(path(['attributes', 'date']) as any, group)
        const start_date = dates.reduce(min, '9999-12-31')
        const end_date = dates.reduce(max, '0000-01-01')
        return { start_date, end_date }
      })
    ) as unknown as ReportActions
  ), [reportStatuses])
}
