const hashes = new Set();
function createHash(str) {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) + hash + char;
    }
    return hash.toString(16);
}
export function isQueryCached(queryObject) {
    const queryString = JSON.stringify(queryObject);
    const queryHash = createHash(queryString);
    if (hashes.has(queryHash)) {
        return true;
    }
    hashes.add(queryHash);
    return false;
}
export const clearQueryCache = () => {
    hashes.clear();
};
