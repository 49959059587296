import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Router,
  getPathname,
  useLocation,
  RenderRoutes,
  browserHistory,
  getActiveRoutes,
  useRedirectRoute,
  getAllRequiredPermissions,
  isAuthRequired
} from '@exivity/routing'
import { useUserHasPermissionFn } from '@exivity/data-layer'

import { trackPageview } from '../../utils/ga'
import { useSAML } from '../../domains/auth/SingleSignOn/SAML/authCycle'
import { isDevEnvironment } from '../../utils/system'
import { authSelectors } from '../../domains/auth/state'
import { app } from '../../routes'

browserHistory.listen(({ location }) => {
  if (!isDevEnvironment()) {
    trackPageview(location.pathname)
  }
})

function useRoutePermissions() {
  const { pathname } = useLocation()
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const replaceRoute = useRedirectRoute()
  const required = getAllRequiredPermissions(getActiveRoutes(pathname, routes))
  const userHasPermission = useUserHasPermissionFn()

  useEffect(() => {
    if (isAuthenticated && !userHasPermission(required)) {
      replaceRoute(app.routes.forbidden)
    }
  })
}

function useRouteAuthentication() {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const { pathname } = useLocation<{ referrer?: string }>()
  const replaceRoute = useRedirectRoute()
  const activeRoutes = getActiveRoutes(pathname, routes)

  useEffect(() => {
    // redirect to home when on app path
    if (getPathname(app) === pathname) {
      replaceRoute(app.routes.home)
    }

    if (!isAuthenticated && isAuthRequired(activeRoutes)) {
      replaceRoute(app.routes.login, pathname)
    }
  })
}

const routes = {
  routes: app
} as any

const Routes = () => {
  // If we ever remove useSAML, be sure to check if there is no other
  // api related code relying on the gui taking the token from the url to refresh token state
  useSAML()
  useRouteAuthentication()
  useRoutePermissions()

  return <RenderRoutes routes={routes} />
}

const Dispatcher = () => (
  <Router>
    <Routes />
  </Router>
)

export default Dispatcher
