var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
import { AppBarButton } from './AppBarButton';
const StyledDropdown = styled(Dropdown) `
  .dropdown__drop {
    padding: ${themeSpace(1)} ${themeSpace(2)};
  }
`;
const UnorderedList = styled.ul `
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    margin-bottom: 5.6px;
  }
`;
export function AppBarDropdown(_a) {
    var { label, children } = _a, rest = __rest(_a, ["label", "children"]);
    return (_jsx(StyledDropdown, Object.assign({ placement: "bottom-end", reference: _jsx(AppBarButton, Object.assign({ type: "button" }, rest, { children: label })) }, { children: _jsx(UnorderedList, { children: children.map((child, index) => (_jsx("li", { children: child }, index))) }) })));
}
