import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themeColor, themeSpace } from '../Theme';
// @ts-ignore
import avatar from './avatar.jpg';
const Avatar = styled.img.attrs({ src: avatar, alt: 'Avatar' }) `
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;
export const Separator = styled.hr `
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  text-align: inherit;
  margin: 0 0 20px;
  border: 0;
  border-top: 1px solid #e5e5e5;
`;
const Container = styled.div `
  display: flex;
  min-width: 250px;
  align-items: center;

  h4 {
    color: #333;
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400;
    margin: 0;
  }

  p {
    color: ${themeColor('gray')};
    font-size: 0.875rem;
    line-height: 1.4;
    margin: 0;
  }

  & > *:not(:last-child) {
    margin-right: ${themeSpace(1)};
  }
`;
export function AppBarAvatar({ name, email }) {
    return (_jsxs(Container, { children: [_jsx(Avatar, {}), _jsxs("div", { children: [_jsx("h4", { children: name }), _jsx("p", { children: email })] })] }));
}
