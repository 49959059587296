import { useState, useCallback } from 'react';
export function useClientRect() {
    const [node, setNode] = useState(null);
    const ref = useCallback((node) => {
        if (node !== null) {
            setNode(node);
        }
    }, []);
    const rect = node ? node.getBoundingClientRect() : null;
    return [rect, ref, node];
}
