import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useReportAccounts } from '@exivity/data-layer'

import { reportsActions } from '../state'

export function useMinimumAccessibleDepth() {
  const dispatch = useDispatch()
  const { minAccountLevel } = useReportAccounts()

  useEffect(() => {
    dispatch(
      reportsActions.filters.updateMinimumAccessibleDepth(
        minAccountLevel
      )
    )
  }, [dispatch, minAccountLevel])
}
