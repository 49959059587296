import { replace } from 'ramda'

import { configFetch } from '../../../../API'
import { createAsyncThunk } from '../../../../store/utils'
import { fetchSystemData } from '../../../../actions/system'
import { authSelectors } from '../selectors'

export const removeTrailingSlash = replace(/\/$/, '')

export const loadStaticConfig = createAsyncThunk<
  {
    isWhiteLabeled: boolean
    apiRoot: string
  },
  void
>('auth/loadStaticConfig', async (_, { dispatch, getState }) => {
  const state = getState()

  try {
    const url = `${self.location.protocol}//${self.location.host}/config.json`

    const response = await configFetch(url)

    const staticConfig = await response.json()

    dispatch(fetchSystemData())

    return {
      isWhiteLabeled: (staticConfig.whiteLabel as boolean) ?? false,
      apiRoot: staticConfig.apiHost
        ? removeTrailingSlash(staticConfig.apiHost)
        : authSelectors.getApiRoot(state) || ''
    }
  } catch {
    return {
      isWhiteLabeled: true,
      apiRoot: authSelectors.getApiRoot(state) || ''
    }
  }
})
