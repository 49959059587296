import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { MdUnfoldLess, MdUnfoldMore } from 'react-icons/md';
import { injectIf } from '../utils';
import { Icon } from '../Icon';
import { themeColor, themeSpace } from '../Theme';
import { Progress } from '../Progress';
export const StyledCollapser = styled(Icon) `
  transform: rotate(45deg);
  font-size: 22px;
`;
export const Collapser = ({ collapsed, onCollapse }) => (_jsx(StyledCollapser, Object.assign({ onClick: () => onCollapse && onCollapse(!collapsed) }, { children: collapsed ? _jsx(MdUnfoldMore, {}) : _jsx(MdUnfoldLess, {}) })));
export const CopyRight = styled.div `
  box-sizing: border-box;
  width: 100%;
  color: ${themeColor('gray')};

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${themeSpace(2)};
  font-size: 0.875rem;
  text-transform: none;

  ${injectIf('hasFooter', css `
      justify-content: space-between;
    `)}
`;
const StyledFooter = styled.footer `
  width: 270px;
  background-color: ${themeColor('dark')};
`;
export const Footer = ({ footer, progress, progressIsActive, progressText, collapsed, onCollapse }) => (_jsxs(StyledFooter, { children: [_jsx("div", Object.assign({ style: { position: 'relative', marginBottom: 30 } }, { children: _jsx(Progress, Object.assign({ active: progressIsActive, percentage: progress, height: 30 }, { children: progressText })) })), (onCollapse || footer) && (_jsxs(CopyRight, Object.assign({ hasFooter: !!footer }, { children: [footer, onCollapse && (_jsx(Collapser, { collapsed: collapsed, onCollapse: onCollapse }))] })))] }));
