import { jsx as _jsx } from "react/jsx-runtime";
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { colorFromPurpose, themeFontFamily, themeFontSize } from '../Theme';
import { Input } from '../Input';
import { useEffect, useState } from 'react';
const StyledInput = styled.input `
  all: unset;
  box-sizing: border-box;
  height: 40px;
  padding: 6px 10px;
  width: 100%;
  background-color: ${colorFromPurpose('secondary', 200)};
  font-family: ${themeFontFamily};
  font-size: ${themeFontSize(2)};
  border-radius: 4px;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid ${colorFromPurpose('primary', 500)};
  }
`;
const Container = styled.div `
  position: relative;
  display: flex;
  flex-basis: 0;
  width: 100%;
`;
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);
    return debouncedValue;
};
export const Searchbar = ({ autoFocus = false, value, onChange }) => {
    const [inputValue, setInputValue] = useState(value);
    const debouncedValue = useDebounce(inputValue, 300);
    useEffect(() => {
        if (debouncedValue !== value)
            onChange(debouncedValue);
    }, [value, debouncedValue, onChange]);
    return (_jsx(Container, { children: _jsx(Input.Adornment, Object.assign({ left: _jsx(MdSearch, { size: 18 }) }, { children: _jsx(StyledInput, { type: "text", autoFocus: autoFocus, "data-testid": "select__search", placeholder: translate('Search...'), value: inputValue, onChange: (e) => setInputValue(e.target.value) }) })) }));
};
