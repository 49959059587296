import { get } from '../../API/index'
import ClassAction, {
  createClassAction
} from '../../store/middleware/ClassAction'
import { FlagsState } from '../../reducers/system/flags'
import { ActionDispatcher } from '../../store/utils'

export class UpdateFlagsData extends ClassAction {
  static readonly type = 'SYSTEM_FLAGS_SET_DATA'
  readonly type = UpdateFlagsData.type
  constructor(public data: FlagsState) {
    super()
  }
}

export const updateFlagsData = createClassAction(UpdateFlagsData)

export function fetchFlagsData(): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    get(
      '/system/flags',
      {},
      {
        background: true,
        workStatusMessage: translate('Updating system information')
      }
    ).then((data: { data: FlagsState }) => {
      dispatch(updateFlagsData(data.data))
    })
  }
}
