import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
const StyledList = styled.ul `
  list-style: none;
  padding: 0;
  margin: 0;
`;
function _ListItem({ index, style, data }) {
    return (_jsx("span", Object.assign({ style: style }, { children: data.renderItem(index) })));
}
const ListItem = React.memo(_ListItem, areEqual);
const style = { height: '100%', width: '100%' };
export function List({ itemCount, renderItem, itemHeight, overscanCount }) {
    return (_jsx(AutoSizer, Object.assign({ style: style }, { children: ({ height: autoHeight }) => (_jsx(FixedSizeList, Object.assign({ itemSize: itemHeight, itemCount: itemCount, height: autoHeight, itemData: { renderItem }, width: '100%', overscanCount: overscanCount, innerElementType: StyledList }, { children: ListItem }))) })));
}
