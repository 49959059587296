import { CalendarMode } from './CalendarMode';
import { dayRangeReducer, Days, StyledDays } from './Days';
import { Months, StyledMonths, monthRangeReducer } from './Months';
import { Quarters, StyledQuarters, quarterRangeReducer } from './Quarters';
import { Years, StyledYears, yearRangeReducer } from './Years';
const TIME_UNITS = {
    [CalendarMode.Days]: {
        Calendar: Days,
        rangeReducer: dayRangeReducer
    },
    [CalendarMode.Months]: {
        Calendar: Months,
        rangeReducer: monthRangeReducer
    },
    [CalendarMode.Quarters]: {
        Calendar: Quarters,
        rangeReducer: quarterRangeReducer
    },
    [CalendarMode.Years]: {
        Calendar: Years,
        rangeReducer: yearRangeReducer
    }
};
export function getCalendar(mode) {
    return TIME_UNITS[mode];
}
export { CalendarMode, StyledDays, StyledMonths, StyledQuarters, StyledYears };
