import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePalette, _themeFontFamily, _themeFontSize, _themeFontWeight } from '../Theme';
const InfoCardTitle = styled.div `
  font-size: ${_themeFontSize('large')};
  font-weight: ${_themeFontWeight('medium')};
  word-break: break-word;
`;
const InfoCardSubtitle = styled.div `
  color: ${themePalette('neutral', 700)};
  font-size: ${_themeFontSize('tiny')};
  font-family: ${_themeFontFamily('monospace')};
  font-weight: ${_themeFontWeight('bold')};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  word-break: break-word;
`;
const InfoCard = ({ children }) => (_jsx(StyledInfoCard, Object.assign({ "data-testid": "info-card-component" }, { children: children })));
const StyledInfoCard = styled.section `
  background-color: ${themePalette('neutral', 100)};
  padding: 1rem;
  word-break: break-word;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
InfoCard.Title = InfoCardTitle;
InfoCard.Subtitle = InfoCardSubtitle;
export { InfoCard };
