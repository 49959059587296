import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
const StyledBrowser = styled.span `
  .browser {
    position: relative;

    .prev,
    .next {
      user-select: none;
      cursor: pointer;
      position: absolute;
    }

    .prev {
      top: 0;
      left: -${(props) => props.offset}px;
    }

    .next {
      top: 0;
      right: -${(props) => props.offset}px;
    }
  }
`;
export function Browser({ prev, next, children, offset = 0 }) {
    return (_jsx(StyledBrowser, Object.assign({ offset: offset }, { children: _jsxs("div", Object.assign({ "data-testid": "browser", className: "browser" }, { children: [_jsx("div", Object.assign({ className: "prev" }, { children: prev })), _jsx("div", Object.assign({ className: "main" }, { children: children })), _jsx("div", Object.assign({ className: "next" }, { children: next }))] })) })));
}
