import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { prop } from '@exivity/fp';
import { MdAdd, MdRemove } from 'react-icons/md';
import { StyledCheckbox } from '../Checkbox';
import { themeSpace, themeColor } from '../Theme';
import { Text } from '../Text';
import { Icon as IconWrapper } from '../Icon';
import { useListContext } from './listContext';
const focusNextElement = (event) => {
    var _a, _b, _c;
    switch (event.key) {
        case 'Down':
        case 'ArrowDown':
            // eslint-disable-next-line no-unused-expressions
            ;
            (_a = event.currentTarget.nextElementSibling) === null || _a === void 0 ? void 0 : _a.focus();
            break;
        case 'Up':
        case 'ArrowUp':
            // eslint-disable-next-line no-unused-expressions
            ;
            (_b = event.currentTarget.previousElementSibling) === null || _b === void 0 ? void 0 : _b.focus();
            break;
        case 'Enter':
            // eslint-disable-next-line no-unused-expressions
            (_c = event.currentTarget) === null || _c === void 0 ? void 0 : _c.click();
            break;
        default:
            break;
    }
};
const Category = styled.li `
  color: ${themeColor('gray')};
`;
const StyledLi = styled.li `
  height: 100%;
  padding-left: calc(${themeSpace(2)} * ${prop('depth')}) !important;

  &.select {
    &__li {
      & > *:not(.select__highlighter) {
        margin-right: 10px;
      }

      &:hover,
      :focus {
        background-color: ${themeColor('lightGray')};
      }
    }
  }

  .select__checkbox {
    flex-shrink: 0;
  }
`;
const StyledButton = styled.button `
  visibility: ${({ leaf }) => (leaf ? 'hidden' : 'visible')};

  width: 20px;
  height: 20px;
  border-radius: 0;
  border: none;
  outline: none;
  flex-shrink: 0;

  padding: 0;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    color: ${themeColor('gray')};
  }
`;
function ExpandButton({ index }) {
    const { list } = useListContext();
    const { expanded, onChangeExpand, isLeaf, isTree, isCategory, categorized } = list[index];
    if (isCategory || categorized || !isTree)
        return null;
    return (_jsx(StyledButton, Object.assign({ leaf: isLeaf(), type: "button", onClick: (e) => {
            e.stopPropagation();
            onChangeExpand();
        } }, { children: _jsx(IconWrapper, { children: expanded ? _jsx(MdRemove, {}) : _jsx(MdAdd, {}) }) })));
}
function CheckBox({ index }) {
    const { list, value } = useListContext();
    const { isActive, isParentActive, multiple, data } = list[index];
    if (!multiple)
        return null;
    const parentIsActive = isParentActive(value);
    return (_jsxs(StyledCheckbox, { children: [_jsx("input", { type: "checkbox", readOnly: true, checked: parentIsActive || isActive(value, data), disabled: parentIsActive }), _jsx("div", { className: "custom-checkbox" })] }));
}
export function Icon({ index }) {
    const { list, value } = useListContext();
    const { renderIcon } = list[index];
    const Icon = renderIcon(value);
    if (!Icon)
        return null;
    return (_jsx("div", { children: _jsx(IconWrapper, { children: Icon }) }));
}
export const SelectOption = ({ style, index }) => {
    const { list, onChange, value } = useListContext();
    const { data, label, path, query, isCategory, isActive, getValue } = list[index];
    if (isCategory) {
        return (_jsx(Category, Object.assign({ "data-testid": "select__li--category", style: style, title: label }, { children: _jsx(Text.Highlight, Object.assign({ className: "select__highlighter", textToMatch: [query] }, { children: label })) })));
    }
    return (_jsxs(StyledLi, Object.assign({ "data-testid": "select__li", className: "select__li", style: {
            height: style === null || style === void 0 ? void 0 : style.height
        }, tabIndex: -1, depth: path.length, title: label, onClick: () => onChange(getValue(value)), onKeyDown: focusNextElement }, { children: [_jsx(ExpandButton, { index: index }), _jsx(CheckBox, { index: index }), _jsx(Icon, { index: index }), _jsx(Text.Highlight, Object.assign({ className: "select__highlighter", active: isActive(value, data), textToMatch: [query] }, { children: label }))] })));
};
