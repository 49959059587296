import { endOfMonth, isSameMonth, startOfMonth } from 'date-fns';
import { orderRange } from '../../DateRange/helpers';
const updateMonthRange = (range, action) => {
    switch (action.type) {
        case 'SELECT_START':
            return [action.date, null];
        case 'SELECT_END':
            if (!range[0])
                return range;
            if (isSameMonth(range[0], action.date))
                return [null, null];
            return orderRange([range[0], action.date]);
        case 'RESET_RANGE':
            return [null, null];
        case 'SELECT_SINGLE_UNIT_AS_RANGE':
            return [action.date, action.date];
    }
};
function fitPartialMonthRange([start, end]) {
    return [
        start ? startOfMonth(start) : null,
        end ? endOfMonth(end) : null
    ];
}
export const monthRangeReducer = (...args) => {
    return fitPartialMonthRange(updateMonthRange(...args));
};
