import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'
import { MdInsertChart } from 'react-icons/md'

import { featureFlags } from '../application/config/features'

const AccountReports = lazy(
  () =>
    import(
      /* webpackChunkName: 'reports.accounts' */ '../components/pages/Reports/Accounts'
    )
)

const ServiceReports = lazy(
  () =>
    import(
      /* webpackChunkName: 'reports.services' */ '../components/pages/Reports/Services'
    )
)

const InstanceReports = lazy(
  () =>
    import(
      /* webpackChunkName: 'reports.instances' */ '../components/pages/Reports/Instances'
    )
)

// @ts-ignore
const BudgetReports = lazy(
  () =>
    import(
      /* webpackChunkName: 'reports.summary' */ '../components/pages/Reports/Budget'
    )
)

const SummaryReports = lazy(
  () =>
    import(
      /* webpackChunkName: 'reports.summary' */ '../domains/reports/pages/Summary'
    )
)

export const reports = {
  path: '/reports',
  meta: {
    title: () => translate('Reports'),
    icon: MdInsertChart
  },
  routes: {
    accounts: {
      path: '/accounts',
      requiresPermissions: [UserGroupPermission.ViewReports],
      meta: {
        title: () => translate('Accounts'),
        documentation: 'https://docs.exivity.com/The%20basics/Reports/Accounts',
        icon: 'group'
      },
      component: AccountReports
    },
    services: {
      path: '/services',
      requiresPermissions: [UserGroupPermission.ViewReports],
      meta: {
        title: () => translate('Services'),
        documentation: 'https://docs.exivity.com/The%20basics/Reports/Services',
        icon: 'library_books'
      },
      component: ServiceReports
    },
    instances: {
      path: '/instances',
      requiresPermissions: [UserGroupPermission.ViewReports],
      meta: {
        title: () => translate('Instances'),
        documentation:
          'https://docs.exivity.com/The%20basics/Reports/Instances',
        icon: 'list'
      },
      component: InstanceReports
    },
    summary: {
      path: '/summary/:id?',
      requiresPermissions: [UserGroupPermission.ViewReports],
      meta: {
        title: () => translate('Summary'),
        documentation: 'https://docs.exivity.com/The%20basics/Reports/Summary',
        icon: 'insert_drive_file'
      },
      component: SummaryReports
    },
    budgets: {
      path: '/budgets',
      requiresPermissions: [UserGroupPermission.ViewBudgets],
      meta: {
        title: () => translate('Budget'),
        documentation: 'https://docs.exivity.com/The%20basics/Reports/Budget',
        feature: featureFlags.budgets
      },
      component: BudgetReports
    }
  }
}
