import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { LAYOUT_TOOLBAR_HEIGHT, themeSpace } from '../Theme';
import { Container, Widget } from './StyledComponents';
const ToolBarDetail = styled.div `
  position: relative;
  width: 100%;

  & > ${Widget} {
    min-height: ${() => LAYOUT_TOOLBAR_HEIGHT};
    padding: ${themeSpace(1)};
  }
`;
export function ToolbarDetail({ children }) {
    return (_jsxs(Container, Object.assign({ style: { flexDirection: 'column' } }, { children: [children[0], _jsx(ToolBarDetail, { children: _jsx(Widget, { children: children[1] }) })] })));
}
