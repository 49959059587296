import { ModalProvider } from '@exivity/ui'

import { ThemeProvider } from './ThemeProvider'
import { DataProvider } from './DataProvider'
import { UIExtensionsProvider } from './UIExtensionsProvider'
import { StateProvider } from './StateProvider'
import { JobsProvider } from '../../../domains/jobs/components/JobsProvider'
import { FormatterProvider } from './FormatterProvider'

export function AllProviders({ children }: any) {
  return (
    <StateProvider>
      <DataProvider>
        <ThemeProvider>
          <UIExtensionsProvider>
            <JobsProvider>
              <FormatterProvider>
                <ModalProvider>{children}</ModalProvider>
              </FormatterProvider>
            </JobsProvider>
          </UIExtensionsProvider>
        </ThemeProvider>
      </DataProvider>
    </StateProvider>
  )
}
