import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { EventLoggingStrategy } from '@orbit/coordinator';
import { PermissionContext, MemoryContext, ServerContext, ServerOnlyContext, TransientMemoryContext } from './contexts';
import { coordinator } from '../SourceCoordinator/Coordinators';
import { memory as appMemory } from '../sources/memory';
import { server, serverOnly } from '../sources/server/server';
import { updateHost, updateToken } from '../sources/server/server';
import { useSystemConfiguration } from '../useConfiguration';
import { clearQueryCache } from './queryExpressionCache';
import { transientMemory } from '../sources/transient';
const EVENT_LOGGING_STRATEGY_NAME = 'event-logging';
const logStrategy = new EventLoggingStrategy({
    name: EVENT_LOGGING_STRATEGY_NAME
});
const EventLogging = () => {
    var _a;
    const systemConfig = useSystemConfiguration();
    const isDebugging = (_a = systemConfig === null || systemConfig === void 0 ? void 0 : systemConfig.attributes) === null || _a === void 0 ? void 0 : _a.APP_DEBUG;
    useEffect(() => {
        if (isDebugging) {
            coordinator.activated &&
                coordinator.activated.then(() => {
                    coordinator.deactivate().then(() => {
                        try {
                            isDebugging
                                ? coordinator.addStrategy(logStrategy)
                                : coordinator.removeStrategy(EVENT_LOGGING_STRATEGY_NAME);
                        }
                        catch (_a) { }
                        coordinator.activate();
                    });
                });
        }
    }, [coordinator, isDebugging]);
    return null;
};
export function DataLayerProvider({ host, token, userPermissions = [], isAuthenticated, readyToFetchConfig, memory = appMemory, children }) {
    const [rdy, setRdy] = useState(true);
    if (token) {
        updateToken(token);
    }
    if (host) {
        updateHost(host);
    }
    useEffect(() => {
        if (!isAuthenticated) {
            memory.cache.reset();
            clearQueryCache();
        }
    }, [memory, isAuthenticated]);
    useEffect(() => {
        // config contains items before auth
        if (readyToFetchConfig) {
            memory.query((q) => q.findRecord({ type: 'configuration', id: 'system' }));
        }
    }, [readyToFetchConfig, memory]);
    useEffect(() => {
        // config contains more after auth
        if (isAuthenticated) {
            memory.query((q) => q.findRecord({ type: 'configuration', id: 'system' })).then(() => {
                setRdy(true);
            });
        }
    }, [isAuthenticated, memory]);
    return (_jsx(MemoryContext.Provider, Object.assign({ value: memory }, { children: _jsx(TransientMemoryContext.Provider, Object.assign({ value: transientMemory }, { children: _jsx(ServerContext.Provider, Object.assign({ value: server }, { children: _jsxs(ServerOnlyContext.Provider, Object.assign({ value: serverOnly }, { children: [_jsx(EventLogging, {}), _jsx(PermissionContext.Provider, Object.assign({ value: userPermissions }, { children: rdy
                                ? children
                                : null }))] })) })) })) })));
}
