import { useState } from 'react'
import {
  useCacheQuery,
  queries,
  SystemConfiguration,
  DEFAULT_SYSTEM_CONFIG,
  SSOLoginMethod
} from '@exivity/data-layer'

import { ReportFormatterOptions } from '../../../utils/formatters/ReportFormatter'
import { useSaveConfiguration } from './useSaveConfiguration'

function createResourceSelectors<
  Selectors extends { [key: string]: (resource: SystemConfiguration) => any }
>(
  selectors: Selectors
): {
  [K in keyof Selectors]: (
    config: SystemConfiguration
  ) => ReturnType<Selectors[K]>
} {
  return selectors
}

export const systemConfigSelector = createResourceSelectors({
  getAppName: ({ attributes }) => attributes.APP_NAME || '',
  getAppLogo: ({ attributes }) => attributes.APP_LOGO,
  getAppIcon: ({ attributes }) => attributes.APP_ICON,
  getAppFavicon: ({ attributes }) => attributes.APP_FAVICON,
  getAppColour: ({ attributes }) => attributes.APP_COLOUR || '',
  getDocumentation: ({ attributes }) => attributes.APP_DOCUMENTATION,
  shouldShowDocumentation: ({ attributes }) =>
    !!(attributes.APP_DOCUMENTATION),
  getAppLanguage: ({ attributes }) => attributes.APP_LANGUAGE || 'en',
  getDateFormat: ({ attributes }) => attributes.DATE_FORMAT || 'dd-MM-yyyy',
  getAppCss: ({ attributes }) => attributes.APP_CSS,

  isAnalyticsEnabled: ({ attributes }) => attributes.ANALYTICS,
  getExtraAnalyticsProperty: ({ attributes }) =>
    attributes.ANALYTICS_EXTRA_PROPERTY,
  isErrorTrackingEnabled: ({ attributes }) => attributes.ERROR_TRACKING,
  isBetaEnabled: ({ attributes }) =>
    process.env.NODE_ENV === 'test' || (attributes.BETA_FEATURES ?? false),
  isDebugMode: ({ attributes }) => attributes.APP_DEBUG ?? false,

  getCurrencyFormat: ({ attributes }) => attributes.CURRENCY_FORMAT,
  getDecimalSeparator: ({ attributes }) => attributes.DECIMAL_SEPARATOR,
  getThousandsSeparator: ({ attributes }) => attributes.THOUSAND_SEPARATOR,
  getCSVDelimiter: ({ attributes }) => attributes.CSV_DELIMITER,
  getCSVDecimalSeparator: ({ attributes }) => attributes.CSV_DECIMAL_SEPARATOR,

  getReportFormatterOptions: ({ attributes }): ReportFormatterOptions => ({
    precisions: {
      quantity: attributes.QUANTITY_PRECISION ?? undefined,
      rate: attributes.RATE_PRECISION ?? undefined,
      report: attributes.REPORT_PRECISION ?? undefined,
      summary: attributes.SUMMARY_PRECISION ?? undefined,
      percentage: attributes.PERCENTAGE_PRECISION ?? undefined
    },
    symbols: {
      decimal: attributes.DECIMAL_SEPARATOR ?? undefined,
      thousands: attributes.THOUSAND_SEPARATOR ?? undefined,
      currency: attributes.CURRENCY_FORMAT || ''
    }
  }),

  getSummaryConfig: ({ attributes }) => ({
    title: attributes.SUMMARY_TITLE,
    address: attributes.SUMMARY_ADDRESS,
    image: attributes.SUMMARY_IMAGE,
    extra: attributes.SUMMARY_EXTRA,
    minCommitMessage: attributes.SUMMARY_MIN_COMMIT
  }),

  getMinimumCommitMessage: ({ attributes }) => attributes.SUMMARY_MIN_COMMIT,

  getSummaryExtra: ({ attributes }) => attributes.SUMMARY_EXTRA,

  // @todo refactor fiscal offset to be 0 - 11 instead of 1 - 12 and subtracting one
  getFiscalOffset: ({ attributes }) => (attributes.REPORT_START_MONTH ?? 1) - 1,
  loginMethodIsSAMLOnly: ({ attributes }) =>
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.SAMLOnly,
  isSamlEnabled: ({ attributes }) =>
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.LocalUserSAML ||
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.SAMLOnly ||
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.LocalUserLDAPSAML,
  isLocalLoginEnabled: ({ attributes }) =>
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.LocalUserSAML ||
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.LocalUserLDAP ||
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.LocalUserOnly ||
    attributes.SSO_LOGIN_METHOD === SSOLoginMethod.LocalUserLDAPSAML,
  getTokenTTL: ({ attributes }) => attributes.TOKEN_TTL,
  arePersistentTokensAllowed: ({ attributes }) =>
    attributes.ALLOW_PERSISTENT_TOKENS ?? false
})

export function useSystemConfiguration() {
  const [systemConfig, updateSystemConfig] = useState<SystemConfiguration>(
    DEFAULT_SYSTEM_CONFIG
  )
  const saveConfig = useSaveConfiguration()

  useCacheQuery(queries.find('configuration', 'system'), (systemConfig) =>
    systemConfig ? updateSystemConfig(systemConfig) : DEFAULT_SYSTEM_CONFIG
  )

  return {
    systemConfig,
    updateSystemConfig,
    saveSystemConfig: () => {
      if (systemConfig) {
        saveConfig(systemConfig)
      }
    },
    loginMethodIsSAMLOnly:
      systemConfigSelector.loginMethodIsSAMLOnly(systemConfig)
  } as const
}
