import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { MdSearch, MdClear } from 'react-icons/md';
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { Icon } from '../Icon';
import { colorFromPurpose, themeFontFamily, themeFontSize, themeSpace } from '../Theme';
const StyledInput = styled.input `
  all: unset;
  box-sizing: border-box;
  height: 40px;
  padding 6px 10px;
  width: 100%;
  background-color: ${colorFromPurpose('secondary', 200)};
  font-family: ${themeFontFamily};
  font-size: ${themeFontSize(2)};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid ${colorFromPurpose('primary', 500)};
  }
`;
const Container = styled.div `
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 ${themeSpace(1)};
  margin-bottom: ${themeSpace(1)};
`;
const StyledButton = styled.button `
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  font-size: ${themeFontSize(4)};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${colorFromPurpose('secondary', 200)};
  background-color: ${colorFromPurpose('secondary', 700)};

  &:hover,
  &:focus {
    background-color: ${colorFromPurpose('secondary', 800)};
  }
`;
const ClearButton = styled.button `
  all: unset;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 7px;
  right: 60px;

  height: 26px;
  width: 26px;
  border-radius: 180px;
  font-size: ${themeFontSize(3)};
  color: ${colorFromPurpose('secondary', 100)};
  background-color: ${colorFromPurpose('secondary', 400)};

  &:hover,
  &:focus {
    background-color: ${colorFromPurpose('secondary', 500)};
  }
`;
export const Searchbar = ({ onSubmit }) => {
    const [query, updateQuery] = useState('');
    const [submittedQuery, updateSubmittedQuery] = useState('');
    function handleSubmit(query) {
        if (submittedQuery !== query)
            onSubmit(query);
        updateSubmittedQuery(query);
    }
    return (_jsxs(Container, { children: [_jsx(StyledInput, { type: "text", "data-testid": "select__search", placeholder: translate('Search...'), autoFocus: true, value: query, onKeyDown: (e) => {
                    if (e.code === 'Enter') {
                        e.preventDefault();
                        handleSubmit(query);
                    }
                }, onChange: (e) => updateQuery(e.target.value) }), submittedQuery.length > 0 && (_jsx(ClearButton, Object.assign({ onClick: () => {
                    handleSubmit('');
                    updateQuery('');
                } }, { children: _jsx(MdClear, {}) }))), _jsx(StyledButton, Object.assign({ type: "button", onClick: () => handleSubmit(query) }, { children: _jsx(Icon, { children: _jsx(MdSearch, {}) }) }))] }));
};
