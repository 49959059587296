import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { themeGlobal, themePalette, themeFontSize, _themeFontSize, _themeFontWeight } from '../Theme';
const horizontalStyle = css `
  grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
`;
const verticalStyle = css `
  grid-template-rows: 1fr;
`;
const StyledMetaTable = styled.div `
  display: grid;
  ${({ vertical }) => (vertical ? verticalStyle : horizontalStyle)}
  gap: 2em 4em;
  font-family: ${themeGlobal('fontFamily')};
  font-size: ${themeFontSize(1)};
`;
const MetaItemContainer = styled.div `
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  gap: 0.2em;
  min-width: 0;
`;
const MetaItemLabel = styled.div `
  text-transform: uppercase;
  color: ${themePalette('neutral', 700)};
  font-size: ${_themeFontSize('small')};
  font-weight: ${_themeFontWeight('bold')};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  word-break: break-word;
`;
const MetaItemValue = styled.div `
  overflow-wrap: break-word;
`;
function MetaTableItem({ label, value }) {
    return (_jsxs(MetaItemContainer, { children: [_jsx(MetaItemLabel, { children: label }), _jsx(MetaItemValue, { children: value })] }));
}
function MetaTable({ vertical = false, children }) {
    return _jsx(StyledMetaTable, Object.assign({ vertical: vertical }, { children: children }));
}
MetaTableItem.displayName = 'MetaTableItem';
MetaTable.displayName = 'MetaTable';
MetaTable.Item = MetaTableItem;
export { MetaTable, MetaTableItem };
