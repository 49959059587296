const SEPARATOR = ':'

function getStorage(useLocalStorage: boolean) {
  // eslint-disable-next-line glass/no-direct-web-storage
  return useLocalStorage ? localStorage : sessionStorage
}

function switchAndCleanStorage(useLocalStorage: boolean) {
  const unwantedStorage = getStorage(!useLocalStorage)
  const wantedStorage = getStorage(useLocalStorage)

  function forEachKey(callback: (key: string | null) => void) {
    for (let i = 0; i < unwantedStorage.length; i++) {
      callback(unwantedStorage.key(i))
    }
  }

  forEachKey((key) => {
    if (key && mustBeCleaned.some((clean) => key.includes(clean))) {
      wantedStorage.setItem(key, unwantedStorage.getItem(key) as string)
    }
  })

  unwantedStorage.clear()
}

function createStorage<K extends string>(name: string) {
  return {
    getStorage(persistent: boolean) {
      switchAndCleanStorage(persistent)

      return {
        setItem(key: K, state: Record<string, any> | string | boolean) {
          getStorage(persistent).setItem(
            name + SEPARATOR + key,
            JSON.stringify(state)
          )
        },
        getItem(key: K) {
          const serializedState = getStorage(persistent).getItem(
            name + SEPARATOR + key
          )

          return serializedState ? JSON.parse(serializedState) : null
        },
        clear() {
          getStorage(persistent).clear()
        }
      }
    },
    switchStorage(useLocalStorage: boolean) {
      switchAndCleanStorage(useLocalStorage)
    },
    fromEitherStorage(key: K) {
      // Try loading state from localStorage, with a fallback to sessionStorage.
      let serializedState: string | null = getStorage(true).getItem(
        name + SEPARATOR + key
      )

      if (serializedState === null) {
        serializedState = getStorage(false).getItem(name + SEPARATOR + key)

        if (serializedState === null) {
          return undefined
        }
      }

      try {
        return JSON.parse(serializedState)
      } catch (e) {
        return serializedState
      }
    }
  }
}

const mustBeCleaned = ['state', 'session']
export const getStateStorage = createStorage('state')
export const getUserSessionStorage = createStorage<'token' | 'user'>('session')
const supportStorage = createStorage('viewUnsupportedBrowser').getStorage(true)

export function switchAllStorages(useLocalStorage: boolean) {
  ;[getStateStorage, getUserSessionStorage].forEach((storage) =>
    storage.switchStorage(useLocalStorage)
  )
}

export const viewUnsupportedBrowser = () =>
  supportStorage.getItem('viewUnsupported') ?? false
export const proceedToUnsupportedBrowser = () =>
  supportStorage.setItem('viewUnsupported', true)
