import { useMemo, useRef } from 'react';
import { assocPath } from 'ramda';
import { useAPIQuery } from './useAPIQuery';
const assocSignal = assocPath(['settings', 'signal']);
export function useAbortableAPIQuery(queryBuilder, options) {
    const queryAPI = useAPIQuery();
    const controllerRef = useRef(new AbortController());
    const query = JSON.stringify(queryBuilder.toQuery());
    return useMemo(() => {
        return {
            abort: (reason) => {
                controllerRef.current.abort(reason);
            },
            query: () => {
                if (controllerRef.current.signal.aborted) {
                    controllerRef.current = new AbortController();
                }
                else {
                    controllerRef.current.abort();
                    controllerRef.current = new AbortController();
                }
                queryAPI(queryBuilder, assocSignal(controllerRef.current.signal, options || {}));
            }
        };
    }, [queryAPI, query, options]);
}
