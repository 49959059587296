import { StoreEnhancer, Reducer, DeepPartial } from '@reduxjs/toolkit'

import { RootState } from '../../reducers'
import { stateObserver } from '../observers/stateObserver'

import migrator from './migrator'

export interface PersistEnhancerProps<T> {
  observe: (state: RootState) => T
  persist: (observedState: T, state: RootState) => void
  load: () => DeepPartial<RootState>
}

function validateState(
  reducer: Reducer<any, any>,
  loadedState: { [key: string]: any }
) {
  const initialState = reducer({}, {})

  if (!loadedState) return {}

  // The migrator will filter keys that became redundant
  return migrator({
    incomingState: loadedState,
    currentState: initialState
  })
}

export function createPersistEnhancer<T extends { [key: string]: any }>({
  observe,
  persist,
  load
}: PersistEnhancerProps<T>): StoreEnhancer {
  return (createStore) => {
    return (reducer: Reducer<any, any>, preloadedState) => {
      const validatedState = validateState(reducer, load())

      const store = createStore(reducer, {
        ...preloadedState,
        ...validatedState
      })

      const persistState = stateObserver({
        observe,
        onChange: persist
      })

      store.subscribe(() => persistState(store.getState()))

      return store
    }
  }
}
