import { DeepPartial } from './types'

export function makeCreateModel<
  T extends {
    type: string
    attributes: Record<any, any>
    relationships?: Record<any, any>
  }
>(
  type: T['type'],
  defaultAttributes: T['attributes'] | (() => T['attributes']) = {},
  defaultRelationships: T['relationships'] | (() => T['relationships']) = {}
) {
  return (
    id?: string,
    attributes: DeepPartial<T['attributes']> = {},
    relationships: DeepPartial<T['relationships']> = {}
  ) =>
    ({
      id,
      type,
      attributes: {
        ...(typeof defaultAttributes === 'function'
          ? (defaultAttributes as any)()
          : defaultAttributes),
        ...attributes
      },
      relationships: {
        ...(typeof defaultRelationships === 'function'
          ? (defaultRelationships as any)()
          : defaultRelationships),
        ...relationships
      }
    } as unknown as T)
}
