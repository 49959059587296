import styled, { css } from 'styled-components';
import { color, height, maxHeight, maxWidth, minHeight, minWidth, size, space, width, borders } from 'styled-system';
export const blockStyles = css `
  ${space}
  ${size}
  ${width}
  ${maxWidth}
  ${minWidth}
  ${height}
  ${maxHeight}
  ${minHeight}
  ${color}
  ${borders}
`;
export const Block = styled.div `
  ${blockStyles}
`;
Block.displayName = 'Block';
