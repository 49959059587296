import { getUserSessionStorage } from "../store/storage";
import store from "../store";
import { workThunks } from "../domains/work/thunks";

import { mainFetch, rawFetch } from "./fetch";

export const defaultTimeout = 1000 * 60 * 10; // Set to 10m, which emulates Proximity and NGINX.

export function toQueryString(obj) {
  const parts = [];

  for (const i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
    }
  }

  return parts.join("&");
}

const defaultOptions = {
  background: false,
  workStatusMessage: null,
  handleRejections: true,
  flashMessage: true,
  fetchTimeout: defaultTimeout,
};

/**
 * A utility to call Proximity.
 *
 * @param {string} url The restful service end point.
 * @param {Object} fetchOptions The fetch options object for the call. Can be null.
 * @param {Object} opts The options object for the call. For default options, see above.
 * @returns {Promise} Resolve function is passed a response json payload as its argument.
 */
function callApi(url, fetchOptions, opts = {}) {
  const options = Object.assign({}, defaultOptions, opts);

  return new Promise((resolve, reject) => {
    let timeout;

    if (options.fetchTimeout) {
      timeout = setTimeout(() => {
        options.handleRejections
          ? store.dispatch(workThunks.showErrorMessage("Request timed out."))
          : reject(new Error("Request timed out."));
      }, options.fetchTimeout);
    }

    mainFetch(url, fetchOptions, options)
      .then((data) => {
        clearTimeout(timeout);
        resolve(data);
      })
      .catch((error) => {
        clearTimeout(timeout);
        options.handleRejections || reject(error);
      });
  });
}

export function call(method, url, parameters = {}, body = {}, options = {}) {
  const token = getUserSessionStorage.fromEitherStorage("token") ?? "";

  const fetchOptions = {
    signal: options.signal,
    method: method.toUpperCase(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...options.headers,
    },
  };

  if (token) {
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }

  if (options.isBinary) {
    fetchOptions.body = body;
    fetchOptions.headers["Content-Type"] = "application/octet-stream";
  } else if (!["GET", "HEAD"].includes(fetchOptions.method)) {
    fetchOptions.body = JSON.stringify(body);
  }

  if (options.redirect) {
    fetchOptions.redirect = options.redirect;
  }

  const querystring = toQueryString(parameters);
  const uri = querystring ? `${url}?${querystring}` : url;

  if (options.raw) {
    return rawFetch(uri, fetchOptions);
  }

  return callApi(uri, fetchOptions, options);
}

export * from "./fetch";
