import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { Link } from '@exivity/routing';
import { injectIf } from '../utils';
import { themeColor, themeSpace, themePurpose } from '../Theme';
import { NavIcon } from './NavIcon';
import { Title } from './Title';
const sharedLinkStyle = css `
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
`;
const dontForwardActive = {
    shouldForwardProp: (prop) => prop !== 'active'
};
const BaseLink = styled(Link).withConfig(dontForwardActive);
const StyledLink = BaseLink `
  ${sharedLinkStyle};
  height: 64px;
  padding: 0 ${themeSpace(2)};
  color: ${themeColor('white')};
  
  &:hover {
    text-decoration: none;
    background-color: ${themeColor('lightDark')};
    color: ${themeColor('white')};
  }
  
   ${injectIf('active', css `
       background-color: ${themeColor('lightDark')};
       ${NavIcon} {
         color: ${themePurpose('brand')};
       }
     `)}
`;
const StyledSubLink = BaseLink `
  ${sharedLinkStyle};
  padding: 0 45px;
  height: 29px;
  color: ${themeColor('gray')};

  ${Title} {
    font-size: .8125rem;
    
    ${injectIf('active', css `
        text-decoration: underline;
        color: ${themeColor('white')};
      `)}
  }
  
  &:hover {
    text-decoration: underline;
    background-color: initial;
    color: ${themeColor('white')};
    
    ${Title} {
      color: ${themeColor('white')};
    }
  }
`;
export function NavLink({ className, to = '', icon, active = false, isSubLink, onClick = () => { }, children }) {
    const Link = isSubLink ? StyledSubLink : StyledLink;
    return (_jsx("li", Object.assign({ tabIndex: -1, onClick: onClick }, { children: _jsxs(Link, Object.assign({ className: className, to: to, active: active }, { children: [_jsx(NavIcon, { children: icon }), _jsx(Title, { children: children })] })) })));
}
