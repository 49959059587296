import { useDispatch, useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { Checkbox, SelectFilter, Label, Menu } from '@exivity/ui'
import { useParams } from '@exivity/routing'
import { invoker, prop } from '@exivity/fp'
import { useIsPrinting } from '@exivity/hooks'
import styled, { css } from 'styled-components'

import { reportsActions, reportSelectors } from '../../state'
import { SummaryGroupInstancesBy } from '../../state/filters/filterTypes'
import { RootState } from '../../../../reducers'
import Export from '../../../../components/molecules/Export/index'
import {
  downloadCsv,
  downloadPdf
} from '../../state/dimensions/thunks/summaryDownload'
import { SummaryParams } from '../../pages/Summary'
import { SummaryPrintMode } from '../../state/api'

const groupByOptions = [
  {
    label: () => translate('Service'),
    value: SummaryGroupInstancesBy.Services
  },
  {
    label: () => translate('Instance'),
    value: SummaryGroupInstancesBy.Instances
  }
]

const groupByLabels = {
  [SummaryGroupInstancesBy.Services]: () => translate('Service'),
  [SummaryGroupInstancesBy.Instances]: () => translate('Instance')
}

const PRINT = 'print'
const PRINT_ALL = 'print-all'
const PDF = 'pdf'
const PDF_ALL = 'pdf-all'
const CSV = 'csv'
const CSV_ALL = 'csv-all'

type PrintOptions = SummaryPrintMode | 'csv' | 'pdf' | 'pdf-all' | 'csv-all'

const StyledExportGroups = styled.span<{ separateStyle: boolean }>`
  ${({ separateStyle }) =>
    separateStyle &&
    css`
      li:nth-child(1),
      li:nth-child(5) {
        color: #999;
        &:hover {
          background-color: white;
        }
      }
    `}
`

export function SummaryMenu() {
  const dispatch = useDispatch()
  const [{ id }] = useParams<SummaryParams>()

  const pdfExportEnabled = useSelector(
    (state: RootState) => state.system.flags.SUPPORTS_PDF_EXPORT
  )
  const showAccounts = useSelector(
    reportSelectors.isIncludedInSummary('accounts')
  )
  const showServices = useSelector(
    reportSelectors.isIncludedInSummary('services')
  )
  const showInstances = useSelector(
    reportSelectors.isIncludedInSummary('instances')
  )
  const consolidated = useSelector(reportSelectors.shouldConsolidateSummary)
  const groupBy = useSelector(reportSelectors.getInstanceGroupBy)
  const printMode = useSelector(reportSelectors.getSummaryPrintMode)

  const isPrinting = useIsPrinting((isPrinting) => {
    if (isPrinting && printMode === null) {
      dispatch(
        reportsActions.api.updateSummaryPrintMode(SummaryPrintMode.Print)
      )
    } else if (!isPrinting) {
      dispatch(reportsActions.api.updateSummaryPrintMode(null))
    }
  })

  const onPrint = ({ key }: { key: PrintOptions }) => {
    if (key === PRINT || key === PRINT_ALL) {
      dispatch(reportsActions.api.updateSummaryPrintMode(key))
      // Allow a rerender first to switch from virtual to full dom for printing
      setTimeout(window.print, 0)
    }

    if (key === PDF || key === PDF_ALL) {
      downloadPdf(key === PDF ? id : null)
    }

    if (key === CSV || key === CSV_ALL) {
      downloadCsv(key === CSV ? id : null)
    }
  }

  if (isPrinting) return null

  return (
    <Menu>
      {consolidated && (
        <Menu.Item>
          <Checkbox
            name="Accounts"
            label={translate('Accounts')}
            checked={showAccounts}
            onChange={(checked) => {
              dispatch(
                reportsActions.filters.includeInSummary('accounts', checked)
              )
            }}
          />
        </Menu.Item>
      )}

      <Menu.Item>
        <Checkbox
          name="Services"
          label={translate('Services')}
          checked={showServices}
          onChange={(checked) => {
            dispatch(
              reportsActions.filters.includeInSummary('services', checked)
            )
          }}
        />
      </Menu.Item>

      <Menu.Item>
        <Checkbox
          name="Instances"
          label={translate('Instances')}
          checked={showInstances}
          onChange={(checked) => {
            dispatch(
              reportsActions.filters.includeInSummary('instances', checked)
            )
          }}
        />
      </Menu.Item>

      <Menu.Item>
        <Label>{translate('Group by')}</Label>
        <SelectFilter
          small
          valueAccessor={prop('value')}
          labelAccessor={invoker(0, 'label')}
          inputValue={groupByLabels[groupBy]()}
          value={groupBy}
          data={groupByOptions}
          onChange={(groupBy) => {
            dispatch(reportsActions.filters.updateInstanceGroupBy(groupBy))
          }}
        />
      </Menu.Item>
      <Menu.Item>
        <StyledExportGroups separateStyle={!consolidated}>
          <Export onClick={onPrint}>
            {consolidated
              ? [
                  { key: PRINT, value: translate('PRINT') },
                  ...(pdfExportEnabled
                    ? [{ key: PDF, value: translate('PDF') }]
                    : []),
                  { key: CSV, value: translate('CSV') }
                ]
              : [
                  { group: translate('This summary') },
                  { key: PRINT, value: translate('PRINT') },
                  ...(pdfExportEnabled
                    ? [{ key: PDF, value: translate('PDF') }]
                    : []),
                  { key: CSV, value: translate('CSV') },
                  { group: translate('All summaries') },
                  { key: PRINT_ALL, value: translate('PRINT') },
                  ...(pdfExportEnabled
                    ? [{ key: PDF_ALL, value: translate('PDF') }]
                    : []),
                  { key: CSV_ALL, value: translate('CSV') }
                ]}
          </Export>
        </StyledExportGroups>
      </Menu.Item>
    </Menu>
  )
}
