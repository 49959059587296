import { useState } from 'react'
import { useCacheQuery, queries } from '@exivity/data-layer'

import { SubConfiguration } from './'
import { useSaveConfiguration } from './useSaveConfiguration'

const DEFAULT_SERVICES_CONFIG = {
  id: 'services' as string,
  type: 'configuration' as const,
  attributes: {
    MAIL_HOST: null,
    MAIL_PORT: null,
    MAIL_USERNAME: null,
    MAIL_PASSWORD: null,
    MAIL_FROM_ADDRESS: null,
    MAIL_FROM_NAME: null,
    MAIL_DRIVER: null,
    MAIL_ENCRYPTION: null,
    NEXMO_KEY: null,
    NEXMO_SECRET: null,
    NEXMO_FROM_NUMBER: null,
    LDAP_ACCOUNT_PREFIX: null,
    LDAP_ACCOUNT_SUFFIX: null,
    LDAP_HOSTS: null,
    LDAP_PORT: null,
    LDAP_TIMEOUT: null,
    LDAP_ENCRYPTION: null,
    LDAP_BASE_DN: null,
    LDAP_DEFAULT_USERGROUP_ID: null,
    LDAP_USERNAME: null,
    LDAP_EMAIL: null
  }
}

export type ServicesConfigAttributes =
  (typeof DEFAULT_SERVICES_CONFIG)['attributes']

export type ServicesConfiguration = SubConfiguration<
  keyof ServicesConfigAttributes
>

export function useServicesConfiguration() {
  const [servicesConfig, updateServicesConfig] =
    useState<ServicesConfiguration>(DEFAULT_SERVICES_CONFIG)

  const saveConfig = useSaveConfiguration()

  useCacheQuery(queries.find('configuration', 'services'), (servicesConfig) =>
    servicesConfig
      ? updateServicesConfig(servicesConfig)
      : DEFAULT_SERVICES_CONFIG
  )

  return {
    servicesConfig,
    updateServicesConfig,
    saveServicesConfig: () => {
      if (servicesConfig) {
        saveConfig(servicesConfig)
      }
    }
  }
}
