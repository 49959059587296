var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
export const _Headers = styled.div `
  padding-left: ${fromTheme((theme) => theme.global.baseSpacing)}em;
  display: flex;
  ${globalFont};

  ${({ maxWidth }) => maxWidth &&
    css `
      max-width: ${maxWidth}px;
    `}

  ${({ flex }) => flex &&
    css `
      > * {
        flex: ${flex};
      }
    `}
  
  ${({ width }) => width &&
    css `
      > * {
        width: ${typeof width === 'number' ? `${width}px` : width};
      }
    `}
  
  header {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: ${fromTheme((theme) => theme.colors.gray)};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    input {
      font-size: 1em;

      &:placeholder-shown {
        text-transform: uppercase;
      }
    }
  }

  padding-bottom: ${fromTheme((theme) => theme.space[1])};
  padding-top: ${fromTheme((theme) => theme.space[1])};

  margin-bottom: ${fromTheme((theme) => theme.space[2])};

  border-bottom: 1px solid ${fromTheme((theme) => theme.colors.lightGray)};
`;
export const _Header = styled.header `
  display: flex;
  justify-content: space-between;

  padding-right: ${fromTheme((theme) => theme.global.baseSpacing)}em;
  text-transform: uppercase;
  color: ${fromTheme((theme) => theme.colors.gray)};

  ${({ flex }) => flex &&
    css `
      flex: ${flex};
    `}
  ${({ width }) => width &&
    css `
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  input {
    font-size: 1em;

    &:placeholder-shown {
      text-transform: uppercase;
    }
  }
`;
const StyledDiv = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  width: 30px;
  padding-left: 5px;
`;
function SortButton(_a) {
    var { name, sort, onClick } = _a, rest = __rest(_a, ["name", "sort", "onClick"]);
    const Icon = name !== sort.by
        ? TiArrowUnsorted
        : sort.asc
            ? TiArrowSortedUp
            : TiArrowSortedDown;
    return (_jsx(StyledDiv, Object.assign({}, rest, { onClick: () => {
            var _a;
            return onClick({
                by: name,
                asc: (_a = !(sort === null || sort === void 0 ? void 0 : sort.asc)) !== null && _a !== void 0 ? _a : false
            });
        } }, { children: _jsx(Icon, { size: 22 }) })));
}
const Header = _Header;
Header.SortButton = SortButton;
const Headers = _Headers;
Headers.Header = Header;
export { Headers };
