var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { injectIf, preciseEm } from '../utils';
import { matchSizes, themeColor, themeGlobal, themeSpace } from '../Theme';
import { useFormContext } from '../Form';
const MAX_WIDTH = 50; // em
const Annotation = styled.div `
  font-size: ${matchSizes}em;
  color: ${themeColor('gray')};
`;
const StyledField = styled.div `
  display: grid;
  width: 100%;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(40px, max-content) minmax(min-content, 2fr);
  grid-template-areas:
    'a b'
    'a c';
  grid-column-gap: ${themeGlobal('baseSpacing')}em;

  ${injectIf('stacked', css `
      grid-template-columns: 1fr;
      grid-template-areas:
        'a'
        'b'
        'c';
    `)}

  >:nth-child(1) {
    grid-area: a;
    align-self: center;
  }

  > :nth-child(2) {
    grid-area: b;
    align-self: end;
    padding-bottom: 5px;
  }

  > :nth-child(3) {
    grid-area: c;
    align-self: start;
  }
`;
const StyledContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${preciseEm(MAX_WIDTH)}em;

  gap: ${(_a) => {
    var { gap, stacked } = _a, props = __rest(_a, ["gap", "stacked"]);
    return themeSpace(gap ? gap : stacked ? 2 : 3, props);
}};
`;
function Container({ className, stacked, gap, children }) {
    const ctx = useFormContext();
    return (_jsx(StyledContainer, Object.assign({ className: className, gap: gap, stacked: stacked || ctx.stacked }, { children: children })));
}
Container.displayName = 'FieldContainer';
export { Container, Annotation, StyledField };
