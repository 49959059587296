import fetch from 'isomorphic-fetch'

import { toQueryString } from '../'

import { processHooks } from './hooks'
import { createHookContext, toQueryObject } from './hookObject'
import { RequestMethod } from './FetchTypes'

interface Params {
  query: {
    [key: string]: string
  }
  [key: string]: any
}

interface Options {
  [key: string]: any
}

type HookError = Error & {
  hook: HookContext<HookType.Error>
}

type ImmutableResponse<T = Response> = Pick<
  T,
  {
    [Key in keyof T]: T[Key] extends (...args: any) => any
      ? Key extends 'clone'
        ? Key
        : never
      : Key
  }[keyof T]
>

export type HookContext<HT extends HookType = any, T = any> = {
  readonly path: string
  readonly type: HT
  readonly method: RequestMethod
  /**
   * @description The response should always remain unchanged for each hook to use.
   * Therefore, the use of all promises have been restricted with the exception of clone.
   * Use clone to get an unrestricted response object.
   */
  readonly response?: ImmutableResponse
  params: Params
  id?: string | null | undefined
  data?: T
  result?: T
  options: Options
} & (HT extends HookType.Error ? { error: HookError } : unknown)

export type Hook<HT extends HookType = any, T = any> = (
  context: HookContext<HT, T>
) => HookContext<HT, T> | void | Promise<HookContext<HT, T> | void>

export interface HookMap<T = any> {
  before: Hook<HookType.Before, T>[]
  after: Hook<HookType.After, T>[]
  error: Hook<HookType.Error, T>[]
}

export enum HookType {
  Before = 'before',
  After = 'after',
  Error = 'error'
}

function setUrl(requestInfo: RequestInfo, url: string) {
  if (typeof requestInfo === 'string') {
    return url
  } else {
    return {
      ...requestInfo,
      url
    }
  }
}

export function fetchWithHooks(
  hooksMap: HookMap,
  service: typeof fetch = fetch
) {
  return async function customfetch(
    requestInfo: RequestInfo,
    options: RequestInit = {},
    otherOptions: any = {}
  ) {
    const hookObject = createHookContext(requestInfo, options, otherOptions)

    return (
      Promise.resolve(hookObject)

        // Run `before` hooks
        .then((hookObject) =>
          processHooks(([] as Hook[]).concat(hooksMap.before), hookObject)
        )

        // Run the original method
        .then((hookObject) => {
          // If `hookObject.result` is set, skip the original method
          if (typeof hookObject.result !== 'undefined') {
            return hookObject
          }

          // Otherwise, call it
          const promise = new Promise<Response>((resolve) => {
            const initUrl =
              typeof requestInfo === 'string' ? requestInfo : requestInfo.url

            // Merge paramters set through string or hook
            const [trimmedUrl, queryString] = initUrl.split('?')
            const mergedQueries = toQueryString({
              ...(queryString && toQueryObject(queryString)),
              ...hookObject.params.query
            })

            const url = mergedQueries.length
              ? [trimmedUrl, mergedQueries].join('?')
              : trimmedUrl

            const result = service(setUrl(requestInfo, url), options)

            resolve(result)
          })

          return promise
            .then(
              (response) =>
                ({
                  ...hookObject,
                  result: response.clone(),
                  response
                } as HookContext)
            )
            .catch((error) => {
              error.hook = hookObject
              throw error
            })
        })

        // Run `after` hooks
        .then((hookObject) => {
          const afterHookObject = {
            ...hookObject,
            type: HookType.After
          } as HookContext<HookType.After>
          return processHooks(hooksMap.after, afterHookObject)
        })

        // Run `errors` hooks
        .catch((error) => {
          const errorHookObject = {
            ...error.hook,
            type: HookType.Error,
            original: error.hook,
            error,
            result: undefined
          } as HookContext<HookType.Error>

          return processHooks(hooksMap.error, errorHookObject).catch(
            (error) => ({
              ...error.hook,
              error,
              result: undefined
            })
          )
        })

        // Resolve with a result or reject with an error
        .then((hookObject) => {
          if (
            typeof hookObject.error !== 'undefined' &&
            typeof hookObject.result === 'undefined'
          ) {
            return Promise.reject(hookObject.error)
          } else {
            return hookObject.result
          }
        })
    )
  }
}
