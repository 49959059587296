import { curry, find, startsWith, rejectIsNil } from '@exivity/fp';
import { match } from 'path-to-regexp';
export const isActivePathname = curry((pathname, routePathname) => startsWith(routePathname, pathname) || !!match(routePathname)(pathname));
const isActiveRoute = (pathname) => {
    return (route) => {
        return isActivePathname(pathname, route.pathname);
    };
};
export const findActiveRoute = curry((pathname, routes) => find(isActiveRoute(pathname), Object.values(routes)));
export const getActiveRoutes = curry((pathname, routes) => {
    const activeRoutes = [findActiveRoute(pathname, routes)].flatMap((x) => x && x.routes ? [x, ...getActiveRoutes(pathname, x.routes)] : [x]);
    return rejectIsNil(activeRoutes);
});
