import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Manager, Reference, Popper as ReactPopper } from 'react-popper';
import styled from 'styled-components';
import { OutsideClickListener } from '../OutsideClickListener';
export const Container = styled.div `
  position: relative;
`;
/**
 * Placement options for Popper
 * @see https://popper.js.org/popper-documentation.html#Popper.placements
 */
export var Placement;
(function (Placement) {
    Placement["RIGHT"] = "right";
    Placement["RIGHT_START"] = "right-start";
    Placement["RIGHT_END"] = "right-end";
    Placement["LEFT"] = "left";
    Placement["LEFT_START"] = "left-start";
    Placement["LEFT_END"] = "left-end";
    Placement["TOP"] = "top";
    Placement["TOP_START"] = "top-start";
    Placement["TOP_END"] = "top-end";
    Placement["BOTTOM"] = "bottom";
    Placement["BOTTOM_START"] = "bottom-start";
    Placement["BOTTOM_END"] = "bottom-end";
    Placement["AUTO"] = "auto";
    Placement["AUTO_START"] = "auto-start";
    Placement["AUTO_END"] = "auto-end";
})(Placement || (Placement = {}));
export const Popper = ({ renderTrigger, open, children, onOutsideClick, placement = Placement.BOTTOM_START, offset = 0 }) => (_jsx(Manager, { children: _jsx(Container, { children: _jsxs(OutsideClickListener, Object.assign({ onOutsideClick: onOutsideClick }, { children: [_jsx(Reference, { children: renderTrigger }), open ? (_jsx(ReactPopper, Object.assign({ placement: placement, modifiers: [
                        {
                            name: 'offset',
                            enabled: offset !== 0,
                            options: {
                                offset: [offset, offset]
                            }
                        }
                    ] }, { children: ({ ref, style, placement: currentPlacement, arrowProps, update }) => children({
                        ref: ref,
                        style: style,
                        placement: currentPlacement,
                        arrowProps: arrowProps,
                        update
                    }) }))) : null] })) }) }));
