"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translator = void 0;
const makeTranslator_1 = require("./makeTranslator");
const translations_1 = require("./translations");
const translate_1 = require("./translate");
function translator(language, allTranslations = translations_1.translations, languages = translate_1.supportedLanguages) {
    const translation = languages.find((translation) => allTranslations[translation].language === language);
    return (0, makeTranslator_1.makeTranslator)(translation ? allTranslations[translation] : undefined);
}
exports.translator = translator;
