import React from 'react'
import { useModal } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { useLogout } from '../../../auth/hooks'
import { clearUserState } from '../../../../actions/tools'

const useConfirmCleanUpCaches = () => {
  const { confirm } = useModal()
  const logout = useLogout()

  return () =>
    confirm({
      body: translate('The page will refresh and you will have to login again.')
    }).then(() => {
      clearUserState().then(() => logout())
    })
}

export function ResumeOptions() {
  const confirmCleanup = useConfirmCleanUpCaches()

  return (
    <div>
      <p>{translate("Here's some things you can try next:")}</p>
      <ul>
        <li>
          <a
            href=".."
            onClick={(e) => {
              e.preventDefault()
              window.history.back()
            }}
          >
            {translate('return to the previous page')}
          </a>
        </li>
        <li>
          <a
            href=".."
            onClick={(e) => {
              e.preventDefault()
              window.location.reload()
            }}
          >
            {translate('reload the page')}
          </a>
        </li>
        <li>
          <a
            href=".."
            onClick={(e) => {
              e.preventDefault()
              confirmCleanup()
            }}
          >
            {translate('clean up local caches')}
          </a>
        </li>
      </ul>
      <p>
        {translate(
          "We can't promise you it will resolve the problem, but it's worth trying."
        )}
      </p>
    </div>
  )
}
