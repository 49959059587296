import styled from 'styled-components';
import { themeSpace, themeColor, themeGlobal } from '../Theme';
import { ITEM_HEIGHT } from './SelectList';
export const StyledContainer = styled.div `
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    font-family: ${themeGlobal('fontFamily')};
    font-weight: normal;
    font-size: 14px;
    line-height: ${themeGlobal('lineHeight')};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    outline: none;

    height: ${() => ITEM_HEIGHT}px;
    padding: 0 ${themeSpace(2)};

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
    }
  }

  .select {
    &__clearable {
      cursor: pointer;
      justify-content: space-between;
      color: ${themeColor('gray')};
    }
  }
`;
