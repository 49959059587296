import { translate } from '@exivity/translations'

import store from '../../store'
import { isAuthError } from '../errors/handler'
import { HookContext, HookType } from '../fetchWithHooks/fetchWithHooks'
import { authActions } from '../../domains/auth/state'

export async function handleResponseErrors(
  ctx: HookContext<HookType.After | HookType.Error>
) {
  const { response, type } = ctx

  if (type === HookType.After) {
    if (!response?.ok) {
      throw new Error(response?.statusText)
    }
  }

  if (type === HookType.Error) {
    const { error } = ctx as HookContext<HookType.Error>
    try {
      // If undefined response, then handle in catch
      if (typeof response === 'undefined') throw new Error()

      const json = await response.clone().json()

      if (json.errors[0] && json.errors[0].status) {
        error.message = json.errors[0].detail 
      } else {
       // json.errors.detail, json.errors.title are needed for API v1 which isnt compliant with spec
        error.message = json.errors.detail 
        || json.errors.title 
        || error.message 
        || translate('API response contains an error.')
      }

      if (isAuthError(response.status)) {
        store.dispatch(authActions.updateIsAuthenticated(false))
      }
    } catch {}

    return ctx as any
  }
}
