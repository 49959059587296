import { lensPath, set, curry, compose } from '@exivity/fp';
import { isBrightColor } from '../utils/isBrightColor';
import { getPalette, hexToNumber } from '../utils/colorUtils';
const makeThemeLens = (path) => lensPath(path);
const themeBrandPaletteLens = makeThemeLens(['colors', 'brand']);
const themeInverseBrandLens = makeThemeLens(['global', 'inverseBrandTextColor']);
const setThemeBrandAndInverse = curry((brand) => {
    const brandNumber = hexToNumber(brand);
    const brandPalette = getPalette(brandNumber);
    return compose(set(themeInverseBrandLens, isBrightColor(brand) ? 'black' : 'white'), set(themeBrandPaletteLens, brandPalette));
});
export { setThemeBrandAndInverse };
