import { combineReducers } from 'redux'

import { reports } from '../domains/reports/state'
import { authReducer as auth, authActions } from '../domains/auth/state'
import { profileReducer as profile } from '../domains/my-profile/state'
import { audit } from '../domains/administration/state'
import { workReducer as work } from '../domains/work/state'
import { datasourcesReducer as datasources } from '../domains/datasources/state'

import system, { SystemState } from './system'
import gui, { GUIState } from './gui'
import logs, { LogsState } from './logs'
import data, { DataState } from './data'

export interface RootState {
  auth: ReturnType<typeof auth>
  reports: ReturnType<typeof reports>
  work: ReturnType<typeof work>
  gui: GUIState
  data: DataState
  datasources: ReturnType<typeof datasources>
  profile: ReturnType<typeof profile>

  // administration
  audit: ReturnType<typeof audit>
  system: SystemState
  logs: LogsState
}

const appReducer = combineReducers<Partial<RootState>>({
  auth,
  reports,
  system,
  work,
  gui,
  audit,
  logs,
  data,
  datasources,
  profile
} as any)

const rootReducer = (state: RootState, action: any) => {
  if (action.type === authActions.updateIsAuthenticated.type) {
    if (action.payload === false) {
      (state as any) = {
        work: state.work
      }
    }
  }

  return appReducer(state, action)
}

export default rootReducer
