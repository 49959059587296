import { createQueryModifier } from './queryModifier';
import { extractId } from './utils';
export class RelationshipOf {
    relationshipOf(type, identifier, relationship) {
        var _a;
        const op = ((_a = this.schema.models[type].relationships) === null || _a === void 0 ? void 0 : _a[relationship].kind) === 'hasOne'
            ? 'findRelatedRecord'
            : 'findRelatedRecords';
        return createQueryModifier(this.schema, {
            op,
            record: {
                type,
                id: extractId(identifier)
            },
            relationship
        });
    }
}
