import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'
import { invalidateAllReportData } from '../invalidateAllReportData'

export const updateCategory = {
  prepare: (id: string | null) => ({
    payload: id,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (
    filters: ReportFilterState,
    action: PayloadAction<string | null>
  ) => {
    filters.category = action.payload
    filters.services.pinned = []
    filters.instances.pinned = []
  }
}

export const updateService = {
  prepare: (id: string | null) => ({
    payload: id,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (
    filters: ReportFilterState,
    action: PayloadAction<string | null>
  ) => {
    filters.service = action.payload
    filters.instances.pinned = []
  }
}
