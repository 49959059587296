import { processHooks } from '../fetchWithHooks/hooks'
import { Hook, HookContext, HookType } from '../fetchWithHooks/fetchWithHooks'

export function ifElse<HT extends HookType, T>(
  predicate: (ctx: HookContext<HT, T>) => boolean | Promise<boolean>,
  trueFuncs: Hook<HT, T>[],
  falseFuncs: Hook<HT, T>[]
) {
  return function (context: HookContext<HT, T>) {
    if (typeof trueFuncs === 'function') {
      trueFuncs = [trueFuncs]
    }
    if (typeof falseFuncs === 'function') {
      falseFuncs = [falseFuncs]
    }

    const check =
      typeof predicate === 'function'
        ? (Promise.resolve(predicate(context)) as Promise<boolean>)
        : !!predicate

    if (!check) {
      return processHooks(falseFuncs, context)
    }

    if (typeof check === 'boolean') {
      return processHooks(falseFuncs, context)
    }

    return check.then((check1) =>
      processHooks(check1 ? trueFuncs : falseFuncs, context)
    )
  }
}
