import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useLayoutEffect, createContext } from 'react';
import styled from 'styled-components';
import { prop } from '@exivity/fp';
import { themePriority, themeColor, useIsSmallScreen } from '../Theme';
import { classNames, globalFont } from '../utils';
import { NavLink } from './NavLink';
import { NavFilter } from './NavFilter';
import { NavGroup } from './NavGroup';
import { Separator } from './Separator';
import { Footer } from './Footer';
import { animateNavElements, getCoverHeight, openGroup } from './animations';
import { horizontalAnimations } from './horizontalAnimations';
const Nav = styled.nav `
  position: fixed;
  top: 60px;
  left: -200px;
  width: 270px;
  height: calc(100vh - 60px);
  z-index: ${themePriority(3)};
  overflow-y: auto;
  overflow-x: hidden;
  ${globalFont};
  ${horizontalAnimations};
  
  background-color: ${themeColor('dark')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  ::-webkit-scrollbar { width: 8px; height: 3px;}
  ::-webkit-scrollbar-button {  background-color: #666; }
  ::-webkit-scrollbar-track {  background-color: #646464;}
  ::-webkit-scrollbar-track-piece { background-color: #000;}
  ::-webkit-scrollbar-thumb { height: 5px; background-color: #666;}
  ::-webkit-scrollbar-corner { background-color: #646464;}}
  ::-webkit-resizer { background-color: #666;}
`;
const Cover = styled.li `
  height: ${prop('height')}px;
  background-color: ${themeColor('dark')};
`;
const StyledNavList = styled.ul `
  margin: 0;
  padding: 0;
  width: 270px;
`;
export const NavGroupContext = createContext({
    openAtIndex: null,
    onClick: () => { }
});
function NavBar({ className = '', collapsed = false, onCollapse, footer, progress = 0, progressIsActive, progressText, children }) {
    const [openAtIndex, setOpenAtIndex] = useState(null);
    const [internalCollapsed, setInternalCollapsed] = useState(collapsed);
    const [element, setElement] = useState(null);
    const isSmallScreen = useIsSmallScreen((isSmall) => {
        element &&
            !collapsed &&
            animateNavElements(element, isSmall ? null : openAtIndex);
    });
    const isCollapsed = isSmallScreen || collapsed;
    useEffect(() => {
        setInternalCollapsed(isCollapsed);
    }, [isCollapsed]);
    useLayoutEffect(() => {
        if (element && openAtIndex && !internalCollapsed) {
            openGroup(element, openAtIndex);
        }
    });
    const expandSideBarClassName = classNames({
        'nav__sidebar--expanded': !internalCollapsed
    }, className);
    return (_jsxs(Nav, Object.assign({ className: expandSideBarClassName, onMouseEnter: () => {
            setInternalCollapsed(false);
            if (isCollapsed && element) {
                animateNavElements(element, openAtIndex);
            }
        }, onMouseLeave: () => {
            setInternalCollapsed(isCollapsed);
            if (isCollapsed && element) {
                animateNavElements(element, null);
            }
        } }, { children: [_jsxs(StyledNavList, Object.assign({ ref: setElement }, { children: [React.Children.map(children, (child) => (_jsx(NavGroupContext.Provider, Object.assign({ value: {
                            openAtIndex,
                            onClick: (index) => {
                                const openIndex = index === openAtIndex ? null : index;
                                if (element) {
                                    animateNavElements(element, openIndex);
                                    setOpenAtIndex(openIndex);
                                }
                            }
                        } }, { children: child })))), _jsx(Cover, { height: element ? getCoverHeight(element) : 0 })] })), _jsx(Footer, { footer: footer, progress: progress, progressIsActive: progressIsActive, progressText: progressText, collapsed: collapsed, onCollapse: isSmallScreen ? undefined : onCollapse })] })));
}
NavBar.Link = NavLink;
NavBar.Filter = NavFilter;
NavBar.Group = NavGroup;
NavBar.Separator = Separator;
export { NavBar };
