import styled, { css } from 'styled-components';
import { Block } from '../Block';
import { themeSpace } from '../Theme';
import { FlexItem } from './Item';
export const Flex = styled(Block) `
  display: flex;
  ${({ direction }) => direction &&
    css `
      flex-direction: ${direction};
    `}
  ${({ wrap }) => wrap &&
    css `
      flex-wrap: ${wrap};
    `}
  ${({ justifyContent }) => justifyContent &&
    css `
      justify-content: ${justifyContent};
    `}
  ${({ alignItems }) => alignItems &&
    css `
      align-items: ${alignItems};
    `}
  ${({ alignContent }) => alignContent &&
    css `
      align-content: ${alignContent};
    `}
  ${({ gap }) => gap &&
    css `
      gap: ${themeSpace(gap)};
    `}
  ${(props) => props.grow &&
    css `
      flex-grow: ${props.grow};
    `}
`;
Flex.Item = FlexItem;
Flex.displayName = 'Flex';
