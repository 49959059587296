import React from 'react'
import { UIExtendPropsContext } from '@exivity/ui'

import { UIextensions } from '../UIExtensions'

export function UIExtensionsProvider({ children }: any) {
  return (
    <UIExtendPropsContext.Provider value={UIextensions}>
      {children}
    </UIExtendPropsContext.Provider>
  )
}
