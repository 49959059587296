import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'

const Jobs = lazy(
  () => import(/* webpackChunkName: 'profile' */ '../domains/jobs/pages/Jobs')
)

export const status = {
  path: '/status',
  requiresPermissions: [UserGroupPermission.ManageReports],
  meta: {
    title: () => translate('Status')
  },
  component: Jobs
}
