import React from "react";
import PropTypes from "prop-types";

import { classNames } from "./../../../utils/misc";

/**
 * @type {React.ComponentType<any>}
 */
const Element = ({ label, children, options, forceFullWidth, forceInline }) => (
  <div
    className={classNames(
      { "ex-form__element--inline": forceInline },
      "ex-form__element uk-margin"
    )}
  >
    {label && <label className="uk-form-label">{label}</label>}
    <div
      className={classNames({
        "ex-form__element--full-width": forceFullWidth,
        "uk-form-controls uk-flex uk-flex-column": true,
        "uk-form-controls-text": options,
      })}
    >
      {children}
    </div>
  </div>
);

Element.propTypes = {
  children: PropTypes.any,
  forceFullWidth: PropTypes.bool,
  forceInline: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  options: PropTypes.bool,
};

export default Element;
