import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { _themeFontFamily, _themeFontSize, _themeFontWeight, themePalette } from '../Theme';
export const PaginationContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
`;
export const PageNumber = styled.button `
  font-size: ${_themeFontSize('small')};
  color: ${themePalette('neutral', 700)};
  font-family: ${_themeFontFamily('monospace')};
  font-weight: ${_themeFontWeight('bold')};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  word-break: break-word;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;
export const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const handleClick = (pageNumber) => {
        onPageChange(pageNumber);
    };
    const getDisplayedPages = () => {
        if (totalPages <= 7) {
            // If total pages are less than or equal to 7, show all pages.
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        }
        else {
            // If currentPage is less than 5, show first 5, dots, then last 2.
            if (currentPage < 5) {
                return [
                    ...Array.from({ length: 5 }, (_, index) => index + 1),
                    '...',
                    totalPages - 1,
                    totalPages
                ];
            }
            // If currentPage is in the last 4 pages, show first 2, dots, then last 5.
            else if (currentPage > totalPages - 4) {
                return [
                    1,
                    2,
                    '...',
                    ...Array.from({ length: 5 }, (_, index) => totalPages - 5 + index + 1)
                ];
            }
            // Otherwise, show first 2, dots, nearby 3, dots, then last 2.
            else {
                return [
                    1,
                    2,
                    '...',
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    '...',
                    totalPages - 1,
                    totalPages
                ];
            }
        }
    };
    return (_jsxs(PaginationContainer, { children: [_jsx(PageNumber, Object.assign({ onClick: () => handleClick(Math.max(1, currentPage - 1)), disabled: currentPage === 1 }, { children: "\u00AB" })), getDisplayedPages().map((page, index) => (_jsx(PageNumber, Object.assign({ onClick: () => typeof page === 'number' && handleClick(page), style: {
                    fontWeight: currentPage === page ? 'bold' : 'normal',
                    cursor: typeof page === 'number' ? 'pointer' : 'default'
                } }, { children: page }), index))), _jsx(PageNumber, Object.assign({ onClick: () => handleClick(Math.min(totalPages, currentPage + 1)), disabled: currentPage === totalPages }, { children: "\u00BB" }))] }));
};
