import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import Label from "../../atoms/Label";
import { isBright } from "../../../utils/misc";

import "./index.css";

class Dropdown extends Component {
  componentDidMount() {
    const $drop = $(this.refs.drop);

    $drop.on("show", () => this.props.onShow && this.props.onShow());
    $drop.on("hide", () => this.props.onHide && this.props.onHide());
  }

  componentWillUnmount() {
    const $drop = $(this.refs.drop);

    $drop.off("show hide");
  }

  renderButton = () => {
    let foreGroundColour;

    if (this.props.backgroundColor) {
      foreGroundColour = isBright(this.props.backgroundColor)
        ? "black"
        : "white";
    }

    return (
      <button
        type="button"
        className="ex-dropdown__trigger uk-button uk-button-link"
        style={{
          backgroundColor: this.props.backgroundColor,
          color: foreGroundColour,
        }}
      >
        {this.props.value}
        {!this.props.value && (
          <span className="ex-dropdown__placeholder">
            {this.props.placeholder}
          </span>
        )}
        <span
          className="ex-dropdown__chevron"
          data-uk-icon="icon: chevron-down"
        />
      </button>
    );
  };

  cloneButton = () => {
    return React.cloneElement(this.props.button, {
      value: this.props.value || this.props.placeholder,
      ...this.props.button.children,
    });
  };

  render() {
    return (
      <div className={`ex-dropdown ${this.props.className || ""}`}>
        {this.props.label && (
          <Label className="uk-margin-small-right">{this.props.label}</Label>
        )}
        <div className="uk-inline">
          {this.props.button ? this.cloneButton() : this.renderButton()}
          <div
            ref="drop"
            data-uk-drop={
              `mode: click; offset: ${this.props.offset}; position: bottom-right;` +
              "animation: uk-animation-slide-top-small; duration: 200"
            }
          >
            <div
              className={`
              uk-card uk-card-body
              uk-card-default
              uk-card-small
              ${this.props.noPadding && "uk-padding-remove"}
            `}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  backgroundColor: PropTypes.string,
  button: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noPadding: PropTypes.bool,
  offset: PropTypes.number,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
};

Dropdown.defaultProps = { offset: 5 };

export default Dropdown;
