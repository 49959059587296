import { PALETTE } from './palette';
export const PURPOSES = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'brand'
];
const BASE_SIZE = 16;
const preciseEm = (fraction, size = BASE_SIZE) => {
    const rounded = Math.round(size * fraction);
    return rounded / size;
};
export const BASE_SPACING = preciseEm(1.25); // 20px
const APP_HEADER_HEIGHT = 60;
const LAYOUT_TOOLBAR = 110;
const APP_CONTENT_HEIGHT = `calc(100vh - (${APP_HEADER_HEIGHT}px + ${2 * BASE_SPACING}em))`;
export const LAYOUT_TOOLBAR_HEIGHT = `calc(100vh - (${LAYOUT_TOOLBAR}px + ${2 * BASE_SPACING}em))`;
const APP_FULLSCREEN_CONTENT_HEIGHT = `calc(100vh - ${2 * BASE_SPACING}em)`;
export const SIZES = {
    tiny: preciseEm(0.7),
    small: preciseEm(0.85),
    base: preciseEm(1),
    large: preciseEm(1.2),
    huge: preciseEm(1.5) // 24px
};
const theme = {
    fonts: {
        custom: {
            families: ['Fira Sans:300,400,500,600,700', 'Fira Mono:400,500,700']
        }
    },
    breakpoints: ['52em', '64em', '75em'],
    space: [
        0,
        `${BASE_SPACING / 2}em`,
        `${BASE_SPACING}em`,
        `${BASE_SPACING * 2}em`,
        `${BASE_SPACING * 4}em` // 4
    ],
    fontSizes: [
        `${SIZES.tiny}rem`,
        `${SIZES.small}rem`,
        `${SIZES.base}rem`,
        `${SIZES.large}rem`,
        `${SIZES.huge}rem`
    ],
    global: {
        baseSize: BASE_SIZE,
        baseSpacing: BASE_SPACING,
        headerHeight: APP_HEADER_HEIGHT,
        contentHeight: APP_CONTENT_HEIGHT,
        fullscreenContentHeight: APP_FULLSCREEN_CONTENT_HEIGHT,
        fontFamily: "'Fira Sans', sans-serif",
        fontFamilyMonospace: "'Fira Mono', monospace",
        textColor: PALETTE.neutral[900],
        textColorMuted: PALETTE.neutral[500],
        lineHeight: 1.5,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: PALETTE.neutral[300],
        outlineWidth: 4,
        outlineAlpha: 0.15,
        shadowAlpha: 0.1,
        inverseBrandTextColor: PALETTE.white,
        purposes: {
            _default: 'primary',
            primary: PALETTE.primary[500],
            secondary: PALETTE.neutral[500],
            success: PALETTE.success[500],
            danger: PALETTE.danger[500],
            warning: PALETTE.warning[500],
            brand: PALETTE.primary[500]
        },
        sizes: Object.assign({ _default: 'base' }, SIZES),
        zPriority: [-1000, 0, 1000, 2000, 3000, 4000]
    },
    button: {
        heights: {
            _default: 'base',
            tiny: preciseEm(2.45, 9.625),
            small: preciseEm(2.45, 12.25),
            base: preciseEm(2.85, 14),
            large: preciseEm(2.7, 16.625),
            huge: preciseEm(2.4, 21) // 50px
        }
    },
    colors: Object.assign({}, PALETTE),
    typography: {
        family: {
            _default: "'Fira Sans', sans-serif",
            monospace: "'Fira Mono', monospace"
        },
        weight: {
            _default: 400,
            medium: 500,
            bold: 700
        },
        size: {
            _default: 'base',
            tiny: `${SIZES.tiny}rem`,
            small: `${SIZES.small}rem`,
            base: `${SIZES.base}rem`,
            large: `${SIZES.large}rem`,
            huge: `${SIZES.huge}rem`
        }
    }
};
export const defaultTheme = theme;
