var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { format as dateFormat } from 'date-fns';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { translate } from '@exivity/translations';
import { SelectInput } from '../Input/SelectInput';
import { ModePicker } from '../Calendar/ModePicker';
import { DateCalendarV2 } from '../Calendar/DateCalendarV2';
import { CalendarMode } from '../Calendar/CalendarModes';
import { useFieldContext } from '../Field/context';
import { withExtendableProps } from '../ExtendProps';
import { DatePickerDropDown } from './DatePickerDropdown';
export function _DatePicker(_a) {
    var { name, format = 'yyyyMMdd', placeholder = translate('Choose date'), required, disabled, min, max, mode, initMode = CalendarMode.Days, value, onChange = identity, onClear } = _a, sizes = __rest(_a, ["name", "format", "placeholder", "required", "disabled", "min", "max", "mode", "initMode", "value", "onChange", "onClear"]);
    const [currentMode, setMode] = useState(initMode);
    const [isOpen, toggleOpen] = useToggle(false);
    const { id } = useFieldContext();
    const calendar = (_jsx(DateCalendarV2, { value: value, min: min, max: max, mode: mode, onChange: (date, mode) => {
            onChange(date, mode);
            toggleOpen();
        }, onClear: onClear ? juxt([onClear, toggleOpen]) : undefined }));
    return (_jsx(DatePickerDropDown, Object.assign({ disabled: disabled, reference: _jsx(SelectInput, Object.assign({ id: id, name: name, value: value ? dateFormat(value, format) : '', placeholder: placeholder, required: required, disabled: disabled }, sizes)), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: !mode ? (_jsx(ModePicker, Object.assign({ value: currentMode, onChange: setMode }, { children: calendar }))) : (calendar) })));
}
export const DatePicker = withExtendableProps('DatePicker')(_DatePicker);
