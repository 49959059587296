import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { Label } from '../Label';
import { Header, Content, Actions, Title, Join } from './components';
function defaultHeader({ collapsible, actions, title, subTitle }) {
    return (_jsxs(Header, { children: [_jsxs(Label.Group, { children: [_jsx(Label, { children: title }), subTitle && _jsx(Label.Sub, { children: subTitle })] }), _jsxs("div", Object.assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Actions, Object.assign({ onClick: (e) => e.stopPropagation() }, { children: actions })), collapsible && _jsx(CollapsibleComponent.Collapse, {})] }))] }));
}
const StyledGroupBox = styled.div `
  box-sizing: border-box;
  width: 100%;
`;
function GroupBox({ children, title, subTitle, actions, className, initialCollapsed, collapsed, onCollapseChange }) {
    const collapsible = initialCollapsed !== undefined || collapsed !== undefined;
    const child = (_jsx(StyledGroupBox, Object.assign({ className: className }, { children: title ? (_jsxs(_Fragment, { children: [defaultHeader({
                    collapsible,
                    title,
                    subTitle,
                    actions
                }), _jsx(Content, { children: children })] })) : (children) })));
    return (_jsx(CollapsibleComponent, Object.assign({ initialCollapsed: initialCollapsed, collapsed: collapsible ? collapsed : false, onCollapseChange: onCollapseChange }, { children: child })));
}
GroupBox.Header = Header;
GroupBox.Title = Title;
GroupBox.Collapser = CollapsibleComponent.Collapse;
GroupBox.Actions = Actions;
GroupBox.Content = Content;
GroupBox.Join = Join;
export { GroupBox };
