import React from 'react'
import { AppBar } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { none, equals } from '@exivity/fp'
import { MdSettings } from 'react-icons/md'
import { getPathname, getRequiredPermissions } from '@exivity/routing'
import { useUserHasPermission } from '@exivity/data-layer'

import { app } from '../../../routes'

export function AdministrationMenu() {
  const { userManagement, settings, auditTrail, logViewer } =
    app.routes.administration.routes

  const canManageUsers = useUserHasPermission(
    getRequiredPermissions(userManagement)
  )
  const canManageSettings = useUserHasPermission(
    getRequiredPermissions(settings)
  )
  const canViewAuditTrail = useUserHasPermission(
    getRequiredPermissions(auditTrail)
  )
  const canViewLogs = useUserHasPermission(getRequiredPermissions(logViewer))

  if (
    none(equals(true), [
      canManageUsers,
      canManageSettings,
      canViewLogs,
      canViewAuditTrail
    ])
  ) {
    return null
  }

  return (
    <AppBar.Dropdown label={translate('administration')} icon={<MdSettings />}>
      {canManageUsers && (
        <AppBar.Link to={getPathname(userManagement)}>
          {translate('User management')}
        </AppBar.Link>
      )}

      {canManageSettings && (
        <AppBar.Link to={getPathname(settings)}>
          {translate('Settings')}
        </AppBar.Link>
      )}

      {canViewAuditTrail && (
        <AppBar.Link to={getPathname(auditTrail)}>
          {translate('Audit Trail')}
        </AppBar.Link>
      )}

      {canViewLogs && (
        <AppBar.Link to={getPathname(logViewer)}>
          {translate('Logviewer')}
        </AppBar.Link>
      )}
    </AppBar.Dropdown>
  )
}
