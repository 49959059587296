import { jsx as _jsx } from "react/jsx-runtime";
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
import { themeGlobal } from '../Theme';
import { Row, Label, Checkbox, ExpandButton, Category, Headers, Header, Column, Container, Divider, Toolbar } from './components';
import { Searchbar } from './Searchbar';
import { Select } from './Select';
import { Title } from './Title';
const StyledList = styled.div `
  box-sizing: border-box;
  font-size: 14px;
  line-height: ${themeGlobal('lineHeight')};
  min-height: 300px;
  height: 100%;
  width: 100%;
`;
const style = { height: '100%', width: '100%', flexGrow: 1 };
function List(props) {
    return (_jsx(StyledList, Object.assign({ className: "ex-list__container" }, { children: _jsx(AutoSizer, Object.assign({ style: style }, { children: ({ height: autoHeight }) => (_jsx(FixedSizeList, Object.assign({ height: autoHeight, width: "100%", itemSize: 30 }, props, { children: props.children }))) })) })));
}
List.Container = Container;
List.Title = Title;
List.Searchbar = Searchbar;
List.Headers = Headers;
List.Divider = Divider;
List.Column = Column;
List.Category = Category;
List.Row = Row;
List.Label = Label;
List.Checkbox = Checkbox;
List.ExpandButton = ExpandButton;
List.Select = Select;
List.Header = Header;
List.Toolbar = Toolbar;
export { List };
