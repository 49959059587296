import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { translate } from '@exivity/translations';
import AutoSizer from 'react-virtualized-auto-sizer';
import useResizeObserver from 'use-resize-observer';
import { append, complement, isEmpty, assocPath, path, equals, reject, includes, curry } from 'ramda';
import { Searchbar } from './Searchbar';
import { ListContext } from './listContext';
import { StyledContainer } from './StyledContainer';
import { SelectOption } from './SelectOption';
import { Actions } from './Actions';
import { VirtualList } from './useVirtualList';
export const ITEM_HEIGHT = 30;
const isNotEmpty = complement(isEmpty);
export function isMultiple(args) {
    return Array.isArray(args);
}
const styles = {
    autoSizer: { minHeight: 300, height: '100%', width: '100%' },
    virtualList: { minHeight: 300 }
};
const identityAny = (id) => id;
export function SelectList({ className, actions, onClear, clearText = translate('Clear selection'), noDataText = translate('No items in this list...'), categorized, multiple, children = SelectOption, getChildNodes, labelAccessor = identityAny, valueAccessor = identityAny, value, onChange = () => { }, data, renderIcon, initExpandedState = {}, searchable }) {
    const [expandedStateTree, updateExpandedStateTree] = useState(initExpandedState);
    const [query, setQuery] = useState('');
    function searchAll(data, query = '', expandedState = {}, parentPath = []) {
        if (query.length === 0)
            return expandedState;
        const getChildren = getChildNodes ? getChildNodes : () => [];
        let newState = expandedState;
        data.forEach((item, index) => {
            const nodePath = [...parentPath, index];
            const children = getChildren(item);
            newState =
                labelAccessor(item).toLowerCase().indexOf(query.toLowerCase()) !== -1
                    ? assocPath(nodePath, true, newState)
                    : newState;
            if (children.length > 0) {
                newState = searchAll(children, query, newState, nodePath);
            }
        });
        return newState;
    }
    const list = useMemo(() => {
        function getList(data = [], expandedState = {}, parents = [], parentPath = [], onlyExpanded = false) {
            const getChildren = getChildNodes ? getChildNodes : () => [];
            return data.flatMap((item, index) => {
                const nodePath = [...parentPath, index];
                const expanded = !!path(nodePath, expandedState);
                const isActive = curry((currentValue, node) => multiple && Array.isArray(currentValue)
                    ? includes(valueAccessor(node), currentValue)
                    : equals(valueAccessor(node), currentValue));
                const node = {
                    data: item,
                    path: nodePath,
                    label: labelAccessor(item),
                    expanded,
                    onChangeExpand: () => updateExpandedStateTree(assocPath(nodePath, !expanded)),
                    query,
                    isTree: !!getChildNodes,
                    isLeaf: () => getChildren(item).length === 0,
                    categorized,
                    isCategory: !!categorized && nodePath.length === 1,
                    multiple,
                    isActive,
                    isParentActive: (currentValue) => parents.some(isActive(currentValue)),
                    getValue: (currentValue) => {
                        if (multiple && parents.some(isActive(currentValue)))
                            return;
                        const nodeValue = valueAccessor(item);
                        // Can add typeguards
                        return !multiple
                            ? nodeValue
                            : isActive(currentValue, item)
                                ? reject(equals(nodeValue), currentValue || [])
                                : append(nodeValue, currentValue);
                    },
                    renderIcon: (currentValue) => {
                        return renderIcon ? renderIcon({ item, value: currentValue }) : null;
                    }
                };
                if (expanded || (categorized && query.length === 0)) {
                    return [
                        node,
                        ...getList(getChildren(item), expandedState, [...parents, item], nodePath, onlyExpanded)
                    ];
                }
                else if (!onlyExpanded) {
                    return [node];
                }
                else if (onlyExpanded && expanded) {
                    return [node];
                }
                else {
                    return [];
                }
            });
        }
        return query.length > 0
            ? getList(data, expandedStateTree, [], [], true)
            : getList(data, expandedStateTree);
    }, [
        data,
        expandedStateTree,
        query,
        categorized,
        getChildNodes,
        multiple,
        labelAccessor,
        valueAccessor,
        renderIcon
    ]);
    // Because list is virtualized it doesnt respond well to resizing
    // In order to adjust when resizing we observe container and remount
    // the autoSizer when there is any change.
    // There is still room for improvement as it doesnt pick up on each resize.
    const { ref, height = 0 } = useResizeObserver();
    return (_jsxs(StyledContainer, Object.assign({ ref: ref, className: className }, { children: [searchable && (_jsx("div", Object.assign({ className: "select__searchbar" }, { children: _jsx(Searchbar, { onSubmit: (value) => {
                        setQuery(value);
                        updateExpandedStateTree(searchAll(data, value, initExpandedState));
                    } }) }))), actions && _jsx(Actions, { children: actions }), onClear && (_jsx("ul", { children: _jsx("li", Object.assign({ "data-testid": "select__clear", className: "select__clearable", onClick: onClear }, { children: clearText })) })), _jsx(ListContext.Provider, Object.assign({ value: useMemo(() => ({
                    list,
                    onChange,
                    value
                }), [list, onChange, value]) }, { children: isNotEmpty(list) && isEmpty(list) ? (_jsx("ul", { children: _jsx("li", Object.assign({ className: "select__clearable" }, { children: translate('Not found...') })) })) : isEmpty(list) ? (_jsx("ul", { children: _jsx("li", Object.assign({ className: "select__clearable" }, { children: noDataText })) })) : list.length <= 10 ? (_jsx("ul", { children: list.map((_, index) => (_jsx(SelectOption, { index: index }, index))) })) : (_jsx("div", Object.assign({ style: styles.autoSizer }, { children: _jsx(AutoSizer, Object.assign({ style: styles.autoSizer }, { children: ({ height: sizerHeight }) => (_jsx(VirtualList, { height: sizerHeight, data: list })) }), height) }))) }))] })));
}
function withoutValueAccessor(props) {
    return props.valueAccessor === undefined && !props.multiple;
}
function multipleWithValueAccessor(props) {
    return props.valueAccessor !== undefined && !!props.multiple;
}
function multipleWithoutValueAccessor(props) {
    return props.valueAccessor === undefined && !!props.multiple;
}
export function renderSelectList(props) {
    if (multipleWithoutValueAccessor(props)) {
        return _jsx(SelectList, Object.assign({}, props));
    }
    else if (multipleWithValueAccessor(props)) {
        return _jsx(SelectList, Object.assign({}, props));
    }
    else if (withoutValueAccessor(props)) {
        return _jsx(SelectList, Object.assign({}, props));
    }
    else {
        return _jsx(SelectList, Object.assign({}, props));
    }
}
