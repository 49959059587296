import styled from 'styled-components';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
export const DatePickerDropDown = styled(Dropdown) `
  position: relative;
  width: 100%;

  .dropdown__drop {
    box-sizing: border-box;
    padding: ${themeSpace(2)};
  }
`;
