import { translate } from '@exivity/translations'
import { lazy } from 'react'

const Login = lazy(
  () =>
    import(
      /* webpackChunkName: 'auth.login' */
      '../../domains/auth/pages/Login'
    )
)

export const login = {
  path: '/login',
  meta: {
    title: () => translate('Login')
  },
  component: Login,
  requiresAuth: false
}
