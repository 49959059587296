import React from 'react'
import { Link } from '@exivity/routing'
import {
  UserGroupPermission,
  useSystemConfiguration,
  useUserHasPermission
} from '@exivity/data-layer'
import { Alert } from '@exivity/ui'

export function DebugWarning() {
  const canSeeDebugWarning = useUserHasPermission([
    UserGroupPermission.ManageSettings
  ])
  const showDebugWarning = useSystemConfiguration().attributes.APP_DEBUG

  if (!showDebugWarning || !canSeeDebugWarning) return null

  return (
    <Alert warning>
      Debug mode is enabled, which can affect performance.&nbsp;
      <span>
        If you&apos;re done debugging, disable in&nbsp;
        <Link title="Settings" to="administration/settings/system">
          Settings &raquo; System
        </Link>{' '}
        &raquo; Development.
      </span>
    </Alert>
  )
}
