import { defaultTimeout } from '../fetch/fetchWithTimeout'

export function useMaxTimeout(time = defaultTimeout) {
  const timeout = setTimeout(() => {
    throw new Error('Request timed out.')
  }, time)

  return () => {
    clearTimeout(timeout)
  }
}
