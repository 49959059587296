var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { Icon } from '../Icon';
import { injectIf, preciseEm } from '../utils';
import { fromTheme, matchThemeProp, matchColorFromPurpose } from '../Theme';
export const StyledButton = styled.button `
  ${space}
  // Purpose vars
  --purpose-100: ${matchColorFromPurpose(100)};
  --purpose-200: ${matchColorFromPurpose(200)};
  --purpose-500: ${matchColorFromPurpose(500)};
  --purpose-600: ${matchColorFromPurpose(600)};
  --purpose-700: ${matchColorFromPurpose(700)};
  --purpose-focus-color: ${matchColorFromPurpose(500, 0.2)};
  --purpose-pressed-color: ${matchColorFromPurpose(500, 0.3)};
  // Shadow vars
  --outline-box-shadow: 0 0 0 1px var(--purpose-500);
  --focused-box-shadow: 0 0 0 4px var(--purpose-focus-color);
  white-space: nowrap;
  font-family: ${fromTheme((theme) => theme.global.fontFamily)};
  font-size: ${matchThemeProp((theme) => theme.global.sizes, {
    modifier: (em) => (em / 16) * 14
})}em;
  line-height: ${fromTheme((theme) => theme.global.lineHeight)};
  min-width: 2.5em;
  height: ${matchThemeProp((theme) => theme.button.heights)}em;
  padding: 0 ${preciseEm(1.5)}em;
  text-transform: uppercase;
  display: inline-flex;
  position: relative;
  appearance: none;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: ${fromTheme((theme) => theme.global.borderRadius)}px;
  flex: none;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  outline: none;
  color: ${fromTheme((theme) => theme.colors.white)};
  background-color: var(--purpose-500);
  &:hover {
    background-color: var(--purpose-600);
  }
  &:focus {
    box-shadow: var(--focused-box-shadow);
  }
  &:active,
  .active {
    background-color: var(--purpose-700);
  }

  ${injectIf('block', css `
      width: 100%;
    `)}
  ${({ brand }) => brand &&
    css `
      color: ${fromTheme((theme) => theme.global.inverseBrandTextColor)};
    `}
  ${({ outlined }) => outlined &&
    css `
      color: var(--purpose-500);
      background-color: transparent;
      box-shadow: inset var(--outline-box-shadow);
      &:hover {
        color: var(--purpose-600);
        background-color: var(--purpose-focus-color);
      }
      &:focus {
        box-shadow: inset var(--outline-box-shadow), var(--focused-box-shadow);
      }
      &:active,
      .active {
        color: var(--purpose-700);
        background-color: var(--purpose-pressed-color);
      }
    `}
  ${({ iconPosition }) => iconPosition === 'right' &&
    css `
      flex-direction: row-reverse;
    `}
  ${({ transparent, outlined }) => transparent &&
    !outlined &&
    css `
      color: var(--purpose-500);
      background-color: transparent;
      box-shadow: none;
      &:hover {
        color: var(--purpose-800);
        background-color: var(--purpose-focus-color);
      }
      &:active {
        color: var(--purpose-800);
        background-color: var(--purpose-pressed-color);
      }
    `}
  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
  ${({ round }) => round &&
    css `
      border-radius: 50%;
      min-width: auto;
      padding: 0;
      height: ${matchThemeProp((theme) => theme.button.heights)}em;
      width: ${matchThemeProp((theme) => theme.button.heights)}em;
    `}
  ${({ flat }) => flat &&
    css `
      border-radius: 0;
    `}
  ${Icon} {
    position: inherit;
    font-size: 1.5em;
    ${(props) => props.round !== true &&
    css `
      margin-${({ iconPosition }) => iconPosition === 'right' ? 'left' : 'right'}: 0.5em;
      ${fromTheme((theme) => theme.global.baseSpacing / 2)}em;
    `};
  }
`;
export function Button(_a) {
    var { icon, children, type = 'button' } = _a, rest = __rest(_a, ["icon", "children", "type"]);
    return (_jsxs(StyledButton, Object.assign({ type: type }, rest, { children: [icon && _jsx(Icon, Object.assign({ "data-testid": "button-icon" }, { children: icon })), children] })));
}
Button.displayName = 'Button';
