import React, { Component } from "react";
import PropTypes from "prop-types";

import { DropdownWithSelect } from "../Dropdown/index";

import Element from "./Element";

class Dropdown extends Component {
  hiddenInput;

  onChange = (item) => {
    if (!this.props.controlled) {
      this.hiddenInput.value = (item && item.key) || undefined;
    }

    this.props.onChange && this.props.onChange(item);
  };

  render() {
    const {
      items,
      label,
      name,
      className,
      placeholderText,
      forceFullWidth,
      width,
      size,
      filterable,
      clearable,
      virtual,
      emptyMessage,
      controlled,
      initialSelected,
      selected,
      value,
    } = this.props;

    return (
      <Element label={label} forceFullWidth={forceFullWidth}>
        <div className={`ex-form__dropdown uk-inline uk-width-${width}`}>
          {!controlled && (
            <input
              ref={(ref) => (this.hiddenInput = ref)}
              type="hidden"
              name={name}
              defaultValue={controlled ? undefined : initialSelected}
              value={controlled ? selected || value : undefined}
            />
          )}
          <DropdownWithSelect
            items={items}
            emptyMessage={emptyMessage}
            filterable={filterable}
            clearable={clearable}
            virtual={virtual}
            controlled={controlled}
            selected={selected || value}
            initialSelected={initialSelected}
            className={`${className || ""} uk-form-${size}`}
            placeholder={placeholderText}
            onChange={this.onChange}
          />
        </div>
      </Element>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  controlled: PropTypes.bool,
  emptyMessage: PropTypes.string,
  filterable: PropTypes.bool,
  forceFullWidth: PropTypes.bool,
  initialSelected: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  selected: PropTypes.any,
  size: PropTypes.oneOf(["small", "normal", "large"]),
  value: PropTypes.any,
  virtual: PropTypes.bool,
  width: PropTypes.oneOf(["auto", "1-1", "1-2", "1-3", "1-4"]),
};

Dropdown.defaultProps = {
  size: "normal",
  width: "auto",
  placeholderText: "Choose one",
};

export default Dropdown;
