import { createSelector } from 'reselect'
import { memory, Resources } from '@exivity/data-layer'

const getReport = (selectedReport: string | null) => {
  if (!selectedReport) return null

  return memory.cache.query((q) =>
    q.findRecord({
      type: 'reportdefinition',
      id: selectedReport
    })
  ) as Resources['reportdefinition'] | null
}

export const getMaxAccountLevel = createSelector(
  getReport,
  (report: Resources['reportdefinition'] | null) => {
    return report ? report.attributes.depth : null
  }
)
