import Icons from "uikit/dist/js/uikit-icons";

let UIkit;
const UIkitReadyQueue = [];
let isUIkitReady = false;

const onUIkitReady = (cb) => {
  if (isUIkitReady === false) {
    UIkitReadyQueue.push(cb);
  } else {
    cb(UIkit);
  }
};

const onUIkitLoaded = () => {
  UIkitReadyQueue.forEach((cb) => {
    cb(UIkit);
  });
  isUIkitReady = true;
};

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== "test") {
  import(/* webpackChunkName: "uikit" */ "uikit").then((uikit) => {
    UIkit = uikit.default;
    UIkit.use(Icons);
    onUIkitLoaded();
  });
}

const uk = () => UIkit;

export { onUIkitReady };
export default uk;
