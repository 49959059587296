var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Label } from '../Label';
import { fromTheme, themeColor, themeGlobal, themePalette } from '../Theme';
import { Tooltip } from '../Tooltip';
import { toggleValue } from '../utils';
const ANIMATION_SPEED = '0.25s';
export const StyledCheckbox = styled.div `
  display: flex;
  align-items: center;

  input {
    // Take it out of document flow and hide it
    position: absolute;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }

  .custom-checkbox {
    position: relative;
    display: inline-block;
    line-height: 1em;

    width: 1em;
    height: 1em;

    color: ${themeGlobal('textColorMuted')};

    will-change: color;
    transition: ${ANIMATION_SPEED} color ease;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      box-sizing: border-box;
      will-change: transform, background-color, color;
      transition: ${ANIMATION_SPEED} all ease;
    }

    &:before {
      content: ' ';
      background-color: ${themeColor('white')};
      border: 1px ${themeColor('gray')} solid;
    }

    &:after {
      transform: scale(0);
    }
  }

  input[type='checkbox'] + .custom-checkbox {
    &:before {
      border-radius: ${themeGlobal('borderRadius')}px;
    }

    &:after {
      content: '\\2714';
      color: ${themeColor('white')};
      line-height: 1.1em;
      text-align: center;
    }
  }

  input[type='checkbox']:checked {
    &:focus {
      + .custom-checkbox {
        &:before {
          background-color: ${themePalette('primary', 600)};
          border-color: ${themePalette('primary', 600)};
        }
      }
    }
  }

  input:checked + .custom-checkbox {
    &:before {
      background-color: ${themeColor('primary')};
    }
  }

  input:disabled {
    cursor: not-allowed;

    & + .custom-checkbox {
      color: ${themeGlobal('textColor')};

      &:before {
        background-color: ${themeColor('black')};
        border: 1px ${themeColor('black')} solid;
        opacity: 0.2;
      }

      &:after {
        color: ${themeColor('black')};
        opacity: 0.3;
      }
    }
  }

  input[type='radio'] + .custom-checkbox {
    &:before {
      border-radius: 50%;
    }

    &:after {
      content: ' ';
      background: ${themeColor('dark')};
      border: 0.2em solid ${themeColor('lightGray')};
      border-radius: 50%;
    }
  }

  input:focus + .custom-checkbox {
    outline: 0;
  }

  input:focus + .custom-checkbox,
  .custom-checkbox:hover {
    &:before {
      background-color: ${themeColor('lightGray')};
    }
  }

  input:checked + .custom-checkbox {
    color: ${themeGlobal('textColor')};

    &:focus {
      &:before {
        background-color: ${themeColor('primary')};
      }
    }

    &:before {
      background-color: ${themeColor('dark')};
      border: 1px ${themeColor('dark')} solid;
    }

    &:after {
      transform: scale(0.9);
    }
  }

  .custom-checkbox + * {
    display: inline-block;
  }

  label {
    cursor: pointer;

    margin-left: 1em;
  }
`;
function renderLabel(label, id) {
    return React.isValidElement(label) ? (React.cloneElement(label, { as: 'label', htmlFor: id })) : (_jsx(Label, Object.assign({ htmlFor: id }, { children: label })));
}
export const Checkbox = (_a) => {
    var { checked, onChange, label, tooltip, tooltipPlacement, name } = _a, props = __rest(_a, ["checked", "onChange", "label", "tooltip", "tooltipPlacement", "name"]);
    const { id } = useMemo(() => ({ id: uuidv4() }), []);
    return (_jsxs(StyledCheckbox, { children: [_jsxs(Tooltip, Object.assign({ content: tooltip, placement: tooltipPlacement, timeout: 0 }, { children: [_jsx("input", Object.assign({ "data-testid": "checkbox", "data-checked": checked, type: "checkbox", checked: checked, id: id, name: name, onChange: (event) => {
                            onChange && onChange(event.target.checked, event);
                        } }, props)), _jsx("div", { className: "custom-checkbox" })] })), label ? renderLabel(label, props.id || id) : null] }));
};
export const StyledCheckboxGroup = styled.div `
  & > :not(:last-child) {
    margin-bottom: ${fromTheme((theme) => theme.space[1])};
  }
`;
Checkbox.Group = function CheckboxGroup({ children, id, value, onChange }) {
    return (_jsx(StyledCheckboxGroup, Object.assign({ "data-testid": "checkbox-group" }, { children: React.Children.map(children, (child) => {
            if (child) {
                return React.cloneElement(child, {
                    onChange: (checked, e) => {
                        onChange(toggleValue(value, child.props.value));
                        child.props.onChange && child.props.onChange(checked, e);
                    },
                    name: child.props.name || id,
                    checked: value.includes(child.props.value)
                });
            }
            return child;
        }) })));
};
