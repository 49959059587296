import React from "react";
import PropTypes from "prop-types";
import { translate } from "@exivity/translations";

import { DropdownWithSelect } from "../Dropdown";
import Icon from "../../atoms/Icon/index";

import "./index.css";

const Export = ({ children, onClick, className }) => {
  const items = children.map((item) => {
    if (item.group) {
      return {
        key: item.group.toUpperCase(),
        group: item.group.toUpperCase(),
      };
    }

    if (typeof item === "string") {
      return {
        key: item,
        value: item.toUpperCase(),
      };
    }

    return item;
  });

  return (
    <DropdownWithSelect
      className={`ex-export ${className || ""}`}
      label={<Icon>download</Icon>}
      placeholder={translate("Export")}
      items={items}
      controlled
      onChange={onClick}
    />
  );
};

Export.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Export.defaultProps = { children: ["csv", "pdf"] };

export default Export;
