export * from './order';
export * from './search';
/** Combine the arrays into one array while removing the duplicates. */
function symmetricDifference(...arrays) {
    return arrays
        .map((array, i) => {
        return array.filter((element) => !arrays.some((a, j) => i !== j && a.includes(element)));
    })
        .flat();
}
export function toggleValue(array, value) {
    return symmetricDifference(array, [value]);
}
