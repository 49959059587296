// https://www.carbondesignsystem.com/data-visualization/color-palettes/
export const palette = [
    '#6929c4',
    '#9f1853',
    '#198038',
    '#b28600',
    '#8a3800',
    '#1192e8',
    '#fa4d56',
    '#002d9c',
    '#009d9a',
    '#a56eff',
    '#005d5d',
    '#570408',
    '#ee538b',
    '#012749'
];
export const getPaletteColor = (index) => palette[index % palette.length];
