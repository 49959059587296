import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'

import { Report, SubTypeFilter } from './'

export const updateSummarizeBy = {
  prepare: <T extends Report>(
    report: T,
    summarizeBy: SubTypeFilter<T, 'summarizeBy'>
  ) => ({
    payload: {
      report,
      summarizeBy
    }
  }),
  reducer<T extends Report>(
    filters: ReportFilterState,
    action: PayloadAction<{
      report: T
      summarizeBy: SubTypeFilter<T, 'summarizeBy'>
    }>
  ) {
    filters[action.payload.report].summarizeBy = action.payload.summarizeBy
  }
}
