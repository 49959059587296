var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Code } from '../Code';
import { Link } from '../Link';
import { LegacyTable } from '../LegacyTable';
const defaultRenderers = {
    code: (props) => _jsx(Code, Object.assign({}, props)),
    inlineCode: (props) => _jsx(Code, Object.assign({}, props)),
    link: (props) => _jsx(Link, Object.assign({}, props)),
    paragraph: ({ children }) => _jsx("span", { children: children }),
    table: (props) => _jsx(LegacyTable, Object.assign({}, props))
};
export const Markdown = (_a) => {
    var { forceAllLineBreaks = false, renderers = {}, children } = _a, rest = __rest(_a, ["forceAllLineBreaks", "renderers", "children"]);
    if (forceAllLineBreaks) {
        children = children === null || children === void 0 ? void 0 : children.replace(/\n/g, '  \n');
    }
    const mergedRenderers = useMemo(() => (Object.assign(Object.assign({}, defaultRenderers), renderers)), [renderers]);
    return (_jsx(ReactMarkdown, Object.assign({}, rest, { components: mergedRenderers, linkTarget: "_blank" }, { children: children })));
};
