import { useDispatch } from 'react-redux'
import { getPathname, useLocation, useNavigate } from '@exivity/routing'

import { RevokeTokens } from '../state/thunks/logout'
import { app } from '../../../routes'
import { authThunks } from '../state'

export function useLogout(revokeTokens?: RevokeTokens) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  return () => {
    dispatch(authThunks.logout(revokeTokens))
    const logoutPathname = getPathname(app.routes.logout)

    if (pathname !== logoutPathname) {
      navigate(logoutPathname)
    }
  }
}
