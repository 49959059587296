/* eslint-disable no-fallthrough,default-case */

const isArray = (val) => val && Array.isArray(val);
const isObject = (val) => val && val.constructor && val.constructor === Object;
const isIterable = (val) =>
  val && val !== null && (isArray(val) || isObject(val));
const isEmpty = (val) => typeof val === "undefined" || val === null;

const merge = (initial, old) => {
  let target;

  switch (true) {
    case initial === null:
      target = old;
      break;

    // Both initial and old can be iterated, recurse!
    case isIterable(initial) && isIterable(old):
      target = initial.constructor();

      switch (true) {
        // If initial is an object, iterate initial
        case isObject(initial) && isObject(initial):
          Object.keys(initial).forEach((key) => {
            target[key] = merge(initial[key], old[key]);
          });
          break;

        // If initial is an array, iterate old
        case isArray(initial) && isArray(old):
          Object.keys(old).forEach((key) => {
            if (isEmpty(initial[key])) {
              target[key] = old[key];
            } else {
              target[key] = merge(initial[key], old[key]);
            }
          });
          break;

        // Types don't match, use initial
        default:
          target = initial;
          break;
      }

      break;

    // Case where initial value is a primitive, and old value is iterable
    case !isIterable(initial) && isIterable(old):
    // Case where initial value is iterable, and old value is a primitive
    case isIterable(initial) && !isIterable(old):
    // Case where old value is null
    case old === null:
    // Case where old value is undefined
    case typeof old === "undefined":
      target = initial;
      break;

    // Both initial and old are primitives
    default:
      target = old;
  }

  return target;
};

const migrator = ({ incomingState, currentState }) =>
  merge(currentState, incomingState);

export default migrator;
