import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useLayoutEffect, useState, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled, { css } from 'styled-components';
import isFuture from 'date-fns/isFuture';
import formatDate from 'date-fns/format';
import compareAsc from 'date-fns/compareAsc';
import { composeAsync, getBoundingClientRect, querySelectorAll, animateElement, arrayFrom, subtract, compose, equals, zipWith, prop, map, any } from '@exivity/fp';
import { themeColor, themePurpose } from '../Theme';
import { injectIf } from '../utils';
const onEnterSelect = (event) => {
    var _a;
    switch (event.key) {
        case 'Enter':
            (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.click();
            break;
        default:
            break;
    }
};
const Line = styled(List) `
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;

  overflow-y: hidden !important;

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 20px;
    &:hover {
      background: #808080;
    }
  }

  ::-webkit-scrollbar-track-piece {
    background: #fff;
    box-sizing: border-box;
    border-radius: 20px;
  }
`;
const selectedDot = css `
  margin: 0 80px;
  background: ${themePurpose('brand')};
  color: ${themePurpose('brand')};
  transform: scale(1.1);
`;
const futureDot = css `
  background: #757575;
  color: #757575;
`;
const Dot = styled.li `
  display: inline-block;
  color: #bdbdbd;
  background: #bdbdbd;
  text-align: center;
  line-height: 1.2;
  position: relative;
  border-radius: 50%;
  margin: 0 20px;
  transition: transform 150ms ease-in-out;

  ${injectIf('future', futureDot)}
  ${injectIf('active', selectedDot)}

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    background: ${themePurpose('brand')};
    color: ${themePurpose('brand')};
    outline: none;
    transform: scale(1.1);
  }

  &:before {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    display: inline-block;
    content: attr(data-year);
    width: 100px;
    position: absolute;
    top: -35px;
    transform: translateX(-50%);
  }
`;
const WrappedDot = ({ style, index, data }) => {
    const { activeIndex, isFutureDate, valueAccessor, dateAccessor, timelineData, onChange, format } = data;
    const item = timelineData[index];
    const date = formatDate(dateAccessor(item), format);
    return (_jsx(Dot, { title: date, tabIndex: 0, style: getItemStyles(style, index, activeIndex), active: index === activeIndex, future: isFutureDate(item), "data-active": String(index === activeIndex), "data-year": date, onClick: () => onChange(valueAccessor(item)), onKeyDown: onEnterSelect }, String(index)));
};
const GradientWindow = styled.div `
  height: 100%;
  width: 100%;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 52%,
    ${themeColor('gray')} 53%,
    rgba(255, 255, 255, 0) 54%,
    rgba(255, 255, 255, 0) 100%
  );
`;
const getDotsFromList = compose(arrayFrom, querySelectorAll('li'));
const getDotPositions = compose(map(prop('left')), map(getBoundingClientRect), getDotsFromList);
const zipWithSubtract = zipWith(subtract);
function getTranslateX(element) {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return matrix.m41;
}
export const getTranslatesXDots = compose(map(getTranslateX), getDotsFromList);
const anyNotZero = any((x) => x !== 0);
const animate = ([el, from]) => {
    const scale = el.getAttribute('data-active') === 'true' ? 'scale(1.1)' : 'scale(1)';
    return animateElement({
        duration: 300,
        easing: 'ease-in-out'
    }, [
        { transform: `translateX(${from}px) ${scale}` },
        { transform: `translateX(0px) ${scale}` }
    ], el);
};
const getItemStyles = (style, index, activeIndex) => {
    var _a;
    const left = Number((_a = style === null || style === void 0 ? void 0 : style.left) !== null && _a !== void 0 ? _a : 0);
    return Object.assign(Object.assign({}, style), { top: 35, height: 24, width: 24, left: activeIndex !== -1 && index > activeIndex ? left + 160 : left + 40 });
};
const autoSizerStyle = { height: 90, width: '100%' };
export function Timeline({ value, format, dateAccessor, valueAccessor, data, onChange }) {
    const [ref, setRef] = useState(null);
    const cache = useRef([]);
    useLayoutEffect(() => {
        if (ref) {
            const translatesX = getTranslatesXDots(ref);
            const from = anyNotZero(translatesX)
                ? translatesX
                : zipWithSubtract(cache.current, getDotPositions(ref));
            cache.current = getDotPositions(ref);
            if (anyNotZero(from)) {
                const elementWithFrom = zipWith((el, pos) => [el, pos], getDotsFromList(ref), from);
                const animateFrom = composeAsync(() => {
                    cache.current = getDotPositions(ref);
                }, Promise.all.bind(Promise), map(prop('finished')), map(animate));
                animateFrom(elementWithFrom);
            }
        }
    });
    const isFutureDate = compose(isFuture, dateAccessor);
    const [timelineData, activeIndex] = useMemo(() => {
        const sortedData = data.sort((a, b) => compareAsc(dateAccessor(a), dateAccessor(b)));
        return [
            sortedData,
            sortedData.findIndex((el) => equals(valueAccessor(el), value))
        ];
    }, [value, data, dateAccessor, valueAccessor]);
    return (_jsx(AutoSizer, Object.assign({ style: autoSizerStyle }, { children: ({ width }) => (_jsx("div", Object.assign({ style: { height: autoSizerStyle.height, width } }, { children: _jsx(GradientWindow, { children: _jsx(Line, Object.assign({ height: autoSizerStyle.height, width: width, innerElementType: "ul", layout: "horizontal", innerRef: setRef, itemData: {
                        activeIndex,
                        onChange,
                        dateAccessor,
                        valueAccessor,
                        timelineData,
                        isFutureDate,
                        format
                    }, itemCount: data.length, itemSize: 84 }, { children: WrappedDot })) }) }))) })));
}
