import { useEffect } from 'react';
import { useDataStore } from '../useDataStore';
export function useStoreListener(event, listener, deps) {
    const store = useDataStore();
    useEffect(() => {
        const listenerFn = (transform) => {
            listener(transform);
        };
        store.on(event, listenerFn);
        return () => {
            store.off(event, listenerFn);
        };
    }, deps);
}
