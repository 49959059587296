var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, createContext, useContext } from 'react';
import { Searchbar } from '../Searchbar';
import { defaultLabelAccessor, SelectList } from '../SelectLegacy';
import { useSearchFilter } from '../useSearchFilter';
import { Block } from '../Block';
const SearchTermContext = createContext('');
export function useSearchTerm() {
    return useContext(SearchTermContext);
}
export function SearchableList(_a) {
    var { placeholder = 'Search...', notFoundText = 'Not found', data, labelAccessor = defaultLabelAccessor } = _a, rest = __rest(_a, ["placeholder", "notFoundText", "data", "labelAccessor"]);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredData = useSearchFilter(data, searchTerm, labelAccessor);
    const gotData = data.length > 0;
    return (_jsxs(_Fragment, { children: [gotData && (_jsx(Block, Object.assign({ px: 1, pb: 1 }, { children: _jsx(Searchbar, { dark: true, value: searchTerm, placeholder: placeholder, onChange: (value, e) => {
                        e.stopPropagation();
                        setSearchTerm(value);
                    } }) }))), _jsx(SearchTermContext.Provider, Object.assign({ value: searchTerm }, { children: _jsx(SelectList, Object.assign({ data: filteredData, labelAccessor: labelAccessor }, rest, { noDataText: data.length === 0 ? rest.noDataText : notFoundText })) }))] }));
}
