import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { orderRange } from '../../DateRange/helpers';
export const updateDayRange = (range, action) => {
    switch (action.type) {
        case 'SELECT_START':
            return [action.date, null];
        case 'SELECT_END':
            if (!range[0])
                return range;
            if (isSameDay(range[0], action.date))
                return [null, null];
            return orderRange([range[0], action.date]);
        case 'RESET_RANGE':
            return [null, null];
        case 'SELECT_SINGLE_UNIT_AS_RANGE':
            return [action.date, action.date];
    }
};
function fitPartialDayRange([start, end]) {
    return [
        start ? startOfDay(start) : null,
        end ? endOfDay(end) : null
    ];
}
export const dayRangeReducer = (...args) => {
    return fitPartialDayRange(updateDayRange(...args));
};
