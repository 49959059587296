import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { compose, prop } from '@exivity/fp';
import { themeColor, themePurpose, themeSpace } from '../Theme';
import { toRgbString } from '../utils';
const brandRGB = compose(toRgbString, themePurpose('brand'));
const StyledProgress = styled.div `
  box-sizing: border-box;
  position: absolute;
  bottom: -${prop('height')}px;
  display: block;
  opacity: 1;
  height: ${prop('height')}px;
  width: 100%;
  transition: transform 1s ease-out;
  background-color: rgba(${brandRGB}, 0.6);
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
  transform-origin: left;
  display: flex;
  align-items: center;
  padding: 0 ${themeSpace(2)};
  color: ${themeColor('dark')};

  &::after {
    position: absolute;
    content: '';
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    transform-origin: left;
    animation: progress-active 1s ease infinite;
  }

  &.ex-progress--percent-0 {
    transition-duration: 0s;
  }

  &.ex-progress--percent-100 {
    transition: opacity 0.5s ease-out;
    background-color: #238e47;
    opacity: 0;
  }

  @keyframes progress-active {
    0% {
      opacity: 0.5;
      transform: scaleX(0);
    }
    100% {
      opacity: 0;
      transform: scaleX(1);
    }
  }
`;
const ProgressText = styled.div `
  position: absolute;
  bottom: -${prop('height')}px;

  height: ${prop('height')}px;
  width: 100%;
  padding: 0 ${themeSpace(2)};
  font-size: 0.875rem;

  display: flex;
  align-items: center;

  color: ${themeColor('gray')};
`;
export function Progress({ active = false, percentage = 0, height = 4, children = '' }) {
    const scale = percentage / 100;
    const progressStyle = { transform: `scaleX(${scale})` };
    return (_jsxs(_Fragment, { children: [_jsx(StyledProgress, { className: `ex-progress--percent-${percentage}`, style: progressStyle, height: height }), active && _jsx(ProgressText, Object.assign({ height: height }, { children: children }))] }));
}
