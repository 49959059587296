import { ObjectOf } from '@exivity/ui'
import { createSelector } from 'reselect'

import { UserGroupPermission } from '../../../data/types'

const memoizedPermissions = createSelector(
  (permissions: ObjectOf<string>) => permissions,
  (permissions: ObjectOf<string>) =>
    Object.keys(permissions).map((key: string): string => permissions[key])
)

type Guard = {
  allow?: string[]
  restrict?: string[]
}

type Options = {
  availablePermissions?: string[]
  allPermissionsSymbol?: string
}

export function hasPermission(
  currentPermissions: string[] = [],
  { allow, restrict }: Guard,
  {
    availablePermissions = memoizedPermissions(UserGroupPermission),
    allPermissionsSymbol = UserGroupPermission.All
  }: Options = {}
) {
  // Short circuit
  if (currentPermissions.includes(allPermissionsSymbol)) {
    return true
  }

  const userIsAllowed = (allowedPermissions: string[] = []) => {
    return allowedPermissions.length > 0
      ? currentPermissions.some(
          (currentPermission) =>
            allowedPermissions.indexOf(currentPermission) !== -1
        )
      : false
  }

  if (restrict && allow) {
    throw new Error('You cannot use the restrict and allow prop together')
  }

  if (restrict) {
    const allowedPermissions = availablePermissions.filter(
      (permission: string) => restrict.indexOf(permission) === -1
    )

    return userIsAllowed(allowedPermissions)
  }

  return userIsAllowed(allow)
}
