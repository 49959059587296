import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'

import { Report } from './'

export const updateSearchFilter = {
  prepare: (report: Report, by: string) => ({
    payload: {
      report,
      by
    }
  }),
  reducer(
    filters: ReportFilterState,
    action: PayloadAction<{ report: Report; by: string }>
  ) {
    filters[action.payload.report].search.by = action.payload.by
  }
}

export const updateSearchTerm = {
  prepare: (report: Report, term: string) => ({
    payload: {
      report,
      term
    }
  }),
  reducer(
    filters: ReportFilterState,
    action: PayloadAction<{ report: Report; term: string }>
  ) {
    filters[action.payload.report].search.term = action.payload.term
  }
}
