import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styled from 'styled-components';
import TextHighlighter from 'react-highlight-words';
import { globalFont, truncateText } from '../utils';
import { Searchbar } from '../Searchbar';
import { fromTheme } from '../Theme';
import { InternalList } from './InternalList';
import { useMultiSearchFilter } from './useMultiSearchFilter';
const StyledResourceList = styled.div `
  ${globalFont}

  height: 100%;
  display: flex;
  flex-direction: column;

  font-size: ${fromTheme((theme) => theme.fontSizes[1])};

  .column-headers {
    padding-left: ${fromTheme((theme) => theme.global.baseSpacing)}em;

    display: flex;
    align-items: center;

    header {
      padding-right: ${fromTheme((theme) => theme.global.baseSpacing)}em;
      text-transform: uppercase;
      color: ${fromTheme((theme) => theme.colors.gray)};

      ${truncateText}

      input {
        font-size: 1em;

        &:placeholder-shown {
          text-transform: uppercase;
        }
      }
    }

    padding-bottom: ${fromTheme((theme) => theme.space[1])};
    padding-top: ${fromTheme((theme) => theme.space[1])};

    margin-bottom: ${fromTheme((theme) => theme.space[2])};

    border-bottom: 1px solid ${fromTheme((theme) => theme.colors.lightGray)};
  }

  .actions {
    padding-left: ${fromTheme((theme) => theme.global.baseSpacing)}em;
    display: flex;
    align-items: center;

    > * {
      margin-right: 10px;
    }

    margin-bottom: ${fromTheme((theme) => theme.space[2])};
  }

  mark {
    background-color: ${fromTheme((theme) => theme.colors.yellow)};
  }
`;
/**
 * A search filter will only be applied after it has at least this amount of characters.
 */
const SEARCH_CHARACTER_THRESHOLD = 2;
function _ResourceList({ data, columns, onClick, isActive, categorization, order, sorting, actions }) {
    const [getFilterState, search] = useMultiSearchFilter(columns, (column) => ({
        by: column.filterBy || (() => ''),
        characterThreshold: SEARCH_CHARACTER_THRESHOLD,
        caseInsensitive: true
    }));
    const internalListColumns = useMemo(() => columns.map((column, i) => ({
        width: column.width,
        renderCell: (item) => column.renderColumn(item, getFilterState(i))
    })), [columns, getFilterState]);
    return (_jsxs(StyledResourceList, { children: [_jsx("div", Object.assign({ className: "column-headers" }, { children: columns.map((column, i, arr) => {
                    var _a;
                    const width = column.width || `${100 / arr.length}%`;
                    return (_jsx("header", Object.assign({ style: { width } }, { children: (_a = column.header) === null || _a === void 0 ? void 0 : _a.call(column, getFilterState(i)) }), i));
                }) })), _jsx("div", Object.assign({ className: "actions" }, { children: actions })), _jsx(InternalList, { data: search(data), columns: internalListColumns, isActive: isActive, categorization: categorization, order: order, sorting: sorting, onClick: onClick })] }));
}
function HighlightedText({ children }) {
    return _jsx("mark", Object.assign({ "data-testid": "highlighted-text" }, { children: children }));
}
function createSearchableColumn({ valueAccessor, placeholder, width }) {
    return {
        header: (context) => (_jsx(Searchbar, { animated: true, placeholder: placeholder === null || placeholder === void 0 ? void 0 : placeholder(), value: context.searchTerm, onChange: context.setSearchTerm })),
        renderColumn: (item, context) => {
            return (_jsx(TextHighlighter, { highlightTag: HighlightedText, searchWords: [context.searchTerm], textToHighlight: valueAccessor(item) }));
        },
        filterBy: valueAccessor,
        width
    };
}
/**
 * @description ResourceList displays a collection of objects of the same type,
 * like services or accounts.
 * The main job of a resource list is to help users find an object
 * and navigate to a full-page representation of it.
 * It has built in solutions for searching, categorizing, ordering,
 * sorting and rendering multiple columns.
 */
export const ResourceList = React.memo(_ResourceList);
ResourceList.useSortable = InternalList.useSortable;
ResourceList.createSearchableColumn = createSearchableColumn;
