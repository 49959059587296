import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { injectIf } from '../utils';
import { TooltipContent, ContentContainer } from './styles';
const OFFSET_FROM_TRIGGER = 8;
const Container = styled.div `
  ${injectIf('referenceWidth', css `
      position: relative;
    `)}
`;
function useTimeout(cb, timeout) {
    const timer = useRef(null);
    const clear = () => {
        if (timer.current !== null) {
            clearTimeout(timer.current);
        }
    };
    return [
        () => {
            clear();
            timer.current = setTimeout(cb, timeout);
        },
        clear
    ];
}
const offsetModifier = {
    name: 'offset',
    options: {
        offset: [0, OFFSET_FROM_TRIGGER]
    }
};
export function Tooltip({ children, content, placement = 'top', referenceWidth = false, timeout = 0, className }) {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [open, setOpen] = useState(false);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [offsetModifier]
    });
    const [setTimer, clearTimer] = useTimeout(() => setOpen(false), timeout);
    return (_jsxs(Container, Object.assign({ referenceWidth: referenceWidth }, { children: [_jsx("div", Object.assign({ ref: setReferenceElement, "data-testid": "ex-tooltip-reference", style: { display: 'flex' }, onMouseEnter: () => {
                    clearTimer();
                    setOpen(true);
                }, onMouseLeave: setTimer }, { children: children })), open && content && (_jsx(ContentContainer, Object.assign({ ref: setPopperElement, style: styles.popper }, attributes.popper, { children: _jsx(TooltipContent, Object.assign({ "data-testid": "custom-content", className: className }, { children: content })) })))] })));
}
