import { useState, useCallback } from 'react';
import { useWindowListener } from '../useWindowListener';
export function useOutsideClickListener(onOutsideClick) {
    const [ref, setRef] = useState(null);
    const handleOutsideClick = useCallback((event) => {
        const target = event.target;
        if (!target) {
            onOutsideClick(event);
        }
        else if (ref && !ref.contains(target)) {
            onOutsideClick(event);
        }
    }, [onOutsideClick, ref]);
    useWindowListener('click', handleOutsideClick, true);
    return setRef;
}
