import { RecordSchema } from '@orbit/records';
import resourceSchema from './schema.json';
/*
 * We need to ignore type safety of schema type because json wont preserve const types.
 * It might be possible in the future: https://github.com/microsoft/TypeScript/issues/32063
 * In the meantime we use a workflow to validate the schema.
 */
// @ts-ignore
const schema = new RecordSchema(resourceSchema);
export { schema };
