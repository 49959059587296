import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { themeGlobal } from '../Theme';
import { Adornment } from '../Adornment';
import { Icon } from '../Icon';
import { Input } from './Input';
import { forwardRef } from 'react';
const StyledSelectInput = styled(Input) `
  ${(props) => !props.disabled &&
    css `
      cursor: pointer;
      /**
     * These two rules are to hide the caret without having to make the SelectInput readonly.
     * This handy because readonly inputs ignore the required attribute and we want to use
     * the required functionality to tell a form that the Select does not have a value yet.
     */
      color: transparent;
      text-shadow: 0 0 0 ${themeGlobal('textColor')};

      box-shadow: none;
    `}
`;
export const SelectInput = forwardRef((props, ref) => (_jsx(Adornment, Object.assign({ className: "selectInput__adornment", right: _jsx(Icon, { children: _jsx(MdKeyboardArrowDown, {}) }) }, { children: _jsx(StyledSelectInput, Object.assign({ ref: ref, "data-testid": "select-input", 
        // To prevent auto complete that we dont want for selects.
        autoComplete: "off" }, props)) }))));
