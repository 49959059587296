import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { globalFont, injectIf } from '../utils';
import { themeColor } from '../Theme';
export const Container = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${globalFont}

  ${injectIf('inline', css `
      i {
        visibility: hidden;
      }
    `)}
  
  ${injectIf('inside', css `
      input {
        padding-top: 14px;
        padding-bottom: 0px;
      }

      i {
        padding-top: 8px;
      }
    `)}
`;
const Label = styled.label `
  color: ${themeColor('gray')};
  transform: scale(0.8);
  transform-origin: left;
  transition: transform 0.1s;
  pointer-events: none;
  z-index: 2;

  ${injectIf('inline', css `
      transform: scale(1) translate(5px, 137%);
    `)}

  ${injectIf('inside', css `
      position: absolute;
      left: 8px;

      ${injectIf('inline', css `
          transform: scale(1) translateY(10px);
        `)}
    `)}
`;
export function FloatingLabel({ label, className = '', outside = false, fixed = false, children }) {
    const [element, ref] = useState(null);
    const [isFocussed, setIsFocussed] = useState(false);
    const [hasValue, setHasValue] = useState(false);
    const id = useMemo(() => uuidv4(), []);
    useEffect(() => {
        const setFocusOn = () => setIsFocussed(true);
        const setFocusOff = () => setIsFocussed(false);
        if (element) {
            element.addEventListener('focusin', setFocusOn);
            element.addEventListener('focusout', setFocusOff);
        }
        return () => {
            if (element) {
                element.removeEventListener('focusin', setFocusOn);
                element.removeEventListener('focusout', setFocusOff);
            }
        };
    }, [element]);
    useEffect(() => {
        setHasValue(() => !!(element === null || element === void 0 ? void 0 : element.value));
    });
    const inline = !isFocussed && !hasValue && !fixed;
    const props = fixed ? { id } : { id, ref };
    return (_jsxs(Container, Object.assign({ className: className, inline: inline, inside: !outside }, { children: [_jsx(Label, Object.assign({ htmlFor: id, inline: inline, inside: !outside }, { children: label })), typeof children === 'function'
                ? children(props)
                : React.cloneElement(children, props)] })));
}
