import { MemorySource } from '@orbit/memory';
import { schema } from '../schema/JSONAPISchema';
/**
 * A transient memory source is used to store data that is not persisted.
 * This means that before every query, the transient memory source is cleared.
 * This is particularly useful for data that changes frequently or does not need to be cached,
 * such as logs.
 */
export const transientMemory = new MemorySource({
    name: 'transient-memory',
    schema,
    autoValidate: false,
    cacheSettings: {
        autoValidate: false
    }
});
/**
 * The 'beforeQuery' event is emitted just before a query operation is performed.
 * Here, we listen for the 'beforeQuery' event and reset the memory source.
 * This clears all records from the memory source, ensuring that the data does not persist
 * between query operations.
 */
transientMemory.on('beforeQuery', () => {
    transientMemory.cache.reset();
});
