const auth = {
  listeners: [],
  permissions: [],

  can: (permission) => {
    const permissions = auth.permissions;

    return (
      permissions &&
      (permissions.includes("*") ||
        (Array.isArray(permission)
          ? permission.reduce(
              (acc, value) => acc || permissions.includes(value),
              false
            )
          : permissions.includes(permission)))
    );
  },
};

let previousAuth = null;

export function handleAuthChange(currentAuth) {
  auth.permissions = currentAuth.permissions;

  if (JSON.stringify(currentAuth) !== JSON.stringify(previousAuth)) {
    auth.listeners.forEach((cb) => cb());
    previousAuth = currentAuth;
  }
}

export default auth;
