import { useState, useMemo } from 'react';
import { searchBy } from '../utils/array';
export function useMultiSearchFilter(sources, getSearchOptions) {
    const [filters, setFilters] = useState(() => sources.map(() => ''));
    function setFilter(index, value) {
        setFilters([...filters.slice(0, index), value, ...filters.slice(index + 1)]);
    }
    function getFilterState(index) {
        return {
            searchTerm: filters[index],
            setSearchTerm: (value) => setFilter(index, value)
        };
    }
    function search(data) {
        return sources.reduce((data, source, i) => searchBy(getSearchOptions(source), filters[i], data), data);
    }
    return useMemo(() => {
        return [getFilterState, search];
    }, [filters, sources]);
}
