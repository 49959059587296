import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  SelectList as BaseSelectList,
  SearchableList as BaseSearchableList,
  fromTheme,
  preciseEm,
  Block,
} from "@exivity/ui";
import { identity } from "ramda";

import uk from "./../../../styles/uikit";

import Dropdown from "./index";

const SelectList = styled(BaseSelectList)`
  padding: ${fromTheme((theme) => theme.space[2])} 0;
  li {
    /* emulate @ex-select-item */
    font-size: ${preciseEm(0.8)}rem;
  }
`;

const SearchableList = styled(BaseSearchableList)`
  padding-bottom: ${fromTheme((theme) => theme.space[2])};
  li {
    /* emulate @ex-select-item */
    font-size: ${preciseEm(0.8)}rem;
  }
`;

function DropdownWithSelect({
  controlled,
  selected,
  initialSelected,
  items,
  valueField = "value",
  onChange,
  label,
  className,
  offset,
  button,
  placeholder = "Select",
  emptyMessage,
  width,
  clearable = false,
  filterable = false,
}) {
  const dropdownRef = useRef(null);
  const [key, setKey] = useState(controlled ? selected : initialSelected);

  useEffect(() => {
    if (controlled) {
      setKey(selected);
    }
  }, [controlled, selected]);

  const onSelectChange = useCallback(
    (item) => {
      if (!controlled) {
        setKey(item ? item.key : undefined);
      }

      onChange && onChange(item);
      const drop = uk().drop(dropdownRef.current.refs.drop);
      drop.toggle.$el.click();
    },
    [controlled, onChange]
  );

  const handleClear = useCallback(() => {
    setKey(null);
    onChange && onChange(null);
  }, [onChange]);

  const List = filterable ? SearchableList : SelectList;

  const value =
    items.find((item) => String(item.key) === String(key))?.[valueField] ?? key;
  return (
    <Dropdown
      ref={dropdownRef}
      label={label}
      placeholder={placeholder}
      className={className}
      offset={offset}
      button={button}
      value={value ? String(value) : value}
      noPadding
    >
      {items.length === 0 && emptyMessage ? (
        <span className="ex-select__empty">{emptyMessage}</span>
      ) : (
        <Block pt={filterable ? 2 : 0}>
          <List
            clearable={clearable && !!key}
            width={width}
            data={items}
            value={items.find((item) => item.key === key)}
            valueAccessor={identity}
            labelAccessor={(item) => item[valueField] || item.group}
            onChange={onSelectChange}
            onClear={clearable && !!key ? handleClear : undefined}
          />
        </Block>
      )}
    </Dropdown>
  );
}

DropdownWithSelect.propTypes = {
  autofocus: PropTypes.bool,
  button: PropTypes.any,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  controlled: PropTypes.bool,
  emptyMessage: PropTypes.node,
  filterable: PropTypes.bool,
  initialSelected: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  offset: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selected: PropTypes.any,
  valueField: PropTypes.string,
  width: PropTypes.number,
};

export default DropdownWithSelect;
