import { useDispatch, useSelector } from 'react-redux'
import { configureStore, Reducer, AnyAction } from '@reduxjs/toolkit'
import { translate } from '@exivity/translations'

import rootReducer, { RootState } from '../reducers'

import { getStateStorage } from './storage'
import { useClassAction } from './middleware/ClassAction'
import { createPersistEnhancer } from './enhancers/persist'
import {
  persistUserState,
  handlePermissionsChange
} from './observers/subscriptions'

export const persistEnhancer = createPersistEnhancer({
  observe: (state) =>
    ({
      auth: state.auth,
      profile: state.profile,
      reportsFilters: state.reports.filters,
      reportsGui: state.reports.gui
    } as const),
  persist: (state, rootState) => {
    const storage = getStateStorage.getStorage(rootState.auth.persistent)

    if (rootState.auth.isAuthenticated) {
      storage.setItem('persist', state)
    } else {
      // can remove this when config is stored in bucket with orbit
      storage.setItem('persist', {})
    }
  },
  load: () => {
    const { reportsFilters, reportsGui, auth, profile, configuration } =
      getStateStorage.fromEitherStorage('persist') || {}

    return {
      auth,
      profile,
      configuration,
      reports: {
        filters: reportsFilters,
        gui: reportsGui
      }
    }
  }
})

const store = configureStore({
  reducer: rootReducer as Reducer<RootState, AnyAction>,
  middleware: (getDefault) =>
    getDefault({
      immutableCheck: false,
      serializableCheck: false,
      thunk: { extraArgument: { translate } }
    }).prepend(useClassAction),
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [persistEnhancer]
})

// Action to initialise subscriptions
// Subscriptions with storeObserver compare state on each action ->
// when we initialise with this action we populate the state to compare against
store.dispatch({ type: 'store/initialized' })

store.subscribe(() => {
  const state = store.getState()
  persistUserState(state)
  handlePermissionsChange(state)
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector = <T>(fn: (state: RootState) => T, eq?: any) =>
  useSelector(fn, eq)

export default store
