var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import { MdClose } from 'react-icons/md';
import { ListFocus } from '../../ListFocus';
import { ListItem } from '../../ListItem';
import { Flex } from '../../Flex';
import { Icon } from '../../Icon';
import { fromTheme } from '../../Theme';
import { DefaultItem } from './DefaultItem';
import { calculateHeight, ITEM_HEIGHT, NO_DATA_TEXT } from './helpers';
export const StyledList = styled(List) `
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
`;
export const StyledClearItemFlex = styled(Flex) `
  color: ${fromTheme((theme) => theme.colors.gray)};
  height: ${ITEM_HEIGHT}px;
  width: 100%;
`;
export function defaultValueAccessor(item) {
    if (!item.value) {
        return item;
    }
    return item.value;
}
export function defaultLabelAccessor(item) {
    if (typeof item === 'string') {
        return item;
    }
    else if (item.label) {
        return typeof item.label === 'function' ? item.label() : item.label;
    }
    else {
        return 'No label';
    }
}
export function SelectList(_a) {
    var { data, width, value, onChange, labelAccessor = defaultLabelAccessor, valueAccessor = defaultValueAccessor, innerElementType = 'ul', noDataText = NO_DATA_TEXT, clearable = false, clearText = 'Clear selection', onClear, children } = _a, rest = __rest(_a, ["data", "width", "value", "onChange", "labelAccessor", "valueAccessor", "innerElementType", "noDataText", "clearable", "clearText", "onClear", "children"]);
    const itemData = useMemo(() => ({
        items: data,
        onChange,
        value,
        labelAccessor,
        valueAccessor
    }), [data, value, onChange, valueAccessor, labelAccessor]);
    const calculatedHeight = calculateHeight(itemData.items);
    return (_jsx("span", Object.assign({ "data-testid": "select-list" }, { children: _jsxs(ListFocus, Object.assign({}, rest, { children: [data.length > 0 && clearable && value && (_jsx(ListItem, { children: _jsxs(StyledClearItemFlex, Object.assign({ "data-testid": "clear-button", justifyContent: "space-between", alignItems: "center", onClick: onClear }, { children: [_jsx(Flex.Item, { children: clearText }), _jsx(Flex.Item, { children: _jsx(Icon, { children: _jsx(MdClose, {}) }) })] })) })), data.length === 0 ? (_jsx(ListItem, { children: noDataText })) : (_jsx(StyledList, Object.assign({ height: calculatedHeight, itemData: itemData, itemCount: itemData.items.length, itemSize: ITEM_HEIGHT, innerElementType: innerElementType, width: width || '100%' }, { children: children || DefaultItem })))] })) })));
}
