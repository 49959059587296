var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from 'react';
import { juxt, thunkify, unless, always } from '@exivity/fp';
import { useFieldContext } from '../Field/context';
import { scientificToDecimal } from './scientificToDecimal';
import { StyledInput } from './StyledInput';
export function useValidState(value) {
    const prevValue = useRef(value);
    const [valid, setValid] = useState(true);
    const setValidity = thunkify(setValid);
    useEffect(() => {
        if (prevValue.current !== value) {
            setValid(true);
        }
        prevValue.current = value;
    }, [value]);
    return {
        danger: !valid,
        onChange: unless(always(valid), setValidity(true)),
        onInvalid: setValidity(false)
    };
}
const getStringValue = (value) => typeof value === 'number' ? scientificToDecimal(value) : value || '';
/** Because we often convert string numbers to number type on the onChange handler
 * without this function it's not possible to input values with
 * a leading zero behind the comma.
 * eg: Number(1,0) will be parsed to 1 making it impossible to write 1,01
 */
function useValue(value) {
    const [val, setValue] = useState(getStringValue(value) || '');
    return {
        value: Number(val) === Number(getStringValue(value))
            ? val
            : getStringValue(value),
        onChange: (event) => {
            if (val !== event.target.value) {
                setValue(event.target.value);
            }
        }
    };
}
export const Input = React.forwardRef((_a, ref) => {
    var { type = 'text', value, onChange } = _a, rest = __rest(_a, ["type", "value", "onChange"]);
    const { id } = useFieldContext();
    const validityProps = useValidState(value);
    const valueProps = useValue(value);
    const inputOnChange = (e) => {
        onChange && onChange(e.target.value, e);
    };
    return (_jsx(StyledInput, Object.assign({ ref: ref, id: id, type: type, value: valueProps.value }, validityProps, { onChange: juxt([
            inputOnChange,
            validityProps.onChange,
            valueProps.onChange
        ]) }, rest)));
});
