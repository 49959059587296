import { range } from '@exivity/fp'
import { Resources, memory } from '@exivity/data-layer'

const getReport = (selectedReport: string | null) => {
  if (!selectedReport) return null

  return memory.cache.query((q) =>
    q.findRecord({
      type: 'reportdefinition',
      id: selectedReport
    })
  ) as Resources['reportdefinition'] | null
}

// @todo fix this shit
export const getDepthLabels = (
  reportId: null | string,
  reportMinAccessibleDepth: number
) => {
  const reportDefinition = getReport(reportId)

  if (
    reportDefinition &&
    reportMinAccessibleDepth &&
    reportDefinition.attributes.depth
  ) {
    const accessibleLevels = range(
      reportMinAccessibleDepth,
      reportDefinition.attributes.depth + 1
    )

    const lvlLabelMap: Record<number, string> = {}

    accessibleLevels.forEach((lvl) => {
      const result = reportDefinition.attributes.level_keys.find(
        ({ level }) => lvl === level
      )

      if (result) {
        lvlLabelMap[lvl] = result.label
      }
    })

    return lvlLabelMap
  }

  return {}
}
