import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdExpandMore } from 'react-icons/md';
import styled from 'styled-components';
import { colorFromPurpose, themePalette } from '../Theme';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { Row, Label } from './components';
const StyledButton = styled(Button) `
  color: ${colorFromPurpose('secondary', 700)};
  padding: 0 0.25rem;
  height: 1.5rem;
  line-height: inherit;
`;
const StyledRow = styled(Row) `
  height: 30px;
`;
const MODIFIERS = [
    {
        name: 'offset',
        options: {
            offset: [0, 4]
        }
    }
];
const DropdownContainer = styled.div `
  width: 200px;
  padding: 0.25rem 0;
  border: solid 1px ${themePalette('neutral', 200)};
`;
export function Select({ className, label, data, onChange }) {
    return (_jsx(Dropdown, Object.assign({ className: className, modifiers: MODIFIERS, placement: "bottom-start", reference: _jsxs(StyledButton, Object.assign({ secondary: true, transparent: true }, { children: [label, _jsx(MdExpandMore, {})] })) }, { children: _jsx(DropdownContainer, { children: data.map((item, index) => (_jsx(StyledRow, Object.assign({ active: label === item, onClick: () => onChange(item) }, { children: _jsx(Label, { children: item }) }), index))) }) })));
}
