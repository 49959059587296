var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createPath } from 'history';
import { useHref } from '../useHref';
import { useLocation } from '../useLocation';
import { useNavigate } from '../useNavigate';
import { useResolvedPath } from '../useResolvedPath';
function isModifiedEvent(event) {
    return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}
export const Link = React.forwardRef(function LinkWithRef(_a, ref) {
    var { onClick, replace: replaceProp = false, state, target, to = '' } = _a, rest = __rest(_a, ["onClick", "replace", "state", "target", "to"]);
    const href = useHref(to);
    const navigate = useNavigate();
    const location = useLocation();
    const path = useResolvedPath(to);
    function handleClick(event) {
        if (onClick)
            onClick(event);
        if (!event.defaultPrevented &&
            event.button === 0 && // Ignore everything but left clicks
            (!target || target === '_self') && // Let browser handle "target=_blank" etc.
            !isModifiedEvent(event) // Ignore clicks with modifier keys
        ) {
            event.preventDefault();
            // If the URL hasn't changed, a regular <a> will do a replace instead of
            // a push, so do the same here.
            const replace = !!replaceProp || createPath(location) === createPath(path);
            navigate(to, { replace, state });
        }
    }
    return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    _jsx("a", Object.assign({}, rest, { ref: ref, href: href, target: target, onClick: handleClick })));
});
