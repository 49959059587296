var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { MdAdd, MdRemove } from 'react-icons/md';
import { translate } from '@exivity/translations';
import { propOr } from 'ramda';
import { StyledCheckbox } from '../Checkbox';
import { colorFromPurpose, themeFontSize, themeSpace } from '../Theme';
import { Icon as IconWrapper } from '../Icon';
import { injectIf } from '../utils';
import { Text } from '../Text';
export const Row = styled.div `
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: ${propOr('10px', 'gap')};
  padding: 0.2rem 1rem;
  padding-left: calc(1rem + 1rem * ${propOr('0', 'depth')});
  overflow-wrap: break-word;
  cursor: default;

  ${injectIf('active', css `
      color: ${colorFromPurpose('primary', 500)};
    `)}

  ${injectIf('muted', css `
      color: ${colorFromPurpose('secondary', 600)};
    `)}

  &:hover {
    background-color: ${colorFromPurpose('secondary', 200)};
  }

  ${injectIf('noHover', css `
      &:hover {
        background-color: inherit;
      }
    `)}
`;
Row.defaultProps = {
    tabIndex: 0
};
export const Checkbox = (_a) => {
    var { checked, onChange } = _a, props = __rest(_a, ["checked", "onChange"]);
    return (_jsxs(StyledCheckbox, { children: [_jsx("input", Object.assign({ type: "checkbox", readOnly: true, checked: checked, onChange: (event) => {
                    onChange && onChange(event.target.checked, event);
                } }, props)), _jsx("div", { className: "custom-checkbox" })] }));
};
export const Label = ({ className, searchTerm = '', children }) => (_jsx(Text.Highlight, Object.assign({ className: className, textToMatch: [searchTerm] }, { children: children })));
const StyledButton = styled.button `
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};

  width: 20px;
  height: 20px;
  border-radius: 0;
  border: none;
  outline: none;
  flex-shrink: 0;

  padding: 0;
  cursor: pointer;
  background-color: ${colorFromPurpose('secondary', 200)};

  svg {
    width: 100%;
    height: 100%;
    color: ${colorFromPurpose('secondary', 600)};
  }
`;
export const ExpandButton = ({ className, hidden = false, expanded, onClick }) => (_jsx(StyledButton, Object.assign({ className: className, title: expanded ? translate('Collapse') : translate('Expand'), hide: hidden, type: "button", onClick: (e) => {
        e.stopPropagation();
        onClick && onClick();
    } }, { children: _jsx(IconWrapper, { children: expanded ? _jsx(MdRemove, {}) : _jsx(MdAdd, {}) }) })));
const StyledCategory = styled.div `
  padding-left: 0;
  cursor: default;
  font-weight: bold;
  color: ${(_a) => {
    var { active } = _a, rest = __rest(_a, ["active"]);
    return active
        ? colorFromPurpose('primary', 500)(rest)
        : colorFromPurpose('secondary', 600)(rest);
}};
`;
export const Category = (_a) => {
    var { className, active, children } = _a, rest = __rest(_a, ["className", "active", "children"]);
    return (_jsx(StyledCategory, Object.assign({ active: active, className: className }, { children: _jsx(Label, Object.assign({}, rest, { children: children })) })));
};
export const Column = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${propOr('1rem', 'gap')};
  width: ${propOr('100%', 'width')};
  word-break: break-all;
  padding-right: ${themeSpace(1)};
`;
export const Header = styled.div `
  font-size: ${themeFontSize(1)};
  color: ${colorFromPurpose('secondary', 700)};
  width: ${propOr('100%', 'width')};
  text-transform: uppercase;
  height: 28.5px;
`;
const StyledHeaders = styled.div `
  display: flex;
  align-items: center;
`;
export const Headers = ({ widths, children }) => (_jsx(StyledHeaders, { children: widths.map((width, index) => (_jsx(Column, Object.assign({ width: width }, { children: Array.isArray(children) ? children[index] : children }), index))) }));
export const Toolbar = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${themeSpace(1)};
  flex-basis: 0;
  border-top: 1px solid ${colorFromPurpose('secondary', 300)};
`;
export const Container = styled.div `
  box-sizing: border-box;

  > * {
    box-sizing: border-box;
  }

  display: flex;
  gap: ${themeSpace(1)};
  flex-direction: column;
  height: 100%;
  padding-top: ${themeSpace(1)};

  > *:not(.ex-list__container):not(${Toolbar}) {
    padding: 0 1rem;
  }
`;
export const Divider = styled.div `
  border-bottom: 1px solid ${colorFromPurpose('secondary', 300)};
`;
