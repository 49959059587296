import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'

import { SettingsPage } from '../domains/administration/pages/Settings/SettingsPage'

import { NavigationTabs } from './NavigationTabs'

const Users = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.usermanagement' */
      '../domains/users/pages/Users'
    )
)

const Groups = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.usermanagement' */
      '../domains/users/pages/UserGroups'
    )
)

const SSO = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.sso' */
      '../domains/administration/pages/Settings/SSO/SSO'
    )
)

const System = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.system' */
      '../domains/administration/pages/Settings/System'
    )
)

const Reporting = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.reporting' */
      '../domains/administration/pages/Settings/Reporting'
    )
)

const Formatting = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.formatting' */
      '../domains/administration/pages/Settings/Formatting'
    )
)

const Interface = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.interface' */
      '../domains/administration/pages/Settings/Interface'
    )
)

const Licence = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.license' */
      '../domains/administration/pages/Settings/Licence'
    )
)

const ThirdPartyServices = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.thirdparty' */
      '../domains/administration/pages/Settings/ThirdPartyServices'
    )
)

const Disclaimer = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.disclaimer' */
      '../domains/administration/pages/Settings/Disclaimer'
    )
)

const AuditTrail = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.audittrail' */
      '../domains/administration/pages'
    )
)

const LogViewer = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.logviewer' */ '../components/pages/Administration/LogViewer'
    )
)

export const administration = {
  path: '/administration',
  routes: {
    settings: {
      path: '/settings',
      requiresPermissions: [UserGroupPermission.ManageSettings],
      meta: {
        title: () => translate('Settings'),
        documentation: 'https://docs.exivity.com/The%20basics/Configuration/'
      },
      component: SettingsPage,
      routes: {
        interface: {
          path: '/interface',
          meta: {
            title: () => translate('Interface')
          },
          component: Interface
        },
        formatting: {
          path: '/formatting',
          meta: {
            title: () => translate('Formatting')
          },
          component: Formatting
        },
        reporting: {
          path: '/reporting',
          meta: {
            title: () => translate('Reporting')
          },
          component: Reporting
        },
        system: {
          path: '/system',
          meta: {
            title: () => translate('System')
          },
          component: System
        },
        sso: {
          path: '/single_sign-on',
          meta: {
            title: () => translate('Single sign-on')
          },
          component: SSO
        },
        thirdparty: {
          path: '/third_party_services',
          meta: {
            title: () => translate('3rd party services')
          },
          component: ThirdPartyServices
        },
        license: {
          path: '/licence',
          meta: {
            title: () => translate('Licence')
          },
          component: Licence
        },
        disclaimer: {
          path: '/disclaimer',
          meta: {
            title: () => translate('Disclaimer')
          },
          component: Disclaimer
        }
      }
    },
    userManagement: {
      path: '/user-management',
      requiresPermissions: [UserGroupPermission.ManageUsers],
      meta: {
        title: () => translate('User management'),
        documentation:
          'https://docs.exivity.com/The%20basics/User%20management/Users'
      },
      component: NavigationTabs,
      routes: {
        user: {
          path: '/users/:id?/:entity(profile|notifications|channels)?/:notification_id?',
          meta: {
            title: () => translate('Users')
          },
          component: Users
        },
        usergroup: {
          path: '/usergroups/:id?',
          meta: {
            title: () => translate('Groups')
          },
          component: Groups
        }
      }
    },
    auditTrail: {
      path: '/audittrail',
      requiresPermissions: [UserGroupPermission.ViewAudit],
      meta: {
        title: () => translate('Audit trail')
      },
      component: AuditTrail
    },
    logViewer: {
      path: '/logviewer',
      requiresPermissions: [UserGroupPermission.ViewLogs],
      meta: {
        title: () => translate('Log viewer')
      },
      component: LogViewer
    }
  }
}
