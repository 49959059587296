import { HookContext } from '../fetchWithHooks/fetchWithHooks'

const JSON_HEADERS = new Set(['application/json', 'application/vnd.api+json'])

export function isJsonResponse(ctx: HookContext) {
  if (ctx.result) {
    return JSON_HEADERS.has(ctx.result.headers.get('Content-Type') || '')
  }
  return false
}

async function parseJsonProgress(thread: number, result: Response) {
  if (!result.body) {
    return result.text()
  }

  const reader = result.body.getReader()
  const decoder = new TextDecoder()

  const isJsonStartToken = (token: string) => token === '{' || token === '['

  let jsonStarted = false
  let json = ''

  // @ts-ignore
  function processChunk() {
    return reader.read().then(function (result) {
      const received = decoder.decode(result.value || new Uint8Array(), {
        stream: !result.done
      })

      // Create an array of received lines which are not empty
      const allLines = received.split(/\r\n|\r|\n/).filter((item) => !!item)

      // Sometimes we get multiple lines at once
      // In this case, iterate them all and start adding to the json buffer when we find a line
      // starting with the jsonStartToken
      if (allLines.length > 1) {
        // @todo verify we can't get into a loop when allLines.length remains to be larger than 1?
        allLines.forEach((line) => {
          if (isJsonStartToken(line[0]) && !jsonStarted) {
            jsonStarted = true
          }
          if (jsonStarted) {
            json += line
          }
        })
      } else {
        if (isJsonStartToken(received[0]) && !jsonStarted) {
          jsonStarted = true
        }

        if (jsonStarted) {
          json += received
        }

        if (result.done) {
          return json
        }
      }

      return processChunk()
    })
  }

  return processChunk()
}

export async function handleJsonResponse(ctx: HookContext) {
  if (ctx.result) {
    ctx.result = await parseJsonProgress(ctx.params.thread, ctx.result)
  }

  return ctx
}
