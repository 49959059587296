import ClassAction, { createClassAction } from '../store/middleware/ClassAction'
import { LogComponentKeys } from '../components/pages/Administration/componentKeys'

export class ChangeComponent extends ClassAction {
  static readonly CHANGE_COMPONENT = 'LOGS_CHANGE_COMPONENT'
  readonly type = ChangeComponent.CHANGE_COMPONENT
  constructor(public component: LogComponentKeys) {
    super()
  }
}

export class ChangeFilter extends ClassAction {
  static readonly CHANGE_FILTER = 'LOGS_CHANGE_FILTER'
  readonly type = ChangeFilter.CHANGE_FILTER
  constructor(public filter: number) {
    super()
  }
}

export class ChangeLogfile extends ClassAction {
  static readonly CHANGE_LOGFILE = 'LOGS_CHANGE_LOGFILE'
  readonly type = ChangeLogfile.CHANGE_LOGFILE
  constructor(public logfile: number) {
    super()
  }
}

export const changeComponent = createClassAction(ChangeComponent)
export const changeFilter = createClassAction(ChangeFilter)
export const changeLogfile = createClassAction(ChangeLogfile)
