import { css } from 'styled-components';
export const horizontalAnimations = css `
  transition: transform 0.2s ease-in-out;

  hr {
    transform: scaleX(0.13);
    transform-origin: right;
    transition: transform 0.2s ease-in-out;
  }

  .nav__icon,
  .nav-text,
  .nav-select__trigger,
  footer {
    transition: transform 0.2s ease-in-out;
    transform: translateX(200px);
    pointer-events: none;
  }

  .nav-text,
  .nav-group__chev,
  .nav-select__trigger,
  footer {
    transition-property: transform, opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    opacity: 0;
  }

  .dropdown__drop {
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    opacity: 0;
  }

  &.nav__sidebar--expanded {
    transition: transform 0.2s ease-in-out;
    transform: translateX(200px);

    .nav-select__opacity {
      transition-property: transform, opacity;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;
      transform: scaleX(1);
      transform-origin: left;
    }

    .nav__icon,
    .nav-text,
    .nav-select__trigger,
    .nav-group__chev,
    footer {
      transition: transform 0.2s ease-in-out;
      transform: translateX(0px);
    }

    .dropdown__drop {
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;
      opacity: 1;
    }

    .nav-text,
    .nav-group__chev,
    .nav-select__trigger,
    footer {
      transition-property: transform, opacity;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;
      opacity: 1;
    }

    .nav__icon,
    .nav-text,
    .nav-select__trigger,
    footer {
      pointer-events: inherit;
    }

    hr {
      transform: scaleX(1);
    }
  }
`;
