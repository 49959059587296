import styled from 'styled-components';
import { fromTheme } from '../Theme';
export const JoinChildren = styled.div `
  display: flex;
  align-items: center;
  gap: ${({ gap = 0 }) => gap}px;

  @media (max-width: ${fromTheme((theme) => theme.breakpoints[0])}) {
    > {
      flex-grow: 1;
    }
  }

  > * {
    &:not(:first-child):not(:last-child) {
      margin-left: 0;
      border-radius: 0;

      & {
        border-radius: 0;
      }
    }

    &:not(:only-child):first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      & * {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:only-child):last-child {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      & * {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;
