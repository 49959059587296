import { useState } from 'react'
import {
  queries,
  useCacheQuery,
  useOperationListener,
  empty,
  withAttribute
} from '@exivity/data-layer'
import { useParams } from '@exivity/routing'
import { lensIndex, lensProp, compose } from 'ramda'
import { pipe } from '@exivity/fp'

import { Variable } from './useVariables'

const createEnvironment = pipe(
  empty('environment'),
  withAttribute('name', ''),
  withAttribute('default_flag', false)
)

type Environment = ReturnType<typeof createEnvironment>

export const attributeLensByIndex = (index: number) =>
  compose(lensIndex<Variable>(index), lensProp('attributes'))

export function useEnvironment() {
  const [params, setParams] = useParams()
  const [environment, updateEnvironment] =
    useState<Environment>(createEnvironment)

  useOperationListener('environment', {
    addRecord: ({ id }) => params.id === 'new' && setParams('id', id),
    removeRecord: ({ id }) => params.id === id && setParams('id', null)
  })

  const originalEnvironment = useCacheQuery(
    queries.find('environment', params.id),
    (queryResult) =>
      queryResult
        ? updateEnvironment(queryResult)
        : updateEnvironment(createEnvironment)
  )

  return {
    environment,
    updateEnvironment,
    isDefaultEnv: originalEnvironment?.attributes.default_flag ?? false
  }
}
