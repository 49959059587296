import config from '../../application/config'
import { fetchWithHooks } from '../fetchWithHooks/fetchWithHooks'
import {
  handleWorkerThreads,
  handleJsonResponse,
  isJsonResponse,
  parseJson,
  handleResponseErrors,
  handleTextResponse,
  iff,
  handleTranscriptError,
  showErrorToUser
} from '../hooks'
import store from '../../store'
import { authSelectors } from '../../domains/auth/state'

export function getApiUrl(url: string) {
  const apiRoot =
    authSelectors.getApiRoot(store.getState()) + '/v' + config.app.apiVersion

  return (apiRoot + url) as RequestInfo
}

export function rawFetch(url: string, fetchOptions?: RequestInit) {
  return fetch(getApiUrl(url), fetchOptions)
}

// @todo otheroptions type
export const mainFetch = (
  url: string,
  fetchOptions?: RequestInit,
  otherOptions?: any
) => {
  // Apart from saving user state everything is foreground.
  // In the future we want to do this entirely differently but for now this was quickest
  // solution where I kept using the otherOption background property used by
  // patch function in persistUserState.js
  const channel = otherOptions?.background ? 'background' : 'foreground'

  return fetchWithHooks({
    before: [handleWorkerThreads(channel)],
    after: [
      handleResponseErrors,
      iff(isJsonResponse, handleJsonResponse, parseJson).else(
        handleTextResponse
      ),
      handleWorkerThreads(channel)
    ],
    error: [
      handleWorkerThreads(channel),
      handleResponseErrors,
      iff((ctx) => ctx.options?.handleRejections, showErrorToUser),
      handleTranscriptError
    ]
  })(getApiUrl(url), fetchOptions, otherOptions)
}
