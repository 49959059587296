var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Link } from '../Link';
import { useLocation } from '../useLocation';
import { useResolvedPath } from '../useResolvedPath';
export const NavLink = forwardRef(function NavLinkWithRef(_a, ref) {
    var { 'aria-current': ariaCurrentProp = 'page', activeClassName = 'active', activeStyle, caseSensitive = false, className: classNameProp = '', end = false, style: styleProp, to = '' } = _a, rest = __rest(_a, ['aria-current', "activeClassName", "activeStyle", "caseSensitive", "className", "end", "style", "to"]);
    const location = useLocation();
    const path = useResolvedPath(to);
    let locationPathname = location.pathname;
    let toPathname = path.pathname;
    if (!caseSensitive) {
        locationPathname = locationPathname.toLowerCase();
        toPathname = toPathname.toLowerCase();
    }
    const isActive = end
        ? locationPathname === toPathname
        : locationPathname.startsWith(toPathname);
    const ariaCurrent = isActive ? ariaCurrentProp : undefined;
    const className = [classNameProp, isActive ? activeClassName : null]
        .filter(Boolean)
        .join(' ');
    const style = Object.assign(Object.assign({}, styleProp), (isActive ? activeStyle : null));
    return (_jsx(Link, Object.assign({}, rest, { ref: ref, "aria-current": ariaCurrent, className: className, style: style, to: to })));
});
