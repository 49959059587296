var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Link as BaseLink } from '@exivity/routing';
import { Icon } from '../Icon';
import { themeGlobal, themeSpace } from '../Theme';
const Link = styled((_a) => {
    var { visible } = _a, rest = __rest(_a, ["visible"]);
    return _jsx(BaseLink, Object.assign({}, rest));
}) `
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
const StyledButton = styled.button `
  display: flex;
  gap: ${themeSpace(1)};
  height: 60px;
  background-color: transparent;
  padding: 0 ${themeSpace(2)};
  font-family: ${themeGlobal('fontFamily')};
  font-size: 0.765625em;
  color: ${themeGlobal('inverseBrandTextColor')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  text-transform: uppercase;
  display: inline-flex;
  position: relative;
  appearance: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  flex: none;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus {
    box-shadow: inset 10px 10px 999em
      rgba(0, 0, 0, ${themeGlobal('shadowAlpha')});
  }

  ${Icon} {
    font-size: 1.5em;
  }

  &:active {
    color: ${themeGlobal('inverseBrandTextColor')};
    text-decoration: underline;
  }

  &:visited {
    color: ${themeGlobal('inverseBrandTextColor')};
  }

  &:hover {
    color: ${themeGlobal('inverseBrandTextColor')};
  }
`;
export function AppBarButton(_a) {
    var { type = 'button', icon, children, visible = true, to } = _a, rest = __rest(_a, ["type", "icon", "children", "visible", "to"]);
    const isSmallScreen = false;
    const ButtonComponent = to ? Link : 'button';
    const buttonProps = to ? { to } : { type };
    return (_jsxs(StyledButton, Object.assign({ visible: visible }, buttonProps, rest, { as: ButtonComponent }, { children: [_jsx(Icon, { children: icon }), isSmallScreen ? null : children] })));
}
