import styled from 'styled-components';
export const Group = styled.div `
  width: 100%;
  display: flex;

  flex-direction: row;
  align-items: center;

  > :first-child {
    flex-grow: 1;
  }

  > :not(:first-child) {
    margin-left: 5px;
  }
`;
