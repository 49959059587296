import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { ITEM_HEIGHT } from './SelectList';
import { SelectOption } from './SelectOption';
export function useVirtualList({ data, itemHeight, visibleCount }) {
    const [scrollPosition, setScrollPosition] = useState(0);
    const containerRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setScrollPosition(containerRef.current.scrollTop);
            }
        };
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    const startIndex = Math.max(0, Math.floor(scrollPosition / itemHeight));
    const endIndex = Math.min(Math.ceil((scrollPosition + visibleCount * itemHeight) / itemHeight), data.length);
    const offset = startIndex * itemHeight;
    return {
        items: data.slice(startIndex, endIndex),
        startIndex,
        offset,
        containerRef
    };
}
export function VirtualList({ height, data }) {
    const visibleCount = Math.ceil(height / ITEM_HEIGHT);
    const { containerRef, items, offset, startIndex } = useVirtualList({
        data,
        itemHeight: ITEM_HEIGHT,
        visibleCount
    });
    const spacerHeight = Math.max(0, data.length * ITEM_HEIGHT - visibleCount * ITEM_HEIGHT);
    const containerStyle = {
        overflowY: 'auto',
        height,
        position: 'relative'
    };
    return (_jsxs("div", Object.assign({ style: containerStyle, ref: containerRef }, { children: [_jsx("ul", Object.assign({ style: { transform: `translateY(${offset}px)` } }, { children: items.map((_, index) => (_jsx(SelectOption, { index: index + startIndex }, index + startIndex))) })), _jsx("div", { style: { height: `${spacerHeight}px` } }, spacerHeight)] })));
}
