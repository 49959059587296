import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { setYear, getYear, startOfYear, format, addMonths, addYears, subMonths } from 'date-fns';
import { StyledTimeUnits, TimeUnit } from '../shared';
import { CalendarMode } from '../CalendarMode';
export const StyledYears = styled(StyledTimeUnits) `
  li {
    width: calc(33.33% - 5px);
  }
`;
export function Years({ value: [start, end], browserDate, onHoverItem, onEndHoverItem, offset = 0, onChange, min, max }) {
    const normalizedValue = useMemo(() => {
        return [
            offset && start ? subMonths(start, offset) : start,
            offset && end ? subMonths(end, offset) : end
        ];
    }, [start, end]);
    function renderYear(_, i) {
        const browseYearMiddle = getYear(browserDate);
        const year = i <= 6 ? browseYearMiddle - (6 - i) : browseYearMiddle + (i - 6);
        const thisYear = startOfYear(setYear(browserDate, year));
        const yearWithOffset = addMonths(thisYear, offset);
        return (_jsx("span", Object.assign({ "data-testid": "calendar-years-year" }, { children: _jsx(TimeUnit, Object.assign({ date: thisYear, mode: CalendarMode.Years, value: normalizedValue, min: min, max: max, onHover: () => onHoverItem === null || onHoverItem === void 0 ? void 0 : onHoverItem(yearWithOffset), onStopHovering: () => onEndHoverItem === null || onEndHoverItem === void 0 ? void 0 : onEndHoverItem(yearWithOffset), onChange: (_, ctrlPressed) => onChange === null || onChange === void 0 ? void 0 : onChange(yearWithOffset, ctrlPressed) }, { children: offset > 0
                    ? format(thisYear, 'yyyy') +
                        ' - ' +
                        format(addYears(thisYear, 1), 'yyyy')
                    : format(thisYear, 'yyyy') })) }), i));
    }
    return (_jsx(StyledYears, Object.assign({ "data-testid": "calendar-years" }, { children: new Array(12).fill(null).map(renderYear) })));
}
