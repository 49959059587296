import { memory } from '@exivity/data-layer'

import {
  InstanceReportItem,
  InstanceReportItemAPI
} from '../../../API/APIResponseTypes/reports'
import { ServiceModel } from '../../../data/types'
import { RootState } from '../../../reducers'

function getStore() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return memory
}

export default async (state: RootState, data: InstanceReportItemAPI[]) =>
  data.map((item) => {
    let service: ServiceModel | null = null
    const newItem = item as InstanceReportItem

    // Format numbers
    const toParse = [
      'total_quantity',
      'unit_based_subtotal_charge',
      'interval_based_subtotal_charge',
      'total_charge',
      'total_cogs',
      'total_net'
    ] as const
    toParse.forEach((field) => {
      newItem[field] = parseFloat(item[field] as string)
    })

    // Add service name & category
    try {
      service = getStore().cache.query((query) =>
        query.findRecord({
          type: 'service',
          id: String(newItem.service_id)
        })
      ) as unknown as ServiceModel

      newItem.key = service.attributes.key
      newItem.service_description = service.attributes.description
      newItem.service_unit_label = service.attributes.unit_label
      newItem.service_charge_type = service.attributes.charge_type
      newItem.service_cogs_type = service.attributes.cogs_type
      newItem.proration_type = service.attributes.proration_type
      newItem.interval = service.attributes.interval
    } catch (error) {
      newItem.service_description = '(unknown service)'
      newItem.service_unit_label = null
    }

    return newItem
  })
