var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components';
import { useStateWithDep } from '@exivity/hooks';
import { matchColorFromPurpose, themeSpace } from '../Theme';
import { injectIf } from '../utils';
import { useTabsContext, TabsContext } from './helpers';
const StyledTab = styled.button `
  --color-unselected: ${matchColorFromPurpose(200, 1, 'secondary')};
  --color-unselected-text: ${matchColorFromPurpose(600, 1, 'secondary')};
  --color-selected: ${matchColorFromPurpose(800, 1, 'secondary')};

  all: unset;
  display: inline-flex;
  justify-content: space-around;
  color: var(--color-unselected-text);

  padding: 0.5rem ${themeSpace(1)};
  user-select: none;

  &:focus,
  &:hover {
    background-color: var(--color-unselected);
    border-bottom: 4px solid var(--color-unselected);
  }

  ${injectIf('isActive', css `
      color: var(--color-selected);
      border-bottom: 4px solid var(--color-selected) !important;
    `)}

  ${injectIf('disabled', css `
      pointer-events: none;
      opacity: 0.6;
    `)}
`;
function Tab(_a) {
    var { children, index, disabled, onClick } = _a, rest = __rest(_a, ["children", "index", "disabled", "onClick"]);
    const { activeIndex, setActiveIndex } = useTabsContext();
    const isActive = activeIndex === index;
    return (_jsx(StyledTab, Object.assign({ type: "button", "data-testid": "tabs-tab", role: "tab", isActive: isActive, "data-active": isActive, disabled: disabled, onClick: (e) => {
            onClick && onClick(e);
            if (!disabled) {
                setActiveIndex(index || 0);
            }
        } }, rest, { children: children })));
}
const StyledTabList = styled.div `
  border-bottom: 1px solid ${matchColorFromPurpose(400, 1, 'secondary')};
  margin-bottom: ${themeSpace(2)};
`;
function TabList(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const { purposes } = useTabsContext();
    return (_jsx(StyledTabList, Object.assign({ role: "tablist" }, purposes, rest, { children: React.Children.map(children, (child, index) => {
            return React.isValidElement(child)
                ? React.cloneElement(child, Object.assign({ index }, purposes))
                : child;
        }) })));
}
const StyledTabPanel = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeSpace(2)};
  height: 100%;

  ${injectIf('animate', css `
      @keyframes slidein {
        from {
          transform: translateX(-10px);
          opacity: 0;
        }

        to {
          transform: translateX(0px);
          opacity: 1;
        }
      }

      animation-duration: 0.5s;
      animation-name: slidein;
    `)}
`;
const TabPanel = ({ index, children }) => {
    const { activeIndex, disableAnimation } = useTabsContext();
    if (index !== activeIndex)
        return null;
    return (_jsx(StyledTabPanel, Object.assign({ role: "tabpanel", animate: !disableAnimation }, { children: children })));
};
function TabPanels({ children }) {
    return (_jsx(_Fragment, { children: React.Children.map(children, (child, index) => {
            return React.isValidElement(child)
                ? React.cloneElement(child, { index })
                : child;
        }) }));
}
const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export function Tabs(_a) {
    var { children, onActiveIndexChange, initialActiveIndex, activeIndex, disableAnimation = false, className } = _a, purposes = __rest(_a, ["children", "onActiveIndexChange", "initialActiveIndex", "activeIndex", "disableAnimation", "className"]);
    const [index, setIndex] = useStateWithDep(initialActiveIndex !== null && initialActiveIndex !== void 0 ? initialActiveIndex : 0);
    const contextValue = {
        activeIndex: activeIndex !== null && activeIndex !== void 0 ? activeIndex : index,
        setActiveIndex: (index) => {
            onActiveIndexChange && onActiveIndexChange(index);
            setIndex(index);
        },
        disableAnimation,
        purposes
    };
    return (_jsx(TabsContext.Provider, Object.assign({ value: contextValue }, { children: _jsx(Container, Object.assign({ className: className }, { children: children })) })));
}
Tabs.Tab = Tab;
Tabs.TabList = TabList;
Tabs.TabPanel = TabPanel;
Tabs.TabPanels = TabPanels;
