import { useEffect, useState } from 'react'
import { useSystemConfiguration } from '@exivity/data-layer'
import { setLanguage, selectedLanguage } from '@exivity/translations'

import { useUserPreferences } from '../../my-profile/hooks'

export function useTranslation() {
  const [language, setLang] = useState(selectedLanguage)
  const appLanguage = useSystemConfiguration().attributes.APP_LANGUAGE

  const [userPreference] = useUserPreferences(
    (preferences) => preferences.language
  )
  const chosenLanguage = userPreference || appLanguage

  useEffect(() => {
    if (chosenLanguage) {
      setLanguage(chosenLanguage)
      setLang(chosenLanguage)
    }
  }, [chosenLanguage])

  return language
}
