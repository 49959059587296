import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useReducer } from 'react';
import { addMonths, addQuarters, addYears, subMonths, subQuarters, subYears } from 'date-fns';
import { CalendarMode } from '../CalendarModes';
import { Browser } from '../../Browser';
var BrowseTypes;
(function (BrowseTypes) {
    BrowseTypes["ADD_MONTH"] = "addMonth";
    BrowseTypes["SUB_MONTH"] = "subMonth";
    BrowseTypes["ADD_QUARTER"] = "addQuarter";
    BrowseTypes["SUB_QUARTER"] = "subQuarter";
    BrowseTypes["ADD_YEAR"] = "addYear";
    BrowseTypes["SUB_YEAR"] = "subYear";
    BrowseTypes["ADD_YEARS"] = "addYears";
    BrowseTypes["SUB_YEARS"] = "subYears";
})(BrowseTypes || (BrowseTypes = {}));
function browseReducer(date, type) {
    switch (type) {
        case BrowseTypes.ADD_MONTH:
            return addMonths(date, 1);
        case BrowseTypes.SUB_MONTH:
            return subMonths(date, 1);
        case BrowseTypes.ADD_QUARTER:
            return addQuarters(date, 1);
        case BrowseTypes.SUB_QUARTER:
            return subQuarters(date, 1);
        case BrowseTypes.ADD_YEAR:
            return addYears(date, 1);
        case BrowseTypes.SUB_YEAR:
            return subYears(date, 1);
        case BrowseTypes.ADD_YEARS:
            return addYears(date, 12);
        case BrowseTypes.SUB_YEARS:
            return subYears(date, 12);
        default:
            throw new Error();
    }
}
function createBrowsers(dispatch, mode) {
    function create(prev, next) {
        return [() => dispatch(prev), () => dispatch(next)];
    }
    switch (mode) {
        case CalendarMode.Years:
            return create(BrowseTypes.SUB_YEARS, BrowseTypes.ADD_YEARS);
        case CalendarMode.Months:
        case CalendarMode.Quarters:
            return create(BrowseTypes.SUB_YEAR, BrowseTypes.ADD_YEAR);
        case CalendarMode.Days:
        default:
            return create(BrowseTypes.SUB_MONTH, BrowseTypes.ADD_MONTH);
    }
}
export function useBrowser(value, selectedMode) {
    const [date, dispatch] = useReducer(browseReducer, value);
    const [onPrev, onNext] = createBrowsers(dispatch, selectedMode);
    return {
        date,
        onPrev,
        onNext
    };
}
export function CalendarBrowser({ value, onChange, mode, children }) {
    const { date, onPrev, onNext } = useBrowser(value, mode);
    useEffect(() => {
        onChange(date);
    }, [date]);
    return (_jsx(Browser, Object.assign({ prev: _jsx("span", Object.assign({ className: "arrow", "data-testid": "prev", onClick: onPrev }, { children: "\u276E" })), next: _jsx("span", Object.assign({ className: "arrow", "data-testid": "next", onClick: onNext }, { children: "\u276F" })) }, { children: children })));
}
