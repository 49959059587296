import { useEffect } from 'react'
import { Alert } from '@exivity/ui'
import { useCacheQuery, queries, useServer } from '@exivity/data-layer'
import { translate } from '@exivity/translations'
import { parseISO, differenceInDays, startOfDay } from 'date-fns'

function useQueryLicence() {
  const server = useServer()
  const licence = useCacheQuery(queries.findAll('licence'))[0]

  useEffect(() => {
    server.query((q) => [q.findRecords('licence')])
  }, [server])

  return licence
}

export function LicenceWarning() {
  const licence = useQueryLicence()

  const expiresInXDays =
    differenceInDays(
      startOfDay(parseISO(licence?.attributes.expiresAfter ?? '')),
      startOfDay(new Date())
    ) + 1

  if (licence && expiresInXDays < 1) {
    return (
      <Alert warning closable title="Licence">
        {translate('Your licence is about to expire.')}
      </Alert>
    )
  }

  if (licence && licence.attributes.status !== 'valid') {
    return (
      <Alert danger title="Licence">
        {translate('Please check your licence.')}
      </Alert>
    )
  }

  if (licence && expiresInXDays <= 14) {
    return (
      <Alert title="Licence" closable>
        {`Your licence expires in ${expiresInXDays} day${
          expiresInXDays === 1 ? '' : 's'
        }`}
        .
      </Alert>
    )
  }

  return null
}
