import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../../reducers'
import migrator from '../../../store/enhancers/migrator'
import { memoizePartial } from '../../../store/observers/stateObserver'

export type SplitPaneName = 'transformer'

export interface PreferencesState {
  language: string | null
}

export type Disclaimer = {
  title: string | null
  text: string | null
  agreeButtonText: string | null
}

export interface GUIState {
  collapse: boolean
  previewerSplitPanePercentage: number
}

const GUIState = {
  collapse: false,
  previewerSplitPanePercentage: 25
} as GUIState

const initialState = {
  hydrated: false,
  preferences: {
    language: null
  } as PreferencesState,
  reports: {} as any,
  gui: GUIState,
  acceptedDisclaimer: {
    title: null,
    text: null,
    agreeButtonText: null
  } as Disclaimer
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function omitHydrated({ hydrated, ...profile }: typeof initialState) {
  return profile
}

export const observeProfilePartial = memoizePartial(omitHydrated)

export type ProfileState = typeof initialState

type PreferencesAction = {
  key: keyof PreferencesState
  language: string | null
}

export const { actions, reducer } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateUserPreferences: {
      prepare: (key: keyof PreferencesState, language: string | null) => ({
        payload: {
          key,
          language
        }
      }),
      reducer: (state, action: PayloadAction<PreferencesAction>) => {
        state.preferences[action.payload.key] = action.payload.language
      }
    },
    updatePreviewPaneHeightPercentage(state, action) {
      state.gui.previewerSplitPanePercentage = action.payload
    },
    updateCollapseSidebar(state, action) {
      state.gui.collapse = action.payload
    },
    updateAcceptedDisclaimer: (state, action: PayloadAction<Disclaimer>) => {
      state.acceptedDisclaimer = action.payload
    },
    loadSlice: (state, action) => {
      return migrator({
        currentState: state,
        incomingState: {
          hydrated: true,
          ...action.payload
        }
      })
    }
  }
})

export const profileActions = {
  ...actions
}

export const profileReducer = reducer

export const profileSelectors = {
  isNavCollapsed(state: RootState) {
    return state.profile.gui.collapse
  },
  isPopulatedByExternalSource(state: RootState) {
    return state.profile.hydrated
  },
  disclaimerIsAccepted(newDisclaimer: Disclaimer) {
    return (state: RootState) => {
      const { acceptedDisclaimer } = state.profile
      // Always take keys of newDisclaimer as it represents the latest shape
      return Object.keys(newDisclaimer).every((property: string) => {
        const key = property as keyof Disclaimer
        return acceptedDisclaimer[key] === newDisclaimer[key]
      })
    }
  },
  getPreviewerSplitPanePercentage(state: RootState) {
    return state.profile.gui.previewerSplitPanePercentage
  }
}
