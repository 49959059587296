import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { pathEq, pathOr } from 'ramda'
import { useOperationListener, useServer, useSystemConfiguration } from '@exivity/data-layer'
import { useSelector } from 'react-redux'

import { ReportFormatter } from '../../../utils/formatters/ReportFormatter'
import { reportSelectors } from '../../../domains/reports/state'

const INIT_CURRENCY = {
	format: null as string | null,
	code: null as string | null
}

const FormatterContext = createContext(new ReportFormatter({} as any))
const CurrencyFormatterContext = createContext(INIT_CURRENCY)

const equalCode = pathEq(['attributes', 'currency_code'])

const equalFormat = pathEq(['attributes', 'currency_format'])

const useGetReportCurrency = () => {
	const server = useServer()
  const selectReportId = useSelector(reportSelectors.getSelectedReportId)
  const systemConfiguration = useSystemConfiguration()
	const [currency, setCurrency] = useState(INIT_CURRENCY) 

  const fetchCurrencyMeta = useCallback(() => {
    if (selectReportId) {
      server.query((q) => q
        .findRecord({ type: 'reportdefinition', id: selectReportId }),
        { fullResponse: true}
      ).then((fullResponse) => {
        setCurrency(pathOr(INIT_CURRENCY, ['data', 'meta', 'currency'], fullResponse));
      })
    }
  }, [selectReportId, server])

  useOperationListener('dataset', {
    updateRecord: (record) => {
      if ((!equalCode(currency.code, record) || !equalFormat(currency.format, record)) && selectReportId) {
        fetchCurrencyMeta()
      }
    }
  })

	useEffect(() => {
		fetchCurrencyMeta()
	}, [fetchCurrencyMeta])

  return useMemo(() => ({
    format: currency.format || systemConfiguration.attributes.CURRENCY_FORMAT || '',
    code: currency.code || systemConfiguration.attributes.CURRENCY || ''
  }), [currency, systemConfiguration.attributes.CURRENCY, systemConfiguration.attributes.CURRENCY_FORMAT])
}

export const FormatterProvider = ({ children }: { children: React.ReactElement }) => {
	const systemConfiguration = useSystemConfiguration()
  const currency = useGetReportCurrency()

  const formatter = useMemo(
    () =>
      new ReportFormatter({
        precisions: {
          quantity:
            systemConfiguration.attributes.QUANTITY_PRECISION ?? undefined,
          rate: systemConfiguration.attributes.RATE_PRECISION ?? undefined,
          report: systemConfiguration.attributes.REPORT_PRECISION ?? undefined,
          summary:
            systemConfiguration.attributes.SUMMARY_PRECISION ?? undefined,
          percentage:
            systemConfiguration.attributes.PERCENTAGE_PRECISION ?? undefined
        },
        symbols: {
          decimal:
            systemConfiguration.attributes.DECIMAL_SEPARATOR ?? undefined,
          thousands:
            systemConfiguration.attributes.THOUSAND_SEPARATOR ?? undefined,
          currency: currency.format
        }
      }),
    [systemConfiguration, currency.format]
  )

	return (
			<FormatterContext.Provider value={formatter}>
				<CurrencyFormatterContext.Provider value={currency}>
					{children}
				</CurrencyFormatterContext.Provider>
			</FormatterContext.Provider>
	)
}

export const useReportCurrency = () => {
	return useContext(CurrencyFormatterContext)
}

export const useReportFormatter = () => {
	return useContext(FormatterContext)
}
