import { useEffect } from 'react'
import { translate } from '@exivity/translations'
import { useDataStore, useServer } from '@exivity/data-layer'
import { useDispatch } from 'react-redux'
import { RecordOperation, RecordTransform } from '@orbit/records'

import { workThunks } from '../../../domains/work/thunks'
import { capitalize } from '../../../utils/string'

const messages = {
  licence: () => translate('Licence key updated.'),
  success: {
    add: (resource: string) =>
      capitalize(translate('{resource} successfully created.', resource)),
    update: (resource: string) =>
      capitalize(translate('{resource} successfully updated.', resource)),
    remove: (resource: string) =>
      capitalize(translate('{resource} successfully deleted.', resource))
  }
}

const notifyResource: Record<string, () => string> = {
  account: () => translate('Account'),
  adjustment: () => translate('Adjustment'),
  environment: () => translate('Environment'),
  extractor: () => translate('Extractor'),
  rate: () => translate('Rate revision'),
  reportdefinition: () => translate('Report definition'),
  service: () => translate('Service'),
  servicecategory: () => translate('Service category'),
  servicesubscription: () => translate('Service subscription'),
  transformer: () => translate('Transformer'),
  user: () => translate('User'),
  usergroup: () => translate('Usergroup'),
  workflow: () => translate('Workflow'),
  licence: () => translate('Licence'),
  metadatadefinition: () => translate('Metadata definition'),
  configuration: () => translate('Settings'),
  notificationsubscription: () => translate('Notification'),
  notificationchannel: () => translate('Notification channel')
}

export const ResourceMessage = ({ children }: any) => {
  const dispatch = useDispatch()
  // need to listen for both memory and server source updates as atomic is not
  // managed yet by server source and atomic results are added manually to memory source
  const memory = useDataStore()
  const server = useServer()

  useEffect(() => {
    const messageUser = (operation: RecordOperation) => {
      if (operation.op === 'addRecord') {
        operation.record.type === 'licence'
          ? dispatch(workThunks.showSuccessMessage(messages.licence()))
          : dispatch(
              workThunks.showSuccessMessage(
                messages.success.add(notifyResource[operation.record.type]())
              )
            )
      }

      if (operation.op === 'updateRecord') {
        dispatch(
          workThunks.showSuccessMessage(
            messages.success.update(notifyResource[operation.record.type]())
          )
        )
      }

      if (operation.op === 'removeRecord') {
        dispatch(
          workThunks.showSuccessMessage(
            messages.success.remove(notifyResource[operation.record.type]())
          )
        )
      }
    }

    const handleUpdateEvent = (transform: RecordTransform) => {
      Array.isArray(transform.operations)
        ? transform.operations.forEach(messageUser)
        : messageUser(transform.operations)
    }

    memory.on('update', handleUpdateEvent)
    server.on('update', handleUpdateEvent)
    return () => {
      memory.off('update', handleUpdateEvent)
      server.off('update', handleUpdateEvent)
    }
  }, [memory, server])

  return children
}
