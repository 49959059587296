import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'
import { getMaxAccountLevel } from '../../../../../selectors/definition'
import { invalidateAllReportData } from '../invalidateAllReportData'

/**
 * The parentAccount filter and account filter are for now always synchronised.
 * EXCEPT: When updating the account filter whilst at the lowest level. In this case the
 * parent accountFilter is set to null as he cannot be a parent.
 * The filters are synchronised with each other as to the user they are the same filter, but under
 * the hood they are used on different query filters (for the api) to
 * generate slightly different results.
 * */

export const updateAccount = {
  prepare: (id: string | null) => ({
    payload: id,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (
    filters: ReportFilterState,
    action: PayloadAction<string | null>
  ) => {
    const parentAccount =
      filters.depth < (getMaxAccountLevel(filters.report) || 0)
        ? action.payload
        : null

    filters.account = action.payload
    filters.parentAccount = parentAccount

    filters.accounts.pinned = []
    filters.services.pinned = []
    filters.instances.pinned = []
  }
}

export const updateParentAccount = {
  prepare: (id: string | null) => ({
    payload: id,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (
    filters: ReportFilterState,
    action: PayloadAction<string | null>
  ) => {
    filters.parentAccount = action.payload
    filters.account = action.payload

    filters.accounts.pinned = []
    filters.services.pinned = []
    filters.instances.pinned = []
  }
}

export const updateDepth = {
  prepare: (depth: number) => ({
    payload: depth,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (filters: ReportFilterState, action: PayloadAction<number>) => {
    filters.depth = Number(action.payload)
    filters.account = null
    filters.parentAccount = null
    filters.accounts.pinned = []
    filters.services.pinned = []
    filters.instances.pinned = []
  }
}

export const updateMinimumAccessibleDepth = {
  prepare: (minimumAccessibleDepth: number) => ({
    payload: minimumAccessibleDepth
  }),
  reducer: (filters: ReportFilterState, action: PayloadAction<number>) => {
    filters.depth =
      filters.depth < action.payload ? action.payload : filters.depth

    filters.minimumAccessibleDepth = action.payload
  }
}

export const drillDown = {
  prepare: (id: string) => ({
    payload: id,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (filters: ReportFilterState, action: PayloadAction<string>) => {
    const maxDepth = getMaxAccountLevel(filters.report)
    if (maxDepth !== filters.depth && filters.parentAccount) {
      filters.depth++
    }

    filters.parentAccount = maxDepth === filters.depth ? null : action.payload
    filters.account = action.payload
    filters.accounts.pinned = []
    filters.services.pinned = []
    filters.instances.pinned = []
    filters.accounts.search.term = ''
    filters.services.search.term = ''
    filters.instances.search.term = ''
  }
}
