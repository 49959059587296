import { isEmpty, unfold } from '@exivity/fp';
const Parser = (transformer) => transformer;
const match = (regex, target) => {
    try {
        return isEmpty(target) ? null : target.match(new RegExp(regex, 'i'));
    }
    catch (_a) {
        return null;
    }
};
export const replacer = (regex, replacer) => (text) => {
    const parse = Parser((state) => {
        const { target, count } = state;
        const matches = match(regex, target);
        if (matches) {
            const index = target.indexOf(matches[0]);
            return {
                target: target.slice(index + matches[0].length),
                count: count + 1,
                result: index > 0
                    ? [target.slice(0, index), replacer(matches[0], count)]
                    : [replacer(matches[0], count)]
            };
        }
        else {
            return {
                count,
                target: '',
                result: isEmpty(target) ? null : [target]
            };
        }
    });
    const initState = parse({
        target: text,
        count: 0,
        result: []
    });
    return unfold((state) => {
        const nextState = parse(state);
        return state.result ? [state.result, nextState] : false;
    }, initState).flat();
};
