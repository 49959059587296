import { getUserSessionStorage } from '../store/storage'

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const authHeader = () => ({
  Authorization: `Bearer ${
    getUserSessionStorage.fromEitherStorage('token') ?? ''
  }`
})

const auth = () => ({
  ...authHeader(),
  ...jsonHeaders
})

export const defaultHeaders = {
  auth
}
