import { useMemo, useState, useEffect } from 'react';
import { listToTreeList } from '../utils/tree';
import { createHelpers, enrichTreeItems, getVisibleItems } from './helpers';
export function useExpandable(data, keyAccessor, parentKeyAccessor, initialExpandedKeys) {
    const [expanded, setExpanded] = useState(initialExpandedKeys || []);
    useEffect(() => {
        initialExpandedKeys && setExpanded(initialExpandedKeys);
    }, []);
    const treeList = useMemo(() => listToTreeList(data, keyAccessor, parentKeyAccessor), [data]);
    return useMemo(() => {
        const expandableList = enrichTreeItems(treeList, keyAccessor, expanded, setExpanded);
        return [
            getVisibleItems(expandableList, keyAccessor, expanded),
            createHelpers(keyAccessor, expanded, setExpanded)
        ];
    }, [treeList, expanded]);
}
