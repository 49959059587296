import { useEffect, useMemo, useState } from 'react'

import { filter, where, isNil } from 'ramda';
import { Resources, UserGroupPermission, queries, useCacheQuery, useServer, useUserHasPermission } from '@exivity/data-layer';
import { useSelector } from 'react-redux';
import { difference, take } from 'ramda';

import { authSelectors } from '../../auth/state';
import { useJobsCtx } from '../components/JobsProvider'

const FETCH_INTERVAL = 10000

export type JobStatus = Resources['job']['attributes']['status']
export type JobType = Resources['job']['attributes']['job_type']

export const jobTypes: JobType[] = ['extract', 'transform', 'prepare_report']

export const jobStatuses: JobStatus[] = [
  'not_started',
  'running',
  'succeed',
  'failed',
  'timeout',
  'error_format',
  'error_cannot_run',
  'killed'
]

export const useHasJobStatusPermission = () => {
  const hasManageDatasourcesPermission = useUserHasPermission([UserGroupPermission.ManageDataSources])
  const hasManageReportPermission = useUserHasPermission([UserGroupPermission.ManageReports])
  const hasViewDatasets = useUserHasPermission([UserGroupPermission.ManageServices, UserGroupPermission.ManageAccounts])

  return {
    canSeeJobs: hasManageDatasourcesPermission || hasManageReportPermission,
    canSeeReportStatuses: hasManageDatasourcesPermission || hasManageReportPermission,
    canSeeDatasets: hasViewDatasets
  }
}

export const useJobs = () => {
  const [selectedJobType, setSelectedJobType] = useState<JobType>();
  const [selectedJobStatus, setSelectedJobStatus] = useState<JobStatus>();
  const jobs = useJobsCtx()

  const filterJobs = filter(
    where({
      attributes: where({
        job_type: (jobType: JobType) => isNil(selectedJobType) || jobType === selectedJobType,
        status: (status: JobStatus) => isNil(selectedJobStatus) || status === selectedJobStatus,
      }),
    })
  );

  return {
    selectedJobType,
    setSelectedJobType,
    selectedJobStatus,
    setSelectedJobStatus,
    jobs: filterJobs(jobs),
  };
};

const getNewlySucceeded = (prevJobs: Resources['job'][], newJobs: Resources['job'][]) => {
  const differenceJobs = difference(newJobs, prevJobs)

  return differenceJobs.filter((job) => job.attributes.status === 'succeed')
}

export const useProvideJobCtx = () => {
  const server = useServer()
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const { canSeeJobs, canSeeDatasets } = useHasJobStatusPermission()

  const jobs = useCacheQuery(
    queries
      .findAll('job')
      .sortByAttribute('start_datetime', 'descending')
  )

  const [appNeedsSync, updateAppNeedsSync] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let prevJobs: Resources['job'][]|null = null

    const getJobs = async ({ silent = false } = {}) => {      
      const fetchedJobs = await server.query((q) => q
        .findRecords('job')
        .sort({ attribute: 'start_datetime', order: 'descending' })
        .page({ limit: 100, offset: 0 })
        .options({
          settings: {
            silent,
            params: {
              sort: '-start_datetime',
            },
          },
        })) as Resources['job'][]

      timeout = setTimeout(() => {
        getJobs({
          silent: true,
        });
      }, FETCH_INTERVAL)

      if (prevJobs) {
        const newlySucceededJobs = getNewlySucceeded(prevJobs, fetchedJobs)

        const transformJobs = newlySucceededJobs.filter((job) => job.attributes.job_type === 'transform')

        if (transformJobs.length > 0 && canSeeDatasets) {
          server
            .query(q => q.findRecords('dataset')
            .page({ limit: -1, offset: 0})
            .options({ settings: { silent: true } })
          )
        }

        const prepareReportJobs = newlySucceededJobs.filter((job) => job.attributes.job_type === 'prepare_report')

        if (prepareReportJobs.length > 0) {
          updateAppNeedsSync(true)
        }
      }

      prevJobs = fetchedJobs
    };

    isAuthenticated && canSeeJobs && getJobs()

    return () => {
      clearTimeout(timeout);
    };
  }, [server, isAuthenticated, canSeeJobs, canSeeDatasets]);

  return useMemo(() => ({
    jobs: take(25, jobs),
    appNeedsSync
  }), [jobs, appNeedsSync])
}
