import { translate } from '@exivity/translations'

import { ActionDispatcher } from '../../store/utils'

import { UserMessage, workActions } from './state'

let clearMessageTimer: NodeJS.Timeout

export const MESSAGE_TIMEOUT = 10000

export function showMessage(message: UserMessage): ActionDispatcher {
  return (dispatch) => {
    clearTimeout(clearMessageTimer)
    clearMessageTimer = setTimeout(() => {
      dispatch(workActions.removeMessage())
    }, MESSAGE_TIMEOUT)
    dispatch(workActions.addMessage(message))
  }
}

export function showSuccessMessage(message: string) {
  return showMessage({
    type: 'success',
    text: message
  })
}

export function showErrorMessage(message: string) {
  return showMessage({
    type: 'danger',
    text: message
  })
}

export function showDefaultApiErrorMessage(): ActionDispatcher {
  return (dispatch) => {
    dispatch(
      showMessage({
        type: 'danger',
        text: translate(
          'An error occurred while accessing the API. Please try logging in again, and if the error persists please contact an administrator to resolve this.'
        )
      })
    )
  }
}

export const workThunks = {
  showErrorMessage,
  showSuccessMessage,
  showDefaultApiErrorMessage
}
