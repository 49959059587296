import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useLayoutEffect } from 'react';
import { browserHistory } from '../browserHistory';
const initRouter = {
    navigator: browserHistory,
    location: browserHistory.location
};
export const ROUTER_CONTEXT = createContext(initRouter);
export const HISTORY_CONTEXT = createContext(browserHistory);
export function Router({ children }) {
    const historyRef = React.useRef(browserHistory);
    const history = historyRef.current;
    const [state, dispatch] = React.useReducer((_, action) => action, {
        action: history.action,
        location: history.location
    });
    // return to clean up listener
    useLayoutEffect(() => history.listen(dispatch), [history]);
    return (_jsx(ROUTER_CONTEXT.Provider, Object.assign({ value: {
            action: state.action,
            location: state.location,
            navigator: history
        } }, { children: _jsx(HISTORY_CONTEXT.Provider, Object.assign({ value: history }, { children: children })) })));
}
