var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox';
import { themeSpace } from '../Theme';
const StyledRadioGroup = styled.div `
  & > :not(:last-child) {
    margin-bottom: ${themeSpace(1)};
  }
`;
function Radio(_a) {
    var { name = '', value, checked, onChange } = _a, rest = __rest(_a, ["name", "value", "checked", "onChange"]);
    return (_jsx(Checkbox, Object.assign({ type: "radio", value: value, name: name, checked: checked, onChange: (val, e) => {
            if (value) {
                onChange && onChange(value, e);
            }
        } }, rest)));
}
Radio.Group = function RadioGroup(_a) {
    var { children, id, value } = _a, radioProps = __rest(_a, ["children", "id", "value"]);
    return (_jsx(StyledRadioGroup, Object.assign({ "data-testid": "radio-group" }, { children: React.Children.map(children, (child) => React.cloneElement(child, Object.assign(Object.assign(Object.assign({}, radioProps), child.props), { name: child.props.name || id, checked: value === child.props.value }))) })));
};
export { Radio };
