var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { complement, compose, isEmpty, equals, identity, always, juxt } from '@exivity/fp';
import { useToggle } from '@exivity/hooks';
import { SelectInput } from '../Input';
import { useFieldContext } from '../Field/context';
import { findActiveNode } from '../SelectList/utils';
import { renderSelectList, isMultiple } from '../SelectList';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
export const SelectDropdown = styled(Dropdown) `
  position: relative;
  width: 100%;

  .dropdown__drop {
    min-width: 100%;
    padding: ${themeSpace(1)} 0;

    .select__searchbar {
      margin-top: 0;
    }
  }
`;
const isNotEmpty = complement(isEmpty);
const alwaysArray = always([]);
export function Select(_a) {
    var { name, value, id, inputRef, className, data = [], onChange, required, disabled, multiple = false, searchable = false, categorized, placeholder = translate('Choose an option'), labelAccessor = identity, valueAccessor = identity, renderIcon, getChildNodes, initExpandedState, onClear, clearText, warning, actions } = _a, sizes = __rest(_a, ["name", "value", "id", "inputRef", "className", "data", "onChange", "required", "disabled", "multiple", "searchable", "categorized", "placeholder", "labelAccessor", "valueAccessor", "renderIcon", "getChildNodes", "initExpandedState", "onClear", "clearText", "warning", "actions"]);
    const [isOpen, toggleOpen] = useToggle(false);
    const { id: fieldId } = useFieldContext();
    const activeItem = useMemo(() => {
        if (multiple || value === undefined)
            return;
        return findActiveNode(getChildNodes || alwaysArray, compose(equals(value), valueAccessor), data);
    }, [value, getChildNodes, valueAccessor, data, multiple]);
    return (_jsx(SelectDropdown, Object.assign({ className: className, disabled: disabled, reference: _jsx(SelectInput, Object.assign({}, sizes, { id: id || fieldId, required: required, name: name, warning: warning, value: multiple && isMultiple(value) && isNotEmpty(value)
                ? translate('{count} selected', value.length)
                : activeItem
                    ? labelAccessor(activeItem)
                    : '', placeholder: placeholder, disabled: disabled })), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: renderSelectList({
            actions,
            multiple,
            searchable,
            categorized,
            value,
            data,
            valueAccessor,
            labelAccessor,
            renderIcon,
            getChildNodes,
            initExpandedState,
            clearText,
            onChange: (value) => {
                onChange && onChange(value);
                !multiple && toggleOpen();
            },
            onClear: onClear ? juxt([onClear, toggleOpen]) : undefined
        }) })));
}
