import { useEffect, useRef } from 'react';
export function useDocEventListener(eventName, handler) {
    var savedHandler = useRef();
    useEffect(function () {
        savedHandler.current = handler;
    }, [handler]);
    useEffect(function () {
        var isSupported = document && document.addEventListener;
        if (!isSupported)
            return;
        var eventListener = function (event) {
            return savedHandler.current && savedHandler.current(event);
        };
        document.addEventListener(eventName, eventListener);
        return function () {
            document.removeEventListener(eventName, eventListener);
        };
    }, [eventName]);
}
