var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { MdClose, MdInfoOutline, MdOutlineWarningAmber, MdOutlineDangerous, MdCheckCircleOutline } from 'react-icons/md';
import { matchColorFromPurpose } from '../Theme';
import { Icon } from '../Icon';
export function Alert(_a) {
    var { title, children, icon, closable, onClosed } = _a, purposes = __rest(_a, ["title", "children", "icon", "closable", "onClosed"]);
    const [visible, setVisible] = useState(true);
    if (!visible) {
        return null;
    }
    const canClose = closable === undefined ? purposes.danger === false : closable;
    const purposeIcon = purposes.success ? (_jsx(MdCheckCircleOutline, { role: "img" })) : purposes.warning ? (_jsx(MdOutlineWarningAmber, { role: "img" })) : purposes.danger ? (_jsx(MdOutlineDangerous, { role: "img" })) : (_jsx(MdInfoOutline, { role: "img" }));
    return (_jsxs(AlertContent, Object.assign({}, purposes, { children: [_jsx(AlertIcon, Object.assign({}, purposes, { children: icon ? icon : purposeIcon })), _jsxs(AlertText, { children: [title && _jsx(AlertTitle, Object.assign({}, purposes, { children: title })), children] }), (canClose || onClosed) && (_jsx(CloseIcon, Object.assign({ role: "button", "aria-label": "close", onClick: () => {
                    onClosed && onClosed();
                    setVisible(false);
                } }, { children: _jsx(MdClose, {}) })))] })));
}
const AlertContent = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.8rem;
  border-left: 3px solid ${matchColorFromPurpose(400)};
  border-radius: 4px;
  color: ${matchColorFromPurpose(600)};
  background-color: ${matchColorFromPurpose(100)};
`;
const AlertText = styled.div `
  flex-grow: 1;
`;
const AlertTitle = styled.h3 `
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  color: ${matchColorFromPurpose(800)};
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const CloseIcon = styled(Icon) `
  flex-shrink: 1;
  cursor: pointer;
  position: relative;
  left: -3px;
`;
const AlertIcon = styled.div `
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color: ${matchColorFromPurpose(500)};
  font-size: 24px;
`;
