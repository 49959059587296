const isFindRecords = (queryExpression) => {
    return queryExpression.op === 'findRecords';
};
export const isRelationshipQuery = (queryExpression) => {
    return (queryExpression.op === 'findRelatedRecord' ||
        queryExpression.op === 'findRelatedRecords');
};
export const getQueryType = (queryExpression) => {
    if (isFindRecords(queryExpression)) {
        return queryExpression.type;
    }
    else {
        return queryExpression.record.type;
    }
};
export const extractId = (identifier) => { var _a; return typeof identifier === 'string' ? identifier : (_a = identifier === null || identifier === void 0 ? void 0 : identifier.id) !== null && _a !== void 0 ? _a : null; };
export function applyMixins(derivedCtor, baseCtors) {
    baseCtors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            derivedCtor.prototype[name] = baseCtor.prototype[name];
        });
    });
}
