import { EnumLiteralsOf } from '.'

export const UserGroupPermission = Object.freeze({
  All: '*' as 'ALL',
  ViewAudit: 'view_audit' as 'VIEW_AUDIT',
  ViewBudgets: 'view_budgets' as 'VIEW_BUDGETS',
  ViewCogs: 'view_cogs' as 'VIEW_COGS',
  ViewLogs: 'view_logs' as 'VIEW_LOGS',
  ViewReports: 'view_reports' as 'VIEW_REPORTS',
  ManageAccounts: 'manage_accounts' as 'MANAGE_ACCOUNTS',
  ManageDatasets: 'manage_datasets' as 'MANAGE_DATASETS',
  ManageDataSources: 'manage_data_sources' as 'MANAGE_DATA_SOURCES',
  ManageMetadataDefinitions:
    'manage_metadata_definitions' as 'MANAGE_METADATA_DEFINITIONS',
  ManageFiles: 'manage_files' as 'MANAGE_FILES',
  ManageReports: 'manage_reports' as 'MANAGE_REPORTS',
  ManageServices: 'manage_catalogue' as 'MANAGE_CATALOGUE',
  ManageSettings: 'manage_settings' as 'MANAGE_SETTINGS',
  ManageUsers: 'manage_users' as 'MANAGE_USERS',
  ManageWorkflows: 'manage_workflows' as 'MANAGE_WORKFLOWS'
})

export type UserGroupPermission = Readonly<
  EnumLiteralsOf<typeof UserGroupPermission>
>
