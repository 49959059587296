import styled from 'styled-components';
import { fromTheme, themeSpace } from '../Theme';
import { toRgbString } from '../utils';
export const Insights = styled.div `
  display: grid;
  height: 100%;
  width: 100%;
  row-gap: ${themeSpace(1)};
  column-gap: 5px;
  grid-template-columns: 300px;
  grid-template-areas:
    'breadcrumb breadcrumb'
    'pie timeline'
    'table table';

  > *:nth-child(1) {
    grid-area: breadcrumb;
  }

  > *:nth-child(2) {
    grid-area: pie;
    height: 425px;
    box-shadow: 0 1px 0
      rgba(
        ${fromTheme((theme) => toRgbString(theme.global.purposes.primary))},
        0.4
      );
  }

  > *:nth-child(3) {
    grid-area: timeline;
    height: 425px;
  }

  > *:nth-child(4) {
    grid-area: table;
  }

  @media only screen and (max-height: 720px) {
    grid-template-columns: 200px;
    > *:nth-child(2),
    > *:nth-child(3) {
      height: 350px;
    }
  }

  @media only screen and (max-width: 1000px) {
    > *:nth-child(2) {
      display: none;
    }

    grid-template-areas:
      'breadcrumb breadcrumb'
      'timeline timeline'
      'table table';
  }
`;
