import { useContext, useCallback } from 'react';
import { allPass, compose, curry, equals, includes, isEmpty, map, reject } from '@exivity/fp';
import { PermissionContext } from '../DataLayerProvider';
import { UserGroupPermission } from '../resources/resources.generated';
const includesAll = includes(UserGroupPermission.All);
export const allPermissionsExceptAll = reject(equals(UserGroupPermission.All), Object.values(UserGroupPermission));
const comparePermissions = compose(allPass, map(includes));
const hasAllRights = comparePermissions(allPermissionsExceptAll);
export const userHasPermission = curry((userPermissions, requiredPermissions) => {
    const hasRequiredRights = comparePermissions(requiredPermissions);
    return includesAll(requiredPermissions)
        ? hasAllRights(userPermissions)
        : isEmpty(requiredPermissions) ||
            hasRequiredRights(userPermissions) ||
            includesAll(userPermissions);
});
const noPermissions = [];
const PERMISSIONS_MAP = {
    user: [UserGroupPermission.ManageUsers],
    usergroup: [UserGroupPermission.ManageUsers],
    dataset: [UserGroupPermission.ManageDatasets],
    datasetday: [UserGroupPermission.ManageDatasets],
    reportdefinition: [UserGroupPermission.ManageReports],
    reportstatus: [UserGroupPermission.ManageReports],
    account: [UserGroupPermission.ManageAccounts],
    metadatadefinition: [UserGroupPermission.ManageMetadataDefinitions],
    metadata: [UserGroupPermission.ManageAccounts],
    service: [UserGroupPermission.ManageServices],
    servicecategory: [UserGroupPermission.ManageServices],
    rate: [UserGroupPermission.ManageServices],
    ratetier: [UserGroupPermission.ManageServices],
    adjustment: [UserGroupPermission.ManageServices],
    workflow: [UserGroupPermission.ManageWorkflows],
    workflowrun: [UserGroupPermission.ManageWorkflows],
    workflowsteplog: [UserGroupPermission.ManageWorkflows],
    workflowstep: [UserGroupPermission.ManageWorkflows],
    workflowschedule: [UserGroupPermission.ManageWorkflows],
    notificationsubscription: noPermissions,
    notificationchannel: noPermissions,
    budget: [UserGroupPermission.ManageAccounts],
    budgetrevision: [UserGroupPermission.ManageAccounts],
    budgetitem: [UserGroupPermission.ManageAccounts],
    environment: [UserGroupPermission.ManageSettings],
    variable: [UserGroupPermission.ManageSettings],
    servicesubscription: [UserGroupPermission.ManageServices],
    licence: [UserGroupPermission.ManageSettings],
    configuration: noPermissions,
    cupr: [UserGroupPermission.ViewBilling],
    extractor: [UserGroupPermission.ManageSettings],
    transformer: [UserGroupPermission.ManageSettings],
    log: [UserGroupPermission.ViewLogs],
    cache: [UserGroupPermission.ManageSettings],
    'third-party-licences': [UserGroupPermission.ManageSettings],
    job: [UserGroupPermission.ManageDataSources]
};
export function getResourcePermissions(type) {
    return PERMISSIONS_MAP[type];
}
export const useUserHasPermissionFn = () => {
    const userPermissions = useContext(PermissionContext);
    return useCallback((requiredPermissions) => userHasPermission(userPermissions, requiredPermissions), [userPermissions]);
};
export const useUserHasPermission = (requiredPermissions) => {
    const userPermissions = useContext(PermissionContext);
    return userHasPermission(userPermissions, requiredPermissions);
};
