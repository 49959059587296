import React from "react";
import PropTypes from "prop-types";
/**
 * @type {React.ComponentType<any>}
 */
const Column = ({ item, valueField }) => (
  <div title={item[valueField]}>{item[valueField]}</div>
);

Column.propTypes = {
  item: PropTypes.object /* Not supported yet */,
  valueField: PropTypes.string,
};

Column.defaultProps = {
  item: null,
  valueField: null,
  label: null,
  width: null,
  flexGrow: 1,
  expandable: false,
  expanded: false,
  searchable: false,
  filterable: false,
  checkable: false,
  className: null,
  headerClassName: null,
  cellRenderer: null,
};

export default Column;
