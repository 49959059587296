import styled, { css } from 'styled-components';
import { matchThemeProp, themeColor, themeGlobal, themePurpose } from '../Theme';
import { globalFont, injectIf, toRgbString } from '../utils';
export const regular = css `
  box-sizing: border-box;
  ${globalFont};
  font-size: ${matchThemeProp((theme) => theme.global.sizes)}rem;

  --border-width: 2px;

  display: block;
  width: 100%;
  min-width: 100px;
  padding: calc(0.5em - var(--border-width)) 0.5em;
  border-radius: ${themeGlobal('borderRadius')}px;
  outline: 0;

  border: var(--border-width) solid ${themeColor('lightGray')};
  background-color: ${themeColor('lightGray')};

  --focus-color: ${matchThemeProp((theme) => theme.global.purposes, {
    modifier: toRgbString
})};

  /* &:hover {
    border-bottom: var(--border-width) solid rgba(var(--focus-color), 1);
  } */

  &:focus {
    border: var(--border-width) solid rgba(var(--focus-color), 1);
  }

  ${injectIf('danger', css `
      border: var(--border-width) solid ${themePurpose('danger')};
    `)}

  ${injectIf('warning', css `
      border: var(--border-width) solid ${themePurpose('warning')};
    `)}

  &::placeholder {
    color: currentcolor;
    opacity: 0.5;
  }

  &[disabled] {
    cursor: not-allowed;
    border-color: rgba(128, 128, 128, 0.2);
    box-shadow: inset 0 0 999em rgba(128, 128, 128, 0.2);
  }
`;
export const outlined = ({ outlined = false }) => outlined &&
    css `
    --border-width: 1px;

    background-color: unset;
    border: var(--border-width) solid
      ${matchThemeProp((theme) => theme.global.purposes)};

    &:hover,
    &:focus {
      border: var(--border-width) solid ${themeColor('dark')};
    }
  `;
export const StyledInput = styled.input `
  ${regular}
  ${outlined}
`;
