import { MdFeed } from 'react-icons/md'
import { AppBar, Tooltip } from '@exivity/ui'
import { translate } from '@exivity/translations'
import styled from 'styled-components'
import { MdErrorOutline } from 'react-icons/md'
import { useAppNeedsSync, useReportDefinitionStatusAlert } from '../../jobs/components/JobsProvider'

const IconContainer = styled.div`
  position: relative;
`

const Badge = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 6px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 1.2em;
`

export function StatusLink() {
  const appNeedsSync = useAppNeedsSync()
  const reportAlert = useReportDefinitionStatusAlert()
  
  return (
    <Tooltip content={translate('Status feed')}>
      <IconContainer>
        {(appNeedsSync || reportAlert) && (
          <Badge><MdErrorOutline /></Badge>
        )}
        <AppBar.Button to="status" icon={<MdFeed />}>
          {translate('Status')}
        </AppBar.Button>
      </IconContainer>
    </Tooltip>
  )
}
