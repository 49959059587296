import { useContext, useEffect, useRef, useCallback } from 'react';
import { ROUTER_CONTEXT } from '../Router';
import { resolvePath } from '../utils';
import { getPathname } from '../helpers';
import { useRoute } from '../useRoute';
export function useNavigate() {
    const navigator = useContext(ROUTER_CONTEXT).navigator;
    const pathname = getPathname(useRoute().route);
    const activeRef = useRef(false);
    useEffect(() => {
        activeRef.current = true;
    });
    return useCallback((to, options = {}) => {
        if (activeRef.current) {
            if (typeof to === 'number') {
                navigator.go(to);
            }
            else {
                const path = resolvePath(to, pathname);
                const fn = options.replace ? navigator.replace : navigator.push;
                fn(path, options.state);
            }
        }
        else {
            console.warn('You should call navigate() in a useEffect, not when ' +
                'your component is first rendered.');
        }
    }, [navigator, pathname]);
}
