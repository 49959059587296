import { useMemo } from 'react';
import { useStyledTheme } from '../hooks';
export function useSizeProp({ tiny, small, large, huge }) {
    const sizesProps = { tiny, small, large, huge };
    const sizes = useStyledTheme((theme) => theme.global.sizes);
    return useMemo(() => {
        const selectedSizes = Object.keys(sizes).filter((size) => sizesProps[size]);
        if (selectedSizes.length > 1) {
            throw new Error('More than one size prop is provided. Please provide only one.');
        }
        return selectedSizes[0];
    }, [tiny, small, large, huge]);
}
