import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { translate } from '@exivity/translations'

import {
  fetchRunLogs,
  runExtractor,
  runTransformer,
  runTransformerPreview,
  Log
} from './thunks'
import { selectors } from './selectors'

export const DEFAULT_ESCAPE_CHAR = '"'
export const ESCAPE_OPTIONS = [DEFAULT_ESCAPE_CHAR, '\\']

type PreviewError = null | {
  message: string
  line: number
}

const initialState = {
  use: {
    runId: null as string | null,
    runStatus: 'Idle' as string,
    runLog: null as Log | null
  },
  previewer: {
    runStatus: 'Idle' as 'running' | 'idle'
  },
  transcript: {
    runId: null as string | null,
    runStatus: 'Idle' as string,
    runLog: null as Log | null,
    previewData: [] as Record<string, string>[],
    previewColumns: [] as string[],
    previewLog: [],
    environmentId: null as string | null,
    dataset: '' as string,
    escapeChar: DEFAULT_ESCAPE_CHAR as string,
    limit: 1000 as number,
    breakAfter: 100 as number,
    date: '' as string,
    useCustomDataset: false as boolean,
    previewError: null as PreviewError
  }
} as const

export const { actions, reducer } = createSlice({
  name: 'datasources',
  initialState,
  reducers: {
    updatePreviewDataset(state, action: PayloadAction<string>) {
      state.transcript.dataset = action.payload
    },
    updatePreviewEnvironmentId(state, action: PayloadAction<string | null>) {
      state.transcript.environmentId = action.payload
    },
    updatePreviewEscapeChar(state, action: PayloadAction<string>) {
      state.transcript.escapeChar = action.payload
    },
    updatePreviewLimit(state, action: PayloadAction<number>) {
      state.transcript.limit = action.payload
    },
    updatePreviewBreakAfter(state, action: PayloadAction<number>) {
      state.transcript.breakAfter = action.payload
    },
    updatePreviewDate(state, action: PayloadAction<string>) {
      state.transcript.date = action.payload
    },
    updateUseCustomDataset(state, action: PayloadAction<boolean>) {
      state.transcript.useCustomDataset = action.payload
    },
    updatePreviewError(state, action: PayloadAction<PreviewError>) {
      state.transcript.previewError = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(runExtractor.rejected, (state, action) => {
        state.use.runId = action.meta.arg.id
        state.use.runStatus = action.payload as string
      })
      .addCase(runExtractor.pending, (state) => {
        state.use.runLog = null
        state.use.runId = null
        state.use.runStatus = translate('Running extractor...')
      })
      .addCase(runExtractor.fulfilled, (state, action) => {
        state.use.runId = action.meta.arg.id
        state.use.runStatus = action.payload
      })
      .addCase(runTransformer.rejected, (state, action) => {
        state.transcript.runId = action.meta.arg.id
        state.transcript.runStatus = action.payload as string
      })
      .addCase(runTransformer.pending, (state) => {
        state.transcript.runId = null
        state.transcript.runLog = null
        state.transcript.runStatus = translate('Running transformer...')
      })
      .addCase(runTransformer.fulfilled, (state, action) => {
        state.transcript.runId = action.meta.arg.id
        state.transcript.runStatus = JSON.stringify(action.payload, null, 4)
      })
      .addCase(fetchRunLogs.pending, (state, action) => {
        state[action.meta.arg.component].runLog = null
      })
      .addCase(fetchRunLogs.fulfilled, (state, action) => {
        state[action.meta.arg.component].runLog = action.payload
      })
      .addCase(runTransformerPreview.pending, (state) => {
        state.transcript.runId = null
        state.transcript.runStatus = translate('Running previewer...')
        state.previewer.runStatus = 'running'
        state.transcript.previewError = null
        state.transcript.previewData = []
        state.transcript.previewColumns = []
      })
      .addCase(runTransformerPreview.rejected, (state, action) => {
        state.transcript.runId = action.meta.arg
        state.transcript.runStatus =
          action.payload || translate('Previewer failed')
        state.previewer.runStatus = 'idle'
        state.transcript.previewError = null
        state.transcript.previewData = []
        state.transcript.previewColumns = []
      })
      .addCase(runTransformerPreview.fulfilled, (state, action) => {
        state.transcript.runId = action.meta.arg
        state.transcript.runStatus = translate('Previewer finished...')
        state.previewer.runStatus = 'idle'
        state.transcript.previewData = action.payload.data
        state.transcript.previewColumns = action.payload.columns
      })
  }
})

export const datasourcesReducer = reducer
export const datasourcesActions = actions
export const datasourcesThunks = {
  fetchRunLogs,
  runExtractor,
  runTransformer,
  runTransformerPreview
}
export const datasourcesSelectors = selectors
