import {
  UpdateAllLogs,
  UpdateShortLogs,
  DeleteShortLog
} from '../../actions/data/logs/logs'

interface LogLine {
  date: number
  level: number
  message: number
  metadata: {
    thread?: string
    own?: number
    comulative?: number
  }
}

export interface Log {
  filename: string
  created: number
  metadata:
    | {
        endswell: boolean
      }
    | any[]
  lines: LogLine[]
}

export interface LogsState {
  shortlogs: {
    use?: { [key: string]: Log }
    transcript?: { [key: string]: Log }
  }
  logs: {
    use?: Log
    transcript?: Log
    edify?: Log
    proximity?: Log
    pigeon?: Log
  }
}

const initialState: LogsState = {
  shortlogs: {},
  logs: {}
}

type LogsAction = UpdateAllLogs | UpdateShortLogs | DeleteShortLog

function deleteShortlogs(state = initialState, action: DeleteShortLog) {
  const shortLogs = { ...(state.shortlogs as any)[action.component] }

  if (Object.prototype.hasOwnProperty.call(shortLogs, action.name)) {
    delete shortLogs[action.name]
  }

  return shortLogs
}

const logs = (state = initialState, action: LogsAction) => {
  switch (action.type) {
    case UpdateAllLogs.type:
      return {
        ...state,
        logs: {
          ...state.logs,
          [action.component]: action.data
        }
      }

    case UpdateShortLogs.type:
      return {
        ...state,
        shortlogs: {
          ...state.shortlogs,
          [action.component]: {
            ...(state.shortlogs as any)[action.component],
            [action.name]: action.data
          }
        }
      }

    case DeleteShortLog.type:
      return {
        ...state,
        shortlogs: {
          ...state.shortlogs,
          [action.component]: deleteShortlogs(state, action)
        }
      }

    default:
      return state
  }
}

export default logs
