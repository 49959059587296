import { useCallback, useMemo, useReducer } from 'react';
import { constrainRangeToBounds, isEmptyRange, isPartialRange, isCompleteRange } from '../DateRange';
export function useDateRange(value, reducer, options) {
    const rangeReducer = useCallback((range, action) => {
        const newRange = reducer(range, action, options);
        return constrainRangeToBounds(newRange, options.bounds);
    }, [options, reducer]);
    const [range, dispatch] = useReducer(rangeReducer, value || [null, null]);
    return useMemo(() => {
        return [
            range,
            {
                selectDateAsRange: (date) => dispatch({ type: 'SELECT_SINGLE_UNIT_AS_RANGE', date }),
                selectDate: (date) => {
                    if (isEmptyRange(range))
                        dispatch({ type: 'SELECT_START', date });
                    if (isPartialRange(range))
                        dispatch({ type: 'SELECT_END', date });
                    if (isCompleteRange(range))
                        dispatch({ type: 'SELECT_START', date });
                },
                resetRange: () => dispatch({ type: 'RESET_RANGE' })
            }
        ];
    }, [range]);
}
