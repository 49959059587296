var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { useToggle } from '@exivity/hooks';
import { find, compose, equals, identity } from '@exivity/fp';
import { MdMenu, MdKeyboardArrowDown } from 'react-icons/md';
import { Dropdown } from '../Dropdown';
import { themeSpace, themeColor, themePriority, themePalette } from '../Theme';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { SelectList } from '../SelectList';
import { NavIcon } from './NavIcon';
export const NavSelectDropdown = styled(Dropdown) `
  width: 100%;
  transform: translateX(-45px);

  .dropdown__drop {
    width: 245px;
    padding: ${themeSpace(1)} 0;
    background-color: #444;
    border-radius: 3px;

    color: ${themePalette('neutral', 300)};

    ${Text} {
      font-size: 0.8125rem;
    }

    li {
      &:hover {
        background-color: ${themePalette('primary', 800)};
      }

      &:focus {
        background-color: ${themePalette('primary', 800)};
      }
    }
  }
`;
const StyledLi = styled.li `
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${themePalette('neutral', 900)};
  overflow: visible;
  z-index: ${themePriority(3)};

  padding: 0 ${themeSpace(2)};
  padding-bottom: 10px;

  .nav__icon {
    pointer-events: none;
  }
`;
const Opacity = styled.div `
  position: absolute;
  height: 40px;
  width: 245px;
  background-color: ${themeColor('white')};
  opacity: 0.15;
  transform: scaleX(0.2);
  transform-origin: left;
  transition-property: opacity, transform;
  transition-duration: 200ms, 200ms;
  transition-timing-function: ease-in-out;
  border-radius: 3px;
`;
const Container = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 245px;
  border-radius: 3px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${themeColor('white')};

    ${Opacity} {
      opacity: 0.3;
      transition: opacity 200ms ease-in-out;
    }
  }

  .nav-select__input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transform: translateX(40px);
    color: ${themeColor('white')};

    input {
      color: ${themeColor('white')};
      background-color: transparent;
      border: none;
      outline: none;
      pointer-events: none;
    }

    span {
      font-size: 20px;
      padding: 0 5px;
    }
  }
`;
const offset = [{
        name: 'offset',
        options: {
            offset: [0, 1]
        }
    }];
export function NavFilter(_a) {
    var { value, className, data = [], onChange, placeholder = translate('Choose an option'), labelAccessor = identity, valueAccessor = identity } = _a, restSelectListProps = __rest(_a, ["value", "className", "data", "onChange", "placeholder", "labelAccessor", "valueAccessor"]);
    const [isOpen, toggleOpen] = useToggle(false);
    const activeItem = value
        ? find(compose(equals(value), valueAccessor), data)
        : '';
    return (_jsxs(StyledLi, Object.assign({ tabIndex: -1, className: className }, { children: [_jsx(NavIcon, { children: _jsx(MdMenu, {}) }), _jsx(NavSelectDropdown, Object.assign({ placement: "bottom-start", modifiers: offset, reference: _jsx(_Fragment, { children: _jsxs(Container, Object.assign({ tabIndex: -1, className: "nav-select__trigger" }, { children: [_jsx(Opacity, { className: "nav-select__opacity" }), _jsxs("span", Object.assign({ className: "nav-select__input" }, { children: [_jsx("input", { disabled: true, placeholder: placeholder, value: activeItem
                                            ? labelAccessor(activeItem) || ''
                                            : '' }), _jsx(Icon, { children: _jsx(MdKeyboardArrowDown, {}) })] }))] })) }), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: _jsx(SelectList, Object.assign({}, restSelectListProps, { value: value, data: data, valueAccessor: valueAccessor, labelAccessor: labelAccessor, onChange: (value) => {
                        onChange && onChange(value);
                        toggleOpen();
                    } })) }))] })));
}
