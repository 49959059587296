var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { map, flip, compose, join, concat, replace } from '@exivity/fp';
import { injectIf } from '../utils';
import { replacer } from './replacer';
import { themePurpose } from '../Theme';
const flippedConcat = flip(concat);
const parenthesize = compose(concat('('), flippedConcat(')'));
const combinedRegex = compose(join('|'), map(parenthesize));
const escapeRegExpFn = replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
const escapedCombinedRegex = compose(combinedRegex, map(escapeRegExpFn));
const subtleHighlight = css `
  background-color: #f8f8f8;
`;
export const StyledHighlight = styled.span `
  background-color: #fff68c;
  padding: 2px 0;

  ${injectIf('subtle', subtleHighlight)}
  ${injectIf('active', css `
      color: ${themePurpose('brand')};
    `)}
`;
const Span = styled.span `
  ${injectIf('active', css `
      color: ${themePurpose('brand')};
    `)}
`;
export const makeHighlighter = (textToMatch, options = { subtle: false, autoEscape: false, active: false }) => replacer(options.autoEscape
    ? escapedCombinedRegex(textToMatch)
    : combinedRegex(textToMatch), (match, count) => (_jsx(StyledHighlight, Object.assign({ subtle: options.subtle, active: options.active }, { children: match }), count)));
export function Highlight(_a) {
    var { className, autoEscape = true, textToMatch, children } = _a, rest = __rest(_a, ["className", "autoEscape", "textToMatch", "children"]);
    if (textToMatch === null || textToMatch === void 0 ? void 0 : textToMatch.some(Boolean)) {
        const highlightText = makeHighlighter(textToMatch, {
            autoEscape,
            subtle: !!rest.subtle,
            active: !!rest.active
        });
        return (_jsx(Span, Object.assign({ className: className }, rest, { children: highlightText(children) })));
    }
    if (textToMatch)
        return _jsx(Span, Object.assign({}, rest, { children: children }));
    return (_jsx(StyledHighlight, Object.assign({ className: className }, rest, { children: children })));
}
