import styled from 'styled-components';
import { position } from 'styled-system';
import { matchThemeProp } from '../Theme';
export const Icon = styled.span `
  ${position}
  position: relative;
  height: 1em;
  line-height: 1em;
  text-transform: none;
  color: ${matchThemeProp((theme) => theme.global.purposes, {
    defaultValue: 'inherit'
})};
`;
