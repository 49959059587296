import styled, { css } from 'styled-components';
import { Block } from '../Block';
export const FlexItem = styled(Block) `
  ${(props) => props.order &&
    css `
      order: ${props.order};
    `}
  ${(props) => props.grow &&
    css `
      flex-grow: ${props.grow};
    `}
  ${(props) => props.shrink &&
    css `
      flex-shrink: ${props.shrink};
    `}
  ${(props) => props.basis &&
    css `
      flex-basis: ${props.basis};
    `}
  ${(props) => props.alignSelf &&
    css `
      align-self: ${props.alignSelf};
    `}
`;
FlexItem.displayName = 'FlexItem';
