var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components';
import { MdLabelOutline } from 'react-icons/md';
import { useSprings, animated } from '@react-spring/web';
import { Icon } from '../Icon';
const StyledArrow = styled.div `
  position: relative;
  box-sizing: border-box;
  display: flex;
  transition: all 1s ease-in;

  &:hover {
    .ex-breadcrumbs {
      &--root,
      &--arrowtop,
      &--arrowbottom,
      &--main {
        background: #e1e1e1;
      }
    }
  }

  ${({ active }) => active &&
    css `
      .ex-breadcrumbs {
        &--root,
        &--arrowtop,
        &--arrowbottom,
        &--main {
          background: #d0e9f0;
        }
      }
    `}

  .ex-breadcrumbs {
    &--root {
      font-size: 25px;
      color: #989898;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      padding: 0 10px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &--arrow {
      display: flex;
      flex-direction: column;

      &:nth-child(1) {
        position: relative;
        left: 3px;

        .ex-breadcrumbs--arrowtop {
          border-top-left-radius: 3px;
        }

        .ex-breadcrumbs--arrowbottom {
          border-bottom-left-radius: 3px;
        }
      }

      &:nth-child(3) {
        position: relative;
        left: -3px;

        .ex-breadcrumbs--arrowtop {
          border-top-right-radius: 3px;
        }

        .ex-breadcrumbs--arrowbottom {
          border-bottom-right-radius: 3px;
        }
      }

      &top {
        height: 20px;
        width: 12px;
        transform: skew(15deg);
      }

      &bottom {
        height: 20px;
        width: 12px;
        transform: skew(-15deg);
      }
    }

    &--main {
      z-index: 2;
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
`;
const StyledBreadcrumbs = styled.ul `
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    display: inline-block;
    float: left;
  }
`;
const Arrow = (_a) => {
    var { root, children } = _a, rest = __rest(_a, ["root", "children"]);
    return (_jsxs(StyledArrow, Object.assign({}, rest, { children: [root ? (_jsx("div", Object.assign({ className: "ex-breadcrumbs--root" }, { children: _jsx(Icon, { children: _jsx(MdLabelOutline, {}) }) }))) : (_jsxs("div", Object.assign({ className: "ex-breadcrumbs--arrow" }, { children: [_jsx("div", { className: "ex-breadcrumbs--arrowtop" }), _jsx("div", { className: "ex-breadcrumbs--arrowbottom" })] }))), _jsx("div", Object.assign({ className: "ex-breadcrumbs--main" }, { children: children })), _jsxs("div", Object.assign({ className: "ex-breadcrumbs--arrow" }, { children: [_jsx("div", { className: "ex-breadcrumbs--arrowtop" }), _jsx("div", { className: "ex-breadcrumbs--arrowbottom" })] }))] })));
};
export const Breadcrumbs = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const [springs] = useSprings(React.Children.count(children), () => ({
        from: { transform: 'translateX(-30px)', opacity: 0 },
        to: { transform: 'translateX(0px)', opacity: 1 }
    }), []);
    return (_jsx(StyledBreadcrumbs, Object.assign({}, rest, { children: springs.map((props, index) => (_jsx(animated.li, Object.assign({ style: props }, { children: _jsx(Arrow, Object.assign({ root: index === 0, active: React.Children.count(children) - 1 === index }, { children: React.Children.toArray(children)[index] })) }), index))) })));
};
