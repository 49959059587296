import { queries, useCacheQuery } from '@exivity/data-layer'
import { useMemo } from 'react'
import { translate } from '@exivity/translations'

export function useUserGroupOptions() {
  const usergroups = useCacheQuery(queries.findAll('usergroup'))

  return useMemo(
    () => [
      {
        id: null,
        attributes: {
          name: translate('Do not automatically create new users')
        }
      },
      ...usergroups
    ],
    [usergroups]
  )
}
