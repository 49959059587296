import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ActionList } from '../ActionList';
import { Button } from '../Button';
export function _CrudList({ data, onCreate, onDelete, keyExtractor, min, max, children }) {
    const minimumMatched = !!min && min >= data.length;
    const maximumMatched = !!max && max <= data.length;
    return (_jsx(ActionList, Object.assign({ keyExtractor: keyExtractor, data: data, itemActions: ({ item, index }) => (_jsx(Button.Delete, { type: "button", disabled: minimumMatched, round: true, small: true, onClick: () => onDelete({ index, item, data }) })), listActions: () => (_jsx(Button.Add, { type: "button", disabled: maximumMatched, round: true, small: true, onClick: () => onCreate({ data }) })) }, { children: children })));
}
export const CrudList = React.memo(_CrudList);
