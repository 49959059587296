import Color from 'color';
export const hexOpacity = (hex, opacity) => {
    const num = Math.floor(opacity * 255);
    return `${hex}${num.toString(16)}`;
};
// return 9 color palette given a color (100 - 900 as keys)
export const getPalette = (colorNumber) => {
    const darkColors = getColorsList(4, 50, 'black', 0, 14, colorNumber);
    const lightColors = getColorsList(4, 80, 'white', 0, 20, colorNumber).reverse();
    const midColor = numberToHex(colorNumber);
    const colors = [...lightColors, midColor, ...darkColors];
    const palette = colors.map((color, index) => {
        const i = (index + 1) * 100;
        return [i, color];
    });
    return Object.fromEntries(palette);
};
export const getColorsList = (colorsAmount, colorsShiftAmount, mixColor, rotate, saturation, mainColor) => {
    const givenColor = isValidHex(numberToHex(mainColor))
        ? numberToHex(mainColor)
        : errorColor;
    return Array(colorsAmount)
        .fill(0)
        .map((_, index) => Color(givenColor)
        .rotate(((index + 1) / colorsAmount) * -rotate)
        .saturate(((index + 1) / colorsAmount) * (saturation / 100))
        .mix(Color(mixColor), ((colorsShiftAmount / 100) * (index + 1)) / colorsAmount)
        .hex());
};
export const numberToHex = (number) => '#' + number;
export const hexToNumber = (number) => number.substr(1, number.length);
export const errorColor = 'transparent';
export const isValidHex = (color) => {
    if (color.substring(0, 1) === '#')
        color = color.substring(1);
    switch (color.length) {
        case 3:
            return /^[0-9A-F]{3}$/i.test(color);
        case 6:
            return /^[0-9A-F]{6}$/i.test(color);
        case 8:
            return /^[0-9A-F]{8}$/i.test(color);
        default:
            return false;
    }
};
