import styled from 'styled-components';
import { fromTheme } from '../Theme';
export const TreeViewNode = styled.div `
  flex-grow: 1;
`;
export const TreeView = styled.div `
  --spacer: 1px solid rgb(214, 212, 212);
  --nesting: calc(
    ${fromTheme((theme) => theme.space[2])} + ${({ offset = 0 }) => offset}px
  );

  & & {
    margin-left: var(--nesting);
  }

  & & & {
    margin-left: calc(var(--nesting) * 2);
  }

  position: relative;

  border-left: var(--spacer);

  display: flex;
  flex-direction: column;

  &:last-child {
    border-left: none;
  }

  & &:before {
    position: absolute;
    top: 0;
    height: 50%;
    width: var(--nesting);
    content: '';
  }

  & & ${TreeViewNode} {
    position: relative;
    margin-left: var(--nesting);
  }

  & & ${TreeViewNode}:before {
    position: absolute;
    border-bottom: var(--spacer);
    width: var(--nesting);
    height: 50%;
    left: calc(var(--nesting) * -1);
    content: '';
  }

  & &:last-child > ${TreeViewNode}:before {
    border-left: var(--spacer);
  }
`;
