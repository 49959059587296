import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { colorFromPurpose, fromTheme, themeColor, themeSpace } from '../Theme';
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
export const GroupIcon = styled(Icon) `
  font-size: ${fromTheme((theme) => theme.global.sizes.large)}em;
  margin-right: ${fromTheme((theme) => theme.global.baseSpacing * 0.25)}em;
  flex-grow: 0;

  svg {
    vertical-align: middle;
    opacity: 0.5;
  }
`;
export const Separator = styled.hr `
  width: 100%;
  height: 1px;
  background-color: ${colorFromPurpose('secondary', 300)};
  border: 0;
  margin: 12px 10px;
  flex-grow: 1;
`;
const StyledGroupTitle = styled.div `
  ${Heading} {
    cursor: default;
    white-space: nowrap;
    color: ${colorFromPurpose('secondary', 700)};
    font-size: 1em;
    user-select: none;
  }
  :hover {
    ${Heading} {
      color: ${({ collapsible }) => collapsible ? themeColor('darkGray') : colorFromPurpose('secondary', 700)};
    }
  }
`;
export function Title({ children, collapsible }) {
    return (_jsx(StyledGroupTitle, Object.assign({ collapsible: collapsible }, { children: typeof children === 'string' ? _jsx(Heading, { children: children }) : children })));
}
const StyledHeader = styled.div `
  display: flex;
`;
export const Header = ({ children }) => {
    const { toggleCollapsed } = CollapsibleComponent.useCollapsible();
    return _jsx(StyledHeader, Object.assign({ onClick: toggleCollapsed }, { children: children }));
};
export const StyledContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeSpace(3)};
  padding-top: ${themeSpace(2)};
  width: 100%;
  height: 100%;
`;
export const Content = ({ children }) => {
    return (_jsx(CollapsibleComponent.Content, { children: _jsx(StyledContent, { children: children }) }));
};
