import { HookContext, HookType } from '../fetchWithHooks/fetchWithHooks'
import store from '../../store'
import { datasourcesActions } from '../../domains/datasources/state'

export function handleTranscriptError(ctx: HookContext<HookType.Error>) {
  const { response, path } = ctx

  if (path.startsWith('transformers') && typeof response !== 'undefined') {
    response
      .clone()
      .text()
      .then((text: string) => {
        const json = JSON.parse(text)
        const stack = json?.errors?.meta?.stack

        store.dispatch(
          datasourcesActions.updatePreviewError({
            message: json.errors.detail,
            line: stack && stack.length ? stack[0].line : 0          
          })
        )
      })
  }
}
