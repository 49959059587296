var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Input } from '../Input/Input';
import { toRgbCss } from '../utils';
import { matchThemeProp } from '../Theme';
const StyledInput = styled(Input) `
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${matchThemeProp((theme) => theme.global.purposes, {
    modifier: toRgbCss
})};
`;
export function HeadingInput(_a) {
    var { label } = _a, rest = __rest(_a, ["label"]);
    const id = useMemo(() => uuidv4(), []);
    return (_jsxs(_Fragment, { children: [_jsx(StyledInput, Object.assign({}, rest, { huge: true, id: id })), _jsx("label", Object.assign({ htmlFor: id, hidden: true }, { children: label }))] }));
}
