import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, createContext, useContext } from 'react';
export const UIExtendPropsContext = createContext({});
export function extendUIProps(extensions) {
    return extensions;
}
export function useExtendProps(component, props) {
    const extensions = useContext(UIExtendPropsContext)[component] || {};
    if (typeof extensions === 'function') {
        return extensions(props);
    }
    return extensions;
}
export function withExtendableProps(name) {
    return function (Component) {
        return forwardRef(function ExtendedComponent(props, ref) {
            const extendedProps = useExtendProps(name, props);
            return _jsx(Component, Object.assign({ ref: ref }, props, extendedProps));
        });
    };
}
