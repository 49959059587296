"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapAccum = void 0;
const ramda_1 = require("ramda");
exports.mapAccum = (0, ramda_1.curry)((f, init, tree) => {
    function go(acc, node, indexPath) {
        const [newAcc, newData] = f(acc, node.data, indexPath, tree);
        return {
            data: newData,
            children: node.children.map((child, i) => go(newAcc, child, (0, ramda_1.append)(i, indexPath)))
        };
    }
    return go(init, tree, []);
});
