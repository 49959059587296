"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.differenceWithNode = exports.differenceWith = void 0;
// Please note that as soons as there is a difference, we stop traversing in depth.
const differenceWith = (compare, tree1, tree2, mismatchedItems = []) => {
    // If tree1 is null, there's nothing to compare; return.
    if (tree1 === null)
        return mismatchedItems;
    // If tree2 is null or their data doesn't match, accumulate tree1's node.
    if (tree2 === null || !compare(tree1.data, tree2.data)) {
        mismatchedItems.push(tree1.data);
    }
    else {
        // If nodes match, proceed to compare children.
        for (let i = 0; i < tree1.children.length; i++) {
            (0, exports.differenceWith)(compare, tree1.children[i], tree2.children.length > i ? tree2.children[i] : null, mismatchedItems);
        }
    }
    return mismatchedItems;
};
exports.differenceWith = differenceWith;
// Returns the node instead of the item
const differenceWithNode = (compare, tree1, tree2, mismatchedNodes = []) => {
    // If tree1 is null, there's nothing to compare; return.
    if (tree1 === null)
        return mismatchedNodes;
    // If tree2 is null or their data doesn't match, accumulate tree1's node.
    if (tree2 === null || !compare(tree1.data, tree2.data)) {
        mismatchedNodes.push(tree1);
    }
    else {
        // If nodes match, proceed to compare children.
        for (let i = 0; i < tree1.children.length; i++) {
            (0, exports.differenceWithNode)(compare, tree1.children[i], tree2.children.length > i ? tree2.children[i] : null, mismatchedNodes);
        }
    }
    return mismatchedNodes;
};
exports.differenceWithNode = differenceWithNode;
