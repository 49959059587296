import store from '../store'
import { RootState } from '../reducers'
import { authActions, authThunks } from '../domains/auth/state'
import { setAlphaEnabled as useAlphaFeatures } from '../application/config/features'

function login(username: string, password: string) {
  return store.dispatch(
    authThunks.loginWithCredentials({
      username,
      password
    })
  )
}

function logout() {
  return store.dispatch(authThunks.logout())
}

function refreshToken(token: string) {
  return store.dispatch(authThunks.loginWithToken(token))
}

function apiRoot(root: string) {
  return store.dispatch(authActions.updateApiRoot(root))
}

function state(callback: (state: RootState) => unknown) {
  return callback(store.getState())
}

async function loginWithToken(token: string) {
  return refreshToken(token)
}

export default {
  // Public (new)
  loginWithToken,

  // Public (legacy)
  login,
  logout,
  refreshToken,
  apiRoot,

  // Private
  state,
  useAlphaFeatures
}
