import color from 'color';
import { css } from 'styled-components';
import { fromTheme } from '../../Theme';
export * from './hooks';
export const toRgbArray = (input) => {
    try {
        return color(input).rgb().array();
    }
    catch (err) {
        return [0, 0, 0];
    }
};
export function toRgbString(input) {
    // If input is a var(--name) input, treat it as a 'r, g, b' value
    if (typeof input === 'string' && input.startsWith('var(--')) {
        return input;
    }
    // If input is a 'r, g, b' value, treat it literally
    if (typeof input === 'string' && input.split(',').length === 3) {
        return input;
    }
    return toRgbArray(input).join(', ');
}
export function toRgbCss(input) {
    // Process arrays and hex values ([r, g, b] | #rrggbb) with toRgbString to get 'r, g, b' back
    return `rgb(${toRgbString(input)})`;
}
export const classNames = (map, ...more) => Object.keys(map).reduce((sum, key) => (map[key] ? `${sum} ${key}` : sum), '') +
    ' ' +
    String(more.join(' '));
export const globalSectionSpacing = css `
  margin: ${(props) => props.noMargin
    ? 0
    : fromTheme((theme) => theme.global.baseSpacing, props)}rem
    0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
export const resetBox = css `
  margin: unset;
  padding: unset;
  border: unset;
  box-sizing: border-box;
`;
export const truncateText = css `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const globalFont = css `
  font-family: ${fromTheme((theme) => theme.global.fontFamily)};
  font-weight: normal;
  font-size: ${fromTheme((theme) => theme.global.baseSize)}px;
  color: ${fromTheme((theme) => theme.global.textColor)};
  line-height: ${fromTheme((theme) => theme.global.lineHeight)};
`;
export const globalScrollbar = css `
  ::-webkit-scrollbar {
    width: ${fromTheme((theme) => theme.global.sizes.base)}em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.4);
    border-radius: 6px;
    border: 4px solid transparent;
    background-clip: padding-box !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.6);
  }
`;
export * from '../isolated';
