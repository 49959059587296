import { translate } from '@exivity/translations'
import { queries } from '@exivity/data-layer'

export type APIResponseRunReports = {
  account_id: string
  rate_id: string | null
  service_id: string
  servicecategory_id: string

  adjustments: {
    id: string
    charge: number
    quantity: number
  }[]

  tier_breakdown: {
    id: string
    quantity: number
    // null when associated service does not have a tiered charge_type
    charge: number | null
    // null when associated service does not have a tiered cogs_type
    cogs: number | null
  }[]

  breakdown: {
    tier_id: string
    quantity: number
  }[]

  avg_interval_based_rate: number
  avg_unit_based_rate: number

  interval_based_subtotal_charge: number
  unit_based_subtotal_charge: number

  min_commit_delta_charge: number
  min_commit_delta_quantity: number

  subtotal_charge: number
  subtotal_quantity: number

  total_charge: number
  total_cogs: number
  total_net: number
  total_quantity: number

  instance_value?: string
}

export interface EnrichedRunReportsItem extends APIResponseRunReports {
  accountName: string
  serviceDescription: string
  serviceCategoryName: string
  unitLabel: string
  adjustments: {
    id: string
    charge: number
    quantity: number
    name: string
  }[]
}

export const enrichSummary =
  (memory: any) => (_: any, data: APIResponseRunReports[]) => {
    function query(query: any) {
      try {
        return memory.cache.query(query.toQuery())
      } catch (e) {
        return null
      }
    }

    return data.map((item) => {
      const service = query(queries.find('service', item.service_id))

      return {
        ...item,
        accountName:
          query(queries.find('account', item.account_id))?.attributes.name ||
          translate('account not found'),

        serviceDescription:
          service?.attributes.description || translate('service not found'),

        serviceCategoryName:
          query(queries.find('servicecategory', item.servicecategory_id))
            ?.attributes.name || translate('service category not found'),

        unitLabel:
          service?.attributes.unit_label || translate('service not found'),

        adjustments: item.adjustments.map((adjustment) => ({
          ...adjustment,
          name:
            query(queries.find('adjustment', String(adjustment.id)))?.attributes
              ?.name || translate('Adjustment not found')
        }))
      }
    })
  }
