import styled from 'styled-components';
import { preciseEm } from '../utils';
import { themeSpace } from '../Theme';
const MAX_WIDTH = 50; // em
const MIN_HEIGHT = 2.5; // em
export const Multiple = styled.div `
  display: flex;
  gap: ${themeSpace(2)};
  flex-wrap: wrap;

  > * {
    flex: 1;
  }

  max-width: ${preciseEm(MAX_WIDTH)}em;
  min-height: ${preciseEm(MIN_HEIGHT)}em;
`;
