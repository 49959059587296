import { PARENT, CHILDREN } from './data';
export function traverseParents(item, callback) {
    const parent = item[PARENT];
    if (parent) {
        callback(parent);
        traverseParents(parent, callback);
    }
}
export function traverseChildren(item, callback) {
    const children = item[CHILDREN];
    if (children) {
        children.forEach((child) => {
            callback(child);
            traverseChildren(child, callback);
        });
    }
}
export function makeGetSubtree(traverseFn) {
    return (node) => {
        const items = [];
        traverseFn(node, (relatedItem) => items.push(relatedItem));
        return items;
    };
}
export const getParents = makeGetSubtree(traverseParents);
export const getChildren = makeGetSubtree(traverseChildren);
