import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "@exivity/ui";
import PropTypes from "prop-types";

const StyledInput = styled(Input)`
  color: #aaa;
  border: none;
  outline: none;
`;

export function GroupHeader({ children, editable, onSave }) {
  const [value, setValue] = useState(children);

  function save() {
    onSave(value);
    setValue(children);
  }

  return editable ? (
    <StyledInput
      outlined
      tiny
      value={value}
      onBlur={save}
      onKeyDown={(e) => e.key === "Enter" && save()}
      onChange={(value) => setValue(value)}
    />
  ) : (
    children
  );
}

GroupHeader.propTypes = {
  children: PropTypes.string,
  editable: PropTypes.bool,
  onSave: PropTypes.func,
};
