var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { MdRemove, MdAdd } from 'react-icons/md';
import { ListItem } from '../ListItem';
import { BranchSpacer } from '../BranchSpacer';
import { classNames } from '../utils';
import { fromTheme } from '../Theme';
import { Icon } from '../Icon';
import { CHILDREN } from '../utils/tree';
export const EXPAND_ICON = MdAdd;
export const COLLAPSE_ICON = MdRemove;
export const ToggleExpandedButton = styled.button.attrs({ type: 'button' }) `
  width: 20px;
  height: 20px;
  border-radius: 0;
  border: none;

  padding: 0;
  cursor: pointer;
`;
const StyledValue = styled.span `
  margin-left: 10px;
`;
const ButtonIcon = styled(Icon) `
  svg {
    width: 100%;
    height: 100%;
    color: ${fromTheme((theme) => theme.colors.gray)};
  }
`;
export function DefaultItem(_a) {
    var { data, index, style = {}, test = 'treelist-item' } = _a, rest = __rest(_a, ["data", "index", "style", "test"]);
    const { items, onChange, labelAccessor } = data;
    const item = items[index];
    function handleChange(e) {
        onChange && onChange(item.getPlainData(), e);
    }
    return (_jsx(ListItem, Object.assign({ "data-testid": test, style: style, onClick: handleChange }, rest, { children: _jsxs(BranchSpacer, Object.assign({ spacing: 20, padding: item[CHILDREN] ? 5 : 0, index: index, data: items }, { children: [_jsx("div", Object.assign({ className: classNames({
                        'treelist__expand--invisible': !item[CHILDREN]
                    }) }, { children: _jsx(ToggleExpandedButton, Object.assign({ "data-testid": `${test}-toggle-expand-button`, onClick: (e) => {
                            e.stopPropagation();
                            item.expand();
                        } }, { children: _jsx(ButtonIcon, { children: item.expanded ? (_jsx(COLLAPSE_ICON, { "data-testid": `${test}-toggle-expand-button-collapse-icon` })) : (_jsx(EXPAND_ICON, { "data-testid": `${test}-toggle-expand-button-expand-icon` })) }) })) })), _jsx(StyledValue, { children: labelAccessor(item) })] })) })));
}
