export const clean = (array) => {
  return array.reduce((acc, item) => {
    if (typeof item !== "undefined" && item !== null) {
      acc.push(item);
    }

    return acc;
  }, []);
};

const collator = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
});
export const stringSort = (a, b) => {
  return collator.compare(a, b);
};

export const stringSortRecordsBy = (sortBy) => (a, b) => {
  return stringSort(a.attributes[sortBy], b.attributes[sortBy]);
};

export const stringSortBy =
  (direction = "asc") =>
  (a, b) => {
    return direction === "asc" ? stringSort(a, b) : stringSort(b, a);
  };

export const stringSortObjectBy =
  (sortBy, direction = "asc") =>
  (a, b) =>
    direction === "asc"
      ? stringSort(a[sortBy], b[sortBy])
      : stringSort(b[sortBy], a[sortBy]);

export const sortByClassAndString = (className) => (a, b) => {
  if (a.class === className && b.class !== className) {
    return 1;
  }

  if (a.class !== className && b.class === className) {
    return -1;
  }

  return stringSort(a.value, b.value);
};

/**
 * A pure version of Array.prototype.splice
 * It will return a new array rather than mutate the array
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
 * @param {Array} array The target array
 * @param {number} start BudgetItem at which to start changing the array
 * @param {number} deleteCount An integer indicating the number of old array elements to remove
 * @param {any} items The elements to add to the array, beginning at the start index
 * @returns {Array}
 */

export function pureSplice(array, start = 0, deleteCount = 0, ...items) {
  const arrayLength = array.length;
  const _deleteCount = deleteCount < 0 ? 0 : deleteCount;
  let _start;
  if (start < 0) {
    if (Math.abs(start) > arrayLength) {
      _start = 0;
    } else {
      _start = arrayLength + start;
    }
  } else if (start > arrayLength) {
    _start = arrayLength;
  } else {
    _start = start;
  }
  return [
    ...array.slice(0, _start),
    ...items,
    ...array.slice(_start + _deleteCount, arrayLength),
  ];
}

export const listItems = (items) => {
  return items.map((item) => ({
    key: item.id,
    attributes: item.attributes,
  }));
};
