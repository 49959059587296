import { UpdateConfiguration } from '../../actions/system/services'

export interface ServicesState {
  MAIL_HOST: string
  MAIL_PORT: number
  MAIL_USERNAME: string
  MAIL_PASSWORD: string
  MAIL_FROM_ADDRESS: string
  MAIL_FROM_NAME: string
  MAIL_DRIVER: string
  MAIL_ENCRYPTION: string
  NEXMO_KEY: string
  NEXMO_SECRET: string
  NEXMO_FROM_NUMBER: string
  LDAP_ACCOUNT_PREFIX: string
  LDAP_ACCOUNT_SUFFIX: string
  LDAP_HOSTS: string
  LDAP_PORT: number
  LDAP_TIMEOUT: number
  LDAP_BASE_DN: string
  LDAP_DEFAULT_USERGROUP_ID: number
  LDAP_USERNAME: string
  LDAP_EMAIL: string
}

const initialState: Partial<ServicesState> = {}

const services = (
  state = initialState,
  action: UpdateConfiguration
): Partial<ServicesState> => {
  switch (action.type) {
    case UpdateConfiguration.type:
      return {
        ...state,
        ...action.configuration
      }

    default:
      return state
  }
}

export default services
