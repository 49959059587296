import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'

import { Report } from './'

function getUnique<T>(arr: T[], arr1: T[]) {
  return [...arr, ...arr1].filter((v, i, a) => a.indexOf(v) === i)
}

export const pin = {
  prepare: (report: Report, ids: string[]) => ({
    payload: {
      report,
      ids
    }
  }),
  reducer(
    filters: ReportFilterState,
    action: PayloadAction<{ report: Report; ids: string[] }>
  ) {
    filters[action.payload.report].pinned = getUnique(
      filters[action.payload.report].pinned,
      action.payload.ids
    )
  }
}

export const unpin = {
  prepare: (report: Report, ids: string[]) => ({
    payload: {
      report,
      ids
    }
  }),
  reducer(
    filters: ReportFilterState,
    action: PayloadAction<{ report: Report; ids: string[] }>
  ) {
    const slice = filters[action.payload.report]
    slice.pinned = slice.pinned.filter((id) => !action.payload.ids.includes(id))
  }
}

export const resetPins = {
  prepare: (report: Report) => ({
    payload: report
  }),
  reducer(filters: ReportFilterState, action: PayloadAction<Report>) {
    filters[action.payload].pinned = []
  }
}
