/* eslint-disable @typescript-eslint/no-var-requires */
import { Resources, memory } from '@exivity/data-layer'
import { RecordQueryBuilder } from '@orbit/records'
import { createSelector } from 'reselect'

export interface SimplifiedAccount {
  id: string | null
  level: number
  name: string
  key: string
}

export const getAccountIdentifier = (account: Resources['account']) => ({
  type: 'account',
  id: account.id
})

export const getAccount = createSelector<any, any, any>(
  getAccountIdentifier,
  (account) => {
    try {
      return memory.cache.query((q: any) =>
        q.findRecord(account)
      ) as Resources['account']
    } catch (e) {
      return null
    }
  }
)

// can optimise by caching results
// DETAILS TABLE BREADCRUMBS REPORT
export const parentAccounts = createSelector(
  getAccount,
  (account: Resources['account']): SimplifiedAccount[] => {
    const parents: SimplifiedAccount[] = []

    let next = account

    try {
      const query = (q: RecordQueryBuilder) =>
        q.findRelatedRecord(next, 'parent')

      while (next) {
        next = memory.cache.query(query) as Resources['account']

        if (next) {
          parents.push(simplifiedAccount(next))
        }
      }
    } catch (e) {}

    return parents
  }
)

export const simplifiedAccount = (
  account: Resources['account'] | null
): SimplifiedAccount => ({
  id: account?.id || '',
  level: account?.attributes.level || 1,
  name: account?.attributes.name || '',
  key: account?.attributes.level_keys[account?.attributes.level - 1].key || ''
})
