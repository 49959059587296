import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { CirclePicker } from 'react-color';
// @ts-ignore
import material from 'material-colors';
import { Dropdown } from '../Dropdown';
import { SelectInput } from '../Input';
import { isBrightColor } from '../utils';
import { themePalette, themeSpace } from '../Theme';
import { useFieldContext } from '../Field/context';
export const StyledDropdown = styled(Dropdown) `
  position: relative;
  width: 100%;
  color: ${({ color }) => color || themePalette('neutral', 900)};
  .dropdown__drop {
    width: 100%;
    padding: ${themeSpace(1)} 0;
  }
`;
const StyledInput = styled(SelectInput) `
  background-color: ${(props) => props.value};
  color: ${(props) => props.color};
  border: 2px solid ${({ value }) => value || themePalette('neutral', 200)};
  &:focus {
    border-color: #0000002d;
  }
`;
const Center = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${themeSpace(1)} 0;
`;
export const DEFAULT_COLORS = [
    material.red['500'],
    material.pink['500'],
    material.purple['500'],
    material.deepPurple['500'],
    material.indigo['500'],
    material.blue['500'],
    '#00a8d8',
    material.cyan['500'],
    material.teal['500'],
    material.green['500'],
    material.lightGreen['500'],
    material.lime['500'],
    material.yellow['500'],
    material.amber['500'],
    material.orange['500'],
    material.deepOrange['500'],
    material.brown['500'],
    material.blueGrey['500']
];
export function ColorPicker({ name, value, onChange, className, placeholder, required, disabled, colors = DEFAULT_COLORS }) {
    const { id } = useFieldContext();
    const color = !value ? 'inherit' : isBrightColor(value) ? '#000' : '#fff';
    return (_jsx(StyledDropdown, Object.assign({ color: color, className: className, reference: _jsx(StyledInput, { id: id, name: name, required: required, disabled: disabled, placeholder: placeholder, "data-testid": "colorpicker__input", value: value, color: color }) }, { children: _jsx(Center, { children: _jsx(CirclePicker, { color: value, colors: colors, onChange: ({ hex }) => onChange && onChange(hex) }) }) })));
}
