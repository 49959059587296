var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { range } from 'ramda';
import { colorFromPurpose, themePalette } from '../Theme';
function NumberRange(_a) {
    var { value, onChange, min, max } = _a, attributes = __rest(_a, ["value", "onChange", "min", "max"]);
    return (_jsxs(Slider, { children: [_jsx("input", Object.assign({ type: "range", min: min, max: max, value: value, list: "ex-numberRange", onChange: (e) => onChange(Number(e.target.value), e) }, attributes)), _jsx(Ticks, Object.assign({ id: "ex-numberRange" }, { children: range(min, max + 1).map((option) => (_jsx("option", { value: option, label: option.toString() }, option))) }))] }));
}
const Slider = styled.div `
  display: flex;
  flex-direction: column;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='’range’'] {
      overflow: hidden;
      -webkit-appearance: none;
      background: ${colorFromPurpose('secondary', 700)};
    }
  }

  input[type='range'] {
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
      height: 6px;
      background: ${colorFromPurpose('secondary', 300)};
      border-radius: 2px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 21px;
      width: 21px;
      border-radius: 100%;
      margin-top: -8px;
      background: ${colorFromPurpose('secondary', 700)};

      &:hover {
        background: ${colorFromPurpose('secondary', 900)};
      }
    }
  }
`;
const Ticks = styled.datalist `
  pointer-events: none;
  display: flex;
  justify-content: space-between;

  option {
    color: ${themePalette('neutral', 800)};
    font-size: 1em;
    padding: 8px;
  }
`;
export { NumberRange };
