import React, { ReactNode } from 'react'

interface WrapIfProps {
  condition: boolean
  children: ReactNode
  wrapper: (node: ReactNode) => ReactNode
}

export function WrapIf({ condition, wrapper, children }: WrapIfProps) {
  return <>{condition ? wrapper(children) : children}</>
}
