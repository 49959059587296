import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdMoreVert } from 'react-icons/md';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { fromTheme, themePriority } from '../Theme';
const OverflowMenuItem = styled.div `
  margin-right: ${fromTheme((theme) => theme.space[1])};

  z-index: ${themePriority(2)};

  display: flex;
  flex-direction: flex-row;
  align-items: center;

  input[type='text'] {
    height: 28px;
    min-width: 200px;
  }

  & > * {
    margin-right: ${fromTheme((theme) => theme.space[1])};
  }

  white-space: nowrap;
`;
const StyledOverflowMenu = styled.div `
  height: 30px;
  position: relative;

  .container {
    float: right;

    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 1rem;
    padding: 0 ${fromTheme((theme) => theme.space[1])};

    .icon {
      font-size: 1.3em;
    }

    transition: all 0.1s linear;

    ${OverflowMenuItem} {
      transition: all 0.1s linear;
    }

    &:not(:hover) {
      transition-delay: 1s;
      background-color: transparent;

      ${OverflowMenuItem} {
        transition-delay: 1s;
        visibility: hidden;
        opacity: 0;
      }
    }

    &:hover {
      background-color: ${fromTheme((theme) => theme.colors.veryLightGray)};

      ${OverflowMenuItem} {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;
export function OverflowMenu({ children, className }) {
    return (_jsx(StyledOverflowMenu, Object.assign({ className: className }, { children: _jsxs("div", Object.assign({ className: "container" }, { children: [children, _jsx(Icon, Object.assign({ className: "icon" }, { children: _jsx(MdMoreVert, {}) }))] })) })));
}
OverflowMenu.Item = OverflowMenuItem;
