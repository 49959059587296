import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, createContext, useContext } from 'react';
import { useToggle } from '@exivity/hooks';
import styled from 'styled-components';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Icon } from '../Icon';
import { fromTheme } from '../Theme';
const StyledIcon = styled(Icon) `
  cursor: pointer;
  user-select: none;
  font-size: ${fromTheme((theme) => theme.global.sizes.large)}em;
  flex-grow: 0;

  svg {
    vertical-align: bottom;
  }
`;
function Collapse() {
    const { collapsed, toggleCollapsed } = useCollapsible();
    return (_jsx(StyledIcon, Object.assign({ "data-testid": "collapsible__collapser", onClick: (e) => {
            e.stopPropagation();
            toggleCollapsed();
        } }, { children: collapsed ? _jsx(MdKeyboardArrowDown, {}) : _jsx(MdKeyboardArrowUp, {}) })));
}
function Content({ children }) {
    const { collapsed } = useCollapsible();
    return collapsed ? null : children;
}
const CollapsibleContext = createContext({
    collapsed: false,
    toggleCollapsed: () => { }
});
function useCollapsible() {
    return useContext(CollapsibleContext);
}
function Collapsible({ initialCollapsed = false, collapsed, onCollapseChange, children }) {
    const [isCollapsed, toggleCollapsed] = useToggle(initialCollapsed);
    const collapsableContext = useMemo(() => ({
        collapsed: collapsed !== null && collapsed !== void 0 ? collapsed : isCollapsed,
        toggleCollapsed: () => {
            toggleCollapsed();
            onCollapseChange &&
                onCollapseChange(collapsed === undefined ? !isCollapsed : !collapsed);
        }
    }), [isCollapsed, collapsed, toggleCollapsed, onCollapseChange]);
    return (_jsx(CollapsibleContext.Provider, Object.assign({ value: collapsableContext }, { children: children })));
}
Collapsible.useCollapsible = useCollapsible;
Collapsible.Collapse = Collapse;
Collapsible.Content = Content;
export { Collapsible };
