import { useEffect, useLayoutEffect, useRef } from 'react';
import { useDataStore } from '../useDataStore';
import { getExpressionResult, omitSort } from '../queries/getExpressionResult';
export function useLiveQuery(queryExpression, onQuery) {
    const memory = useDataStore();
    const onQueryRef = useRef(onQuery);
    const stringifiedQuery = JSON.stringify(queryExpression);
    useLayoutEffect(() => {
        onQueryRef.current = onQuery;
    });
    useEffect(() => {
        const liveQuery = memory.cache.liveQuery(omitSort(queryExpression), {
            debounce: true
        });
        onQueryRef.current(getExpressionResult(queryExpression, liveQuery.query.bind(liveQuery)));
        const unsubscribe = liveQuery.subscribe((update) => {
            onQueryRef.current(getExpressionResult(queryExpression, update.query.bind(update)));
        });
        return () => {
            unsubscribe();
        };
    }, [memory, stringifiedQuery]);
}
