import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';
import { Text } from '../Text';
import { themeColor, themeSpace } from '../Theme';
const StyledLoader = styled.div `
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: ${themeSpace(3)};
  }

  span {
    color: ${themeColor('gray')};
  }
`;
export function Loader({ timeout, timeoutMessage = '', className, children = 'Loading...' }) {
    const [isTimedOut, setIsTimedOut] = useState(false);
    useEffect(() => {
        let clock;
        if (typeof timeout === 'number') {
            clock = setTimeout(() => {
                setIsTimedOut(true);
            }, timeout);
        }
        return () => {
            if (clock) {
                clearTimeout(clock);
            }
        };
    }, [timeout]);
    return (_jsxs(StyledLoader, Object.assign({ className: className }, { children: [_jsx(Spinner, {}), _jsx(Text, { children: isTimedOut ? timeoutMessage : children })] })));
}
