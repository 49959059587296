"use strict";
/**
 * @description () -> a
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.id = void 0;
const id = () => (data) => {
    return data;
};
exports.id = id;
