import { extendUIProps } from '@exivity/ui'
import { useSystemConfiguration } from '@exivity/data-layer'

export const dateCalendarSelect = extendUIProps({
  DatePicker: (props) => {
    const dateFormat = useSystemConfiguration().attributes.DATE_FORMAT

    return {
      format: props.format || dateFormat
    }
  },
  DateRangePicker: (props) => {
    const dateFormat = useSystemConfiguration().attributes.DATE_FORMAT

    return {
      format: props.format || dateFormat
    }
  },
  DateFilter: (props) => {
    const dateFormat = useSystemConfiguration().attributes.DATE_FORMAT

    return {
      format: props.format || dateFormat
    }
  },
  DateRangeFilter: (props) => {
    const dateFormat = useSystemConfiguration().attributes.DATE_FORMAT

    return {
      format: props.format || dateFormat
    }
  }
})
