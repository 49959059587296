import { FormatNumberOptions, formatNumber } from './helpers'

interface Precisions {
  quantity?: number
  rate?: number
  report?: number
  summary?: number
  percentage?: number
}

interface Symbols {
  decimal?: string
  thousands?: string
  currency: string
}

export interface ReportFormatterOptions {
  precisions: Precisions
  symbols: Symbols
}
export class ReportFormatter {
  precisions: Precisions
  symbols: Symbols

  constructor(options: ReportFormatterOptions) {
    this.precisions = options.precisions
    this.symbols = options.symbols
  }

  private format = (amount: number, options: FormatNumberOptions = {}) => {
    return formatNumber(amount, {
      thousandsSeparator: this.symbols.thousands,
      decimalSeparator: this.symbols.decimal,
      ...options
    })
  }

  private formatCurrency = (
    amount: number,
    options: FormatNumberOptions = {}
  ) => {
    return this.format(amount, {
      adornment: { type: 'currency', symbol: this.symbols.currency } as const,
      ...options
    })
  }

  formatQuantity = (amount: number) =>
    this.format(amount, { precision: this.precisions.quantity })

  formatRate = (amount: number) =>
    this.formatCurrency(amount, { precision: this.precisions.rate })

  formatReport = (amount: number) =>
    this.formatCurrency(amount, { precision: this.precisions.report })

  formatSummary = (amount: number) =>
    this.formatCurrency(amount, { precision: this.precisions.summary })

  formatPercentage = (amount: number) =>
    this.format(amount, {
      precision: this.precisions.percentage,
      adornment: { type: 'percentage' }
    })
}
