import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, arrayMove } from "react-sortable-hoc";

import AdvancedSelect from "./AdvancedSelect";

const SortableList = AdvancedSelect
  ? SortableContainer(AdvancedSelect)
  : undefined;

class SortableAdvancedSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { data: props.data };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onSort } = this.props;
    // We need to inform React Virtualized that the items have changed heights
    const instance = this.SortableList;

    if (oldIndex !== newIndex) {
      this.setState(
        { data: arrayMove(this.state.data, oldIndex, newIndex) },
        () => onSort && onSort(this.state.data)
      );

      instance.forceUpdate();
    }
  };

  render() {
    const { data } = this.state;

    return (
      <SortableList
        {...this.props}
        distance={3}
        data={data}
        onTable={(instance) => (this.SortableList = instance)}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

SortableAdvancedSelect.propTypes = {
  data: PropTypes.any,
  onSort: PropTypes.func,
};

export default SortableAdvancedSelect;
