import { useEffect, useState } from 'react'
import { queries, useAPIQuery, useCacheQuery } from '@exivity/data-layer'
import { format } from 'date-fns'
import { prop, map, dropLast, compose } from 'ramda'

// remove '-yyyymm' from id
export const deriveDatasetId = compose(dropLast(7), prop<'id', string>('id'))

export function useCUPRs() {
  const queryApi = useAPIQuery()
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date()
  ])
  const datasets = map(prop('id'), useCacheQuery(queries.findAll('dataset')))

  const cuprs = useCacheQuery(
    queries
      .findAll('cupr')
      .filterByAttribute('month', 'gte', format(dateRange[0], 'yyyy-MM'))
      .filterByAttribute('month', 'lte', format(dateRange[1], 'yyyy-MM'))
  )

  useEffect(() => {
    queryApi(queries.findAll('dataset'))
  }, [queryApi])

  useEffect(() => {
    queryApi(
      queries
        .findAll('cupr')
        .filterByAttribute('month', 'gte', format(dateRange[0], 'yyyy-MM'))
        .filterByAttribute('month', 'lte', format(dateRange[1], 'yyyy-MM'))
    )
  }, [queryApi, dateRange])

  const cuprsCurrentMonth = useCacheQuery(
    queries
      .findAll('cupr')
      .filterByAttribute('month', 'gte', format(new Date(), 'yyyy-MM'))
  )

  return {
    cuprs,
    cuprsCurrentMonth,
    datasets,
    dateRange,
    setDateRange
  } as const
}
