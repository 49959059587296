import { workThunks } from '../../domains/work/thunks'
import store from '../../store'
import { HookContext, HookType } from '../fetchWithHooks/fetchWithHooks'

export async function showErrorToUser(ctx: HookContext<HookType.Error>) {
  const { error } = ctx

  if (!error.message) {
    store.dispatch(workThunks.showDefaultApiErrorMessage())
  }

  if (error.message && error.message !== 'The user aborted a request.') {
    store.dispatch(workThunks.showErrorMessage(error.message))
  }

  return ctx
}
