"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translate = exports.setLanguage = exports.supportedLanguages = exports.selectedLanguage = void 0;
const translations_1 = require("./translations");
const translators_1 = require("./translators");
exports.selectedLanguage = 'en';
exports.supportedLanguages = Object.keys(translations_1.translations);
function setLanguage(language) {
    exports.selectedLanguage = language;
}
exports.setLanguage = setLanguage;
function translate(text, ...variables) {
    return (0, translators_1.translator)(exports.selectedLanguage)(text, ...variables);
}
exports.translate = translate;
