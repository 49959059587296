import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { orderBy, classNames } from '../../utils';
import { fromTheme } from '../../Theme';
import { createListStrategy } from './List';
const StyledItem = styled.li `
  cursor: pointer;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    background-color: ${fromTheme((theme) => theme.colors.lightGray)};
  }

  &.active {
    color: ${fromTheme((theme) => theme.global.purposes.primary)};
  }

  .sortable & {
    &:active {
      cursor: grabbing;
    }

    .columns > :first-child {
      padding-left: ${fromTheme((theme) => theme.global.baseSpacing * 1.5)}em;
    }
  }
`;
export function createFlatStrategy(data, { order, isActive, sortable, onClick, renderColumns }) {
    return createListStrategy(data, {
        prepareData: (data) => {
            return order ? orderBy(order, data) : data;
        },
        renderItem: ({ item }) => {
            const active = (isActive === null || isActive === void 0 ? void 0 : isActive(item)) || false;
            return sortable(_jsx(StyledItem, Object.assign({ tabIndex: 0, "data-active": active, className: classNames({ active }), onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick(item) }, { children: renderColumns(item) })), { item, slot: 'listitem' });
        }
    });
}
