import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Field, Group, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { compose } from 'ramda'

import { authActions, authSelectors } from '../state'

export const DomainInput = () => {
  const dispatch = useDispatch()
  const domain = useSelector(authSelectors.getApiRoot)

  return (
    <Input
      placeholder={translate('Domain')}
      value={domain}
      onChange={compose(dispatch, authActions.updateApiRoot)}
    />
  )
}

interface DomainGroupProps {
  fetchConfig: () => void
  collapsed?: boolean
}

export const DomainGroup = ({
  fetchConfig,
  collapsed = true
}: DomainGroupProps) => {
  return (
    <Form>
      <Group title={translate('Domain')} initialCollapsed={collapsed}>
        <Field.Container>
          <Field>
            <DomainInput />
          </Field>
        </Field.Container>
        <Form.Toolbar>
          <Button block success onClick={() => fetchConfig()}>
            {translate('connect')}
          </Button>
        </Form.Toolbar>
      </Group>
    </Form>
  )
}
