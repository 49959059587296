import { CalendarMode } from '@exivity/ui'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import migrator from '../../../store/enhancers/migrator'

export enum ChartType {
  Line = 'line',
  StackedColumn = 'stacked_column'
}

export const { actions, reducer } = createSlice({
  name: 'reports/global/gui',
  initialState: {
    searchEnabled: false,
    legendEnabled: true,
    highlightedItem: null as string | null,
    chartType: ChartType.Line,
    dateRangeMode: CalendarMode.Days as CalendarMode
  },
  reducers: {
    enableSearch: (state, action: PayloadAction<boolean>) => {
      state.searchEnabled = action.payload
    },
    toggleLegend: (state) => {
      state.legendEnabled = !state.legendEnabled
    },
    highlightItem: (state, action: PayloadAction<string | null>) => {
      state.highlightedItem = action.payload
    },
    updateChartType: (state, action: PayloadAction<ChartType>) => {
      state.chartType = action.payload
    },
    updateDateRangeCalendarMode: (
      state,
      action: PayloadAction<CalendarMode>
    ) => {
      state.dateRangeMode = action.payload
    },
    loadSlice: (state, action) =>
      migrator({
        incomingState: action.payload,
        currentState: state
      })
  }
})
