var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { SelectInput } from '../Input';
import { useFieldContext } from '../Field/context';
import { renderSelectList } from '../SelectList';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
const StyledDropdown = styled(Dropdown) `
  .dropdown__drop {
    padding: ${themeSpace(1)} 0;

    .select__searchbar {
      margin-top: 0;
    }
  }
`;
export function SelectFilter(_a) {
    var { name, value, inputValue, className, data = [], onChange, required, disabled, placement = 'bottom-start', multiple = false, searchable = false, categorized, placeholder = translate('Choose an option'), labelAccessor = identity, valueAccessor = identity, getChildNodes, initExpandedState, renderIcon, onClear, clearText } = _a, purposes = __rest(_a, ["name", "value", "inputValue", "className", "data", "onChange", "required", "disabled", "placement", "multiple", "searchable", "categorized", "placeholder", "labelAccessor", "valueAccessor", "getChildNodes", "initExpandedState", "renderIcon", "onClear", "clearText"]);
    const [isOpen, toggleOpen] = useToggle(false);
    const { id } = useFieldContext();
    return (_jsx(StyledDropdown, Object.assign({ className: className, disabled: disabled, placement: placement, reference: _jsx(SelectInput, Object.assign({ id: id, title: inputValue, required: required, name: name, outlined: true, secondary: true, value: inputValue, placeholder: placeholder, disabled: disabled }, purposes)), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: renderSelectList({
            multiple,
            searchable,
            categorized,
            value,
            data,
            valueAccessor,
            labelAccessor,
            getChildNodes,
            renderIcon,
            initExpandedState,
            clearText,
            onChange: (value) => {
                onChange && onChange(value);
                !multiple && toggleOpen();
            },
            onClear: onClear ? juxt([onClear, toggleOpen]) : undefined
        }) })));
}
