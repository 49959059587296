import Store from '@orbit/memory'
import {
  AtomicDoc,
  atomicResultsToTransforms,
  atomicDocToTransforms
} from '@exivity/data-layer'

import { fetchWithHooks } from '../fetchWithHooks/fetchWithHooks'
import {
  useMaxTimeout,
  handleJsonResponse,
  handleResponseErrors,
  handleTextResponse,
  handleWorkerThreads,
  isJsonResponse,
  showErrorToUser,
  parseJson,
  iff
} from '../hooks'
import { getUserSessionStorage } from '../../store/storage'
import store from '../../store'
import { authSelectors } from '../../domains/auth/state'

export function getApiUrl() {
  return authSelectors.getApiRoot(store.getState()) + '/v2/'
}

export const atomicFetch = (memory: Store) => (atomicDocs: AtomicDoc[]) => {
  const body = {
    'atomic:operations': atomicDocs
  }

  const channel = 'foreground'

  const fetchOptions = {
    headers: {
      'Content-Type':
        'application/vnd.api+json;ext="https://jsonapi.org/ext/atomic"',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${
        getUserSessionStorage.fromEitherStorage('token') ?? ''
      }`
    },
    method: 'POST',
    body: JSON.stringify(body)
  }

  const cleanTimeout = useMaxTimeout()

  return fetchWithHooks({
    before: [handleWorkerThreads(channel)],
    after: [
      cleanTimeout,
      handleResponseErrors,
      iff(isJsonResponse, handleJsonResponse, parseJson).else(
        handleTextResponse
      ),
      (ctx) => {
        // This means the result doesn't contain data that the request alrdy had.
        // Can only be remove operations
        if (!ctx.result || !ctx.result['atomic:results']) {
          memory.update(atomicDocToTransforms(atomicDocs), {
            // we have a request strategy that forwards memory updates to server,
            // but we don't want to forward this one because it's alrdy done
            doNotForward: true
          })
        } else {
          memory.update(
            atomicResultsToTransforms(ctx.result['atomic:results'], atomicDocs),
            {
              doNotForward: true
            }
          )
        }

        return ctx
      },
      handleWorkerThreads(channel)
    ],
    error: [
      cleanTimeout,
      handleWorkerThreads(channel),
      handleResponseErrors,
      showErrorToUser
    ]
  })(getApiUrl(), fetchOptions).catch((e) => {
    // do nothing because error hooks alrdy do everything
  })
}
