import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { Header, Title, Content, Separator, GroupIcon } from './components';
function defaultHeader({ collapsible = false, title, Icon }) {
    return (_jsxs(Header, { children: [Icon && (_jsx(GroupIcon, { children: _jsx(Icon, {}) })), _jsx(Title, Object.assign({ collapsible: collapsible }, { children: title })), _jsx(Separator, {}), collapsible && _jsx(CollapsibleComponent.Collapse, {})] }));
}
function defaultContent(content) {
    return _jsx(Content, { children: content });
}
const Container = styled.section `
  width: 100%;
`;
function Group({ title, Icon, children, collapsed, initialCollapsed, onCollapseChange }) {
    const collapsible = initialCollapsed !== undefined || collapsed !== undefined;
    const child = (_jsx(Container, { children: title ? (_jsxs(_Fragment, { children: [defaultHeader({ collapsible, Icon, title }), defaultContent(children)] })) : (children) }));
    return (_jsx(CollapsibleComponent, Object.assign({ initialCollapsed: initialCollapsed, collapsed: collapsible ? collapsed : false, onCollapseChange: onCollapseChange }, { children: child })));
}
Group.Header = Header;
Group.Title = Title;
Group.Separator = Separator;
Group.Icon = GroupIcon;
Group.Content = Content;
Group.Collapser = CollapsibleComponent.Collapse;
export { Group };
