import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CalendarMode, DateRangeFilter as UIDateRangeFilter } from '@exivity/ui'
import { useSystemConfiguration } from '@exivity/data-layer'

import { reportsActions, reportSelectors } from '../../state'
import { RootState } from '../../../../reducers'
import { systemConfigSelector } from '../../../administration/resources'

function useReportsDateRange() {
  const dispatch = useDispatch()

  const range = useSelector(reportSelectors.getDateRange)
  const mode = useSelector(
    (state: RootState) => state.reports.gui.dateRangeMode
  )

  return useMemo(() => {
    return {
      value: range,
      mode,
      onChange: (newRange: [Date, Date]) => {
        dispatch(reportsActions.filters.updateDateRange(newRange))
      },
      onChangeMode: (mode: CalendarMode) => {
        dispatch(reportsActions.gui.updateDateRangeCalendarMode(mode))
      }
    }
  }, [dispatch, range, mode])
}

export function DateRangeFilter() {
  const { value, mode, onChange, onChangeMode } = useReportsDateRange()
  const fiscalOffset = systemConfigSelector.getFiscalOffset(
    useSystemConfiguration()
  )

  return (
    <UIDateRangeFilter
      placement="bottom-start"
      offset={fiscalOffset}
      mode={mode}
      value={value}
      onModeChange={onChangeMode}
      onChange={onChange}
    />
  )
}
