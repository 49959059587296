import { useMemo } from 'react';
import { useStyledTheme } from '../hooks';
export function usePurposeProp({ primary, secondary, success, warning, danger, brand } = {}) {
    const purposesProps = { primary, secondary, success, warning, danger, brand };
    const purposes = useStyledTheme((theme) => theme.global.purposes);
    return useMemo(() => {
        const selectedPurposes = Object.keys(purposes).filter((purpose) => purposesProps[purpose]);
        if (selectedPurposes.length > 1) {
            throw new Error('More than one purpose prop is provided. Please provide only one.');
        }
        return selectedPurposes[0] || 'primary';
    }, [primary, secondary, success, warning, danger]);
}
