import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePalette } from '../Theme';
import { palette } from './config';
const LegendContainer = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem 2rem;
`;
const LegendColor = styled.div `
  background-color: ${({ index }) => palette[index % palette.length]};
  width: 1rem;
  height: 1rem;
`;
const LegendLabel = styled.div `
  font-size: 0.8rem;
  color: ${themePalette('neutral', 600)};
  cursor: default;
`;
const LegendItem = styled.div `
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const Legend = ({ keys, onChange }) => (_jsx(LegendContainer, { children: keys.map((key, i) => {
        return (_jsxs(LegendItem, Object.assign({ onMouseEnter: () => onChange(key), onMouseLeave: () => onChange(null) }, { children: [_jsx(LegendColor, { index: i }), _jsx(LegendLabel, { children: key })] }), i));
    }) }));
