import styled, { css } from 'styled-components';
import { themePriority } from '../Theme';
const fadeInGrow = css `
  @keyframes fadeInGrow {
    from {
      opacity: 0;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  animation: fadeInGrow linear 0.1s;
`;
export const ContentContainer = styled.div `
  z-index: ${themePriority(3)};
`;
export const TooltipContent = styled.div `
  background-color: #666666;
  color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  font-family: 'Fira Sans';
  font-size: 12px;
  border-radius: 2px;
  padding: 3px 6px;
  ${fadeInGrow};
`;
