import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { MdSearch } from 'react-icons/md';
import { animated } from '@react-spring/web';
import { Input } from '../Input';
import { toRgbString } from '../utils';
import { fromTheme, themeColor } from '../Theme';
import { Adornment } from '../Adornment';
const AnimatedStyledInput = animated(Input);
function getLightgray(props) {
    return fromTheme((theme) => toRgbString(theme.colors.lightGray))(props);
}
const StyledInput = styled(AnimatedStyledInput) `
  border-color: transparent;
  background-color: ${(props) => props.dark ? `rgba(${getLightgray(props)}, 0.7)` : '#fff'};

  &:hover,
  &:focus {
    border-color: transparent;
    background-color: ${themeColor('lightGray')};
  }

  ${(props) => props.animated &&
    css `
      will-change: padding-left;
      transition: padding-left 0.1s ease;

      &:not(:hover):not(:focus) {
        background-color: transparent;
        padding-left: 0px !important;
        border-color: transparent;
      }

      &:hover,
      &:focus {
        padding-left: 0.5em !important;
      }
    `}
`;
export function Searchbar(props) {
    return (_jsx(Adornment, Object.assign({ right: _jsx(MdSearch, {}) }, { children: _jsx(StyledInput, Object.assign({ animated: !props.dark }, props)) })));
}
