import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { prop } from 'ramda';
import { themeGlobal, themeSpace } from '../Theme';
import { Panel } from './Panel';
import { LoadingOverlay } from '../Loaders';
const Layout = styled.div `
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: ${prop('width')}px minmax(0, 1fr);
  grid-template-rows: auto;
  grid-gap: ${themeSpace(1)};
  background-color: #f4f4f4;

  & > * {
    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      top: calc(${themeGlobal('headerHeight')}px + ${themeSpace(2)});
      position: sticky;
      max-height: calc(
        ${themeGlobal('contentHeight')} -
          ${({ heightOffset }) => heightOffset !== null && heightOffset !== void 0 ? heightOffset : '0px'}
      );
      z-index: 1;
    }

    &:nth-child(2) {
      box-sizing: border-box;
      min-height: calc(
        ${themeGlobal('contentHeight')} -
          ${({ heightOffset }) => heightOffset !== null && heightOffset !== void 0 ? heightOffset : '0px'}
      );
      padding: ${themeSpace(2)};
    }
  }
`;
const selector = 'ex-layout__master-detail';
export function MasterDetail({ width = 350, loading = false, heightOffset, children }) {
    return (_jsxs(Layout, Object.assign({ id: selector, width: width, heightOffset: heightOffset }, { children: [_jsx(Panel, { children: _jsx(LoadingOverlay, Object.assign({ loading: loading }, { children: children[0] })) }), _jsx(Panel, { children: _jsx(LoadingOverlay, Object.assign({ loading: loading }, { children: children[1] })) })] })));
}
MasterDetail.selector = '#' + selector;
