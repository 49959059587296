var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { juxt } from '@exivity/fp';
import { useFieldContext } from '../Field/context';
import { outlined, regular } from '../Input/StyledInput';
import { useValidState } from '../Input';
export const StyledTextArea = styled.textarea `
  ${regular}
  ${outlined}

  ${(props) => props.resize &&
    css `
      resize: ${props.resize};
    `};

  min-height: 8.5em;
  ${(props) => props.width &&
    css `
      width: ${props.width};
    `}
`;
export function TextArea(_a) {
    var { onChange, rows = 5 } = _a, rest = __rest(_a, ["onChange", "rows"]);
    const { id } = useFieldContext();
    const validityProps = useValidState();
    const inputOnChange = (e) => {
        onChange && onChange(e.target.value, e);
    };
    return (_jsx(StyledTextArea, Object.assign({ id: id, name: id, rows: rows }, validityProps, { onChange: juxt([inputOnChange, validityProps.onChange]) }, rest)));
}
