import { RequestMethod } from './FetchTypes'
import { HookContext, HookType } from './fetchWithHooks'

export function toQueryObject(query: string) {
  if (query) {
    return query.split('&').reduce((acc, next) => {
      const [property, value] = next.split('=')
      acc[decodeURIComponent(property)] = decodeURIComponent(value)
      return acc
    }, {} as { [key: string]: string })
  }

  return {}
}

const DATA_METHODS = ['POST', 'PATCH', 'PUT']

export function parseUrl(url: string) {
  // eslint-disable-next-line max-len
  let [str, host, path, id] =
    url.match(
      /(https?:\/\/.*)\/v1([/[a-z]*(?:[/?]|$)]*)\/?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|[0-9]*)\??(.*)?/
    ) || []
  path = path ? path.replace(/^\/+|[/?]+$/g, '') : path
  const [, query] = url.split('?')
  return [str, host, path, id, query]
}

export function createHookContext(
  requestInfo: RequestInfo,
  options: RequestInit = {},
  otherOptions: any = {}
): HookContext {
  const matches =
    typeof requestInfo === 'string'
      ? parseUrl(requestInfo)
      : parseUrl(requestInfo.url)

  const [, , path, id, query] = matches

  const hook = {
    type: HookType.Before,
    options: otherOptions,

    params: { query: toQueryObject(query) },
    id,

    get method() {
      return options.method
        ? (options.method as RequestMethod)
        : RequestMethod.GET
    },

    get path() {
      if (!path) {
        return ''
      }

      return path
    }
  } as const

  const data =
    options.method && DATA_METHODS.includes(options.method.toUpperCase())
      ? { data: options.body }
      : null

  return Object.assign(hook, data)
}

export function isHookContext(hookObject: any) {
  return (
    typeof hookObject === 'object' &&
    typeof hookObject.method === 'string' &&
    typeof hookObject.type === 'string'
  )
}
