import {
  RecordRelationship,
  RecordHasOneRelationship,
  RecordHasManyRelationship
} from '@orbit/records'
import { Dict } from '@orbit/utils'
import { ServiceChargeModel, ServiceInterval } from '@exivity/data-layer'

import { EnumLiteralsOf } from './index'

const SERVICE_TYPE = 'service'

export const ServiceType = Object.freeze({
  ServiceNameInHeader: 'service_name_in_header' as 'SERVICE_NAME_IN_HEADER',
  ServiceNameInData: 'service_name_in_data' as 'SERVICE_NAME_IN_DATA'
})

export type ServiceType = EnumLiteralsOf<typeof ServiceType>

export const ServiceChargeType = Object.freeze({
  DeprecatedManual: 'manual' as 'MANUAL',
  DeprecatedAutomatic: 'automatic' as 'AUTOMATIC',
  DeprecatedManualPerInterval: 'manual_per_interval' as 'MANUAL_PER_INTERVAL',
  DeprecatedAutomaticPerInterval:
    'automatic_per_interval' as 'AUTOMATIC_PER_INTERVAL',
  Manual: 'manual_per_unit' as 'MANUAL_PER_UNIT',
  Automatic: 'automatic_per_unit' as 'AUTOMATIC_PER_UNIT',
  ManualTieredStandard: 'manual_tiered_standard' as 'MANUAL_TIERED_STANDARD',
  ManualTieredInherited: 'manual_tiered_inherited' as 'MANUAL_TIERED_INHERITED',
  None: 'none' as 'NONE',
  Other: 'other' as 'OTHER'
})

export type ServiceChargeType = EnumLiteralsOf<typeof ServiceChargeType>

export const ServiceCogsType = Object.freeze({
  DeprecatedManualPerInterval: 'manual_per_interval' as 'MANUAL_PER_INTERVAL',
  DeprecatedAutomaticPerInterval:
    'automatic_per_interval' as 'AUTOMATIC_PER_INTERVAL',
  None: 'none' as 'NONE',
  Manual: 'manual_per_unit' as 'MANUAL_PER_UNIT',
  Automatic: 'automatic_per_unit' as 'AUTOMATIC_PER_UNIT',
  ManualTieredStandard: 'manual_tiered_standard' as 'MANUAL_TIERED_STANDARD',
  ManualTieredInherited: 'manual_tiered_inherited' as 'MANUAL_TIERED_INHERITED'
})

export type ServiceCogsType = EnumLiteralsOf<typeof ServiceCogsType>

export const ServiceProrationType = Object.freeze({
  Full: 'full' as 'FULL',
  None: 'none' as 'NONE'
})

export type ServiceProrationType = EnumLiteralsOf<typeof ServiceProrationType>

export interface ServiceModel {
  type: typeof SERVICE_TYPE
  id: string
  attributes: ServiceAttributes
  relationships?: ServiceRelationships
}

export interface ServiceAttributes {
  key: string
  category_id: string
  description: string
  unit_label: string
  dset: string
  type: ServiceType
  usage_col: string
  consumption_col: string
  instance_col: string
  interval: ServiceInterval
  charge_model: ServiceChargeModel | null
  charge_type: ServiceChargeType
  cogs_type: ServiceCogsType
  proration_type: ServiceProrationType
  readonly created_at?: string
  readonly updated_at?: string
  readonly seen_at?: string
  readonly orphan?: boolean
}

export interface ServiceRelationships extends Dict<RecordRelationship> {
  servicecategory: RecordHasOneRelationship
  rates: RecordHasManyRelationship
  adjustments: RecordHasManyRelationship
  dset: RecordHasOneRelationship
  items: RecordHasManyRelationship
}

export const DEFAULT_SERVICE: ServiceModel = {
  id: '',
  type: SERVICE_TYPE,
  attributes: {
    type: ServiceType.ServiceNameInHeader,
    interval: ServiceInterval.Individually,
    charge_type: ServiceChargeType.Automatic,
    cogs_type: ServiceCogsType.Automatic,
    proration_type: ServiceProrationType.None,
    charge_model: null
  } as any
}
