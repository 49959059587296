import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement } from 'react';
import styled from 'styled-components';
import { useClientRect } from '../useClientRect';
import { matchThemeProp } from '../Theme';
const StyledAdornment = styled.i `
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;

  font-size: ${matchThemeProp((theme) => theme.global.sizes, {
    modifier: (em) => em * 20
})}px;
`;
const AdornmentWrapper = styled.div `
  position: relative;
  width: 100%;

  .adornment {
    &__left {
      left: ${(props) => props.inset}px;
    }

    &__right {
      right: ${(props) => props.inset}px;
    }
  }
`;
export const Adornment = ({ left, right, children, inset = 10, test = 'adornment', className }) => {
    const [leftRect, leftRef] = useClientRect();
    const [rightRect, rightRef] = useClientRect();
    const paddingLeft = leftRect ? leftRect.width + inset : 0;
    const paddingRight = rightRect ? rightRect.width + inset : 0;
    const newProps = Object.assign(Object.assign({}, children.props), { style: Object.assign({ paddingRight,
            paddingLeft, width: '100%' }, (children.props.style || {})) });
    return (_jsxs(AdornmentWrapper, Object.assign({ className: className, inset: inset, "data-test": `${test}-wrapper` }, { children: [cloneElement(children, newProps), _jsx(StyledAdornment, Object.assign({ ref: leftRef, className: "adornment__left", "data-test": `left-${test}` }, { children: left })), _jsx(StyledAdornment, Object.assign({ ref: rightRef, className: "adornment__right", "data-test": `right-${test}` }, { children: right }))] })));
};
