import React, { createContext, useContext } from 'react'
import { Resources, queries, saveRecord, useAtomic, useCacheQueryFn } from '@exivity/data-layer';
import { pipe } from 'ramda';
import { withAttribute, empty } from '@exivity/data-layer';
import { format } from 'date-fns'

import { useDateFormatter } from '../../../components/hooks/useDateFormatter';
import { useProvideJobCtx } from '../resources/useJobs';
import { useReportStatuses, ReportActions } from '../resources/useReportStatuses';

const JobsContext = createContext({
  jobs: [] as Resources['job'][],
  appNeedsSync: false
})

const ReportsThatRequireAction = createContext({} as ReportActions)

const createJob = ({
  reportdefinition_id,
  start_date,
  end_date
}: Resources['job']['attributes']['options']) =>
  pipe(
    empty('job'),
    withAttribute('job_type', 'prepare_report'),
    withAttribute('options', {
      reportdefinition_id,
      start_date,
      end_date
    })
  )()

export const useReportDefinitionStatusAlert = () => {
  return Object.keys(
    useContext(ReportsThatRequireAction)
  ).length > 0
}

export const useReportDefinitionsThatRequireAction = () => {
  const actions = useContext(ReportsThatRequireAction)
  const query = useCacheQueryFn()
  const atomic = useAtomic()
  const formatter = useDateFormatter()
  
  return Object.keys(actions).map((reportDefinitionId) => ({
    reportDefinition: query(queries.find('reportdefinition', reportDefinitionId)),
    start: format(
      formatter.parseISO(actions[reportDefinitionId].start_date),
      'PPP'
    ),
    end: format(
      formatter.parseISO(actions[reportDefinitionId].end_date),
      'PPP'
    ),
    options: {
      reportdefinition_id: reportDefinitionId,
      start_date: actions[reportDefinitionId].start_date,
      end_date: actions[reportDefinitionId].end_date
    },
    createJob: () => {
      return atomic(
        saveRecord(
          createJob({
            reportdefinition_id: reportDefinitionId,
            start_date: actions[reportDefinitionId].start_date,
            end_date: actions[reportDefinitionId].end_date
          })
        )
      )
    }
  }))
}

export const JobsProvider = ({ children }: { children: React.ReactNode }) => {
  const jobsContext = useProvideJobCtx()
  const reportStatusCtx = useReportStatuses(jobsContext.appNeedsSync)

  return (
    <ReportsThatRequireAction.Provider value={reportStatusCtx}>
      <JobsContext.Provider value={jobsContext }>
        {children}
      </JobsContext.Provider>
    </ReportsThatRequireAction.Provider>
  )
}

export const useJobsCtx = () => {
  return useContext(JobsContext).jobs	
}

export const useAppNeedsSync = () => {
  return useContext(JobsContext).appNeedsSync	
}
