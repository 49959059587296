import { AppBar } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { MdPerson } from 'react-icons/md'
import { getPathname } from '@exivity/routing'

import { useCanRenderFeature } from '../../../components/organisms/Feature/Feature'
import { useCurrentUser, useLogout } from '../../auth/hooks'
import { app } from '../../../routes'

export function UserMenu() {
  const canRenderFeature = useCanRenderFeature()
  const logout = useLogout()
  const user = useCurrentUser()

  const name =
    (canRenderFeature('enhancedUserProvisioning')
      ? user?.display_name
      : user?.username) || ''

  return (
    <AppBar.Dropdown label={name} icon={<MdPerson />}>
      <AppBar.Avatar name={name} email={user?.email_address || ''} />
      <AppBar.Separator />
      <AppBar.Link to={getPathname(app.routes.profile)}>
        {translate('My profile')}
      </AppBar.Link>
      <AppBar.Link to={getPathname(app.routes.myNotifications)}>
        {translate('My notifications')}
      </AppBar.Link>
      <AppBar.Link
        onClick={(e) => {
          e.preventDefault()
          logout()
        }}
      >
        {translate('Sign out')}
      </AppBar.Link>
    </AppBar.Dropdown>
  )
}
