var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { MdArrowDropDown } from 'react-icons/md';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icon';
import { Placement } from '../LegacyDropdown/Popper';
import { themeColor, themeSpace } from '../Theme';
import { Button } from './Button';
export const SelectOption = styled.button `
  all: unset;
  line-height: 30px;
  padding: 0 ${themeSpace(2)};

  &:hover,
  :focus {
    background-color: ${themeColor('lightGray')};
  }
`;
SelectOption.defaultProps = {
    type: 'button'
};
const StyledButton = styled(Button) `
  padding: 0;

  ${Icon} {
    margin: 0;
  }
`;
StyledButton.displayName = 'TriggerButton';
export function Select(_a) {
    var { placement = Placement.BOTTOM_END, children, onClick } = _a, rest = __rest(_a, ["placement", "children", "onClick"]);
    const reference = (_jsx(StyledButton, Object.assign({}, rest, { icon: _jsx(MdArrowDropDown, {}), onClick: (e) => {
            onClick && onClick(e);
        } })));
    return (_jsx(Dropdown, Object.assign({ placement: placement, closeOnClick: false, reference: reference }, { children: children })));
}
