import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { useOutsideClickListener } from '../useOutsideClickListener/useOutsideClickListener';
const defaultFn = () => ({});
const StyledDiv = styled.div `
  display: block;
`;
export const OutsideClickListener = ({ onOutsideClick = defaultFn, children }) => {
    const myRef = useOutsideClickListener(onOutsideClick);
    return _jsx(StyledDiv, Object.assign({ ref: myRef }, { children: children }));
};
