import { parse } from 'date-fns'

import config from '../../../../application/config'
import { FetchStatus } from '../../../../API/types'
import { RootState } from '../../../../reducers'
import { Dimension } from '../dimensions'
import { FORMAT } from '../filters'

import { getDepthLabels } from './account'

const makeSelector =
  <S extends RootState, SR>(slice: (state: S) => SR) =>
  <R>(cb: (partial: SR) => R) =>
  (state: S) =>
    cb(slice(state))

const api = makeSelector((state) => state.reports.api)

const dimensions = makeSelector((state) => state.reports.dimensions)

const filters = makeSelector((state) => state.reports.filters)

type ReportType = 'instances' | 'accounts' | 'services'

const dimensionSelectors = {
  getDimensionData: <T extends Dimension>(dimension: T) => {
    return dimensions(
      (dimensions) =>
        dimensions[dimension]
          .data as RootState['reports']['dimensions'][T]['data']
    )
  },

  getDataSingleAccountSummary: <
    T extends 'summaryInstances' | 'summaryServices'
  >(
    dimension: T,
    accountId: string | null
  ) => {
    return dimensions((dimensions) => {
      const data = dimensions[dimension].data
      if (!data || !accountId) return []

      return data[accountId] || []
    })
  },

  getDataAllAccountsSummary: <T extends 'summaryInstances' | 'summaryServices'>(
    dimension: T
  ) => {
    return dimensions((dimensions) => {
      const data = dimensions[dimension].data

      return data ? Object.values(data) : []
    })
  },

  summaryReportHasData: dimensions(
    (dimensions) =>
      !!dimensions.summaryServices.data || !!dimensions.summaryInstances.data
  ),

  getSummaryReportAccountIds: dimensions((dimensions) => {
    return dimensions.summaryServices.data
      ? Object.keys(dimensions.summaryServices.data)
      : []
  }),

  getReportAccountIds: dimensions(
    (dimensions) => dimensions.resources.data?.account_ids || []
  ),

  getReportServiceIds: dimensions(
    (dimensions) => dimensions.resources.data?.service_ids || []
  ),

  getReportServiceCategoryIds: dimensions(
    (dimensions) => dimensions.resources.data?.servicecategory_ids || []
  ),

  isBusy<T extends Dimension>(dimension: T) {
    return dimensions(
      (dimensions) => dimensions[dimension].status === FetchStatus.Loading
    )
  },

  summaryIsBusy: dimensions(
    (dimensions) =>
      dimensions.summaryInstances.status === FetchStatus.Loading ||
      dimensions.summaryServices.status === FetchStatus.Loading
  ),

  shouldFetch<T extends Dimension>(dimension: T) {
    return dimensions(
      (dimensions) => dimensions[dimension].status === FetchStatus.Idle
    )
  }
}

const filterSelectors = {
  getSelectedReportId: filters((filters) => filters.report),
  getAccountDepth: filters((filters) => filters.depth),
  getMinimumAccessibleAccountDepth: filters(
    (filters) => filters.minimumAccessibleDepth
  ),
  getParentAccountDepth: filters((filters) =>
    filters.parentAccount
      ? Math.min(filters.depth + 1, config.reports.maxLevel)
      : filters.depth
  ),
  getSelectedAccount: filters((filters) => filters.account),
  getSelectedParentAccount: filters((filters) => filters.parentAccount),
  getSelectedServiceId: filters((filters) => filters.service),
  getSelectedServiceCategoryId: filters((filters) => filters.category),
  getGranularity: filters((filters) => filters.granularity),

  getInstanceGroupBy: filters((filters) => filters.instances.groupBy),

  // @todo use dateFormatter
  getDateRange: filters(
    (filters) =>
      filters.dateRange.map((date) => parse(date, FORMAT, new Date())) as [
        Date,
        Date
      ]
  ),

  getAccountDepthLabels: filters((filters) =>
    getDepthLabels(filters.report, filters.minimumAccessibleDepth)
  ),

  isIncludedInSummary(reportType: ReportType) {
    return filters((filters) => filters[reportType].includedInSummary)
  },

  shouldConsolidateSummary: filters((filters) => filters.summary.consolidated),

  getSummaryPrintMode: api((api) => api.summaryPrintMode)
}

export const selectors = {
  ...dimensionSelectors,
  ...filterSelectors
}
