import styled from 'styled-components';
import { themeColor } from '../Theme';
import { Icon } from '../Icon';
export const NavIcon = styled(Icon).attrs({
    className: 'nav__icon'
}) `
  color: ${themeColor('white')};
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 3px;
  margin-right: 10px;
`;
