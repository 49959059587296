var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Strategy } from '@orbit/coordinator';
function isAddRecordOp(operation) {
    return operation.op === 'addRecord';
}
// A strategy that cleans up old licences from the memory (when posting a new one)
// It also makes sure the licence key is not stored in memory
export class CleanLicenceStrategy extends Strategy {
    constructor() {
        super({
            name: 'clean-sensitive-data'
        });
        this.handleBeforeUpdate = (transform) => {
            if (Array.isArray(transform.operations)) {
                for (let operation of transform.operations) {
                    this.cleanLicence(operation);
                }
            }
            else {
                this.cleanLicence(transform.operations);
            }
        };
    }
    activate(coordinator, options) {
        const _super = Object.create(null, {
            activate: { get: () => super.activate }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.activate.call(this, coordinator, options);
            const memory = coordinator.getSource('memory');
            this._memory = memory;
            this._memory.on('beforeSync', this.handleBeforeUpdate);
        });
    }
    deactivate() {
        const _super = Object.create(null, {
            deactivate: { get: () => super.deactivate }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.deactivate.call(this);
            this._memory && this._memory.off('beforeSync', this.handleBeforeUpdate);
        });
    }
    cleanLicence(operation) {
        if (isAddRecordOp(operation) && operation.record.type === 'licence') {
            const records = this._memory &&
                this._memory.cache.query((q) => q.findRecords('licence'));
            if (Array.isArray(records)) {
                records.forEach((record) => {
                    this._memory &&
                        this._memory.cache.update((t) => t.removeRecord(record));
                });
            }
            operation.record.attributes && delete operation.record.attributes.licence;
        }
    }
}
