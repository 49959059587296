import React from 'react'
import { useSystemConfiguration } from '@exivity/data-layer'

import {
  alphaEnabled,
  FeatureFlags,
  FeatureFlagValue,
  featureFlags,
  FeatureNames
} from '../../../application/config/features'
import { systemConfigSelector } from '../../../domains/administration/resources'

import { canRenderFeature } from './helpers'

// @todo see if this is working as expected?
export function useFeatureFlag() {
  const betaEnabled = systemConfigSelector.isBetaEnabled(
    useSystemConfiguration()
  )

  return (flag?: FeatureFlagValue) => {
    if (flag === 'alpha' && alphaEnabled) {
      return true
    }

    if (flag === 'beta' && betaEnabled) {
      return true
    }

    if (flag === 'released') {
      return true
    }

    return true
    // throw new Error('feature has not been flagged.')
  }
}

export function useCanRenderFeature() {
  const betaEnabled = systemConfigSelector.isBetaEnabled(
    useSystemConfiguration()
  )
  // @todo make a dev global or test global
  if (process.env.NODE_ENV === 'test') return () => true

  return (feature: FeatureNames) => {
    return canRenderFeature(feature, {
      flags: featureFlags,
      alpha: alphaEnabled,
      beta: betaEnabled
    })
  }
}

type ExtensibleFeatureNames = FeatureNames

type Props = {
  name: ExtensibleFeatureNames
  flags?: FeatureFlags<ExtensibleFeatureNames>
  alpha?: boolean
  beta?: boolean
  allowUnknown?: boolean
  children: React.ReactElement<any>
}

const Feature = ({
  name,
  flags = featureFlags,
  alpha = alphaEnabled,
  allowUnknown = false,
  children
}: Props) => {
  const beta = systemConfigSelector.isBetaEnabled(useSystemConfiguration())

  return canRenderFeature(name, { flags, allowUnknown, alpha, beta })
    ? children
    : null
}

export default Feature
