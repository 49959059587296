var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import styled, { css } from 'styled-components';
import { injectIf, injectUnless, preciseEm } from '../utils';
import { matchButtonHeight, matchColorFromPurpose, matchThemeProp, themeColor, themeFontFamily, themeGlobal } from '../Theme';
import { Icon } from '../Icon';
const RadioButtonContext = createContext({
    value: '',
    onChange: () => { }
});
const Option = styled.button `
  --light-color: ${matchColorFromPurpose(200, 1, 'secondary')};
  --dark-color: ${matchColorFromPurpose(700, 1, 'secondary')};
  --text-dark: ${matchColorFromPurpose(900, 1, 'secondary')};

  display: inline-flex;
  gap: 0.5em;
  align-items: center;
  font-family: ${themeFontFamily};
  font-size: ${matchThemeProp((theme) => theme.global.sizes, {
    modifier: (em) => (em / 16) * 14
})}em;
  line-height: ${themeGlobal('lineHeight')};
  color: var(--text-dark);
  text-transform: uppercase;

  height: ${matchButtonHeight}em;
  padding: 0 ${preciseEm(1.5)}em;
  border-radius: 0px;
  border: 0px;
  background-color: var(--light-color);

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  ${injectIf('active', css `
      color: ${themeColor('white')};
      background-color: var(--dark-color);
    `)}

  ${injectUnless('active', css `
      &:hover {
        background-color: ${matchColorFromPurpose(300, 1, 'secondary')};
      }
    `)}
  
  ${Icon} {
    font-size: 1.5em;
  }
`;
const Container = styled.div `
  --radius: ${themeGlobal('borderRadius')}px;

  display: flex;

  ${Option}:first-of-type {
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
  }

  ${Option}:last-of-type {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
`;
function RadioButtonOption({ value, title, disabled, className, children }) {
    const _a = useContext(RadioButtonContext), { value: selectedValue, onChange, disabled: allDisabled } = _a, sizeAndPurpose = __rest(_a, ["value", "onChange", "disabled"]);
    return (_jsxs(_Fragment, { children: [_jsx("label", Object.assign({ htmlFor: `radio-btn-${value}`, hidden: true }, { children: children })), _jsx(Option, Object.assign({ id: `radio-btn-${value}`, className: className, type: "button" }, sizeAndPurpose, { title: title, disabled: allDisabled || disabled, active: value === selectedValue, onClick: () => onChange(value) }, { children: children }))] }));
}
function RadioButton(_a) {
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    return (_jsx(Container, Object.assign({ className: className }, { children: _jsx(RadioButtonContext.Provider, Object.assign({ value: props }, { children: children })) })));
}
RadioButton.Option = RadioButtonOption;
export { RadioButton };
