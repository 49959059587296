import { curry, mapObjIndexed } from '@exivity/fp';
const defaultSettings = {
    requiresAuth: true
};
const _createRoutes = curry((path, route) => {
    const pathname = path !== '/' ? path + route.path : route.path;
    return (route.routes
        ? Object.assign(Object.assign(Object.assign({}, defaultSettings), route), { pathname, routes: mapObjIndexed(_createRoutes(pathname), route.routes) }) : Object.assign(Object.assign(Object.assign({}, defaultSettings), route), { pathname }));
});
// For some reason I have to cast this to keep T inference all the way
// through: app.routes.reports.routes
export const createRoutes = _createRoutes('');
