import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { isAfter, isBefore, isSameDay, isSameMonth, isSameQuarter, isSameYear, isWithinInterval } from 'date-fns';
import { classNames } from '../../utils';
import { fromTheme } from '../../Theme';
import { CalendarMode } from './CalendarMode';
const isSame = {
    [CalendarMode.Days]: isSameDay,
    [CalendarMode.Months]: isSameMonth,
    [CalendarMode.Quarters]: isSameQuarter,
    [CalendarMode.Years]: isSameYear
};
export const isMac = () => {
    const ua = navigator.userAgent;
    return ua.indexOf('Mac OS X') > -1;
};
const isCtrlPressed = (e) => {
    return isMac() ? e.metaKey : e.ctrlKey;
};
export function TimeUnit({ value: [startValue, endValue], date, onHover, onStopHovering, onChange, mode, min, max, children }) {
    if ((min && isBefore(date, min)) || (max && isAfter(date, max))) {
        return _jsx("li", Object.assign({ className: "time-unit out-of-bounds" }, { children: children }));
    }
    const isStart = !!(startValue && isSame[mode](date, startValue));
    const isEnd = !!(endValue && isSame[mode](date, endValue));
    const isActive = Boolean(isStart || isEnd);
    const isWithinRange = Boolean(startValue &&
        endValue &&
        isWithinInterval(date, {
            start: startValue,
            end: endValue
        })) && !isActive;
    return (_jsx("li", Object.assign({ "data-testid": `time-unit${isActive ? '-active' : ''}${isWithinRange ? '-within-range' : ''}`, className: classNames({
            active: isActive,
            start: isStart,
            end: isEnd,
            'within-range': !isActive && isWithinRange
        }, 'time-unit'), onMouseOver: () => onHover === null || onHover === void 0 ? void 0 : onHover(date), onMouseLeave: () => onStopHovering === null || onStopHovering === void 0 ? void 0 : onStopHovering(date), onClick: (e) => onChange === null || onChange === void 0 ? void 0 : onChange(date, isCtrlPressed(e)) }, { children: children })));
}
const TIME_UNIT_HEIGHT = 24;
const TIME_UNIT_VERTICAL_PADDING = 4;
const TIME_UNIT_BORDER = 2;
export const TIME_UNIT_TOTAL_HEIGHT = TIME_UNIT_HEIGHT + 2 * TIME_UNIT_VERTICAL_PADDING + 2 * TIME_UNIT_BORDER;
export const StyledTimeUnits = styled.ul `
  padding: 10px 0;
  margin: 0;

  li {
    list-style-type: none;
    display: inline-block;
    text-align: center;
    border: ${TIME_UNIT_BORDER}px solid white;
  }

  .time-unit {
    height: ${TIME_UNIT_HEIGHT}px;
    padding: ${TIME_UNIT_VERTICAL_PADDING}px 0;
    cursor: pointer;
    user-select: none;

    &.active {
      color: white;
      background-color: ${fromTheme((theme) => theme.global.purposes.primary)};
    }

    &.out-of-bounds {
      background-color: ${fromTheme((theme) => theme.colors.lightGray)};
      cursor: not-allowed;
    }

    &:hover:not(.out-of-bounds) {
      color: white;
      background-color: ${fromTheme((theme) => theme.global.purposes.primary)};
    }
  }
`;
