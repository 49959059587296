import { useEffect, useState } from 'react';
import { useDataStore } from '../useDataStore';
import { useUserHasPermission } from '../permissions';
import { UserGroupPermission } from '../resources/resources.generated';
const debug = false;
export function useImportWorker(token, host) {
    const [importStatus, setImportStatus] = useState('idle');
    const canViewDatasets = useUserHasPermission([UserGroupPermission.ManageAccounts, UserGroupPermission.ManageServices]);
    const canManageMetadataDefinitions = useUserHasPermission([UserGroupPermission.ManageMetadataDefinitions]);
    const memory = useDataStore();
    useEffect(() => {
        const importWorker = new Worker(new URL('./import.worker.js', import.meta.url));
        importWorker.onmessage = ({ data }) => {
            const { type, payload } = data;
            switch (type) {
                case 'transform': {
                    memory.cache.preLargeSync();
                    debug && console.timeLog('importing records...');
                    memory
                        .sync(payload)
                        .then(() => {
                        debug && console.timeEnd('importing records...');
                        memory.cache.postLargeSync();
                        setTimeout(() => {
                            setImportStatus('succeeded');
                        }, 2000);
                    })
                        .catch((e) => {
                        debug && console.error(e);
                        setImportStatus('failed');
                    });
                    break;
                }
                case 'authError': {
                    setImportStatus('authError');
                    break;
                }
            }
        };
        importWorker.onerror = () => {
            setImportStatus('failed');
        };
        setImportStatus('pending');
        debug && console.time('importing records...');
        importWorker.postMessage({
            type: 'import',
            data: {
                isDebugging: debug,
                host,
                token,
                canViewDatasets,
                canManageMetadataDefinitions
            }
        });
        return () => {
            importWorker.terminate();
        };
    }, [memory, canViewDatasets, canManageMetadataDefinitions]);
    return importStatus;
}
