import { omit } from 'ramda';
import { sortRecords } from './sortRecords';
export const omitSort = omit(['sort']);
const emptyArr = [];
function hasExpressionValidId(qe) {
    return ((qe.op === 'findRecord' ||
        qe.op === 'findRelatedRecord' ||
        qe.op === 'findRelatedRecords') &&
        typeof qe.record.id === 'string' &&
        qe.record.id !== 'new');
}
function isFindRecords(qe) {
    return qe.op === 'findRecords';
}
// When query is not given valid id -> do not execute query
export function shouldNotExecuteQuery(qe) {
    return !isFindRecords(qe) && !hasExpressionValidId(qe);
}
// When id is null or undefined -> look at operation and return result accordingly
function resultWhenInvalidExpression(qe) {
    return qe.op === 'findRecords' || qe.op === 'findRelatedRecords'
        ? emptyArr
        : null;
}
function sortCollection(queryExpression, result) {
    if (result === undefined)
        return resultWhenInvalidExpression(queryExpression);
    return Array.isArray(result)
        ? sortRecords(result, queryExpression.sort)
        : result;
}
// Its possible to pass undefined or null as id in our queries builder
export function getExpressionResult(queryExpression, getResponse) {
    return (shouldNotExecuteQuery(queryExpression)
        ? resultWhenInvalidExpression(queryExpression)
        : sortCollection(queryExpression, getResponse()));
}
