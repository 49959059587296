import { translate } from '@exivity/translations'
import { lazy } from 'react'

const Logout = lazy(
  () =>
    import(
      /* webpackChunkName: 'auth.logout' */
      '../../domains/auth/pages/Logout'
    )
)

export const logout = {
  path: '/logout',
  meta: {
    title: () => translate('Logout')
  },
  component: Logout,
  requiresAuth: false
}
