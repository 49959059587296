"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getObjDifference = void 0;
function findDifferences(objA, objB) {
    const result = {};
    for (const key in objA) {
        if (['id', 'type', 'links'].includes(key)) {
            continue;
        }
        if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            result[key] = objA[key];
        }
    }
    return result;
}
function getObjDifference(updatedRecord, initialRecord) {
    if (!initialRecord)
        return updatedRecord;
    const diffsAB = findDifferences(updatedRecord.attributes, initialRecord.attributes);
    const diffsABRel = findDifferences(updatedRecord.relationships, initialRecord.relationships);
    return {
        id: updatedRecord.id,
        type: updatedRecord.type,
        attributes: Object.assign({}, diffsAB),
        relationships: Object.assign({}, diffsABRel)
    };
}
exports.getObjDifference = getObjDifference;
