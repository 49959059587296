import {
  BudgetReportActions,
  DATA_REPORTS_BUDGET_SELECT,
  DATA_REPORTS_BUDGET_SET_DATE_RANGE,
  DATA_REPORTS_UPDATE_BUDGETS
} from '../../../actions/data/reports/budgets/plain'
import { BudgetReportData } from '../../../API/APIResponseTypes/reports'
import { reportsActions } from '../../../domains/reports/state'

export type BudgetReport = {
  report: BudgetReportData[] | string
  range: [string, string] | null
  selectedBudget: string | null
}

const INITIAL_STATE: BudgetReport = {
  report: [] as BudgetReportData[],
  range: null,
  selectedBudget: null
}

export function budgets(
  state = INITIAL_STATE,
  action: BudgetReportActions
): BudgetReport {
  switch (action.type) {
    case DATA_REPORTS_UPDATE_BUDGETS:
      return {
        ...state,
        report: action.data
      }

    case DATA_REPORTS_BUDGET_SELECT:
      return {
        ...state,
        selectedBudget: action.budgetId
      }

    case DATA_REPORTS_BUDGET_SET_DATE_RANGE:
      return {
        ...state,
        range: action.range ? [action.range[0], action.range[1]] : action.range
      }

    case reportsActions.filters.updateReport.type:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}
