import { call } from '../'
import {
  APIEndpoints,
  APIResponseSchema,
  GetParameters,
  GetResponse
} from '../ResponseSchema'
import { RequestMethod } from '../fetchWithHooks/FetchTypes'

// For the time being I made both url and params parameters lenient for legacy purposes.
function fetchMethodBody<M extends RequestMethod>(method: M) {
  function fetchWithBody<
    T extends APIEndpoints,
    Params extends GetParameters<APIResponseSchema[T][M]>
  >(
    url: T,
    parameters?: Params,
    body?: Record<string, any>,
    options?: Record<string, any>
  ): Promise<GetResponse<APIResponseSchema<Params>[T][M]>>

  function fetchWithBody(
    url: string,
    parameters?: Record<string, any>,
    body?: Record<string, any>,
    options?: Record<string, any>
  ): Promise<any>

  function fetchWithBody(
    url: string,
    parameters = {},
    body = {},
    options = {}
  ) {
    return call(method, url, parameters, body, options)
  }

  return fetchWithBody
}

function fetchMethod<M extends RequestMethod>(method: M) {
  function fetchNoBody<
    T extends APIEndpoints,
    Params extends GetParameters<APIResponseSchema[T][M]>
  >(
    url: T,
    parameters?: Params,
    options?: Record<string, any>
  ): Promise<GetResponse<APIResponseSchema<Params>[T][M]>>

  function fetchNoBody(
    url: string,
    parameters?: Record<string, any>,
    options?: Record<string, any>
  ): Promise<any>

  function fetchNoBody(
    url: string,
    parameters = {} as Record<string, any>,
    options = {}
  ) {
    return call(method, url, parameters, {}, options)
  }

  return fetchNoBody
}

export const get = fetchMethod(RequestMethod.GET)
export const post = fetchMethodBody(RequestMethod.POST)
export const put = fetchMethodBody(RequestMethod.PUT)
export const patch = fetchMethodBody(RequestMethod.PATCH)
export const del = fetchMethodBody(RequestMethod.DELETE)
export const head = fetchMethod(RequestMethod.HEAD)
