export class AccountStructure {
    constructor(account) {
        var _a;
        this.account = account;
        this.children = [];
        this.nodePath = [];
        this.isLeaf = (((_a = account.relationships.children) === null || _a === void 0 ? void 0 : _a.data) || []).length === 0;
    }
    getSortedChildren(sort) {
        return [...this.children].sort((a, b) => {
            if (sort === 'name') {
                return a.account.attributes.name.localeCompare(b.account.attributes.name);
            }
            return a.account.attributes.level_keys[a.account.attributes.level - 1].key.localeCompare(b.account.attributes.level_keys[a.account.attributes.level - 1].key);
        });
    }
}
export function isPathExpanded(nodePath, expandedState) {
    let currentState = expandedState;
    for (const index of nodePath) {
        if (typeof currentState === 'boolean')
            return false;
        if (currentState && currentState[index] !== undefined) {
            currentState = currentState[index];
        }
        else {
            return false;
        }
    }
    return true;
}
export function setInPlace(state, path) {
    let current = state;
    for (let i = 0; i < path.length; i++) {
        if (i === path.length - 1) {
            current[path[i]] = true;
        }
        else {
            if (!current[path[i]]) {
                current[path[i]] = {};
            }
            if (typeof current[path[i]] === 'boolean') {
                current[path[i]] = {};
            }
            current = current[path[i]];
        }
    }
}
export function searchTree(accountNodes, attribute, query = '', initialExpandedState = {}) {
    if (query.length === 0)
        return initialExpandedState;
    const lowerCaseQuery = query.toLowerCase();
    function searchNodes(nodes, state) {
        nodes.forEach((node) => {
            const attributeValue = attribute === 'key'
                ? node.account.attributes.level_keys[node.account.attributes.level - 1].key.toLowerCase()
                : node.account.attributes.name.toLowerCase();
            if (attributeValue.includes(lowerCaseQuery)) {
                setInPlace(initialExpandedState, node.nodePath);
            }
            if (node.children.length > 0) {
                searchNodes(node.children, state);
            }
        });
    }
    searchNodes(accountNodes, initialExpandedState);
    return initialExpandedState;
}
export function getList(accounts, expanded, isSearch, sortKey) {
    const result = [];
    const stack = [...accounts];
    while (stack.length > 0) {
        const account = stack.shift();
        if (!account)
            continue;
        const isExpanded = isPathExpanded(account.nodePath, expanded);
        if (isExpanded) {
            result.push(account);
            stack.unshift(...account.getSortedChildren(sortKey));
        }
        else if (!isSearch) {
            result.push(account);
        }
    }
    return result;
}
