import { translate } from '@exivity/translations'
import { lazy } from 'react'

const ResetPassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'auth.resetPassword' */
      '../../domains/auth/pages/ResetPassword'
    )
)

export const resetPassword = {
  path: '/resetpassword/:token?',
  meta: {
    title: () => translate('Reset password'),
  },
  component: ResetPassword,
  requiresAuth: false
}
