import { insertIf } from '../../../utils/misc'
import { NOT_AVAILABLE_CHARACTER } from '../.'
import { spreadMetadataValues } from '../metadata'
import auth from '../../../utils/auth'
import { UserGroupPermission } from '../../../data/types'

export default (report, includeCogsAndNet) => {
  if (!report) {
    return [];
  }

  // For testing purposes in if statement
  if (includeCogsAndNet === undefined) {
    includeCogsAndNet = auth.can(UserGroupPermission.ViewCogs);
  }

  let metadataKeys = {}

  const services = report.reduce((record, next) => {
    const key = next.service_id

    if (!record[key]) {
      const cogsAndNet = {
        cogs: next.total_cogs,
        net: next.total_net
      }

      if (record.metadata) {
        const keys = Object.keys(record.metadata);
  
        for (let i = 0; i < keys.length; i++) {
          metadataKeys[keys[i]] = keys[i];
        }
      }

      record[key] = {
        id: key,
        type: 'service',
        key: next.key,
        chargeType: next.service_charge_type,
        cogsType: next.service_cogs_type,
        prorationType: next.proration_type,
        interval: next.interval,
        service: next.service_description,
        category: next.servicecategory_name,
        quantity: next.total_quantity,
        unit: next.service_unit_label,
        charge: next.total_charge,
        avgUnitBasedRate: next.service_charge_type === 'none'
          ? NOT_AVAILABLE_CHARACTER
          : next.avg_unit_based_rate,
        ...insertIf(includeCogsAndNet, cogsAndNet),
        ...(next.metadata ? spreadMetadataValues(next.metadata) : {})
      }
    } else {
      if (includeCogsAndNet) {
        record[key].cogs = record[key].cogs + next.total_cogs;
        record[key].net = record[key].net + next.total_net;
      }

      record[key].quantity = record[key].quantity + next.total_quantity
      record[key].charge = record[key].charge + next.total_charge
    }

    return record
  }, {})

   // Calculate rateAvg and then return
  return {
    report: Object.values(services),
    metaColumns: Object.keys(metadataKeys).map((key) => ({
      key: `metadata_${key}`,
      label: key,
      type: 'string'
    }))
  }
}
