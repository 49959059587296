import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createGlobalStyle, ThemeProvider as NativeThemeProvider } from 'styled-components';
import { useWebFonts } from './useWebFonts';
import { defaultTheme } from './defaultTheme';
import { setThemeBrandAndInverse } from './';
const GlobalStyle = createGlobalStyle `
  body > * {
    font-family: 'Fira Sans', sans-serif;
  }
`;
export function ThemeProvider({ theme = defaultTheme, brandColor, children }) {
    const [selectedTheme, setTheme] = useState(theme);
    useEffect(() => {
        if (brandColor) {
            setTheme(setThemeBrandAndInverse(brandColor));
        }
    }, [brandColor]);
    useWebFonts(selectedTheme.fonts);
    return (_jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsxs(NativeThemeProvider, Object.assign({ theme: selectedTheme }, { children: [_jsx(GlobalStyle, {}), children] })) })));
}
