var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { useToggle } from '@exivity/hooks';
import { when, always } from '@exivity/fp';
import { useOutsideClickListener } from '../useOutsideClickListener';
import { themeColor, themePriority } from '../Theme';
export const Drop = styled.div `
  display: inline-block; // This makes the div adjust its width based on content
  overflow-x: auto; // Add scrolling in case content is too wide
  background-color: ${themeColor('white')};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  z-index: ${themePriority(4)};
  min-width: 200px;
  max-width: 500px;
`;
export function Dropdown(_a) {
    var { style, className = '', reference, placement = 'bottom-start', modifiers, closeOnClick = true, disabled, isOpen, onToggleOpen, children } = _a, divProps = __rest(_a, ["style", "className", "reference", "placement", "modifiers", "closeOnClick", "disabled", "isOpen", "onToggleOpen", "children"]);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [open, toggleOpen] = useToggle(false);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers
    });
    const show = isOpen || open;
    const toggleDropdown = disabled ? () => { } : onToggleOpen || toggleOpen;
    const outsideClickRef = useOutsideClickListener(() => show && toggleDropdown());
    return (_jsxs("div", Object.assign({ ref: outsideClickRef, style: style, className: className }, divProps, { children: [_jsx("div", Object.assign({ ref: setReferenceElement, onKeyDown: (e) => e.key === 'Enter' && toggleDropdown(), onClick: toggleDropdown }, { children: reference })), show && (_jsx(Drop, Object.assign({ ref: setPopperElement, className: "dropdown__drop", style: styles.popper, onClick: when(always(closeOnClick), toggleDropdown) }, attributes.popper, { children: children })))] })));
}
