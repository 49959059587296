import { useSelector, useDispatch } from 'react-redux'
import { SelectFilter, Tooltip } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useParams } from '@exivity/routing'

import { reportsActions, reportSelectors } from '../../state'
import { useMinimumAccessibleDepth } from '../useMinimumAccessibleDepth'

export function AccountDepthFilter() {
  const dispatch = useDispatch()
  const [, setParams] = useParams()
  const depth = useSelector(reportSelectors.getAccountDepth)
  const depthLabels = useSelector(reportSelectors.getAccountDepthLabels)
  const selectedReport = useSelector(reportSelectors.getSelectedReportId)

  useMinimumAccessibleDepth()

  const labels = Object.values(depthLabels)

  return (
    <Tooltip
      placement="bottom"
      content={(() => {
        if (!selectedReport) {
          return translate('Please choose a report first')
        }

        if (!labels.length) {
          return translate('No accounts in this report')
        }
      })()}
    >
      <SelectFilter
        placeholder={translate('Depth')}
        disabled={!selectedReport || !labels.length}
        inputValue={depthLabels[depth]}
        value={depthLabels[depth]}
        data={labels}
        onChange={(depthLabel: string) => {
          const entry = Object.entries(depthLabels).find(
            ([, label]) => label === depthLabel
          )

          if (entry)
            dispatch(reportsActions.filters.updateDepth(Number(entry[0])))
          // At summary report se id when switching to null. Better rename id to accountId.
          setParams('id', null)
        }}
      />
    </Tooltip>
  )
}
