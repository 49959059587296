var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MdAdd, MdEdit, MdDelete, MdDone, MdClear } from 'react-icons/md';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { Button } from './Button';
const StyledButton = styled(Button).attrs(({ round, children }) => ({
    children: round ? undefined : children
})) `
  ${Icon} {
    font-size: 1.6em;
    position: relative;
  }
`;
export function New(_a) {
    var { children = 'New', type = 'submit', round } = _a, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, Object.assign({ "data-testid": "add-button", success: true, type: type, round: round }, rest, { children: children })));
}
export function Add(_a) {
    var { children = 'Add', type = 'submit', round } = _a, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, Object.assign({ "data-testid": "add-button", icon: _jsx(MdAdd, {}), success: true, type: type, round: round }, rest, { children: children })));
}
export function Create(_a) {
    var { children = 'Create', type = 'submit', round } = _a, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, Object.assign({ "data-testid": "create-button", icon: _jsx(MdDone, {}), success: true, type: type, round: round }, rest, { children: children })));
}
export function Delete(_a) {
    var { children = 'Delete', type = 'button', round } = _a, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, Object.assign({ "data-testid": "delete-button", icon: _jsx(MdDelete, {}), danger: true, type: type, round: round }, rest, { children: children })));
}
export function Edit(_a) {
    var { children = 'Edit', type = 'submit', round } = _a, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, Object.assign({ "data-testid": "edit-button", icon: _jsx(MdEdit, {}), primary: true, type: type, round: round }, rest, { children: children })));
}
export function Update(_a) {
    var { children = 'Update', type = 'submit', round } = _a, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, Object.assign({ "data-testid": "update-button", icon: _jsx(MdDone, {}), primary: true, type: type, round: round }, rest, { children: children })));
}
export function Cancel(_a) {
    var { children = 'Cancel', type = 'button', round } = _a, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, Object.assign({ "data-testid": "cancel-button", icon: _jsx(MdClear, {}), outlined: true, secondary: true, type: type, round: round }, rest, { children: children })));
}
export function Clear(_a) {
    var { children = 'Cancel', type = 'button' } = _a, rest = __rest(_a, ["children", "type"]);
    return (_jsx(StyledButton, Object.assign({ outlined: true, secondary: true, type: type, "data-testid": "clear-button", icon: _jsx(MdClear, {}) }, rest, { children: children })));
}
