const neutral = {
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121' // dark
};
const primary = {
    100: '#CCF0FC',
    200: '#99E0F6',
    300: '#66CEEE',
    400: '#33BCE4',
    500: '#00A8D8',
    600: '#0095C0',
    700: '#0081A8',
    800: '#006D8E',
    900: '#005874'
};
const success = {
    100: '#DCFCE7',
    200: '#B2F7D1',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#0F813B',
    800: '#166534',
    900: '#14532D'
};
const warning = {
    100: '#FEF3C7',
    200: '#FDE6A7',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B85D03',
    800: '#92400E',
    900: '#78350F'
};
const danger = {
    100: '#FEE2E2',
    200: '#FBD7D7',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B81313',
    800: '#991B1B',
    900: '#7F1D1D'
};
// default secondary to neutral palette
const secondary = neutral;
// default brand to primary palette
const brand = primary;
const legacyColors = {
    dark: '#222',
    lightDark: '#2e2e2e',
    darkGray: '#666',
    gray: '#999',
    lightGray: '#eee',
    veryLightGray: '#f8f8f8',
    blue: '#00a8d8',
    red: '#ef5350',
    orange: '#faa05a',
    yellow: '#FAF25A',
    green: '#379e4b'
};
const blackAndWhite = {
    black: '#000000',
    white: '#FFFFFF'
};
export const PALETTE = Object.assign(Object.assign(Object.assign({}, blackAndWhite), { neutral,
    primary,
    secondary,
    success,
    warning,
    danger,
    brand }), legacyColors);
