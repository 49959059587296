import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { colorFromPurpose } from '../Theme';
import { Select } from './Select';
function isSelectableTitle(props) {
    return typeof props.data !== 'undefined';
}
export const Text = styled.span `
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${colorFromPurpose('secondary', 800)};
`;
const SelectTitle = styled(Select) `
  button {
    color: ${colorFromPurpose('secondary', 800)};
    font-size: 16px;
  }
`;
export function Title(props) {
    return isSelectableTitle(props) ? (_jsx(SelectTitle, { label: props.children, data: props.data, onChange: props.onChange })) : (_jsx(Text, { children: props.children }));
}
