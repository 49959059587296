var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { getDaysInMonth, setDate, getDay, startOfMonth } from 'date-fns';
import { globalFont } from '../../../utils';
import { CalendarMode } from '../CalendarMode';
import { StyledTimeUnits, TimeUnit, TIME_UNIT_TOTAL_HEIGHT } from '../shared';
export const StyledWeekDays = styled.ul `
  ${globalFont}
  opacity: 0.6;
  margin: 0;
  padding: 10px 0;

  li {
    display: inline-block;
    width: 14.28%;
    text-align: center;
  }
`;
export const WeekDays = () => (_jsxs(StyledWeekDays, { children: [_jsx("li", { children: "Mo" }), _jsx("li", { children: "Tu" }), _jsx("li", { children: "We" }), _jsx("li", { children: "Th" }), _jsx("li", { children: "Fr" }), _jsx("li", { children: "Sa" }), _jsx("li", { children: "Su" })] }));
const MAX_AMOUNT_OF_WEEKS = 6;
export const StyledDays = styled(StyledTimeUnits) `
  height: ${MAX_AMOUNT_OF_WEEKS * TIME_UNIT_TOTAL_HEIGHT}px;

  li {
    width: calc(14.28% - 4px);
  }
`;
export function Days(_a) {
    var { value, browserDate, onHoverItem, onEndHoverItem } = _a, rest = __rest(_a, ["value", "browserDate", "onHoverItem", "onEndHoverItem"]);
    const weekdayOffset = getDay(startOfMonth(browserDate)) - 1;
    const nbOfPlaceholderDays = weekdayOffset < 0 ? 7 + weekdayOffset : weekdayOffset;
    function renderDay(_, i) {
        const day = i + 1 - nbOfPlaceholderDays;
        const thisDay = setDate(browserDate, day);
        if (i < nbOfPlaceholderDays)
            return _jsx("li", { "data-testid": "calendar-days-placeholder" }, i);
        return (_jsx("span", Object.assign({ "data-testid": "calendar-days-day" }, { children: _jsx(TimeUnit, Object.assign({ date: thisDay, mode: CalendarMode.Days, value: value, onHover: onHoverItem, onStopHovering: onEndHoverItem }, rest, { children: String(day) })) }), i));
    }
    const nbOfDays = getDaysInMonth(browserDate) + nbOfPlaceholderDays;
    return (_jsxs(_Fragment, { children: [_jsx(WeekDays, {}), _jsx(StyledDays, Object.assign({ "data-testid": "calendar-days" }, { children: new Array(nbOfDays).fill(null).map(renderDay) }))] }));
}
