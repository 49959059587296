export enum SummaryGroupServicesBy {
  ServiceCategory = 'servicecategory_id'
}

export enum SummaryGroupInstancesBy {
  Instances = 'instance_value',
  Services = 'service_id'
}

export enum Granularity {
  Day = 'day',
  Month = 'month'
}
