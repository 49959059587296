import React from "react";
import PropTypes from "prop-types";

import { classNames } from "../../../utils/misc";

import "./index.css";

/**
 * @type {React.ComponentType<any>}
 */
const Label = ({ children, className, htmlFor }) => (
  <label
    className={classNames(
      {
        "ex-label": true,
      },
      className
    )}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
};

Label.defaultProps = {
  htmlFor: "",
  className: "",
};

export default Label;
