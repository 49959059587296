import React from "react";
import PropTypes from "prop-types";
import serialize from "form-serialize";

import Toolbar from "./Toolbar";
import Element from "./Element";
import TextInput from "./TextInput";
import TextArea from "./TextArea";
import Checkbox from "./Checkbox";
import Dropdown from "./Dropdown";

import "./index.css";

const onSubmit = (event, cb) => {
  event.preventDefault();

  const form = event.target;
  const data = serialize(form, {
    hash: true,
    empty: true,
  });

  cb && cb(data, event.target);
};

const onKeyPress = (event) => {
  const txtArea = /textarea/i.test(event.target.tagName);
  if (!txtArea && (event.which || 0) === 13) {
    event.preventDefault();
  }
};

const onInvalid = (event, cb) => {
  if (cb) {
    event.preventDefault();
    cb(event.target.name);
  }
};

const Form = (props) => (
  <form
    className={`ex-form uk-form-${props.variation} ${props.className || ""}`}
    noValidate={!props.validate}
    style={props.style}
    onSubmit={(event) => onSubmit(event, props.onSubmit)}
    onInvalid={(event) => onInvalid(event, props.onInvalid)}
    onKeyPress={props.noEnterSubmit ? onKeyPress : null}
  >
    {props.children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noEnterSubmit: PropTypes.bool,
  onInvalid: PropTypes.func,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
  validate: PropTypes.bool,
  variation: PropTypes.oneOf(["stacked", "horizontal"]),
};

Form.defaultProps = {
  variation: "stacked",
  validate: true,
  noEnterSubmit: false,
};

export default Form;

export { Toolbar, Element, TextInput, TextArea, Checkbox, Dropdown };
