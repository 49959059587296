import union from 'lodash/union';
import diff from 'lodash/difference';
import { PARENT, getParents, getChildren } from '../utils/tree';
import { makeGetAccessValues } from '../utils/accessor';
import { compose } from '../utils/compose';
export function hasCollapsedParents(item, keyAccessor, expanded) {
    return getParents(item).some((parent) => !expanded.includes(keyAccessor(parent)));
}
export function toggleExpansion(key, expanded) {
    return expanded.includes(key) ? diff(expanded, [key]) : union(expanded, [key]);
}
export function createHelpers(keyAccessor, expanded, setExpanded) {
    const getKeys = makeGetAccessValues(keyAccessor);
    const getParentKeys = (item) => getKeys([item, ...getParents(item)]);
    const getChildKeys = (item) => getKeys([item, ...getChildren(item)]);
    return {
        expand: {
            children: compose(setExpanded, (keys) => union(expanded, keys), getChildKeys)
        },
        collapse: {
            parents: compose(setExpanded, (keys) => diff(expanded, keys), getParentKeys),
            children: compose(setExpanded, (keys) => diff(expanded, keys), getChildKeys)
        }
    };
}
export function enrichTreeItems(list, keyAccessor, expanded, setExpanded) {
    return list.map((item) => {
        const key = keyAccessor(item);
        return Object.assign(Object.assign({}, item), { expanded: expanded.includes(key), expand: () => setExpanded(toggleExpansion(key, expanded)) });
    });
}
export function getVisibleItems(data, keyAccessor, expanded) {
    return data.filter((item) => !item[PARENT] || !hasCollapsedParents(item, keyAccessor, expanded));
}
