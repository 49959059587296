import styled from 'styled-components';
import { maxWidth } from 'styled-system';
import { globalFont, preciseEm } from '../utils';
import { fromTheme, matchThemeProp } from '../Theme';
export const LegacyTable = styled.table `
  ${maxWidth}
  ${globalFont};
  table-layout: fixed;
  border-collapse: collapse;
  line-height: 200%;
  width: 100%;
  tbody,
  td,
  th,
  tfoot,
  thead,
  tr {
  }
  thead tr,
  tbody tr:not(:last-child) {
  }
  tfoot tr {
    border-top: 1px solid ${fromTheme((theme) => theme.colors.lightGray)};
  }
  caption {
    text-align: left;
    text-transform: uppercase;
    font-size: ${preciseEm(0.9)}em;
    color: ${fromTheme((theme) => theme.colors.gray)};
  }
  td,
  th {
    font-size: ${matchThemeProp((theme) => theme.global.sizes, {
    modifier: (em) => (em / 16) * 14
})}em;
    padding: ${(props) => (props.compact ? preciseEm(0) : preciseEm(0.2))}em 0;
    vertical-align: ${(props) => props.align || 'top'};
    &:not(:first-child) {
      padding-left: ${(props) => props.compact ? preciseEm(0.1) : preciseEm(0.4)}em;
    }
    &:not(:last-child) {
      padding-right: ${(props) => props.compact ? preciseEm(0.1) : preciseEm(0.4)}em;
    }
  }
  th {
    color: ${fromTheme((theme) => theme.colors.gray)};
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
  }
`;
LegacyTable.displayName = 'Table';
