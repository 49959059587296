import { fetchWithHooks } from '../fetchWithHooks/fetchWithHooks'
import { handleWorkerThreads } from '../hooks'

export const configFetch = (url: string, fetchOptions?: RequestInit) => {
  const channel = 'foreground'

  return fetchWithHooks({
    before: [handleWorkerThreads(channel)],
    after: [handleWorkerThreads(channel)],
    error: [handleWorkerThreads(channel)]
  })(url, fetchOptions)
}
