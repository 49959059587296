import { addMonths, addYears, endOfYear, isSameYear, startOfYear, subDays, subMonths } from 'date-fns';
import { orderRange } from '../../DateRange/helpers';
export const updateYearRange = (range, action) => {
    switch (action.type) {
        case 'SELECT_START':
            return [action.date, null];
        case 'SELECT_END':
            if (!range[0])
                return range;
            if (isSameYear(range[0], action.date))
                return [null, null];
            return orderRange([range[0], action.date]);
        case 'RESET_RANGE':
            return [null, null];
        case 'SELECT_SINGLE_UNIT_AS_RANGE':
            return [action.date, subDays(addYears(action.date, 1), 1)];
    }
};
function fitPartialYearRange([start, end], offset) {
    return [
        start ? addMonths(startOfYear(start), offset) : null,
        end ? subMonths(endOfYear(end), offset === 0 ? 0 : 12 - offset) : null
    ];
}
export const yearRangeReducer = (range, action, options) => {
    return fitPartialYearRange(updateYearRange(range, action, options), options.offset);
};
