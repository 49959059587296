/**
 * Generate a unique random 8 character id (not cryptographically secure).
 */
const randomIdMemory: (string | number)[] = []
export const randomId = () => {
  let id

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'test') {
    do {
      id = Math.random().toString(36).substring(2, 10)
    } while (randomIdMemory.indexOf(id) > -1)
  } else {
    id = randomIdMemory.length
  }

  randomIdMemory.push(id)

  return id
}

/**
 * Return false if argument is undefined, null or false.
 * A value of 0 is considered a value in this function.
 * Also, "" is considered a value in this function.
 */
export const hasValue = (test: unknown) =>
  typeof test !== 'undefined' && test !== null && test !== false

/**
 * Variadic function which tests all arguments with hasValue(),
 * returning false if one of its arguments
 * has no value (hasValue() returns false for this argument).
 *
 * @deprecated Unused
 */
export function onlyIf(...args: unknown[]) {
  for (let i = 0; i < args.length; i++) {
    if (hasValue(args[i]) === false) {
      return false
    }
  }

  return true
}

/**
 * Returns whether hex colour (in #FFFFFF format) is bright to tweak foreground colour.
 */
export const isBright = (hex: string) => {
  const c = hex.substring(1) // Strip #
  const rgb = parseInt(c, 16) // Convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // Extract red
  const g = (rgb >> 8) & 0xff // Extract green
  const b = (rgb >> 0) & 0xff // Extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // Per ITU-R BT.709

  return luma > 150
}

/**
 * Nice formatted size.
 */
export const bytesToSize = (bytes: number) => {
  // Return bytes if it's not numeric
  if (isNaN(bytes)) {
    return bytes
  }

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (String(bytes) === '0') {
    return '0 ' + sizes[0]
  }
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]
}

/**
 * Create a string from a className map and optional extra classNames.
 */
export const classNames = (map: Record<string, unknown>, ...more: string[]) => {
  const classes = Object.keys(map).reduce(
    (sum, key) => (map[key] ? `${sum} ${key}` : sum),
    ''
  )

  return classes + ' ' + String(more.join(' '))
}

/**
 * Returns a promise which resolves (or rejects) after the specified ms.
 */
export const timeout = (ms: number, useReject = false) => {
  return new Promise<string>((resolve, reject) => {
    setTimeout(() => {
      const message = 'Timed out in ' + ms + 'ms.'
      useReject ? reject(new Error(message)) : resolve(message)
    }, ms)
  })
}

/**
 * Race a promise against a timeout.
 */
export const promiseTimeout = <T>(
  promise: Promise<T>,
  ms: number,
  timeoutRejects = true
) => {
  return Promise.race([promise, timeout(ms, timeoutRejects)])
}

/**
 * Wait for a condition to become truthy.
 */
export const waitFor = (
  condition: () => unknown,
  { timeout = 0, wait = 50, description = '' } = {}
) => {
  return new Promise<void>((resolve, reject) => {
    let timedOut = false

    if (timeout > 0) {
      setTimeout(() => {
        timedOut = true
      }, timeout)
    }

    const loop = () => {
      if (condition()) {
        return resolve()
      }

      if (timedOut) {
        return reject(
          new Error(
            `Wait loop timed out in ${timeout}ms${
              description ? ` (waiting for ${description})` : ''
            }.`
          )
        )
      }

      setTimeout(loop, wait)
    }

    loop()
  })
}

export const decodeBase64 = (str: string) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const insertIf = (
  condition: unknown,
  object: unknown,
  defaultValue = {}
): any => {
  if (condition) {
    return object
  }

  return defaultValue
}

export const caseInsensitiveIncludes = (string: string, substr: string) => {
  return string.toLowerCase().includes(substr.toLowerCase())
}

export const dispatchResize = (timeout = 1000, cb?: () => unknown) => {
  const event = new Event('resize')
  setTimeout(() => {
    window.dispatchEvent(event)
    cb && cb()
  }, timeout)
}
