import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useState, useRef } from 'react';
import { equals } from 'ramda';
import { WarningModal, ConfirmModal, NoticeModal } from './variations';
const MODALS = {
    confirm: ConfirmModal,
    notice: NoticeModal,
    warning: WarningModal
};
const ModalContext = createContext({});
export function ModalProvider({ children }) {
    const [modelType, setType] = useState('confirm');
    const [props, setProps] = useState({});
    const [show, setShow] = useState(false);
    const resolve = useRef(() => {
        setShow(false);
    });
    const reject = useRef(() => {
        setShow(false);
    });
    const modal = (type) => {
        return (initProps) => {
            modelType !== type && setType(type);
            initProps && !equals(props, initProps) && setProps(initProps);
            !show && setShow(true);
            return {
                then(cb) {
                    resolve.current = () => {
                        setShow(false);
                        cb();
                    };
                    return this;
                },
                catch(cb) {
                    reject.current = () => {
                        setShow(false);
                        cb();
                    };
                }
            };
        };
    };
    const Modal = MODALS[modelType];
    return (_jsxs(ModalContext.Provider, Object.assign({ value: {
            setModal: modal,
            close: () => setShow(false),
            resolve: resolve.current,
            reject: reject.current
        } }, { children: [children, show && _jsx(Modal, Object.assign({}, props))] })));
}
export function useModalActions() {
    const { resolve, reject } = useContext(ModalContext);
    return { resolve, reject };
}
export function useModal() {
    const { setModal } = useContext(ModalContext);
    if (!setModal) {
        throw new Error('Modal can be used only within its root');
    }
    return {
        confirm: setModal('confirm'),
        notice: setModal('notice'),
        warning: setModal('warning')
    };
}
