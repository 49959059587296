import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { withStyles } from '../utils/withStyles';
import { Variations } from './Variations';
function defaultRenderHeader({ header }) {
    return header;
}
function defaultRenderContent({ item, column }) {
    if (!column) {
        throw Error('Unable to render a content cell');
    }
    return item[column];
}
function defaultRenderFooter({ footer }) {
    return footer;
}
export function _Table({ data, columns, keyExtractor, renderHeaderCell = defaultRenderHeader, renderContentCell = defaultRenderContent, renderFooterCell = defaultRenderFooter, context }) {
    const hasFooter = useMemo(() => columns.some((column) => !!column.footer), [columns]);
    return (_jsxs("table", Object.assign({ style: { width: '100%' } }, { children: [_jsx("thead", Object.assign({ "data-testid": "table-header" }, { children: _jsx("tr", { children: columns.map((column, columnIndex) => (_jsx("th", { children: typeof column.header === 'function'
                            ? column.header({ data, context: context })
                            : renderHeaderCell({
                                header: column.header,
                                context: context
                            }) }, columnIndex))) }) })), _jsx("tbody", Object.assign({ "data-testid": "table-content" }, { children: data.map((item, index) => (_jsx("tr", { children: columns.map((column, columnIndex) => {
                        const shared = { item, context: context, index, data };
                        return (_jsx("td", { children: typeof column.content === 'function'
                                ? column.content(shared)
                                : renderContentCell(Object.assign({ column: column.content }, shared)) }, columnIndex));
                    }) }, keyExtractor(item, index)))) })), hasFooter && (_jsx("tfoot", { children: _jsx("tr", Object.assign({ "data-testid": "table-footer" }, { children: columns.map((column, columnIndex) => (_jsx("td", { children: typeof column.footer === 'function'
                            ? column.footer({ data, context: context })
                            : renderFooterCell({
                                footer: column.footer,
                                context: context,
                                data
                            }) }, columnIndex))) })) }))] })));
}
const Table = withStyles(Variations.Default)(_Table);
export { Table };
