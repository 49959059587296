import styled from 'styled-components';
import { Adornment } from '../Adornment';
import { themeColor } from '../Theme';
export const InputAdornment = styled(Adornment) `
  &:not(.selectInput__adornment) > {
    .adornment {
      &__left,
      &__right {
        padding-right: 8px;
        font-size: 14px;
        color: ${themeColor('gray')};
      }
    }
  }
`;
