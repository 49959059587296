import React, { useEffect, useState } from 'react'
import { translate } from '@exivity/translations'
import { useSelector, useDispatch } from 'react-redux'
import { Markdown, useModal } from '@exivity/ui'
import { useSystemConfiguration } from '@exivity/data-layer'

import { profileActions, profileSelectors } from '../state'
import { useLogout } from '../../auth/hooks'

export function useDisclaimer() {
  const dispatch = useDispatch()
  const [denied, setDenied] = useState(false)
  const logout = useLogout()

  const { confirm } = useModal()

  const systemConfiguration = useSystemConfiguration()

  const disclaimer = {
    title: systemConfiguration.attributes.DISCLAIMER_TITLE,
    text: systemConfiguration.attributes.DISCLAIMER_TEXT,
    agreeButtonText: systemConfiguration.attributes.DISCLAIMER_AGREE_BUTTON_TEXT
  }

  const userAcceptanceIsPopulated = useSelector(
    profileSelectors.isPopulatedByExternalSource
  )
  const isEnabled = systemConfiguration.attributes.DISCLAIMER_ENABLED ?? false
  const isAccepted = useSelector(
    profileSelectors.disclaimerIsAccepted(disclaimer)
  )

  useEffect(() => {
    if (isEnabled && userAcceptanceIsPopulated && !isAccepted && !denied) {
      confirm({
        title: disclaimer.title || '',
        body: <Markdown>{disclaimer.text || ''}</Markdown>,
        acceptLabel: disclaimer.agreeButtonText || '',
        declineLabel: translate('Logout')
      })
        .then(() => {
          dispatch(profileActions.updateAcceptedDisclaimer(disclaimer))
        })
        .catch(() => {
          setDenied(true)
          logout()
        })
    }
  })
}
