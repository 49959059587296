import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { startOfDay, startOfMonth, startOfQuarter, startOfYear, endOfDay, endOfMonth, endOfQuarter, endOfYear } from 'date-fns';
import { CalendarMode, getCalendar } from '../CalendarModes';
import { CalendarBrowser } from '../CalendarBrowser';
import { Button } from '../../Button';
import { globalFont } from '../../utils';
import { CalendarHeader } from '../CalendarHeader';
import { withExtendableProps } from '../../ExtendProps';
const StyledDateCalendar = styled.div `
  ${globalFont}

  min-width: 300px;

  .header,
  .arrow {
    font-size: 20px;
    padding: 10px;
  }

  .active,
  .time-unit:hover {
    border-radius: 180px;
  }

  button {
    width: 100%;
  }
`;
function _DateCalendar({ mode = CalendarMode.Days, className, value, onChange = () => null, offset = 0, onClear, min, max }) {
    const [browserDate, setBrowserDate] = useState(value || new Date());
    const { Calendar } = getCalendar(mode);
    return (_jsx(StyledDateCalendar, Object.assign({ "data-testid": "date-calendar", className: className }, { children: _jsxs(CalendarBrowser, Object.assign({ value: browserDate, mode: mode, onChange: setBrowserDate }, { children: [_jsx(CalendarHeader, { date: browserDate, mode: mode, offset: offset }), _jsx(Calendar, { value: [value || null, null], browserDate: browserDate, offset: offset, min: min, max: max, onChange: (date) => onChange && onChange(date, mode) }), onClear && _jsx(Button.Clear, Object.assign({ onClick: onClear }, { children: "Clear date" }))] })) })));
}
export const DateCalendarV2 = withExtendableProps('DateCalendar')(_DateCalendar);
DateCalendarV2.startOfRange = (date, mode) => ({
    [CalendarMode.Days]: startOfDay,
    [CalendarMode.Months]: startOfMonth,
    [CalendarMode.Quarters]: startOfQuarter,
    [CalendarMode.Years]: startOfYear
}[mode](date));
DateCalendarV2.endOfRange = (date, mode) => ({
    [CalendarMode.Days]: endOfDay,
    [CalendarMode.Months]: endOfMonth,
    [CalendarMode.Quarters]: endOfQuarter,
    [CalendarMode.Years]: endOfYear
}[mode](date));
