import { useEffect } from 'react'
import { querySelector, unlessIsNil } from '@exivity/fp'
import { useSystemConfiguration } from '@exivity/data-layer'

export function useFavicon() {
  const systemConfiguration = useSystemConfiguration()
  useEffect(() => {
    unlessIsNil((el: HTMLLinkElement) => {
      el.href =
        systemConfiguration.attributes.APP_FAVICON ||
        systemConfiguration.attributes.APP_ICON ||
        ''
    }, querySelector<Document, HTMLLinkElement>('#favicon', document))
  }, [
    systemConfiguration.attributes.APP_FAVICON,
    systemConfiguration.attributes.APP_ICON
  ])
}
