import { useEffect } from 'react'
import { useImportWorker } from '@exivity/data-layer'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { Loader, FillContentVerticalSpace } from '@exivity/ui'

import { authActions, authSelectors } from '../../auth/state'
import { getUserSessionStorage } from '../../../store/storage'
import { workThunks } from '../../work/thunks'

interface SynResourcesProps {
  children: any
}

export function SyncResources({ children }: SynResourcesProps) {
  const dispatch = useDispatch()
  const host = useSelector(authSelectors.getApiRoot)
  const token = getUserSessionStorage.fromEitherStorage('token') ?? ''
  const status = useImportWorker(token, host)

  useEffect(() => {
    if (status === 'succeeded') {
      dispatch(
        workThunks.showSuccessMessage(
          translate('Data synchronization successful')
        )
      )
    }

    if (status === 'failed') {
      dispatch(
        workThunks.showErrorMessage(translate('Data synchronization failed'))
      )
    }

    if (status === 'authError') {
      dispatch(authActions.updateIsAuthenticated(false))
    }
  }, [status])

  if (status === 'succeeded') return children

  return (
    <FillContentVerticalSpace>
      <Loader
        timeout={30000}
        timeoutMessage={translate(
          'Loading is taking a bit longer than expected... Try refreshing the page.'
        )}
      >
        {translate('Syncing data...')}
      </Loader>
    </FillContentVerticalSpace>
  )
}
