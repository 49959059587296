var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
import { useFieldContext } from '../Field/context';
function LabelGroup(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (_jsx("div", Object.assign({ className: "label-group" }, { children: React.Children.map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, rest) : null) })));
}
const SubLabel = styled.label `
  ${globalFont}

  display: block;
  font-size: ${fromTheme((theme) => theme.global.sizes.small)}rem;
  color: ${fromTheme((theme) => theme.global.textColorMuted)};

  > * {
    display: block;
    font-size: ${fromTheme((theme) => theme.global.sizes.small)}rem;
    color: ${fromTheme((theme) => theme.global.textColorMuted)};
    margin: 0;
  }
`;
export const StyledLabel = styled.label `
  ${globalFont}
`;
function Label(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const { id } = useFieldContext();
    return (_jsx(StyledLabel, Object.assign({ htmlFor: id }, rest, { children: children })));
}
Label.Group = LabelGroup;
Label.Sub = SubLabel;
export { Label };
