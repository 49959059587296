import { v4 as uuidv4 } from 'uuid';
import { assoc, assocPath } from 'ramda';
export function empty(type) {
    return () => ({ type, attributes: {}, relationships: {} });
}
export function withTestId(id) {
    return (resource) => assoc('id', id, resource);
}
export function withLocalId(resource) {
    return assoc('lid', uuidv4(), resource);
}
export function withAttribute(attribute, value) {
    return function (resource) {
        return assocPath(['attributes', attribute], value, resource);
    };
}
export function withRelationship(relationship, value) {
    return function (resource) {
        return assocPath(['relationships', relationship, 'data'], value, resource);
    };
}
export function withRelationshipByLocalId(relationship, value) {
    return function (resource) {
        return assocPath(['relationships', relationship, 'data'], value, resource);
    };
}
export function isResource(args) {
    return args && Object.prototype.hasOwnProperty.call(args, 'id');
}
export function hasId(args) {
    return args && Object.prototype.hasOwnProperty.call(args, 'id');
}
export function ifId(resource, trueFn) {
    return hasId(resource) ? trueFn(resource) : null;
}
export function getIdOrLid(resource) {
    return hasId(resource) ? resource.id : resource.lid;
}
export function getIdOrNull(resource) {
    return hasId(resource) ? resource.id : null;
}
