import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { registerScrollListener, unregisterScrollListener } from './utils/onScroll';
import { getDimensions, getPositionOffset, getScrollOffset } from './utils/dimensions';
import createDetectElementResize from './utils/detectElementResize';
/**
 * Specifies the number of miliseconds during which to disable
 * pointer events while a scroll is in progress.
 * This improves performance and makes scrolling smoother.
 */
export const IS_SCROLLING_TIMEOUT = 150;
const getWindow = () => (typeof window !== 'undefined' ? window : undefined);
export function useWindowScroller() {
    const ref = useRef(null);
    const onList = useCallback((node) => {
        ref.current = node;
    }, []);
    const win = getWindow();
    const onScroller = useCallback((node) => {
        if (node && win) {
            node && node.updatePosition();
        }
    }, []);
    const onScroll = useCallback((node) => {
        if (node && win) {
            ref.current && ref.current.scrollTo(node.scrollTop);
        }
    }, []);
    return [onList, onScroller, onScroll];
}
export class WindowScroller extends React.PureComponent {
    constructor() {
        super(...arguments);
        this._window = getWindow();
        this._isMounted = false;
        this._positionFromTop = 0;
        this._positionFromLeft = 0;
        this.state = Object.assign(Object.assign({}, getDimensions(this.props.scrollElement, this.props)), { isScrolling: false, scrollLeft: 0, scrollTop: 0 });
        this._registerChild = (element) => {
            if (element && !(element instanceof Element)) {
                console.warn('WindowScroller registerChild expects to be passed Element or null');
            }
            this._child = element;
            this.updatePosition();
        };
        this._onChildScroll = ({ scrollTop }) => {
            if (this.state.scrollTop === scrollTop) {
                return;
            }
            const scrollElement = this.props.scrollElement;
            if (scrollElement) {
                if (typeof scrollElement.scrollTo === 'function') {
                    scrollElement.scrollTo(0, scrollTop + this._positionFromTop);
                }
                else {
                    ;
                    scrollElement.scrollTop = scrollTop + this._positionFromTop;
                }
            }
        };
        this._registerResizeListener = (element) => {
            if (element === window) {
                window.addEventListener('resize', this._onResize, false);
            }
            else {
                this._detectElementResize.addResizeListener(element, this._onResize);
            }
        };
        this._unregisterResizeListener = (element) => {
            if (element === window) {
                window.removeEventListener('resize', this._onResize, false);
            }
            else if (element) {
                this._detectElementResize.removeResizeListener(element, this._onResize);
            }
        };
        this._onResize = () => {
            this.updatePosition();
        };
        // Referenced by utils/onScroll
        this.__handleWindowScrollEvent = () => {
            if (!this._isMounted) {
                return;
            }
            const { onScroll } = this.props;
            const scrollElement = this.props.scrollElement;
            if (scrollElement) {
                const scrollOffset = getScrollOffset(scrollElement);
                const scrollLeft = Math.max(0, scrollOffset.left - this._positionFromLeft);
                const scrollTop = Math.max(0, scrollOffset.top - this._positionFromTop);
                this.setState({
                    isScrolling: true,
                    scrollLeft,
                    scrollTop
                });
                onScroll({
                    scrollLeft,
                    scrollTop
                });
            }
        };
        // Referenced by utils/onScroll
        this.__resetIsScrolling = () => {
            this.setState({ isScrolling: false });
        };
    }
    updatePosition(scrollElement = this.props
        .scrollElement) {
        const { onResize } = this.props;
        const { height, width } = this.state;
        const thisNode = this._child || ReactDOM.findDOMNode(this);
        if (thisNode instanceof Element && scrollElement) {
            const offset = getPositionOffset(thisNode, scrollElement);
            this._positionFromTop = offset.top;
            this._positionFromLeft = offset.left;
        }
        const dimensions = getDimensions(scrollElement, this.props);
        if (height !== dimensions.height || width !== dimensions.width) {
            this.setState({
                height: dimensions.height,
                width: dimensions.width
            });
            onResize({
                height: dimensions.height,
                width: dimensions.width
            });
        }
    }
    componentDidMount() {
        const scrollElement = this.props.scrollElement;
        this._detectElementResize = createDetectElementResize();
        this.updatePosition(scrollElement);
        if (scrollElement) {
            registerScrollListener(this, scrollElement);
            this._registerResizeListener(scrollElement);
        }
        this._isMounted = true;
    }
    componentDidUpdate(prevProps) {
        const { scrollElement } = this.props;
        const { scrollElement: prevScrollElement } = prevProps;
        if (prevScrollElement !== scrollElement &&
            prevScrollElement != null &&
            scrollElement != null) {
            this.updatePosition(scrollElement);
            unregisterScrollListener(this, prevScrollElement);
            registerScrollListener(this, scrollElement);
            this._unregisterResizeListener(prevScrollElement);
            this._registerResizeListener(scrollElement);
        }
    }
    componentWillUnmount() {
        const scrollElement = this.props.scrollElement;
        if (scrollElement) {
            unregisterScrollListener(this, scrollElement);
            this._unregisterResizeListener(scrollElement);
        }
        this._isMounted = false;
    }
    render() {
        const { children } = this.props;
        const { isScrolling, scrollTop, scrollLeft, height, width } = this.state;
        return children({
            onChildScroll: this._onChildScroll,
            registerChild: this._registerChild,
            height,
            isScrolling,
            scrollLeft,
            scrollTop,
            width
        });
    }
}
WindowScroller.defaultProps = {
    onResize: () => null,
    onScroll: () => null,
    scrollingResetTimeInterval: IS_SCROLLING_TIMEOUT,
    scrollElement: getWindow(),
    serverHeight: 0,
    serverWidth: 0
};
