import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Resources, UserGroupPermission, UserSource } from '@exivity/data-layer'

import {
  getUserSessionStorage,
  switchAllStorages
} from '../../../store/storage'
import { loginSAML, logoutSAML } from '../SingleSignOn/SAML/authCycle'
import { FetchStatus } from '../../../API/types'
import { isDevPreviewHostname } from '../../../utils/system'

import { thunks, removeTrailingSlash } from './thunks'

const DEV_SERVER_HOSTNAME = 'dev.exivity.net'

function getInitialHostName() {
  if (typeof self !== 'undefined') {
    // If we're running on netlify or in local development,
    // default to our dev server for convenience.
    return isDevPreviewHostname() || process.env.NODE_ENV === 'development'
      ? DEV_SERVER_HOSTNAME
      : self.location.hostname
  }
  return 'example.com'
}

export const initialProtocol = 'https://'
export const initialHostName = getInitialHostName()

const initialState = {
  staticConfigHydrated: false,
  persistent: false,
  isAuthenticated: false,
  currentUserId: null as string | null,
  currentUser: {
    type: 'user',
    id: '',
    attributes: {
      username: '',
      display_name: '',
      email_address: '',
      current_password: '',
      password: '',
      source: UserSource.LOCAL as UserSource
    }
  } as Resources['user'],
  permissions: [] as UserGroupPermission[],
  signingOut: FetchStatus.Idle as FetchStatus,
  apiRoot: initialProtocol + initialHostName,
  isWhiteLabeled: true,
  refreshingSession: FetchStatus.Idle as FetchStatus
}

export const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updatePersistent(state, action: PayloadAction<boolean>) {
      switchAllStorages(action.payload)
      state.persistent = action.payload
    },
    updateCurrentUserId(state, action) {
      state.currentUserId = action.payload
    },
    updateCurrentUser(state, action: PayloadAction<Resources['user']>) {
      state.currentUser = action.payload
    },
    updateToken(state, action) {
      const session = getUserSessionStorage.getStorage(state.persistent)
      session.setItem('token', action.payload)
    },
    updateIsAuthenticated(state, action) {
      if (!action.payload) {
        const session = getUserSessionStorage.getStorage(state.persistent)
        session.clear()
      }

      state.isAuthenticated = action.payload
    },
    updatePermissions(state, action) {
      state.permissions = action.payload
    },
    updateApiRoot(state, action: PayloadAction<string>) {
      state.apiRoot = removeTrailingSlash(action.payload)
    },
    updateIsWhiteLabel(state, action: PayloadAction<boolean>) {
      state.isWhiteLabeled = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.updateCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
      })
      .addCase(thunks.refreshToken.pending, (state) => {
        state.refreshingSession = FetchStatus.Loading
      })
      .addCase(thunks.refreshToken.fulfilled, (state) => {
        state.refreshingSession = FetchStatus.Succeeded
      })
      .addCase(thunks.refreshToken.rejected, (state) => {
        state.refreshingSession = FetchStatus.Failed
      })
      .addCase(thunks.logout.pending, (state) => {
        state.signingOut = FetchStatus.Loading
      })
      .addCase(thunks.logout.fulfilled, (state) => {
        state.signingOut = FetchStatus.Succeeded
      })
      .addCase(thunks.logout.rejected, (state) => {
        state.signingOut = FetchStatus.Failed
      })
      .addCase(thunks.loadStaticConfig.fulfilled, (state, action) => {
        state.staticConfigHydrated = true
        state.apiRoot = action.payload.apiRoot
        state.isWhiteLabeled = action.payload.isWhiteLabeled
      })
      
  }
})

export const authReducer = reducer

export const authActions = actions

export { authSelectors } from './selectors'

export const authThunks = {
  loginSAML,
  logoutSAML,
  ...thunks
}
