import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { MdContentCopy, MdDone } from 'react-icons/md';
import { translate } from '@exivity/translations';
import { Button, Tooltip, Flex } from '../';
import { themePalette, themeGlobal } from '../Theme';
export const CopyCode = styled.pre `
  margin-top: 0.5em;
  color: ${themePalette('neutral', 600)};
  font-family: ${themeGlobal('fontFamilyMonospace')};
  -moz-tab-size: 4;
  tab-size: 4;
  overflow: auto;
  padding: 4px;
  padding-left: 12px;
  border: 1px solid ${themePalette('neutral', 300)};
  border-radius: 3px;
  background-color: ${themePalette('neutral', 100)};
`;
export function CopyToClipboard({ value }) {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => setCopied(false), 1000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [copied]);
    return (_jsx(CopyCode, { children: _jsxs(Flex, Object.assign({ gap: "1em", justifyContent: "space-between", alignItems: "center" }, { children: [value, _jsx(Tooltip, Object.assign({ content: translate('copy') }, { children: _jsx(Button, { "data-testid": "copy-to-clipboard", success: copied, round: true, transparent: true, icon: copied ? _jsx(MdDone, {}) : _jsx(MdContentCopy, {}), onClick: () => {
                            setCopied(true);
                            navigator.clipboard.writeText(value);
                        } }) }))] })) }));
}
