import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { PARENT, CHILDREN } from '../utils/tree';
import { getDistanceFromSibling } from './helpers';
const StyledBranchSpacer = styled.div `
  margin-left: ${({ spacing, level }) => spacing * (level - 1)}px;
  height: 100%;

  .treelist__expand--invisible {
    visibility: hidden;
  }
`;
const Content = styled.div `
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const BranchSpacer = ({ children, index, data, spacing = 40, padding = 0 }) => {
    if (padding > spacing) {
        console.warn('BranchSpacer: Padding cannot be greater than spacing');
    }
    const item = data[index];
    const distance = getDistanceFromSibling(data, index);
    return (_jsx(StyledBranchSpacer, Object.assign({ firstChild: item[PARENT] && item[PARENT][CHILDREN][0].key === item.key, level: item.level, spacing: spacing, padding: Math.min(padding, spacing), distance: distance }, { children: _jsx(Content, { children: children }) })));
};
