import { queries, SystemConfiguration, memory } from '@exivity/data-layer'
import { isEmpty } from 'ramda'

import {
  ServiceReportItem,
  ServiceReportItemAPI
} from '../../../API/APIResponseTypes/reports'
import {
  ServiceModel,
  ServiceCategoryModel,
  MetadataModel
} from '../../../data/types'
import { RootState } from '../../../reducers'
import { getMetadataValues } from '../metadata'

function getStore() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return memory
}

export default async (state: RootState, data: ServiceReportItemAPI[]) => {
  const dateFormat = (
    getStore()
      .cache
      .query(
        // @ts-ignore
        queries.find('configuration', 'system').toQuery()
      ) as SystemConfiguration | null
  )?.attributes.DATE_FORMAT ?? 'dd-MM-yyyy'

  return data.map(item => {
    let service: ServiceModel
    let servicecategory: ServiceCategoryModel
    const newItem = item as ServiceReportItem

    // Format numbers
    const toParse = [
      'total_quantity',
      'unit_based_subtotal_charge',
      'interval_based_subtotal_charge',
      'total_charge',
      'total_cogs',
      'total_net'
    ] as const
    toParse.forEach((field) => {
      newItem[field] = parseFloat(item[field] as string)
    })

    // Add service name & category
    try {
      service = getStore().cache.query((query) =>
        query.findRecord({
          type: 'service',
          id: String(newItem.service_id)
        })
      ) as unknown as ServiceModel
      servicecategory = getStore().cache.query((query) =>
        query.findRelatedRecord(
          {
            type: 'service',
            id: String(newItem.service_id)
          },
          'servicecategory'
        )
      ) as unknown as ServiceCategoryModel

      const metadatas = getStore().cache.query((q) =>
        q.findRelatedRecords(service, 'metadata')
      ) as unknown as MetadataModel[]


      if (!isEmpty(metadatas)) {
        newItem.metadata = getMetadataValues(
          metadatas,
          dateFormat
        )
      }

      newItem.key = service.attributes.key
      newItem.service_description = service.attributes.description
      newItem.servicecategory_id = servicecategory.id
      newItem.servicecategory_name = servicecategory.attributes.name
      newItem.service_unit_label = service.attributes.unit_label
      newItem.service_charge_type = service.attributes.charge_type
      newItem.service_cogs_type = service.attributes.cogs_type
      newItem.proration_type = service.attributes.proration_type
      newItem.interval = service.attributes.interval
    } catch (error) {
      newItem.service_description = '(unknown service)'
      newItem.servicecategory_id = null
      newItem.servicecategory_name = '(unknown servicecategory)'
      newItem.service_unit_label = null
    }

    return newItem
  })
}
