export const NOT_AVAILABLE_CHARACTER = "-";

const compareNumbers = (a, b) => b.value - a.value;

const groupOther = (data, maxSeries) => {
  if (data.length <= maxSeries) {
    return data.sort(compareNumbers);
  }

  const sorted = data.sort(compareNumbers);
  const biggest = sorted.slice(0, maxSeries);
  const other = sorted
    .slice(maxSeries)
    .reduce((acc, next) => acc + next.value, 0);

  return [...biggest, { key: "other", title: "Other", value: other }];
};

const filterPinned = (report, pinned, filterBy) => {
  if (report && pinned.length) {
    return report.filter((item) => pinned.includes(item[filterBy]));
  }

  return report;
};

export { groupOther, filterPinned };
