import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components';
import { Tabs } from '../../Tabs';
import { CalendarMode } from '../CalendarModes/CalendarMode';
const StyledModePicker = styled.div `
  .tab-list {
    display: flex;

    > * {
      flex-grow: 1;
    }
  }
`;
const MODE_TABS = {
    [CalendarMode.Days]: 'Days',
    [CalendarMode.Months]: 'Months',
    [CalendarMode.Quarters]: 'Quarters',
    [CalendarMode.Years]: 'Years'
};
export function ModePicker({ initialMode, modes = Object.values(CalendarMode), value, onChange, children }) {
    const [mode, setMode] = useState(initialMode);
    if (modes.length < 2) {
        throw new Error('The ModePicker component is redundant if it has less than 2 options');
    }
    const currentMode = mode !== undefined ? mode : value;
    return (_jsxs("div", { children: [_jsx(StyledModePicker, { children: _jsx(Tabs, Object.assign({ activeIndex: modes.indexOf(currentMode), onActiveIndexChange: (i) => {
                        onChange && onChange(modes[i]);
                        mode && setMode(modes[i]);
                    } }, { children: _jsx(Tabs.TabList, Object.assign({ className: "tab-list" }, { children: modes.map((mode) => (_jsx(Tabs.Tab, { children: MODE_TABS[mode] }, mode))) })) })) }), React.isValidElement(children) &&
                React.cloneElement(children, { mode: currentMode })] }));
}
