import {
  alphaEnabled,
  FeatureFlags,
  featureFlags,
  FeatureNames
} from '../../../application/config/features'

type ExtensibleFeatureNames = FeatureNames | string

type Options = {
  flags?: FeatureFlags<ExtensibleFeatureNames>
  alpha?: boolean
  beta?: boolean
  allowUnknown?: boolean
}

export function canRenderFeature(
  name: ExtensibleFeatureNames,
  {
    flags = featureFlags,
    alpha = alphaEnabled,
    beta = false,
    allowUnknown = false
  }: Options = {}
) {
  const featureFlag = flags[name]

  // Throw error if name is unknown and allowUnknown is false
  if (!allowUnknown && !featureFlag) {
    throw new Error(`Feature ${name} is not defined`)
  }

  // Check if feature is in alpha
  if (featureFlag === 'alpha' && !alpha) {
    return false
  }

  // Check if feature is in beta
  if (featureFlag === 'beta' && !beta) {
    return false
  }

  return true
}
