import { jsx as _jsx } from "react/jsx-runtime";
import { format, subYears, addYears } from 'date-fns';
import styled from 'styled-components';
import { CalendarMode } from '../CalendarModes';
export const StyledHeader = styled.div `
  user-select: none;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
`;
export function formatDateHeader(browseDate, mode, offset) {
    switch (mode) {
        case CalendarMode.Years:
            return (format(subYears(browseDate, 6), 'yyyy') +
                ' - ' +
                format(addYears(browseDate, 5), 'yyyy'));
        case CalendarMode.Months:
            return format(browseDate, 'yyyy');
        case CalendarMode.Quarters:
            return offset > 0
                ? format(browseDate, 'yyyy') +
                    ' - ' +
                    format(addYears(browseDate, 1), 'yyyy')
                : format(browseDate, 'yyyy');
        case CalendarMode.Days:
        default:
            return format(browseDate, 'MMMM yyyy');
    }
}
export function CalendarHeader({ date, mode, offset }) {
    return (_jsx(StyledHeader, Object.assign({ "data-testid": "calendar-header" }, { children: formatDateHeader(date, mode, offset) })));
}
