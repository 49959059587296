import React from 'react'
import { ThemeProvider as UIThemeProvider } from '@exivity/ui'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSystemConfiguration } from '@exivity/data-layer'
import { DndProvider } from 'react-dnd'

// We also import fonts in app.js
// They are located there so we can import this file for testing

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const systemConfig = useSystemConfiguration()

  return (
    <DndProvider backend={HTML5Backend}>
      <UIThemeProvider brandColor={systemConfig?.attributes.APP_COLOUR}>
        {children}
      </UIThemeProvider>
    </DndProvider>
  )
}
