import React, { useMemo, useEffect } from 'react'
import {
  DataLayerProvider,
  AtomicContext,
  useDataStore,
  JSONAPIFetchEvent
} from '@exivity/data-layer'
import { useDispatch, useSelector } from 'react-redux'

import { authActions, authSelectors } from '../../../domains/auth/state'
import { getUserSessionStorage } from '../../../store/storage'
import { workActions } from '../../../domains/work/state'
import { workThunks } from '../../../domains/work/thunks'
import { atomicFetch } from '../../../API'

import { ResourceMessage } from './ResourceMessage'

type DataLayerProps = {
  children: React.ReactNode
}

const AtomicProvider = ({ children }: any) => {
  const memory = useDataStore()
  return (
    <AtomicContext.Provider
      // @ts-ignore
      value={useMemo(() => atomicFetch(memory), [memory])}
    >
      {children}
    </AtomicContext.Provider>
  )
}

const useThreadsAndMessages = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const addThread = (e: JSONAPIBeforeEvent) => {
      if (e.detail.settings.silent) return // ignore silent requests
      dispatch(workActions.addThread('foreground'))
    }
    const removeThread = (e: JSONAPIAfterEvent) => {
      if (e.detail.settings.silent) return // ignore silent requests
      dispatch(workActions.removeThread('foreground'))
    }

    const handleAuthError = (e: JSONAPIErrorEvent) => {
      dispatch(authActions.updateIsAuthenticated(false))
      dispatch(workActions.removeThread('foreground'))
      dispatch(workThunks.showErrorMessage(e.detail.message))
    }
    const handleError = (e: JSONAPIErrorEvent) => {
      dispatch(workActions.removeThread('foreground'))
      dispatch(workThunks.showErrorMessage(e.detail.message))
    }

    window.addEventListener(JSONAPIFetchEvent.Before, addThread)
    window.addEventListener(JSONAPIFetchEvent.After, removeThread)
    window.addEventListener(JSONAPIFetchEvent.AuthError, handleAuthError)
    window.addEventListener(JSONAPIFetchEvent.Error, handleError)
    return () => {
      window.removeEventListener(JSONAPIFetchEvent.Before, addThread)
      window.removeEventListener(JSONAPIFetchEvent.After, removeThread)
      window.removeEventListener(JSONAPIFetchEvent.AuthError, handleAuthError)
      window.removeEventListener(JSONAPIFetchEvent.Error, handleError)
    }
  }, [dispatch])
}

export function DataProvider({ children }: DataLayerProps) {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const userPermissions = useSelector(authSelectors.getUserPermissions)
  const host = useSelector(authSelectors.getApiRoot)
  const token = getUserSessionStorage.fromEitherStorage('token') ?? ''
  const staticConfigHydrated = useSelector(authSelectors.staticConfigHydrated)

  useThreadsAndMessages()

  return (
    <DataLayerProvider
      host={host}
      token={token}
      userPermissions={userPermissions}
      isAuthenticated={isAuthenticated}
      readyToFetchConfig={staticConfigHydrated}
    >
      <AtomicProvider>
        <ResourceMessage>{children}</ResourceMessage>
      </AtomicProvider>
    </DataLayerProvider>
  )
}
