"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tube = void 0;
/**
 * @description tube is like pipe except its immediately invoked with the first
 * argument
 */
function tube(arg, ...fns) {
    return fns.reduce((v, f) => f(v), arg);
}
exports.tube = tube;
