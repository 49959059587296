import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'
import { MdLibraryBooks } from 'react-icons/md'

import { featureFlags } from '../application/config/features'

const Overview = lazy(
  () =>
    import(
      /* webpackChunkName: 'services.overview' */ '../domains/services/pages/Overview/Overview'
    )
)

const Rates = lazy(
  () =>
    import(
      /* webpackChunkName: 'services.rates' */ '../domains/rates/pages/Overview'
    )
)

// @ts-ignore
const Adjustments = lazy(
  () =>
    import(
      /* webpackChunkName: 'services.adjustments' */
      '../domains/adjustments/pages/Adjustments'
    )
)
// @ts-ignore
const Subscriptions = lazy(
  () =>
    import(
      /* webpackChunkName: 'services.adjustments' */
      '../domains/subscriptions/pages/Subscriptions'
    )
)

export const services = {
  path: '/services',
  requiresPermissions: [UserGroupPermission.ManageServices],
  meta: {
    title: () => translate('Services'),
    icon: MdLibraryBooks
  },
  routes: {
    overview: {
      path: '/overview/:id?',
      meta: {
        title: () => translate('Overview'),
        documentation: 'https://docs.exivity.com/The%20basics/Services/Manage'
      },
      component: Overview
    },
    rates: {
      path: '/rates/:serviceId?/:accountId?/:rateId?',
      meta: {
        title: () => translate('Rates'),
        documentation: 'https://docs.exivity.com/The%20basics/Services/Rates'
      },
      component: Rates
    },
    adjustments: {
      path: '/adjustments/:accountId?/:adjustmentId?',
      meta: {
        title: () => translate('Adjustments'),
        documentation:
          'https://docs.exivity.com/The%20basics/Services/Adjustments'
      },
      component: Adjustments
    },
    subscriptions: {
      path: '/subscriptions/:accountId?/:subscriptionId?',
      meta: {
        title: () => translate('Subscriptions'),
        documentation:
          'https://docs.exivity.com/The%20basics/Services/Subscriptions',
        feature: featureFlags.subscriptions
      },
      component: Subscriptions
    }
  }
}
