import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { format as dateFormat } from 'date-fns';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { translate } from '@exivity/translations';
import { SelectInput } from '../Input/SelectInput';
import { ModePicker } from '../Calendar/ModePicker';
import { DateRangeCalendarV2 } from '../Calendar/DateRangeCalendarV2/DateRangeCalendarV2';
import { CalendarMode } from '../Calendar/CalendarModes';
import { useFieldContext } from '../Field/context';
import { withExtendableProps } from '../ExtendProps';
import { DatePickerDropDown } from './DatePickerDropdown';
function formatRange(range, format) {
    return range.map((date) => dateFormat(date, format)).join(' - ');
}
export function _DateRangePicker({ name, format = 'yyyyMMdd', placeholder = translate('Choose date'), required, disabled, min, max, initialMode = CalendarMode.Months, mode, value, onModeChange = identity, onChange = identity, onClear }) {
    const [currentMode, setMode] = useState(initialMode);
    const [isOpen, toggleOpen] = useToggle(false);
    const { id } = useFieldContext();
    const calendar = (_jsx(DateRangeCalendarV2, { mode: mode, value: value, min: min, max: max, onChange: (date) => {
            onChange(date);
            if (date[0] && date[1]) {
                toggleOpen();
            }
        }, onClear: onClear ? juxt([onClear, toggleOpen]) : undefined }));
    return (_jsx(DatePickerDropDown, Object.assign({ disabled: disabled, reference: _jsx(SelectInput, { id: id, name: name, value: value ? formatRange(value, format) : '', placeholder: placeholder, required: required, disabled: disabled }), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: _jsx(ModePicker, Object.assign({ value: mode || currentMode, onChange: juxt([setMode, onModeChange]) }, { children: calendar })) })));
}
export const DateRangePicker = withExtendableProps('DateRangePicker')(_DateRangePicker);
