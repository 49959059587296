// taken from https://gist.github.com/jiggzson/b5f489af9ad931e3d186
export function scientificToDecimal(num) {
    let numb = num.toString();
    // If the number is not in scientific notation return it as it is.
    if (!/\d+\.?\d*e[+-]*\d+/i.test(numb)) {
        return numb;
    }
    // Remove the sign.
    const numberSign = Math.sign(Number(numb));
    numb = Math.abs(Number(numb)).toString();
    // Parse into coefficient and exponent.
    const [coefficient, exponent] = numb.toLowerCase().split('e');
    let zeros = Math.abs(Number(exponent));
    const exponentSign = Math.sign(Number(exponent));
    const [integer, decimals] = (coefficient.indexOf('.') !== -1 ? coefficient : `${coefficient}.`).split('.');
    if (exponentSign === -1) {
        zeros -= integer.length;
        numb =
            zeros < 0
                ? integer.slice(0, zeros) + '.' + integer.slice(zeros) + decimals
                : '0.' + '0'.repeat(zeros) + integer + decimals;
    }
    else {
        if (decimals)
            zeros -= decimals.length;
        numb =
            zeros < 0
                ? integer + decimals.slice(0, zeros) + '.' + decimals.slice(zeros)
                : integer + decimals + '0'.repeat(zeros);
    }
    return numberSign < 0 ? '-' + numb : numb;
}
