import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { createListStrategy } from './List';
import { classNames } from '../../utils';
import { fromTheme } from '../../Theme';
const StyledCategory = styled.li `
  text-transform: uppercase;
  color: ${fromTheme((theme) => theme.colors.gray)};
  display: flex;
  align-items: center;
`;
const StyledCategorizedItem = styled.li `
  cursor: pointer;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    background-color: ${fromTheme((theme) => theme.colors.lightGray)};
  }

  &.active {
    color: ${fromTheme((theme) => theme.global.purposes.primary)};
  }

  .columns > :first-child {
    padding-left: ${fromTheme((theme) => theme.global.baseSpacing)}em;
  }

  .sortable & {
    :active {
      cursor: grabbing;
    }

    .columns > :first-child {
      padding-left: ${fromTheme((theme) => theme.global.baseSpacing * 2)}em;
    }
  }
`;
function listSorter(categoryDirection = 'asc', direction = 'asc') {
    function sortBy(a, b, dir) {
        if (a < b) {
            return dir === 'asc' ? -1 : 1;
        }
        else if (a > b) {
            return dir === 'asc' ? 1 : -1;
        }
    }
    return function (a, b) {
        if (a.type === 'category' && b.type === 'category') {
            return sortBy(a.orderBy, b.orderBy, categoryDirection) || 0;
        }
        else {
            return (sortBy(a.categoryId, b.categoryId, categoryDirection) ||
                sortBy(a.orderBy, b.orderBy, direction) ||
                0);
        }
    };
}
export function createCategorizedStrategy(data, { categorization, order, isActive, onClick, renderColumns, sortable }) {
    function makeCategoryItems() {
        return categorization.categories.map((category) => {
            var _a;
            return {
                type: 'category',
                category: category,
                categoryId: categorization.getCategoryId(category),
                orderBy: ((_a = categorization.order) === null || _a === void 0 ? void 0 : _a.by(category)) ||
                    categorization.getCategoryId(category)
            };
        });
    }
    function makeItem(payload) {
        const category = categorization.getItemCategoryId(payload);
        return {
            type: 'categorized-item',
            payload,
            categoryId: category,
            orderBy: (order === null || order === void 0 ? void 0 : order.by(payload)) || payload
        };
    }
    return createListStrategy(data, {
        prepareData: () => {
            var _a;
            const categorizedList = makeCategoryItems();
            data.forEach((item) => categorizedList.push(makeItem(item)));
            return categorizedList.sort(listSorter((_a = categorization.order) === null || _a === void 0 ? void 0 : _a.direction, order === null || order === void 0 ? void 0 : order.direction));
        },
        renderItem: ({ item }) => {
            if (item.type === 'category') {
                return (_jsx(StyledCategory, Object.assign({ "data-testid": "category-header", className: "category" }, { children: categorization === null || categorization === void 0 ? void 0 : categorization.renderHeader(item.category) })));
            }
            else {
                const active = (isActive === null || isActive === void 0 ? void 0 : isActive(item.payload)) || false;
                return sortable(_jsx(StyledCategorizedItem, Object.assign({ tabIndex: 0, "data-active": active, "data-category": item.categoryId, className: classNames({ active }, 'categorized'), onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick(item.payload) }, { children: renderColumns(item.payload) })), { item: item.payload, slot: `listitem-${item.categoryId}` });
            }
        }
    });
}
