import { lazy } from 'react'
import { translate } from '@exivity/translations'

const MyProfile = lazy(
  () => import(/* webpackChunkName: 'profile' */ '../domains/my-profile/pages')
)

export const profile = {
  path: '/profile',
  meta: {
    title: () => translate('My profile')
  },
  component: MyProfile
}
