import styled from 'styled-components';
export const Title = styled.div.attrs({ className: 'nav-text' }) `
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  width: 100%;
`;
