import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavBar} from '@exivity/ui'
import { useCacheQuery, queries } from '@exivity/data-layer'
import { prop, viewAttribute } from '@exivity/fp'
import { useNavigate, useExactRoute } from '@exivity/routing'
import { match, MatchResult } from 'path-to-regexp'

import { reportsActions, reportSelectors } from '../../state'

function removeParamsFromPath(data: MatchResult) {
  if (!data) return '';
  let { path, params } = data;
  
  // Sort params by value length in descending order to avoid partial replacement issues
  const sortedParams = Object.entries(params).sort((a, b) => b[1].length - a[1].length);
  
  sortedParams.forEach(([key, value]) => {
    // Escape special characters for use in regex
    const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`/${escapedValue}`, 'g');
    path = path.replace(regex, '');
  });

  // Clean up any double slashes that might occur after parameter removal
  path = path.replace(/\/\/+/g, '/');

  // Optionally, remove trailing slash if it's not the root path
  if (path.length > 1) {
    path = path.replace(/\/$/, '');
  }

  return path;
}

const useResetParams = () => {
  const to = useNavigate()
  const exactRoute = useExactRoute()
  const matched = match(exactRoute.pathname)(location.pathname)

  return () => {
    matched &&
      to(
        removeParamsFromPath(
          matched
        )
      )
  }
}

export function ReportFilter() {
  const reportDefinitions = useCacheQuery(
    queries
      .findAll('reportdefinition')
      .sortByAttribute('name', 'ascending')
  )
  const selectedReport = useSelector(reportSelectors.getSelectedReportId)
  const dispatch = useDispatch()
  const resetParams = useResetParams()

  useEffect(() => {
    if (!selectedReport && reportDefinitions.length) {
      dispatch(reportsActions.filters.updateReport(reportDefinitions[0].id))
    }
  }, [dispatch, selectedReport, reportDefinitions])

  return (
    <NavBar.Filter
      value={selectedReport}
      labelAccessor={viewAttribute('name')}
      valueAccessor={prop('id')}
      data={reportDefinitions}
      onChange={(id) => {
        dispatch(reportsActions.filters.updateReport(id))
        resetParams()
      }}
    />
  )
}
