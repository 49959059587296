import { MdHelp } from 'react-icons/md'
import { getDocumentationLink, useRoute } from '@exivity/routing'
import { AppBar, Tooltip } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useSystemConfiguration } from '@exivity/data-layer'

import { systemConfigSelector } from '../../administration/resources'

export function DocumentationLink() {
  // We have to retrieve the definition this way because this component
  // wants to have to location definition and not the current route definition (nested)
  const documentationLink = getDocumentationLink(useRoute().exactRoute)
  const shouldShowDocumentation = systemConfigSelector.shouldShowDocumentation(
    useSystemConfiguration()
  )

  const showLink = !!documentationLink && shouldShowDocumentation

  return (
    <Tooltip content={showLink ? translate('Documentation') : ''}>
      <AppBar.Button
        visible={showLink}
        icon={<MdHelp />}
        onClick={() => window.open(documentationLink)}
      >
        {translate('help')}
      </AppBar.Button>
    </Tooltip>
  )
}
