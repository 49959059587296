var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { JSONAPIRequestProcessor } from '@orbit/jsonapi';
import { isAuthError } from './server';
export var JSONAPIFetchEvent;
(function (JSONAPIFetchEvent) {
    JSONAPIFetchEvent["Before"] = "jsonapiBeforeFetch";
    JSONAPIFetchEvent["After"] = "jsonapiAfterFetch";
    JSONAPIFetchEvent["Error"] = "jsonapiError";
    JSONAPIFetchEvent["AuthError"] = "jsonapiAuthError";
})(JSONAPIFetchEvent || (JSONAPIFetchEvent = {}));
export class ExRequestProcessor extends JSONAPIRequestProcessor {
    initFetchSettings(customSettings = {}) {
        const settings = super.initFetchSettings(customSettings);
        if ('signal' in customSettings) {
            settings.signal = customSettings.signal;
        }
        return settings;
    }
    fetch(url, customSettings) {
        const _super = Object.create(null, {
            fetch: { get: () => super.fetch }
        });
        return __awaiter(this, void 0, void 0, function* () {
            window.dispatchEvent(new CustomEvent(JSONAPIFetchEvent.Before, {
                detail: {
                    url,
                    settings: customSettings
                }
            }));
            return _super.fetch.call(this, url, customSettings)
                .then((response) => {
                window.dispatchEvent(new CustomEvent(JSONAPIFetchEvent.After, {
                    detail: {
                        url,
                        settings: customSettings,
                        response
                    }
                }));
                return response;
            })
                .catch((e) => {
                var _a, _b, _c;
                if (e.response && isAuthError(e.response.status)) {
                    window.dispatchEvent(new CustomEvent(JSONAPIFetchEvent.AuthError, {
                        detail: {
                            url,
                            settings: customSettings
                        }
                    }));
                }
                else {
                    window.dispatchEvent(new CustomEvent(JSONAPIFetchEvent.Error, {
                        detail: {
                            url,
                            settings: customSettings,
                            response: e.response,
                            error: e,
                            message: ((_c = (_b = (_a = e.data) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.detail) ||
                                e.message ||
                                'API response contains an error.'
                        }
                    }));
                }
                throw e;
            });
        });
    }
}
