import { useState } from 'react'
import { useCacheQuery, queries } from '@exivity/data-layer'

import { SubConfiguration } from './'
import { useSaveConfiguration } from './useSaveConfiguration'

const DEFAULT_SAML_CONFIG = {
  id: 'saml' as string,
  type: 'configuration' as const,
  attributes: {
    SAML_ENTITY_ID: null,
    SAML_SSO_ENDPOINT: null,
    SAML_SLO_ENDPOINT: null,
    SAML_X509_CERTIFICATE: null,
    SAML_ADVANCED: null,
    SAML_DEFAULT_USERGROUP_ID: null,
    SAML_ATTRIBUTE_DISPLAY_NAME: null,
    SAML_ATTRIBUTE_EMAIL: null,
    SAML_ATTRIBUTE_USERNAME: null,
    SAML_ATTRIBUTE_USERGROUP: null,
    SAML_ACCOUNT_PROVISIONING: null
  }
}

export type SAMLConfigAttributes = (typeof DEFAULT_SAML_CONFIG)['attributes']

export type SAMLConfiguration = SubConfiguration<keyof SAMLConfigAttributes>

export function useSAMLConfiguration() {
  const [samlConfig, updateSamlConfig] =
    useState<SAMLConfiguration>(DEFAULT_SAML_CONFIG)
  const saveConfig = useSaveConfiguration()

  useCacheQuery(queries.find('configuration', 'saml'), (samlConfig) =>
    samlConfig ? updateSamlConfig(samlConfig) : DEFAULT_SAML_CONFIG
  )

  return {
    samlConfig,
    updateSamlConfig,
    saveSamlConfig: () => {
      if (samlConfig) {
        saveConfig(samlConfig)
      }
    }
  }
}
