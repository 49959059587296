var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from 'styled-components';
import { Block } from '../Block';
import { themePriority } from '../Theme';
export const Overlay = styled(Block) `
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: ${({ centerContent }) => (centerContent ? 'center' : 'stretch')};
  ${(_a) => {
    var { inLocalArea } = _a, rest = __rest(_a, ["inLocalArea"]);
    return inLocalArea
        ? `
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: hsla(0, 0%, 100%, .8);
      z-index: ${themePriority(2)(rest)}
    `
        : `
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: ${themePriority(5)(rest)}
    `;
}}
`;
Overlay.LocalArea = styled(Block) `
  position: relative;
`;
