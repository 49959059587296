import { Insights } from './Insights';
import { Detail } from './Detail';
import { ToolbarDetail } from './ToolbarDetail';
import { MasterDetail } from './MasterDetail';
import { MasterMasterDetail } from './MasterMasterDetail';
import { DetailAccessory, Title } from './DetailAccessory';
export const Layout = {
    Insights,
    Detail,
    ToolbarDetail,
    MasterDetail,
    MasterMasterDetail,
    DetailAccessory,
    Title
};
