import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { fromTheme, themeColor, themeSpace } from '../Theme';
import { Icon } from '../Icon';
import { Label } from '../Label';
export const StyledHeader = styled.div `
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${themeColor('lightGray')};
  height: 60px;
  align-items: center;
  padding: 0 ${themeSpace(2)};
  border: 2px solid ${themeColor('lightGray')};
  border-radius: 3px;
`;
export const Header = ({ children }) => {
    const { toggleCollapsed } = CollapsibleComponent.useCollapsible();
    return _jsx(StyledHeader, Object.assign({ onClick: toggleCollapsed }, { children: children }));
};
export const Actions = styled.div.attrs({
    onClick: (e) => e.stopPropagation()
}) `
  display: flex;
  flex-direction: row;
  margin: 0 ${themeSpace(1)};

  align-items: center;

  ${Icon} {
    color: black;
  }

  > *:not(:last-child) {
    margin: 0 10px;
  }
`;
const StyledContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeSpace(2)};
  padding: ${fromTheme((theme) => theme.global.baseSpacing)}em;
  border: 2px solid ${themeColor('lightGray')};
  margin-top: -2px;
`;
export const Content = ({ children }) => (_jsx(CollapsibleComponent.Content, { children: _jsx(StyledContent, { children: children }) }));
export const Title = ({ children, subTitle }) => (_jsxs(Label.Group, { children: [_jsx(Label, { children: children }), subTitle && _jsx(Label.Sub, { children: subTitle })] }));
export const Join = styled.div `
  display: flex;
  align-items: center;
  ${({ gap }) => css `
      gap: ${gap}px;
    `}
`;
