import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useVirtualizer } from '@tanstack/react-virtual';
import AutoSizer from 'react-virtualized-auto-sizer';
import { themeGlobal } from '../Theme';
function useInvokeRenderOnLayout() {
    const [hasRendered, setHasRendered] = useState(false);
    useEffect(() => {
        setHasRendered(true);
    }, []);
    return hasRendered;
}
const StyledList = styled.div `
  font-size: 14px;
  line-height: ${themeGlobal('lineHeight')};
  min-height: 300px;
  height: 100%;
  width: 100%;
`;
const style = { height: '100%', width: '100%', flexGrow: 1 };
function DynamicList({ itemData, itemCount, estimateSize, keyAccessor, containerRef, children }) {
    const parentRef = React.useRef(null);
    // We want to trigger a render once the parent has rendered because 
    // we need to know the height of the parent for useVirtualizer to work
    const hasRendered = useInvokeRenderOnLayout();
    const virtualizer = useVirtualizer({
        count: hasRendered ? itemCount : 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => estimateSize
    });
    const items = virtualizer.getVirtualItems();
    return (_jsx(StyledList, Object.assign({ ref: containerRef, className: "ex-list__container" }, { children: _jsx(AutoSizer, Object.assign({ style: style }, { children: ({ height: autoHeight }) => {
                var _a, _b;
                return (_jsx("div", Object.assign({ ref: parentRef, style: {
                        height: autoHeight,
                        overflowY: 'auto',
                        contain: 'strict'
                    } }, { children: _jsx("div", Object.assign({ style: {
                            height: virtualizer.getTotalSize(),
                            width: '100%',
                            position: 'relative'
                        } }, { children: _jsx("div", Object.assign({ style: {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${(_b = (_a = items[0]) === null || _a === void 0 ? void 0 : _a.start) !== null && _b !== void 0 ? _b : 0}px)`,
                            } }, { children: items.map((virtualRow) => {
                                return (_jsx("div", Object.assign({ "data-index": virtualRow.index, ref: virtualizer.measureElement }, { children: children({
                                        index: virtualRow.index,
                                        data: itemData
                                    }) }), `${keyAccessor(virtualRow.index)}`));
                            }) })) })) })));
            } })) })));
}
export { DynamicList };
