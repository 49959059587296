import styled from 'styled-components';
import { themeSpace } from '../Theme';
const Left = styled.div `
  margin-right: calc(${themeSpace(1)} / 2);

  > *:not(:last-child) {
    margin-right: ${themeSpace(1)};
  }
`;
const Right = styled.div `
  margin-left: calc(${themeSpace(1)} / 2);

  > *:not(:first-child) {
    margin-left: ${themeSpace(1)};
  }
`;
const _Toolbar = styled.div `
  margin-bottom: ${themeSpace(1)};

  display: flex;
  direction: row;
  justify-content: space-between;

  ${Left}, ${Right} {
    display: flex;
    direction: row;

    align-items: center;
  }
`;
export const Toolbar = _Toolbar;
Toolbar.Left = Left;
Toolbar.Right = Right;
