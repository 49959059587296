import styled, { css } from 'styled-components';
import { Block } from '../Block';
import { GridItem } from './Item';
export const Grid = styled(Block) `
  display: grid;
  ${(props) => props.template &&
    css `
      grid-template: ${props.template};
    `}
  ${(props) => props.gap &&
    css `
      grid-gap: ${props.gap};
    `}
`;
Grid.Item = GridItem;
Grid.displayName = 'Grid';
