import { MemorySource, MemoryCache } from '@orbit/memory';
import { schema } from '../schema/JSONAPISchema';
class Cache extends MemoryCache {
    constructor() {
        super(...arguments);
        this.disabledProcessors = [];
        this.tempEmit = null;
    }
    set processors(value) {
        this._processors = value;
    }
    get processors() {
        return this._processors;
    }
    preLargeSync() {
        if (!this.disabledProcessors.length) {
            this.disabledProcessors = this._processors;
            this._processors = [];
        }
        if (!this.tempEmit) {
            this.tempEmit = this.emit;
            this.emit = () => { };
        }
    }
    postLargeSync() {
        this._processors = this.disabledProcessors;
        this.disabledProcessors = [];
        this.emit = this.tempEmit;
        this.tempEmit = null;
        this.emit('reset');
    }
}
const memory = new MemorySource({
    name: 'memory',
    schema,
    autoValidate: false,
    cacheSettings: {
        autoValidate: false
    },
    cacheClass: Cache,
    defaultQueryOptions: {
        parallelRequests: true
    }
});
export { memory };
