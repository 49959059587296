import { useEffect } from 'react'
import { useRoute, getTitleBreadcrumb } from '@exivity/routing'
import { useSystemConfiguration } from '@exivity/data-layer'

export function usePageTitle() {
  const appName = useSystemConfiguration().attributes.APP_NAME
  const pageTitle = getTitleBreadcrumb(useRoute().activeRoutes)

  useEffect(() => {
    document.title = appName ? pageTitle + ' | ' + appName : pageTitle
  }, [appName, pageTitle])

  return pageTitle
}
