import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'
import { MdCloud } from 'react-icons/md'

import { featureFlags } from '../application/config/features'

const DataPipelines = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.extractors' */
      '../domains/datasources/pages/DataPipelines'
    )
)

const Extractors = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.extractors' */
      '../domains/datasources/pages/Extractors'
    )
)

const Transformers = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.transformers' */
      '../domains/datasources/pages/Transformers'
    )
)

const Datasets = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.datasets' */ '../domains/dataset/pages/Datasets'
    )
)

const Lookups = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.lookups' */ '../domains/datasources/pages/LookUps'
    )
)

const MetadataDefinitions = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.metadatadefinitions' */ '../domains/metadata/pages/Overview'
    )
)

const ReportDefinitions = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.reportDefinitions' */
      '../domains/report-definitions/pages/ReportDefinitions'
    )
)

const Workflows = lazy(
  () =>
    import(
      /* webpackChunkName: 'pipelines.workflows2' */ '../domains/workflows/pages/Workflows'
    )
)

const Environments = lazy(
  () =>
    import(
      /* webpackChunkName: 'administration.variables' */
      '../domains/datasources/pages/Environments'
    )
)

export const datapipelines = {
  path: '/data-pipelines',
  meta: {
    title: () => translate('Data pipelines'),
    icon: MdCloud
  },
  component: DataPipelines,
  routes: {
    extractors: {
      path: '/extractors/:extractorId?/:tab?',
      requiresPermissions: [UserGroupPermission.ManageDataSources],
      meta: {
        title: () => translate('Extractors'),
        documentation:
          'https://docs.exivity.com/Advanced/Data%20pipelines/Extractors'
      },
      component: Extractors
    },
    transformers: {
      path: '/transformers/:transformerId?',
      requiresPermissions: [UserGroupPermission.ManageDataSources],
      meta: {
        title: () => translate('Transformers'),
        documentation:
          'https://docs.exivity.com/Advanced/Data%20pipelines/Transformers'
      },
      component: Transformers
    },
    datasets: {
      path: '/datasets/:id?',
      requiresPermissions: [UserGroupPermission.ManageDatasets],
      meta: {
        title: () => translate('Datasets'),
        documentation:
          'https://docs.exivity.com/Advanced/Data%20pipelines/Datasets',
        feature: featureFlags.dataset
      },
      component: Datasets
    },
    lookups: {
      path: '/lookups/:name?',
      requiresPermissions: [UserGroupPermission.ManageFiles],
      meta: {
        title: () => translate('Lookups'),
        documentation:
          'https://docs.exivity.com/Advanced/Data%20pipelines/Lookups'
      },
      component: Lookups
    },
    metadata: {
      path: '/metadata/:id?',
      requiresPermissions: [UserGroupPermission.ManageMetadataDefinitions],
      meta: {
        title: () => translate('Metadata'),
        documentation:
          'https://docs.exivity.com/Advanced/Data%20pipelines/Metadata'
      },
      component: MetadataDefinitions
    },
    reportDefinitions: {
      path: '/reports/:id?',
      requiresPermissions: [UserGroupPermission.ManageReports],
      meta: {
        title: () => translate('Reports'),
        documentation:
          'https://docs.exivity.com/Advanced/Data%20pipelines/Reports'
      },
      component: ReportDefinitions
    },
    workflows: {
      path: '/workflows/:id?',
      requiresPermissions: [UserGroupPermission.ManageWorkflows],
      meta: {
        title: () => translate('Workflows'),
        documentation:
          'https://docs.exivity.com/Advanced/Data%20pipelines/Workflows'
      },
      component: Workflows
    },
    environments: {
      path: '/environments/:id?',
      requiresPermissions: [UserGroupPermission.ManageSettings],
      meta: {
        title: () => translate('Environments'),
        documentation:
          'https://docs.exivity.com/the%20basics/configuration/global%20variables/#creating-a-default-environment'
      },
      component: Environments
    }
  }
}
