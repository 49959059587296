import styled from 'styled-components';
import { themeColor, themeFontFamily, themeFontSize, themeSpace } from '../Theme';
import { StyledButton } from '../Button/Button';
export const ModalWrapper = styled.div `
  font-family: ${themeFontFamily};
  font-size: ${themeFontSize(2)};
  color: ${themeColor('darkGray')};
  position: absolute;
  background-color: ${themeColor('white')};
  width: 600px;
  left: calc((100vw - 600px) / 2);
  top: 50px;
`;
export const Header = styled.div `
  padding: ${themeSpace(2)} ${themeSpace(3)};
`;
export const Body = styled.div `
  padding: 30px ${themeSpace(3)};
  max-height: 400px;
  border-top: solid 1px ${themeColor('lightGray')};
  border-bottom: solid 1px ${themeColor('lightGray')};
  overflow-y: auto;
`;
export const Footer = styled.div `
  display: flex;
  justify-content: flex-end;
  padding: ${themeSpace(2)} ${themeSpace(3)};
  ${StyledButton} {
    margin-left: 20px;
  }
`;
