import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { startOfQuarter, setQuarter, format, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { CalendarMode } from '../CalendarMode';
import { StyledTimeUnits, TimeUnit } from '../shared';
export const StyledQuarters = styled(StyledTimeUnits) `
  li {
    width: calc(100% - 5px);
  }
`;
export function Quarters({ value: [start, end], browserDate, onHoverItem, onEndHoverItem, offset = 0, onChange, min, max }) {
    const normalizedValue = useMemo(() => {
        return [
            offset && start ? subMonths(startOfMonth(start), offset) : start,
            offset && end ? subMonths(endOfMonth(end), offset) : end
        ];
    }, [start, end]);
    function renderQuarter(_, i) {
        const quarter = i + 1;
        const thisQuarter = startOfQuarter(setQuarter(browserDate, quarter));
        const quarterWithOffset = addMonths(thisQuarter, offset);
        return (_jsx("span", Object.assign({ "data-testid": "calendar-quarters-quarter" }, { children: _jsx(TimeUnit, Object.assign({ date: thisQuarter, mode: CalendarMode.Quarters, value: normalizedValue, min: min, max: max, onHover: () => onHoverItem === null || onHoverItem === void 0 ? void 0 : onHoverItem(quarterWithOffset), onStopHovering: () => onEndHoverItem === null || onEndHoverItem === void 0 ? void 0 : onEndHoverItem(quarterWithOffset), onChange: (_, ctrlPressed) => onChange === null || onChange === void 0 ? void 0 : onChange(quarterWithOffset, ctrlPressed) }, { children: format(thisQuarter, 'Qo') })) }), i));
    }
    return (_jsx(StyledQuarters, Object.assign({ "data-testid": "calendar-quarters" }, { children: new Array(4).fill(null).map(renderQuarter) })));
}
