var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Children, cloneElement } from 'react';
import { usePurposeProp, useSizeProp } from '../utils';
import { insertIf } from '../utils/insertIf';
import { JoinChildren } from '../JoinChildren';
export function InputGroup(_a) {
    var { children, gap } = _a, rest = __rest(_a, ["children", "gap"]);
    const purposeProp = usePurposeProp(rest);
    const sizeProp = useSizeProp(rest);
    return (_jsx(JoinChildren, Object.assign({ gap: gap }, { children: Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                throw new Error('Only React Element(s) can be passed to <InputGroup />');
            }
            return cloneElement(child, Object.assign(Object.assign(Object.assign({}, rest), { [purposeProp]: true }), insertIf(!!sizeProp, { [sizeProp]: true })));
        }) })));
}
