import { append, curry, flip, reduce } from 'ramda';
const removeRecordDoc = (record) => ({
    op: 'remove',
    ref: {
        type: record.type,
        id: record.id
    }
});
export const removeRecord = curry((record, atomicDocs) => append(removeRecordDoc(record), atomicDocs));
export const removeRecords = curry((records, atomicDocs) => 
// @ts-ignore
reduce(flip(removeRecord), atomicDocs, records));
