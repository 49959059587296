import { createAsyncThunk } from '../../../../../store/utils'
import {
  Report,
  runReport
} from '../../../../../API/requests/reports/runReport'
import { reportSelectors } from '../../'
import { defaultHeaders } from '../../../../../API/headers'
import { fetchIncludedResources } from '../../../../../API/requests/reports/fetchIncludedResources'
import { authSelectors } from '../../../../auth/state'

const DIMENSIONS = {
  accounts: 'accounts',
  services: 'services',
  instances: 'services,instances',
  summaryServices: 'accounts,services',
  summaryInstances: 'accounts,services,instances'
}

/**
 * @description Retrieves a usage report for a selected time frame
 */
export const fetchReportDataAndIncludedResources = createAsyncThunk<
  any,
  Report
>(
  'reports/data/fetch',
  (report, { getState, dispatch, signal }) => {
    const state = getState()
    const headers = defaultHeaders.auth()
    const endpoint = (endpoint: string) =>
      authSelectors.makeUrl(endpoint)(state)

    return Promise.all([
      fetchIncludedResources(
        endpoint(`/reports/${state.reports.filters.report}/resources`),
        { signal, headers },
        { state, dispatch, signal }
      ),
      runReport(
        report,
        DIMENSIONS[report],
        endpoint(`/reports/${state.reports.filters.report}/run`),
        { signal, headers },
        { state, dispatch, signal }
      )
    ])
  },
  {
    condition: (report: Report, { getState }: any) => {
      const state = getState()
      return (
        !reportSelectors.isBusy(report)(state) &&
        typeof state.reports.filters.report === 'string'
      )
    }
  }
)
