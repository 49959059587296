var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { compose } from 'ramda';
import styled from 'styled-components';
import { globalFont, toRgbCss } from '../utils';
import { colorFromPurpose, fromTheme, matchThemeProp } from '../Theme';
import { Input } from '../Input';
const brandRGB = compose(toRgbCss, colorFromPurpose('brand', 500));
export const Heading = styled.h2 `
  font-size: ${fromTheme((theme) => theme.global.sizes.huge)}em;
  color: ${brandRGB};
  margin-bottom: 10px;
`;
export const SubHeading = styled.h3 `
  ${globalFont};
`;
export const EditableHeading = styled((_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (_jsx(Heading, Object.assign({ as: Input, huge: true, value: children }, rest)));
}) `
  input {
    padding-top: 5px;
    padding-bottom: 5px;
    color: ${matchThemeProp((theme) => theme.global.purposes, {
    modifier: toRgbCss
})};
  }
`;
export const HeadingGroup = styled.hgroup `
  ${Heading} {
    margin: 0;
  }

  ${SubHeading} {
    margin: 0;
    margin-top: 5px;
  }
`;
