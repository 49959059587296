import { filter, map, prop } from '@exivity/fp';
const filterOperations = (...ops) => {
    function operationGuard(arg) {
        return ops.includes(arg.op);
    }
    return (operations) => filter(operationGuard, operations);
};
const filterResources = (...resources) => {
    function resourceGuard(arg) {
        return resources.includes(arg.record.type);
    }
    return (operations) => filter(resourceGuard, operations);
};
const getOperationsProp = (transform) => Array.isArray(transform.operations)
    ? transform.operations
    : [transform.operations];
const getRecordProp = map(prop('record'));
export const transformSelectors = {
    filterOperations,
    filterResources,
    getOperationsProp,
    getRecordProp
};
