export function createListStrategy(data, { prepareData, renderItem }) {
    const preparedData = prepareData(data);
    return {
        itemCount: preparedData.length,
        renderItem: (index) => {
            const item = preparedData[index];
            return renderItem({ items: preparedData, item, index });
        }
    };
}
