"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tree = void 0;
const atIndexPath_1 = require("./atIndexPath");
const differenceWith_1 = require("./differenceWith");
const flatten_1 = require("./flatten");
const fromRoot_1 = require("./fromRoot");
const isLeaf_1 = require("./isLeaf");
const map_1 = require("./map");
const mapAccum_1 = require("./mapAccum");
const of_1 = require("./of");
const reduce_1 = require("./reduce");
const removeAtPath_1 = require("./removeAtPath");
const updateAtPath_1 = require("./updateAtPath");
const updateChildren_1 = require("./updateChildren");
const updateData_1 = require("./updateData");
const viewChildren_1 = require("./viewChildren");
const viewData_1 = require("./viewData");
exports.Tree = {
    atIndexPath: atIndexPath_1.atIndexPath,
    differenceWith: differenceWith_1.differenceWith,
    differenceWithNode: differenceWith_1.differenceWithNode,
    flatten: flatten_1.flatten,
    fromRoot: fromRoot_1.fromRoot,
    isLeaf: isLeaf_1.isLeaf,
    map: map_1.map,
    mapAccum: mapAccum_1.mapAccum,
    of: of_1.of,
    reduce: reduce_1.reduce,
    removeAtPath: removeAtPath_1.removeAtPath,
    updateAtPath: updateAtPath_1.updateAtPath,
    updateChildren: updateChildren_1.updateChildren,
    updateData: updateData_1.updateData,
    viewChildren: viewChildren_1.viewChildren,
    viewData: viewData_1.viewData
};
