import { useParams } from '@exivity/routing'
import { useState } from 'react'
import {
  empty,
  queries,
  Resources,
  useCacheQuery,
  withAttribute
} from '@exivity/data-layer'
import { pipe } from 'ramda'

export type VariableMeta = {
  duplicate: boolean
  override: boolean
  inherited: boolean
  inheritedName: boolean
  inheritedEncrypted: boolean
  data: Variable
}

export const createVariable = (name = '', value = '', encrypted = false) =>
  pipe(
    empty('variable'),
    withAttribute('name', name || ''),
    withAttribute('value', value || ''),
    withAttribute('encrypted', encrypted)
  )()

const queryDefaultVariables = (id: string | null) =>
  queries.relationshipOf('environment', id, 'variables')

export type Variable = ReturnType<typeof createVariable> | Resources['variable']

export function useVariables() {
  const [params] = useParams()
  const [variables, updateVariables] = useState<Variable[]>([])

  const defaultEnvironment = useCacheQuery(
    queries
      .findAll('environment')
      .filterByAttribute('default_flag', 'equal', true)
  )

  const [newVariables, updateNewVariables] = useState<Variable[]>(
    defaultEnvironment[0]
      ? []
      : [createVariable()]
    )

  const inheritedVariables = useCacheQuery(
    queryDefaultVariables(defaultEnvironment[0]?.id)
  )

  const originalVariables = useCacheQuery(
    queries.relationshipOf('environment', params.id, 'variables'),
    (QR) => {
      updateVariables(QR)
      updateNewVariables(
        defaultEnvironment[0]
        ? []
        : [createVariable()]
      )
    }
  )

  return {
    defaultEnvironment,
    variables,
    inheritedVariables,
    updateVariables,
    newVariables,
    updateNewVariables,
    originalVariables
  }
}
