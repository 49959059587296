import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePalette } from '../Theme';
const SVG = styled.svg `
  .a {
    fill: ${({ color }) => (color ? color : themePalette('neutral', 600))};
  }
`;
export const Extract = ({ width = 24, height = 24, color }) => (
/* eslint-disable-next-line max-len */
_jsxs(SVG, Object.assign({ color: color, xmlns: "http://www.w3.org/2000/svg", width: `${width}px`, height: `${height}px`, viewBox: "0 0 48 48" }, { children: [_jsx("path", { className: "a", d: "M10,11a4,4,0,1,1,4-4A4,4,0,0,1,10,11Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,10,5Z" }), _jsx("path", { className: "a", d: "M6,28a4,4,0,1,1,4-4A4,4,0,0,1,6,28Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,6,22Z" }), _jsx("path", { className: "a", d: "M10,45a4,4,0,1,1,4-4A4,4,0,0,1,10,45Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,10,39Z" }), _jsx("path", { className: "a", d: "M21,16.71c-4.09,0-5.41-1.94-6.8-4-.31-.45-.63-.92-1-1.4l1.58-1.21c.4.51.74,1,1.06,1.49C17.17,13.54,18,14.71,21,14.71Z" }), _jsx("path", { className: "a", d: "M14.79,37.32l-1.58-1.21c.36-.49.68-1,1-1.4,1.39-2.06,2.71-4,6.8-4v2c-3,0-3.83,1.18-5.15,3.12C15.53,36.31,15.19,36.81,14.79,37.32Z" }), _jsx("rect", { className: "a", x: "12", y: "22.71", width: "9", height: "2" }), _jsx("path", { className: "a", d: "M46,13.29C45.54,10.24,40.76,8,34.5,8s-11,2.24-11.46,5.29h0V34.71h0c.42,3,5.2,5.29,11.46,5.29s11-2.24,11.46-5.29h0V13.29ZM34.5,38c-5.44,0-9.5-2-9.5-3.71V30.73c2,1.48,5.46,2.41,9.5,2.41S42,32.21,44,30.73v3.56C44,36,39.94,38,34.5,38Zm0-6.86c-5.44,0-9.5-2-9.5-3.71V23.87c2,1.48,5.46,2.42,9.5,2.42S42,25.35,44,23.87v3.56C44,29.18,39.94,31.14,34.5,31.14Zm0-6.85c-5.44,0-9.5-2-9.5-3.72V17c2,1.48,5.46,2.42,9.5,2.42S42,18.49,44,17v3.56C44,22.32,39.94,24.29,34.5,24.29Zm0-6.86c-5.44,0-9.5-2-9.5-3.72S29.06,10,34.5,10,44,12,44,13.71,39.94,17.43,34.5,17.43Z" })] })));
export const Transform = ({ width = 24, height = 24, color }) => (
/* eslint-disable-next-line max-len */
_jsxs(SVG, Object.assign({ color: color, xmlns: "http://www.w3.org/2000/svg", width: `${width}px`, height: `${height}px`, viewBox: "0 0 48 48" }, { children: [_jsx("path", { className: "a", d: "M25.47,18.12c-.42-3-5.2-5.29-11.46-5.29S3,15.08,2.55,18.12h0V39.54h0C3,42.59,7.74,44.83,14,44.83s11-2.24,11.46-5.29h0V18.12ZM14,42.83c-5.44,0-9.5-2-9.5-3.71V35.56C6.54,37,10,38,14,38s7.46-.93,9.5-2.41v3.56C23.51,40.87,19.44,42.83,14,42.83ZM14,36c-5.44,0-9.5-2-9.5-3.71V28.7c2,1.48,5.46,2.42,9.5,2.42s7.46-.94,9.5-2.42v3.56C23.51,34,19.44,36,14,36Zm0-6.85c-5.44,0-9.5-2-9.5-3.72V21.85c2,1.47,5.46,2.41,9.5,2.41s7.46-.94,9.5-2.41V25.4C23.51,27.16,19.44,29.12,14,29.12Zm0-6.86c-5.44,0-9.5-2-9.5-3.72s4.06-3.71,9.5-3.71,9.5,2,9.5,3.71S19.44,22.26,14,22.26Z" }), _jsx("path", { className: "a", d: "M45.31,12.62l.18-1.22-2.34-1.16c-.08-.42-.12-.59-.2-1l1.7-2L44,6.2l-2.54.61-.8-.64,0-2.61-1.17-.39L38,5.27l-1,0-1.66-2-1.15.46.16,2.6c-.31.29-.45.4-.76.68L31,6.54l-.59,1.09,1.8,1.89c-.06.42-.09.6-.15,1l-2.28,1.28L30,13l2.6.29c.22.36.31.51.54.87l-.92,2.45,1,.77L35.35,16l1,.33.87,2.46,1.24,0,.73-2.51,1-.38,2.25,1.33.92-.82-1-2.39.49-.9ZM34.15,11.27a3.4,3.4,0,0,1,3.06-3.74,2.06,2.06,0,0,1,.35,0,3.42,3.42,0,0,1,.33,6.82,2.06,2.06,0,0,1-.35,0A3.39,3.39,0,0,1,34.15,11.27Z" }), _jsx("path", { className: "a", d: "M38.51,25.65l-1.84-1.16L38.24,23l-.37-.76-2.15.3L36,20.37,35.19,20l-1.46,1.61-1.21-1.81-.81.23-.08,2.17-2.08-.61-.48.7L30.41,24l-2,.87c0,.43,0,.41.08.84l2.13.45-1,1.95.61.59,1.92-1,.51,2.11.85.07.81-2,1.75,1.29.69-.5-.67-2.06,2.17-.14Zm-5,1.17a2,2,0,1,1,2-2A2,2,0,0,1,33.46,26.82Z" })] })));
export const Report = ({ width = 24, height = 24, color }) => (
/* eslint-disable-next-line max-len */
_jsxs(SVG, Object.assign({ color: color, xmlns: "http://www.w3.org/2000/svg", width: `${width}px`, height: `${height}px`, viewBox: "0 0 48 48" }, { children: [_jsx("path", { className: "a", d: "M10,14,3.33,19.55V44h21V14H9.67m.66,2.3V20H5.9Zm12,25.7h-17V22h7V16h10Z" }), _jsx("rect", { className: "a", x: "8.33", y: "25.02", width: "11", height: "2" }), _jsx("rect", { className: "a", x: "8.33", y: "29.02", width: "11", height: "2" }), _jsx("rect", { className: "a", x: "8.33", y: "33.02", width: "11", height: "2" }), _jsx("path", { className: "a", d: "M44.48,13.43l.19-1.22-2.35-1.16c-.08-.42-.12-.59-.2-1l1.7-2L43.17,7l-2.54.61L39.83,7l0-2.62L38.68,4,37.13,6.09l-1,0-1.66-2-1.15.46.17,2.61-.77.68-2.57-.49-.59,1.09,1.8,1.89c-.06.42-.08.6-.15,1l-2.27,1.28.24,1.21,2.6.29.54.87-.92,2.45,1,.77,2.17-1.45,1,.33.87,2.46,1.24,0L38.33,17l1-.38L41.54,18l.92-.82-1-2.39.48-.9ZM33.32,12.08a3.41,3.41,0,0,1,3.06-3.74l.35,0a3.42,3.42,0,0,1,.33,6.82,1.94,1.94,0,0,1-.34,0A3.4,3.4,0,0,1,33.32,12.08Z" }), _jsx("path", { className: "a", d: "M37.68,26.46l-1.84-1.15,1.57-1.51L37,23l-2.15.3.24-2.16-.77-.34L32.9,22.45l-1.2-1.81-.82.23L30.8,23l-2.08-.61-.48.7,1.34,1.72-2,.87c0,.43,0,.41.09.84L29.8,27l-1,1.95.6.59,1.92-1,.52,2.11.84.07.82-2L35.29,30l.68-.49-.67-2.07,2.17-.14Zm-5,1.17a2,2,0,1,1,2-2A2,2,0,0,1,32.63,27.63Z" })] })));
