import { useState, useEffect, useContext } from 'react';
import { TransientMemoryContext } from './DataLayerProvider';
import { getExpressionResult } from './queries/getExpressionResult';
import { omitSort } from './queries/getExpressionResult';
export function useTransientCache(queryBuilder) {
    const transientSource = useContext(TransientMemoryContext);
    const [result, setResult] = useState([]);
    const queryExpression = queryBuilder.toQuery();
    const stringifiedQuery = JSON.stringify(queryExpression);
    useEffect(() => {
        const liveQuery = transientSource.cache.liveQuery(omitSort(queryExpression), { debounce: true });
        setResult(getExpressionResult(queryExpression, liveQuery.query.bind(liveQuery)));
        const unsubscribe = liveQuery.subscribe((update) => {
            const result = getExpressionResult(queryExpression, update.query.bind(update));
            setResult(result);
        });
        return () => {
            unsubscribe();
        };
    }, [transientSource, stringifiedQuery]);
    return result;
}
