/**
 * Returns whether hex colour (in #FFFFFF format) is bright to tweak foreground colour.
 */
export function isBrightColor(hex) {
    const c = hex.substring(1); // Strip #
    const rgb = parseInt(c, 16); // Convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // Extract red
    const g = (rgb >> 8) & 0xff; // Extract green
    const b = (rgb >> 0) & 0xff; // Extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Per ITU-R BT.709
    return luma > 150;
}
