import { addQuarters, endOfQuarter, isSameQuarter, startOfQuarter, endOfMonth, startOfMonth, subDays } from 'date-fns';
import { orderRange } from '../../DateRange/helpers';
const updateQuarterRange = (range, action) => {
    switch (action.type) {
        case 'SELECT_START':
            return [action.date, null];
        case 'SELECT_END':
            if (!range[0])
                return range;
            if (isSameQuarter(range[0], action.date))
                return [null, null];
            return orderRange([range[0], action.date]);
        case 'RESET_RANGE':
            return [null, null];
        case 'SELECT_SINGLE_UNIT_AS_RANGE':
            return [action.date, subDays(addQuarters(action.date, 1), 1)];
    }
};
function fitPartialQuarterRange([start, end], offset) {
    if (offset === 0) {
        return [
            start ? startOfQuarter(start) : null,
            end ? endOfQuarter(end) : null
        ];
    }
    return [
        start ? startOfMonth(start) : null,
        end ? endOfMonth(end) : null
    ];
}
export const quarterRangeReducer = (range, action, options) => {
    return fitPartialQuarterRange(updateQuarterRange(range, action, options), options.offset);
};
