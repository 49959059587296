import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themeColor, themePalette, themeSpace } from '../Theme';
const StatisticLabel = styled.div `
  font-weight: bold;
  line-height: 1;
  font-size: 0.8rem;
  color: ${themePalette('neutral', 500)};
  white-space: nowrap;
`;
const StatisticValue = styled.div `
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-size: 2.1rem;
  white-space: nowrap;
`;
const Statistic = styled.div `
  align-items: center;
  width: fit-content;
`;
const StatisticsContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: ${themeSpace(2)};
  padding-bottom: ${themeSpace(1)};
  overflow-x: auto;
  overflow-y: hidden;

  ${Statistic}:first-child {
    color: ${themePalette('brand', 500)};
  }

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${themePalette('neutral', 300)};
    border-radius: 20px;

    &:hover {
      background: ${themePalette('neutral', 500)};
    }
  }

  ::-webkit-scrollbar-track-piece {
    background: ${themeColor('white')};
    box-sizing: border-box;
    border-radius: 20px;
  }
`;
export const Statistics = ({ data }) => {
    return (_jsx(StatisticsContainer, { children: data.map(({ value, label }, index) => (_jsxs(Statistic, { children: [_jsx(StatisticValue, { children: value }), _jsx(StatisticLabel, { children: label })] }, index))) }));
};
