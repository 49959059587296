import { CSVDecimalSeparator, CSVDelimiter, DateFormat, DecimalSeparator, PasswordPolicy, ReportStartMonth, SSOLoginMethod, ThousandSeparator, TokenTTL } from '../resources/resources.generated';
import { queries, useCacheQuery } from '../';
export const DEFAULT_SYSTEM_CONFIG = {
    id: 'system',
    type: 'configuration',
    attributes: {
        APP_DEBUG: false,
        APP_NAME: null,
        APP_LOGO: null,
        APP_ICON: null,
        APP_FAVICON: null,
        APP_COLOUR: '#00a8d8',
        APP_CSS: '',
        APP_DOCUMENTATION: false,
        APP_LANGUAGE: 'en',
        PUBLIC_ROOT: null,
        SSO_LOGIN_METHOD: SSOLoginMethod.LocalUserLDAP,
        // USE_LOCAL_STORAGE: false,
        ANALYTICS: false,
        ANALYTICS_EXTRA_PROPERTY: null,
        ERROR_TRACKING: false,
        BETA_FEATURES: false,
        TOKEN_TTL: TokenTTL.FourHours,
        ALLOW_PERSISTENT_TOKENS: false,
        USE_CACHE: true,
        CURRENCY: 'EUR',
        CURRENCY_FORMAT: '€',
        DECIMAL_SEPARATOR: DecimalSeparator.Dot,
        CSV_DELIMITER: CSVDelimiter.Comma,
        CSV_DECIMAL_SEPARATOR: CSVDecimalSeparator.Dot,
        THOUSAND_SEPARATOR: ThousandSeparator.Comma,
        RATE_PRECISION: 8,
        REPORT_PRECISION: 2,
        SUMMARY_PRECISION: 2,
        QUANTITY_PRECISION: 6,
        PERCENTAGE_PRECISION: 2,
        DATE_FORMAT: DateFormat.DASH_DMY,
        SUMMARY_ADDRESS: null,
        SUMMARY_IMAGE: null,
        SUMMARY_TITLE: 'Summary',
        SUMMARY_EXTRA: null,
        SUMMARY_MIN_COMMIT: 'Uplift for minimum commitment of {quantity} {label}',
        GRAPH_MAX_SERIES: 15,
        REPORT_START_MONTH: ReportStartMonth.January,
        DISCLAIMER_ENABLED: false,
        DISCLAIMER_TITLE: 'Disclaimer',
        DISCLAIMER_TEXT: '',
        DISCLAIMER_AGREE_BUTTON_TEXT: 'I agree',
        PASSWORD_POLICY: PasswordPolicy.LengthDictionary,
        ADDITIONAL_CORS_ORIGINS: null,
        MAX_LOGIN_ATTEMPTS: 5,
        LOGIN_ATTEMPTS_INTERVAL: null
        // BLOCK_LOGIN_DURATION: null
    }
};
export function useSystemConfiguration() {
    return (useCacheQuery(queries.find('configuration', 'system')) ||
        DEFAULT_SYSTEM_CONFIG);
}
