import { useDispatch } from 'react-redux'
import { useRef } from 'react'

export function useAbortableDispatch<T extends (...args: any[]) => any>(fn: T) {
  const dispatch = useDispatch()
  const abortLogin = useRef(() => {})

  return [
    (...args: Parameters<T>) => {
      abortLogin.current = dispatch(fn(...args)).abort
    },
    () => {
      abortLogin.current()
      abortLogin.current = () => {}
    }
  ] as const
}
