import { append, curry, flip, reduce } from 'ramda';
const addRecordDoc = (record) => ({
    op: 'add',
    data: record
});
const updateRecordDoc = (record) => ({
    op: 'update',
    data: record
});
export const saveRecord = curry((record, atomicDocs) => append(record.id ? updateRecordDoc(record) : addRecordDoc(record), atomicDocs));
export const saveRecords = curry((records, atomicDocs) => 
// @ts-ignore
reduce(flip(saveRecord), atomicDocs, records));
